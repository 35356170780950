import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";

import Layout from "@/layout/Layout/Layout";

import "@/assets/css/main.scss";
import { ErrorFallback } from "./components/blocks";

function App() {
  return (
    <HelmetProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Layout />
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
