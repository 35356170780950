import type { FormEvent } from "react";

import cn from "classnames";
import { useSnackbar } from "notistack";
import { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { SearchWithSuggestions, TitleWithIcon } from "@/blocks/index";
import {
  addedErrorOnField,
  checkForRussianLetters,
  getValueFromArray,
  calculateAge,
  updatedInputsData,
  isPassportExpired,
  clearValues,
} from "@/helpers/index";
import useClientOptions from "@/hooks/useClientOptions";
import useLegalFormOptions from "@/hooks/useLegalForms";
import { Button, InputList, Uploader, UploaderItemSmall } from "@/ui/index";

import styles from "./AddEditClientForm.module.scss";
import {
  individualPersonArray,
  legalPersonArray,
  radioButtons,
  soleProprietorshipArray,
  birthCertificationArray,
} from "./mockData";

const AddEditClientForm: Blocks.AddEditClientForm = ({
  isEditForm,
  client,
  senData,
  onReset,
  errors,
  isLoading,
  formId,
  error,
  inApp,
  changePosition,
  hasReset,
}) => {
  const { officeId, id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { enqueueSnackbar } = useSnackbar();
  const [age, setAge] = useState<number | null>(null);
  const [showError, setShowError] = useState(false);
  const [selectedClientByPhone, setSelectedClientByPhone] = useState<Model.Client | string>("");
  const [selectedClientByEmail, setSelectedClientByEmail] = useState<Model.Client | string>("");
  const [clientId, setClientId] = useState<number | string | undefined>();
  const [selectedOption, setSelectedOption] = useState<string>(queryParams.get("position") || "legalPerson");
  const [searchValue, setSearchValue] = useState<string | number>();
  const { clientOptions } = useClientOptions(Number(officeId) || Number(id));
  const { legalFormOptions } = useLegalFormOptions(searchValue);
  const [selectedLegalForm, setSelectedLegalForm] = useState<Option | undefined>();
  const [linkedPerson, setLinkedPerson] = useState<Model.Client | null>();
  const [legalRepresentativeId, setLegalRepresentativeId] = useState<number | string | null>(null);
  const [editLegalRepresentive, setEditLegalRepresentive] = useState<Model.Client | null>(null);
  const [personPositions, setPersonPositions] = useState<InputData[]>(radioButtons);
  const [legalPerson, setLegalPerson] = useState<InputData[]>(clearValues(legalPersonArray));
  const [soleProprietorship, setSoleProprietorship] = useState<InputData[]>(clearValues(soleProprietorshipArray));
  const [individualPerson, setIndividualPerson] = useState<InputData[]>(individualPersonArray);
  const [birthCertification, setBirthCertification] = useState<Model.Scan | undefined>(client?.birthCertificate);
  const [birthCertificationInputs, setBirthCertificationInputs] = useState<InputData[]>(
    clearValues(birthCertificationArray),
  );
  const [files, setFiles] = useState<File[]>([]);
  const [isExpired, setIsExpired] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const inputMap: Record<string, { state: InputData[]; updateFunc: React.Dispatch<React.SetStateAction<InputData[]>> }> = {
    personPositions: {
      state: personPositions,
      updateFunc: setPersonPositions,
    },
    legalPerson: {
      state: legalPerson,
      updateFunc: setLegalPerson,
    },
    soleProprietorship: {
      state: soleProprietorship,
      updateFunc: setSoleProprietorship,
    },
    individualPerson: {
      state: individualPerson,
      updateFunc: setIndividualPerson,
    },
  };
  const { isError, newInputs } = checkForRussianLetters(
    inputMap[selectedOption] ? inputMap[selectedOption].state : legalPerson,
  );

  const selectedUpdateByField = (filed: Model.Client) => {
    const { state, updateFunc } = inputMap[selectedOption];

    if (filed !== null && typeof filed !== "string") {
      const updatedState = state.map((el) => {
        let newItem = el;

        if (filed[el.field as keyof Model.Client]) {
          let newValue = filed[el.field as keyof Model.Client];
          if (el.field === "linkedPerson") {
            setLinkedPerson(newValue as Model.Client);
            setEditLegalRepresentive(newValue as Model.Client);
            setLegalRepresentativeId(Number((newValue as Model.Client).id));
          }
          if (el.field === "dateOfBirth") {
            if (typeof newValue === "string") {
              const clientAge = calculateAge(newValue);
              setAge(clientAge);

              if (
                clientAge !== null &&
                clientAge < 18 &&
                filed.legalRepresentative &&
                typeof filed.legalRepresentative !== "number"
              ) {
                setEditLegalRepresentive(filed.legalRepresentative as Model.Client); // @ts-ignore
                setLegalRepresentativeId(filed.legalRepresentative.id);
                setBirthCertification(filed?.birthCertificate);
              }
            }
          }

          if (el.field === "legalForm") {
            // @ts-ignore
            newValue = newValue.id as number;
          } // @ts-ignore
          newItem = { ...el, value: newValue };
        }
        return newItem;
      });
      const certificateData = birthCertificationInputs.map((el) => {
        let newItem = el;
        if (filed[el.field as keyof Model.Client]) {
          // @ts-ignore
          newItem = { ...el, value: filed[el.field as keyof Model.Client] };
        }
        return newItem;
      });
      updateFunc(updatedState as InputData[]);
      setBirthCertificationInputs(certificateData);
    }
  };

  const errorOnFocus = () => {
    if (formRef.current !== null) {
      const elements = formRef.current.querySelectorAll("[data-is-error='true']");

      if (elements.length > 0) {
        // elements[0].scrollIntoView();
      }
    }
  };

  useEffect(() => {
    if (linkedPerson && selectedOption === "soleProprietorship") {
      const inputs = soleProprietorship.map((item) => {
        return item.field === "inn" && linkedPerson.inn ? { ...item, value: String(linkedPerson.inn) } : item;
      });
      setSoleProprietorship(inputs);
    }
  }, [linkedPerson]);

  useEffect(() => {
    const inputs = inputMap[selectedOption] ? inputMap[selectedOption].state : legalPerson;
    setIsExpired(Boolean(isPassportExpired(inputs)));

    const errors = inputs.filter((item) => item.invalid);

    if (errors.length > 0) {
      setTimeout(() => {
        errorOnFocus();
      }, 50);
    }
  }, [inputMap, selectedOption]);

  useEffect(() => {
    if (isExpired) {
      enqueueSnackbar("Паспорт недействителен", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    }

    const newList = [...individualPerson].map((el) => {
      let item = el;
      if (el.field === "passportIssueDate") {
        item = {
          ...el,
          invalid: isExpired,
          helperText: isExpired ? "Паспорт недействителен" : "",
        };
      }
      return item;
    });

    setIndividualPerson(newList);
  }, [isExpired]);

  useEffect(() => {
    setBirthCertification(client?.birthCertificate);

    if (isEditForm && client) {
      const currentPosition = client.personPosition?.position;
      setSelectedOption(currentPosition);
      if (currentPosition === "individualPerson") {
        if (client.dateOfBirth) {
          setAge(calculateAge(client.dateOfBirth));
        }
        if (client.legalRepresentative && typeof client.legalRepresentative !== "number") {
          setEditLegalRepresentive(client.legalRepresentative);
          setLegalRepresentativeId(Number(client.legalRepresentative.id));
        }
      }

      if (currentPosition === "legalPerson" && client.legalFormName) {
        setSelectedLegalForm({ name: String(client.legalFormName), value: Number(client.legalForm) });
      }

      if (currentPosition === "soleProprietorship" && client.linkedPerson && typeof client.linkedPerson !== "number") {
        //@ts-ignore
        setEditLegalRepresentive(client.linkedPerson);
      }

      setPersonPositions(
        personPositions.map((item) => ({
          ...item,
          options: item.options?.map((option) => ({
            ...option,
            value: option.id === currentPosition ? 1 : 0,
            disabled: client.id ? option.id !== currentPosition : false,
          })),
        })),
      );

      if (currentPosition in inputMap) {
        const { state, updateFunc } = inputMap[currentPosition];
        if (currentPosition === "legalPerson") {
          setLegalPerson(updatedInputsData(state, client));
        } else {
          updateFunc(updatedInputsData(state, client));
          setBirthCertificationInputs(updatedInputsData(birthCertificationInputs, client));
        }
      }
    }
  }, [isEditForm, client]);

  useEffect(() => {
    if (errors) {
      if (errors.length > 0) {
        if (selectedOption in inputMap) {
          const { state, updateFunc } = inputMap[selectedOption];
          const nonField = errors?.find((error) => error.attr === "nonFieldErrors");

          if (nonField) {
            enqueueSnackbar(nonField.detail, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }

          updateFunc(addedErrorOnField(errors, state));
          setBirthCertificationInputs(addedErrorOnField(errors, birthCertificationInputs));
        }
      } else {
        const { state, updateFunc } = inputMap[selectedOption];
        updateFunc(addedErrorOnField([], state));
        setBirthCertificationInputs(addedErrorOnField([], birthCertificationInputs));
      }
    }
  }, [errors]);

  useEffect(() => {
    if (error) {
      setSelectedClientByPhone("");
      setSelectedClientByEmail("");
      setClientId(undefined);
    }
  }, [error]);

  useEffect(() => {
    selectedUpdateByField(selectedClientByPhone as Model.Client);
    if ((selectedClientByPhone as Model.Client)?.id) {
      setClientId((selectedClientByPhone as Model.Client)?.id);
    }
  }, [selectedClientByPhone]);

  useEffect(() => {
    selectedUpdateByField(selectedClientByEmail as Model.Client);
    if ((selectedClientByEmail as Model.Client)?.id) {
      setClientId((selectedClientByEmail as Model.Client)?.id);
    }
  }, [selectedClientByEmail]);

  useEffect(() => {
    if (selectedOption === "individualPerson" && age !== undefined && age !== null) {
      const newInputs = individualPerson.map((item) => {
        let newItem = item;
        if (
          item.field === "passportSeries" ||
          item.field === "passportNumber" ||
          item.field === "passportCode" ||
          item.field === "passportIssuedBy" ||
          item.field === "passportIssueDate" ||
          item.field === "address"
        ) {
          newItem = { ...item, required: age > 14 };
        }
        return newItem;
      });
      setIndividualPerson(newInputs);
    }
  }, [age, selectedOption]);

  const changeHandle = (
    newVal: {
      field: string;
      value: string | number;
      id: string | number;
      suggestion?: { [name: string]: any };
    },
    inputType: string,
  ) => {
    if (newVal.field === "dateOfBirth") {
      const newAge = calculateAge(String(newVal.value));
      setAge(newAge);
    }
    if (inputType === "personPositions") {
      //eslint-disable-next-line
      const newPosition = String(newVal.id).split("__")[1];
      setSelectedOption(newPosition);
      if (changePosition && newPosition !== client?.personPosition?.position) {
        changePosition(newPosition);
      }
    }

    if (newVal.field === "legalForm") {
      const newSelectedLegalForm = legalFormOptions.filter((item) => item.value === newVal.value);

      if (newSelectedLegalForm.length) setSelectedLegalForm(newSelectedLegalForm[0]);
    }

    if (inputType in inputMap) {
      const { state, updateFunc } = inputMap[inputType];
      const newInputProps = state.map((item) => {
        let newItem = item;
        if (newVal.field === item.field) {
          newItem = { ...item, value: newVal.value };
        }
        if (item.options) {
          newItem = {
            ...item,
            options: item.options?.map((option) => ({
              ...option,
              value: `${formId}__${option.id}` === newVal.id && newVal.value === "on" ? 1 : 0,
            })),
          };
        }

        if (
          newVal.suggestion &&
          (newVal.suggestion[item.field] !== undefined ||
            (item.field === "addressFias" && newVal.suggestion.address !== undefined))
        ) {
          if (item.field === "addressFias" || item.field === "address") {
            newItem.value = `${newVal.suggestion.postcode}, ${newVal.suggestion.address}`;
          } else {
            newItem.value = newVal.suggestion[item.field];
          }
        }
        return newItem;
      });
      updateFunc(newInputProps);
    }
  };

  const changeHandleCertificate = (newVal: {
    field: string;
    value: string | number;
    id: string | number;
    suggestion?: { [name: string]: any };
  }) => {
    const newInputProps = birthCertificationInputs.map((item) => {
      let newItem = item;
      if (newVal.field === item.field) {
        newItem = { ...item, value: newVal.value };
      }
      return newItem;
    });
    setBirthCertificationInputs(newInputProps);
  };

  const submitHandle = (e: FormEvent) => {
    e.preventDefault();

    const isAttachedBirthCertificate = !(Number(age) < 18 && !birthCertification && files.length === 0);
    setShowError(!isAttachedBirthCertificate);
    const errors = newInputs.filter((item) => item.invalid);

    if (errors.length > 0 || isError || isExpired) {
      const { updateFunc } = inputMap[selectedOption];
      updateFunc(newInputs);
      errorOnFocus();
    } else {
      const newValues: Partial<Model.Client> = getValueFromArray(inputMap[selectedOption].state);

      let newLegalForm;

      if (String(newValues.legalForm) === "") {
        newLegalForm = selectedLegalForm?.value || legalFormOptions[0].value;
      } else {
        newLegalForm = newValues.legalForm;
      }

      let desiredValue: Partial<Model.Client> & {
        personPosition: string;
        legalRepresentative?: number | Model.LinkedPerson;
        linkedPerson?: number | string | Model.LinkedPerson;
      } = {
        ...newValues,
        personPosition: selectedOption,
      };

      if (selectedOption === "legalPerson") {
        desiredValue.legalForm = Number(newLegalForm);
      }
      if (selectedOption === "soleProprietorship") {
        desiredValue.linkedPerson = legalRepresentativeId || (client?.linkedPerson as Model.LinkedPerson)?.id;
      }
      if (selectedOption === "individualPerson") {
        if (Number(age) < 18) {
          desiredValue.legalRepresentative = Number(legalRepresentativeId);
          const certificate: Partial<Model.Client> = getValueFromArray(birthCertificationInputs);
          desiredValue = { ...desiredValue, ...certificate };
        }
        desiredValue.snils = desiredValue.snils?.trim().replace(/\*|-| |\$/g, "");
      }
      const formData = new FormData();
      for (const key in desiredValue) {
        if (Object.prototype.hasOwnProperty.call(desiredValue, key)) {
          const value = desiredValue[key as keyof Partial<Model.Client>];
          formData.append(key, String(value));
        }
      }

      if (files.length > 0) {
        formData.append("birthCertificate", files[0]);
      }
      if (age === null) {
        senData(formData, selectedOption, clientId);
      } else if (age < 18 && legalRepresentativeId !== null && isAttachedBirthCertificate) {
        senData(formData, selectedOption, clientId);
      } else if (age >= 18) {
        senData(formData, selectedOption, clientId);
      }
    }
  };

  const fileChangeHandle = (data: File[]) => {
    setFiles(data);
  };

  const fileDeleteHandle = () => {
    setFiles([]);
    setBirthCertification(undefined);
  };

  const searchAction = (val: string | number) => {
    setSearchValue(val);
  };

  const selectClose = () => {
    setSearchValue(undefined);
  };

  return (
    <form
      onSubmit={submitHandle}
      className={cn(styles.form, { [styles.edit]: isEditForm })}
      autoComplete="off"
      ref={formRef}>
      <h6>Основная информация</h6>
      <div className={styles.formTabs}>
        <InputList values={personPositions} changeHandle={(val) => changeHandle(val, "personPositions")} formId={formId} />
      </div>
      <div className={styles.formList}>
        {selectedOption === "legalPerson" && (
          <InputList
            values={legalPerson}
            selectedLegalForm={selectedLegalForm}
            legalForms={legalFormOptions}
            setSelectedClientByPhone={setSelectedClientByPhone}
            setSelectedClientByEmail={setSelectedClientByEmail}
            personPosition={selectedOption}
            changeHandle={(val) => changeHandle(val, "legalPerson")}
            formId={formId}
            withLink={!inApp}
            changeSearchSelect={searchAction}
            onSelectClose={selectClose}
          />
        )}
        {selectedOption === "soleProprietorship" && (
          <InputList
            values={soleProprietorship}
            clientOptions={clientOptions}
            selectedClient={clientOptions[0]}
            setSelectedClientByPhone={setSelectedClientByPhone}
            setSelectedClientByEmail={setSelectedClientByEmail}
            setLinkedPerson={setLinkedPerson}
            personPosition={selectedOption}
            changeHandle={(val) => changeHandle(val, "soleProprietorship")}
            formId={formId}
            isEdit={isEditForm}
            setSearchValue={setLegalRepresentativeId}
            member={editLegalRepresentive}
            searchValue={legalRepresentativeId}
            withLink={!inApp}
          />
        )}
        {selectedOption === "individualPerson" && (
          <InputList
            values={individualPerson}
            setSelectedClientByPhone={setSelectedClientByPhone}
            setSelectedClientByEmail={setSelectedClientByEmail}
            personPosition={selectedOption}
            changeHandle={(val) => changeHandle(val, "individualPerson")}
            title="Паспортные данные"
            titleBeforeField="passportSeries"
            formId={formId}
            withLink={!inApp}
          />
        )}
      </div>
      {selectedOption === "individualPerson" && age !== null && age < 18 && (
        <>
          <h6>Законный представитель</h6>
          <SearchWithSuggestions
            className={styles.col12}
            setLinkedPersonId={setLegalRepresentativeId}
            isEdit={isEditForm}
            editMember={editLegalRepresentive}
            linkedPersonId={legalRepresentativeId}
            label="Выберите физ. лицо"
            placeholder="ФИО клиента"
            ageLimit={18}
          />
          <TitleWithIcon title="Свидетельство о рождении" active={Boolean(birthCertification) || files.length > 0} />

          {showError && files.length === 0 && (
            <span className={styles.errorMessage}>Свидетельство о рождении не предоставлено.</span>
          )}
          <SwitchTransition>
            <CSSTransition
              key={!birthCertification && files.length === 0 ? "uploader" : "file"}
              timeout={250}
              classNames="fade">
              {!birthCertification && files.length === 0 ? (
                <div className={styles.documentForm}>
                  <Uploader
                    onChange={(data) => fileChangeHandle(data)}
                    multiple={false}
                    size="lg"
                    isSuccess={false}
                    isLoading={false}
                  />
                </div>
              ) : (
                <UploaderItemSmall
                  file={files[0]}
                  scan={birthCertification}
                  handleFileDelete={fileDeleteHandle}
                  id={birthCertification?.id || 0}
                  delay={0}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
          <InputList values={birthCertificationInputs} changeHandle={changeHandleCertificate} formId={formId} />
        </>
      )}

      <hr />
      <div className={styles.buttons}>
        <Button type="submit" variant="primary" disabled={isExpired || isLoading} loader={isLoading}>
          Сохранить
        </Button>
        {(client?.id || hasReset) && (
          <Button type="button" variant="secondary" onClick={onReset}>
            Отменить
          </Button>
        )}
      </div>
    </form>
  );
};

export default AddEditClientForm;
