import { declinationOfNumber } from "./utils";
import { fieldsForCheckRussianLetters } from "../constants";

export const getValueFromArray = <T>(array: InputValue[] | undefined): T => {
  let values: Partial<T> = {};

  array?.forEach(({ field, value }) => {
    values = { ...values, [field]: value };
  });

  return values as T;
};

export const addedErrorOnField = (errors: ErrorObject[], inputs: InputData[]) => {
  const newInputs = inputs.map((item) => {
    const errorItem = errors?.find((error) => error.attr === item.field);
    return errorItem
      ? {
          ...item,
          helperText: errorItem.detail,
          invalid: true,
        }
      : {
          ...item,
          helperText: "",
          invalid: false,
        };
  });

  return newInputs;
};

export const checkTimePickersForEmpty = (array: Schedule.ScheduleItem[]) => {
  const isEmpty = array.some((el) => el.available && el.timeSlots.some((item) => item.value === ""));

  const emptyDays: Schedule.ScheduleItem[] = array.map(({ available, timeSlots, ...rest }) => ({
    ...rest,
    available,
    timeSlots: available ? timeSlots.map((item) => ({ ...item, invalid: item.value === "" })) : timeSlots,
  }));

  return { emptyDays, isEmpty };
};

export const checkForRussianLetters = (inputs: InputData[]) => {
  let isError = false;
  const regex = /[А-Яа-яЁё]/g;
  const license = /^(?=.*\d)[\d№-]{1,25}$/u;
  const symbols = /[<>@#$%^&*~№}{]/;
  const symbolsN = /[<>@#$%^&*~}{]/;
  const newInputs =
    inputs &&
    inputs?.map((item) => {
      let newItem: InputData = { ...item, invalid: false, helperText: undefined };
      const isInvalid = String(item.value).length > 0 || (String(item.value).length === 0 && item.required);

      if (!item.disabled && fieldsForCheckRussianLetters.includes(item.field) && !item.value?.toString().match(regex)) {
        isError = isInvalid || false;
        newItem = {
          ...item,
          invalid: isInvalid,
          helperText: isInvalid ? "Введите текст на русском" : undefined,
        };
      }
      if (
        (item.pattern === "letters" || item.pattern === "russian" || item.pattern === "russianN") &&
        item.field !== "license"
      ) {
        const withSimbols =
          item.pattern === "russianN" ? symbolsN.test(String(item.value)) : symbols.test(String(item.value));
        isError = withSimbols || false;
        if (isError)
          newItem = {
            ...item,
            invalid: true,
            helperText: withSimbols ? "Ввведите только разрешенные символы (знаки препинания)" : "Введите только буквы",
          };
      }
      if (item.field === "license" && !item.value?.toString().match(license)) {
        isError = true;
        newItem = {
          ...item,
          invalid: true,
          helperText: "Введите только цифры, тире и №",
        };
      }
      if (item.maxLength && String(item.value).length > item.maxLength) {
        const symbols = declinationOfNumber(item.maxLength, ["символ", "символа", "символов"]);

        isError = isInvalid || false;
        newItem = {
          ...item,
          invalid: true,
          helperText: `Поле должно содержать не более ${item.maxLength} ${symbols}`,
        };
      }
      if (item.minLength && String(item.value).length < item.minLength) {
        const symbols = declinationOfNumber(item.minLength, ["символ", "символа", "символов"]);

        isError = isInvalid || false;
        newItem = {
          ...item,
          invalid: isInvalid,
          helperText: isInvalid ? `Поле должно содержать не менее ${item.minLength} ${symbols}` : undefined,
        };
      }
      return newItem;
    });
  if (!isError) {
    isError = false;
  }

  return { isError, newInputs };
};

export const updatedInputsData = <T extends Record<string, unknown>>(
  inputsData: InputData[],
  data: Partial<T> = {},
): InputData[] => {
  if (data !== null && Object.keys(data).length > 0) {
    return inputsData.map((input) => {
      const newInput = { ...input };

      if (input.field in data && input.field !== "priority") {
        const fieldValue = data[input.field as keyof T];

        if (fieldValue !== null && fieldValue !== undefined && typeof fieldValue === "object") {
          const optionValue = fieldValue as unknown as Option;

          newInput.value = optionValue.value === undefined ? Number(optionValue.id) : optionValue.value;
          newInput.defaultOption = { name: optionValue.name, value: optionValue.value || Number(optionValue.id) };
        } else if (typeof fieldValue === "string" || typeof fieldValue === "number") {
          newInput.value = fieldValue;
        } else if (typeof fieldValue === "boolean") {
          newInput.value = Number(fieldValue);
        }
      }

      return newInput;
    });
  }

  return inputsData;
};

export const updateAddressInputs = (adressFields: addressType[], data: Office.OfficeFullResponce): addressType[] => {
  return adressFields.map((el) => {
    if (data && (data.address as Pick<AddressModel.FormAddress, "notary" | "fias" | "notaryOffice">)[el.addressType]) {
      return {
        ...el,
        address: data.address ? updatedInputsData(el.address, data.address[el.addressType]) : el.address,
      };
    }
    return el;
  });
};

export const updatedScheduleData = (
  scheduleData: Schedule.ScheduleItem[],
  data: Schedule.ScheduleItemResponse[],
): Schedule.ScheduleItem[] => {
  return scheduleData.map((schedule: Schedule.ScheduleItem) => {
    const matchingData = data.find((item) => item.day === schedule.day);
    if (matchingData) {
      return {
        ...schedule,
        available: matchingData.isWork,
        timeSlots: schedule.timeSlots.map((el: Schedule.timeSlot) =>
          el.field === "startedAt"
            ? {
                ...el,
                id: `${schedule.field}_${el.id}`,
                value: matchingData.startedAt,
                field: "start",
              }
            : {
                ...el,
                id: `${schedule.field}_${el.id}`,
                value: matchingData.finishedAt,
                field: "end",
              },
        ),
      };
    }
    return schedule;
  });
};
export const clearValues = (inputs: InputData[]): InputData[] => {
  const newInputs = inputs.map((el) => ({ ...el, value: "" }));
  return newInputs;
};

export const isFormFilled = (values: InputData[]) => {
  let isError = false;
  const newInputs = values.map((el) => {
    let newItem = el;
    if (el.required && el.disabled !== true && el.value !== undefined && el.value !== "") {
      isError = true;
      newItem = {
        ...el,
        invalid: true,
      };
    }
    if (el.field === "action" && el.value !== "") {
      isError = true;
      newItem = {
        ...el,
        invalid: true,
        helperText: "Нотариальное действие",
      };
    }

    return newItem;
  });

  return { isError, newInputs };
};
