import { PlaneIcon } from "@/assets/icons";
import { useAppSelector } from "@/store/hooks";
import Button from "@/ui/Button/Button";

import styles from "./AuthForm.module.scss";

export const SuccessLetter = () => {
  const { successEmail } = useAppSelector((state) => state.auth);
  return (
    <div className={`${styles.form} ${styles.successLetter}`}>
      <div className={styles.icon}>
        <PlaneIcon />
      </div>
      <h4 className={styles.subtitle}>Проверьте почту</h4>
      <p className={styles.text}>
        На почту <span className={styles.email}>{successEmail}</span> мы отправили письмо с ссылкой для восстановления
        пароля
      </p>
      <Button href="/auth" variant="secondary">
        Перейти на главную
      </Button>
    </div>
  );
};
