import { useSnackbar } from "notistack";
import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { SwitchTransition, TransitionGroup, CSSTransition } from "react-transition-group";

import { useAppSelector, useActions } from "@/store/hooks";
import { UploaderButton, UploaderItem, Loader } from "@/ui/index";

import styles from "./Scans.module.scss";

const Scans = () => {
  const { clientId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState<ScanItem[]>([]);

  const { createScan, deleteScan, fetchScans } = useActions();
  const {
    scans: { loading, error, data },
  } = useAppSelector((state) => state.client);

  useEffect(() => {
    fetchScans(Number(clientId));
  }, []);
  useEffect(() => {
    if (data) {
      setFiles(data);
    }
  }, [data]);

  useEffect(() => {
    error &&
      enqueueSnackbar(error, {
        variant: "error",
      });
  }, [error]);

  const handleFileChange = (data: File[], documentId: string) => {
    if (data.length > 0) {
      const formData = new FormData();

      data.forEach((file) => {
        formData.append("scanners", file);
      });

      formData.append("document", documentId);
      createScan({ clientId: Number(clientId), data: formData });
    }
  };

  const handleDelete = (id: number, documentId: number) => {
    deleteScan({ scanId: id, clientId: Number(clientId), documentId });
  };

  return (
    <div className={styles.scans}>
      <SwitchTransition>
        <CSSTransition key={loading ? "loader" : "content"} timeout={250} classNames="fade">
          {loading ? (
            <Loader />
          ) : (
            <>
              {files.map((item) => {
                return (
                  <Fragment key={item.name}>
                    <h6>{item.name}</h6>
                    <div className={styles.scanslist}>
                      <TransitionGroup component={null}>
                        {item.scans.map((el) => (
                          <CSSTransition key={el.id} timeout={300} classNames="fade">
                            <UploaderItem
                              file={el.file}
                              id={el.id}
                              name={el.name}
                              loading={el.loading}
                              handleDelete={(id) => handleDelete(id, item.id)}
                            />
                          </CSSTransition>
                        ))}
                      </TransitionGroup>
                      <UploaderButton
                        multiple={true}
                        onChange={(id) => handleFileChange(id, String(item.id))}
                        accept=".pdf, image/*"
                        isSuccess={!item.loading}
                        isLoading={item.loading || false}
                      />
                    </div>
                  </Fragment>
                );
              })}
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Scans;
