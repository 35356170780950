import type { FC } from "react";

import cn from "classnames";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Datepicker } from "tw-elements";

import { formatDate } from "@/helpers/utils";

import styles from "./Datepicker.module.scss";

const DatePicker: FC<Input> = ({
  value,
  hasLabelMark,
  label,
  id,
  disabled,
  field,
  onChange,
  required,
  placeholder,
  className,
  inputSize,
  dateProps,
  invalid,
  helperText,
}) => {
  const [currentValue, setCurrentValue] = useState<string | number>();
  const [isInvalid, setIsInvalid] = useState(invalid ? invalid : false);
  const [errorMessage, setErrorMessage] = useState(helperText ? helperText : "");
  const myInput = useRef<HTMLInputElement>(null);

  const limitDateRange = (limitFutureMonths: number, limitPastMonths: number) => {
    const currentDate = new Date();
    let futureYear = currentDate.getFullYear();
    let futureMonth = currentDate.getMonth() + limitFutureMonths;

    if (futureMonth > 11) {
      futureMonth -= 12;
      futureYear += 1;
    }

    const min = new Date(currentDate.getFullYear(), currentDate.getMonth() - limitPastMonths, 1);
    const max = new Date(futureYear, futureMonth + 1, 0);
    return { min, max };
  };

  useLayoutEffect(() => {
    setCurrentValue(value);

    if (myInput.current !== null) {
      const { max, min } = limitDateRange(Number(dateProps?.limitFutureMonths), Number(dateProps?.limitPastMonths));
      const init = async () => {
        await new Datepicker(myInput.current, {
          monthsFull: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ],
          monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
          weekdaysNarrow: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          okBtnText: "Ok",
          clearBtnText: "Очистить",
          cancelBtnText: "Отменить",
          format: "dd.mm.yyyy",
          title: "Выберите дату",
          placeholder,
          disableFuture: Boolean(dateProps?.disableFuture),
          disablePast: Boolean(dateProps?.disablePast),
          max: new Date(max),
          min: new Date(min),
        });

        if (myInput.current !== null) {
          myInput.current.dispatchEvent(new Event("input", { bubbles: true }));

          myInput.current.addEventListener("dateChange.te.datepicker", (event: any) => {
            setCurrentValue(formatDate(event.date));
          });

          myInput.current.addEventListener("close.te.datepicker", (event: any) => {
            if (event?.srcElement?.children[0]?.value === "") {
              setCurrentValue("");
            }
          });
        }
      };
      init();
    }
  }, []);

  useEffect(() => {
    if (invalid !== undefined) {
      setIsInvalid(invalid);
    }
  }, [invalid]);

  useEffect(() => {
    if (helperText) {
      setErrorMessage(helperText);

      if (helperText.length > 0) {
        setIsInvalid(true);
      }
    }
  }, [helperText]);

  useEffect(() => {
    if (currentValue !== undefined && currentValue !== value) {
      onChange({ field, value: currentValue, id });
    }
  }, [currentValue]);

  const datePickerClasses = cn(
    styles.datePicker,
    className && styles[className],
    className && className,
    inputSize && styles[inputSize],
  );

  return (
    <div className={datePickerClasses} data-is-error={isInvalid}>
      {label ? <label className={cn(styles.label, (hasLabelMark || required) && styles.labelMarked)}>{label}</label> : null}
      <div className={cn(styles.inputWrap, "fadeIn", isInvalid && styles.error)} data-te-input-wrapper-init ref={myInput}>
        <input
          type="text"
          name={field}
          defaultValue={value}
          placeholder={String(placeholder)}
          required={required}
          disabled={disabled}
          className={styles.input}
          data-te-datepicker-toggle-ref
        />
      </div>
      <TransitionGroup component={null}>
        {isInvalid && (
          <CSSTransition key="errorText" timeout={250} classNames="fade">
            <span className={styles.errorText} title={errorMessage}>
              {errorMessage}
            </span>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default DatePicker;
