import type { AxiosResponse } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { Endpoints } from "@/api/endpoints";
import { $api } from "@/api/index";
import { ActionType } from "@/types/enums";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const fetchScans = createAsyncThunk(ActionType.GET_SCANS, async (clientId: number, { rejectWithValue }) => {
  try {
    const url = `${Endpoints.CLIENTS}${clientId}/scanners/`;
    const { data }: AxiosResponse<Response.Document[]> = await $api.get(url);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createScan = createAsyncThunk(
  ActionType.CREATE_SCANS,
  async (
    {
      clientId,
      data,
    }: {
      clientId: number;
      data: FormData;
    },
    { rejectWithValue },
  ) => {
    try {
      const url = `${Endpoints.CLIENTS}${clientId}/scanners/`;
      const res: AxiosResponse<Response.ScanItem[]> = await $api.post(url, data, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteScan = createAsyncThunk(
  ActionType.DELETE_SCANS,
  async (
    {
      clientId,
      scanId,
    }: {
      clientId: number;
      scanId: number;
      documentId: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const url = `${Endpoints.CLIENTS}${clientId}/scanners/${scanId}/`;
      const res: AxiosResponse<ResponseSuccess> = await $api.delete(url);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
