import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

import { getDateString, getDefaultStatus } from "@/helpers/index";

const initialState: OfficeSlice = {
  offices: [],
  filters: [] as Office.Option[],
  office: {} as OfficeFullCard,
  hasNewStatus: false,
  succesEmail: "",
};

const OfficeSlice = createSlice({
  name: "office",
  initialState,
  reducers: {
    setOffices: (state, { payload }: PayloadAction<Office.OfficesResponce[]>) => {
      const newOffices = payload?.map((el: Office.OfficesResponce) => {
        const { id, status, createdAt, ...other } = el;
        return {
          id,
          officeNumber: id,
          status: getDefaultStatus(status),
          createdDate: getDateString(createdAt),
          ...other,
        };
      });
      state.offices = [...newOffices];
    },
    setNewStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.hasNewStatus = payload;
    },
    setOffice: (state, { payload }: PayloadAction<Office.OfficeFullResponce>) => {
      const { id, status, createdAt, ...other } = payload;
      state.office = {
        id,
        officeNumber: id,
        status: getDefaultStatus(status),
        createdDate: getDateString(createdAt),
        ...other,
      };
    },
    setFilters: (state, { payload }: PayloadAction<Office.Option[]>) => {
      state.filters = payload;
    },
    setSuccessEmail: (state, { payload }: PayloadAction<string>) => {
      state.succesEmail = payload;
    },
  },
});

export const { setOffices, setOffice, setFilters, setSuccessEmail, setNewStatus } = OfficeSlice.actions;
export default OfficeSlice.reducer;
