import type { FC } from "react";

import cn from "classnames";
import { useState } from "react";

import { CorruptedFile } from "@/assets/icons";
import { ImageStatus } from "@/types/enums";

import styles from "./Picture.module.scss";

type PictureProps = FC<{
  src: string;
  alt: string;
  onClick?: () => void;
  className?: string;
}>;

export const Picture: PictureProps = ({ src, onClick, alt, className }) => {
  const [status, setStatus] = useState<ImageStatus>(ImageStatus.Loading);
  const onError = () => setStatus(ImageStatus.Error);
  const isError = status === ImageStatus.Error;

  return (
    <>
      {isError ? (
        <div className={styles.corrupted}>
          <CorruptedFile />
        </div>
      ) : (
        <img src={src} alt={alt} onClick={onClick} onError={onError} className={className} />
      )}
    </>
  );
};
