import type { FormEvent } from "react";

import { enqueueSnackbar } from "notistack";
import { useState, useEffect, useLayoutEffect } from "react";

import { Container } from "@/layout/Container";
import { usePatchApplicationMutation } from "@/store/services/application.query";
import { Button, InputList } from "@/ui/index";

import styles from "./ApplicationRights.module.scss";
import { inputs } from "./mockdata";

export const ApplicationRights = ({ officeId, applicationId, isEdit, data }: Blocks.ApplicationRights) => {
  const [values, setValues] = useState<InputData[]>(inputs);
  const [isLoading, setIsLoading] = useState(false);
  const [changeRights, changedRights] = usePatchApplicationMutation();
  const [currentData, setCurrentData] = useState<Blocks.ApplicationRights["data"] | null>(null);

  useLayoutEffect(() => {
    if (
      !currentData ||
      currentData.termApplication !== data.termApplication ||
      currentData.transferPermission !== data.transferPermission
    ) {
      setCurrentData(data);
      const newValues = values.map((item) => {
        const newItem = item;

        if (Object.keys(data).find((key) => key === item.field)) {
          if (item.field === "transferPermission") {
            // @ts-ignore
            newItem.defaultValue = data[item.field] || data[item.field] === null ? "withRight" : "withoutRight";
          } else {
            // @ts-ignore
            newItem.value = data[item.field] || "";
          }
        }

        return newItem;
      });
      setValues(newValues);
    }
  }, [data]);

  useEffect(() => {
    if (changedRights.isSuccess && changedRights.data) {
      enqueueSnackbar("Права установлены!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }

    if (changedRights.isError) {
      setIsLoading(false);
    }
  }, [changedRights]);

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) => (newVal.field === item.field ? { ...item, value: newVal.value } : item));
    setValues(newInputProps);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    let data = {};
    // @ts-ignore
    values.forEach(({ field, value, defaultValue }) => {
      data = { ...data, [field]: value || defaultValue };
    });
    // @ts-ignore
    data.transferPermission = data.transferPermission === "withRight";
    //@ts-ignore
    changeRights({ officeId, applicationId, data });
  };

  return (
    <Container>
      <form onSubmit={onSubmit} className={styles.form}>
        <h6>Установление прав</h6>

        {isEdit ? (
          <>
            <InputList values={values} changeHandle={changeHandle} formId={""} />
            <Button type="submit" variant="primary" disabled={isLoading} isFullWidth loader={isLoading}>
              Сохранить
            </Button>
          </>
        ) : (
          <div className={styles.fields}>
            {/* @ts-ignore */}
            {values.map(({ id, type, label, value, options, defaultValue }) =>
              type === "radioGroup" && options ? (
                <div key={id} className={styles.radio}>
                  {options.find((item) => item.id === defaultValue)?.name}
                </div>
              ) : (
                <div key={id} className={styles.input}>
                  <div className={styles.label}>{label}</div>
                  <div className={styles.value}>{value || "—"}</div>
                </div>
              ),
            )}
          </div>
        )}
      </form>
    </Container>
  );
};
