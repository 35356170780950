export { default as Auth } from "@/pages/Auth/Auth";
export { default as Office } from "@/pages/Office/Office";
export { default as CreateOffice } from "@/pages/CreateOffice/CreateOffice";
export { default as RolesAndAccess } from "@/pages/RolesAndAccess/RolesAndAccess";
export { default as NotaryActions } from "@/pages/NotaryActions/NotaryActions";
export { default as OfficeInfoEdit } from "@/pages/OfficeInfoEdit/OfficeInfoEdit";
export { default as OfficeDetail } from "@/pages/OfficeDetail/OfficeDetail";
export { default as PageNotFound } from "@/pages/PageNotFound/PageNotFound";
export { default as AddAndEditEmployee } from "@/pages/AddAndEditEmployee/AddAndEditEmployee";
export { default as Employee } from "@/pages/Employee/Employee";
export { default as AddClient } from "@/pages/AddClient/AddClient";
export { default as Client } from "@/pages/Client/Client";
export { default as ActionEdit } from "@/pages/ActionEdit/ActionEdit";
export { default as AddApplication } from "@/pages/AddApplication/AddApplication";
export { default as Application } from "@/pages/Application/Application";
export { default as Applications } from "@/pages/Applications/Applications";
