import type { FC, FormEvent } from "react";

import cn from "classnames";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DownloadIcon, ArrowDownIcon } from "@/assets/icons";
import { downloadRequest } from "@/helpers/downloadRequest";
import {
  useFetchDocumentsQuery,
  useLazyDownloadMemberDocumentsQuery,
  useChangeDocumentsCheckboxesMutation,
} from "@/store/services/application.query";
import { Button, Checkbox } from "@/ui/index";

import styles from "./LoadDocuments.module.scss";
import LoadDocumentsGroup from "./LoadDocumentsGroup";

const LoadDocuments: FC<UI.LoadDocuments> = ({ memberId, isEdit, name, downloadAll = true }) => {
  const { officeId, applicationId } = useParams();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [documents, setDocuments] = useState<Model.FetchDocumentWithChecked[] | null>(null);

  const fetchDocuments = useFetchDocumentsQuery({
    memberId,
    officeId: Number(officeId),
    applicationId: Number(applicationId),
  });

  const [downloadDocumnets, downloadedDocumnets] = useLazyDownloadMemberDocumentsQuery();
  const [changeCheckboxes, changedCheckboxes] = useChangeDocumentsCheckboxesMutation();

  useEffect(() => {
    if (fetchDocuments && fetchDocuments.isSuccess && fetchDocuments.data) {
      setDocuments(fetchDocuments.data as Model.FetchDocumentWithChecked[]);
    }
  }, [fetchDocuments]);

  useEffect(() => {
    if (downloadedDocumnets.isSuccess && downloadedDocumnets.data) {
      downloadRequest(downloadedDocumnets.data as Blob, name);
    }

    if (downloadedDocumnets.isError && downloadedDocumnets.error) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((downloadedDocumnets.error as Model.Error)?.message) {
        error = (downloadedDocumnets.error as Model.Error).message;
      } else if ((downloadedDocumnets.error as ErrorSimpleResponse)?.errors) {
        error = (downloadedDocumnets.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (downloadedDocumnets.error as ErrorObject[]) {
        error = (downloadedDocumnets.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [downloadedDocumnets]);

  useEffect(() => {
    if (changedCheckboxes.isSuccess && changedCheckboxes.data) {
      //@ts-ignore
      enqueueSnackbar(changedCheckboxes.data.message || "Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
    if (changedCheckboxes.isError && (changedCheckboxes.error as ErrorObject[])) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((changedCheckboxes.error as Model.Error)?.message) {
        error = (changedCheckboxes.error as Model.Error).message;
      } else if ((changedCheckboxes.error as ErrorSimpleResponse)?.errors) {
        error = (changedCheckboxes.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (changedCheckboxes.error as ErrorObject[]) {
        error = (changedCheckboxes.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [changedCheckboxes]);

  const downloadHandle = () => {
    downloadDocumnets({
      memberId,
      officeId: Number(officeId),
      applicationId: Number(applicationId),
    });
  };

  const handleClick = (docIndex: number) => {
    setActiveIndex(activeIndex === docIndex ? null : docIndex);
  };

  const changeChecked = (value: { field: string; value: boolean; id: number | string }) => {
    const data = { [value.field]: value.value };
    changeCheckboxes({ memberId, officeId: Number(officeId), applicationId: Number(applicationId), data });
  };

  return (
    <div className={styles.loadDocuments}>
      <h5>
        Документы
        {documents && documents.length > 0 && downloadAll && (
          <Button
            variant="secondary"
            type="button"
            size="small"
            onClick={downloadHandle}
            disabled={downloadedDocumnets.isFetching}>
            <DownloadIcon /> Скачать все
          </Button>
        )}
      </h5>
      <div className={styles.accordionWrap}>
        {documents &&
          documents.length > 0 &&
          documents.map((doc, docIndex) =>
            doc.checked === null ? (
              doc.documents.map((el, elIndex) => (
                <LoadDocumentsGroup
                  key={el.id || elIndex}
                  document={el}
                  isEdit={isEdit}
                  index={elIndex}
                  memberId={memberId}
                />
              ))
            ) : (
              <div key={doc.id}>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    isChecked={doc.checked.value}
                    field={doc.checked.codename}
                    label={doc.name}
                    id={doc.id}
                    onChange={changeChecked}
                    disabled={!isEdit}
                  />
                  {doc.documents.length > 0 && (
                    <div onClick={() => handleClick(docIndex)}>
                      <ArrowDownIcon className={cn(styles.arrow, { [styles.show]: activeIndex === docIndex })} />
                    </div>
                  )}
                </div>
                <div
                  className={cn(
                    styles.accordionContent,
                    activeIndex === docIndex || doc.documents.length === 0 ? styles.show : styles.hide,
                  )}>
                  {doc.documents.map((el, elIndex) => (
                    <LoadDocumentsGroup
                      key={el.id || elIndex}
                      document={el}
                      isEdit={isEdit}
                      index={elIndex}
                      memberId={memberId}
                    />
                  ))}
                </div>
              </div>
            ),
          )}
      </div>
    </div>
  );
};

export default LoadDocuments;
