// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Preloader_spin-anim__DJLOf {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Preloader_fadeIn__KFC0D {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Preloader_fadeOut__1-8pT {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes Preloader_pulse__7AODn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.Preloader_wrap__enBSD {
  position: relative;
  height: 100%;
}

.Preloader_preloader__aRd-C {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}
.Preloader_preloader__aRd-C.Preloader_active__qDHCn {
  transition: all 0.25s ease-in-out;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/Preloader/Preloader.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,kBAAA;EACA,YAAA;AA8BF;;AA3BA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,UAAA;EACA,UAAA;AA8BF;AA5BE;EACE,iCAAA;EACA,UAAA;AA8BJ","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.wrap {\n  position: relative;\n  height: 100%;\n}\n\n.preloader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  margin: 0 auto;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 0;\n  opacity: 0;\n\n  &.active {\n    transition: all 0.25s ease-in-out;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Preloader_wrap__enBSD`,
	"preloader": `Preloader_preloader__aRd-C`,
	"active": `Preloader_active__qDHCn`,
	"spin-anim": `Preloader_spin-anim__DJLOf`,
	"fadeIn": `Preloader_fadeIn__KFC0D`,
	"fadeOut": `Preloader_fadeOut__1-8pT`,
	"pulse": `Preloader_pulse__7AODn`
};
export default ___CSS_LOADER_EXPORT___;
