import { Action1Icon, Action2Icon, Action3Icon, Action4Icon, Action5Icon, Action6Icon } from "@/assets/icons";

import styles from "./Action.module.scss";

const getActionIcon = (id: number | undefined) => {
  switch (id) {
    case 1:
      return <Action1Icon />;
    case 2:
      return <Action2Icon />;
    case 3:
      return <Action3Icon />;
    case 4:
      return <Action4Icon />;
    case 5:
    case 7:
    case 8:
      return <Action5Icon />;
    case 6:
    case 9:
    case 10:
      return <Action6Icon />;
    default:
      return null;
  }
};
const ActionIcon = ({ id }: { id: number }) => {
  return <div className={`${styles.svgWrap} ${styles[`action${id as number}`]}`}>{getActionIcon(Number(id))}</div>;
};

export default ActionIcon;
