// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes DeadlineForDocuments_spin-anim__B1R3G {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes DeadlineForDocuments_fadeIn__MyUrH {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes DeadlineForDocuments_fadeOut__aDPVJ {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes DeadlineForDocuments_pulse__-\\+rMo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.DeadlineForDocuments_input__P6F-S {
  width: 156px;
  margin-left: auto;
}

.DeadlineForDocuments_wrapper__KjOq7 {
  padding: 0 32px 24px;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/DeadlineForDocuments/DeadlineForDocuments.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,YAAA;EACA,iBAAA;AA8BF;;AA3BA;EACE,oBAAA;EACA,aAAA;EACA,sBAAA;AA8BF","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.input {\n  width: 156px;\n  margin-left: auto;\n}\n\n.wrapper {\n  padding: 0 32px 24px;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `DeadlineForDocuments_input__P6F-S`,
	"wrapper": `DeadlineForDocuments_wrapper__KjOq7`,
	"spin-anim": `DeadlineForDocuments_spin-anim__B1R3G`,
	"fadeIn": `DeadlineForDocuments_fadeIn__MyUrH`,
	"fadeOut": `DeadlineForDocuments_fadeOut__aDPVJ`,
	"pulse": `DeadlineForDocuments_pulse__-+rMo`
};
export default ___CSS_LOADER_EXPORT___;
