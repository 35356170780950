import type { FormEvent } from "react";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ErrorResult } from "@/blocks/index";
import { getValueFromArray, updatedInputsData, addedErrorOnField } from "@/helpers/index";
import { Container } from "@/layout/index";
import { useUserPermissions } from "@/store/hooks";
import { useCreateApplicationMutation, useGetApplicationActionsQuery } from "@/store/services/application.query";
import { InputList, BackLink, Button } from "@/ui/index";

import styles from "./AddApplication.module.scss";
import { applocation } from "./mockdata";

const isFormFilled = (values: InputData[]): boolean => {
  return values.every((el) => {
    return el.required && el.disabled !== true ? el.value !== undefined && el.value !== "" : true;
  });
};

const AddApplication = () => {
  const { officeId, id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = useUserPermissions();
  const [values, setValues] = useState<InputData[]>(applocation);
  const [actions, setActions] = useState<Model.Action[]>([]);
  const [actionOptions, setActionOptions] = useState<Option[]>([]);
  const [currectAction, setCurrentAction] = useState<number | null>(null);
  const [selectedClientByName, setSelectedClientByName] = useState<Model.Client | string>("");
  const [selectedClientByPhone, setSelectedClientByPhone] = useState<Model.Client | string>("");
  const [selectedClientByEmail, setSelectedClientByEmail] = useState<Model.Client | string>("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fetchActions = useGetApplicationActionsQuery(String(officeId) || String(id));
  const [createApplication, createdApplication] = useCreateApplicationMutation();
  const [isError, setIsError] = useState<number | null>(null);

  const valueUpdateFieldBySelected = (filed: string, value: string | Model.Client) => {
    if (value !== null && typeof value !== "string") {
      setValues(updatedInputsData(values, value));
    } else {
      setValues(values.map((el) => (el.field === filed ? { ...el, value } : el)));
    }
  };

  useEffect(() => {
    setIsError(permissions?.customCreateApplications ? null : 403);
  }, [permissions?.customCreateApplications]);

  useEffect(() => {
    setValues(
      values.map((item) => {
        let newItem = item;
        if (item.field === "action") {
          newItem = {
            ...item,
            options: actionOptions.filter((el) => el.value !== actionOptions[0].value),

            value: actions.length > 0 ? actionOptions[0].value : 0,
          };
        }
        return newItem;
      }),
    );
  }, [actions]);

  useEffect(() => {
    console.log("values", values);
    setIsFormValid(isFormFilled(values));
  }, [values]);

  useEffect(() => {
    if (createdApplication.isSuccess && createdApplication.data) {
      enqueueSnackbar("Заявка создана!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate(`/applications/${officeId || id}`);
      setIsLoading(false);
    }

    if (createdApplication.isError && createdApplication.error) {
      // setSelectedClientByPhone("");
      // setSelectedClientByEmail("");
      setIsLoading(false);
      const errors = createdApplication.error as ErrorObject[];
      if (errors.length > 0) {
        const nonField = errors?.find((error) => error.attr === "nonFieldErrors");
        if (nonField) {
          enqueueSnackbar(nonField.detail, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
        setValues(addedErrorOnField(errors, values));
      } else {
        setValues(addedErrorOnField([], values));
      }
    }
  }, [createdApplication]);

  useEffect(() => {
    if (fetchActions.isSuccess && fetchActions.data) {
      const actionsList = (fetchActions.data as Model.Action[]).filter((item) => item.isActive);
      setActions(actionsList);

      const newActions = [] as Option[];
      newActions.push({ name: "Нотариальное действие", value: "" });
      actionsList.forEach((el) => {
        newActions.push({ name: el.actionName, value: Number(el.id) });
      });
      setActionOptions(newActions);
    }
  }, [fetchActions]);

  useEffect(() => {
    valueUpdateFieldBySelected("email", selectedClientByEmail);
  }, [selectedClientByEmail]);

  useEffect(() => {
    valueUpdateFieldBySelected("phoneNumber", selectedClientByPhone);
  }, [selectedClientByPhone]);

  useEffect(() => {
    valueUpdateFieldBySelected("name", selectedClientByName);
  }, [selectedClientByName]);

  useEffect(() => {
    if (currectAction) {
      const action = actions.filter((action) => action.id === currectAction);

      setValues(
        values.map((item) => {
          const newItem = item;

          if (item.field === "type" && action[0] && action[0].actionType) {
            newItem.value = typeof action[0].actionType === "string" ? action[0].actionType : action[0].actionType.value;
            // @ts-ignore
            newItem.defaultOption = action[0].actionType;
          }
          return newItem;
        }),
      );
    }
  }, [currectAction, actions]);

  const changeHandle: InputOnChange = (newVal) => {
    if (newVal.field === "action") {
      setCurrentAction(Number(newVal.value));
    }
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
  };

  const submitHandle = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const desiredValues: Model.Application = getValueFromArray(values);
    let selectedClientId: number | string | undefined = undefined;

    if (selectedClientByEmail && typeof selectedClientByEmail !== "string") {
      selectedClientId = selectedClientByEmail.id;
    } else if (selectedClientByPhone && typeof selectedClientByPhone !== "string") {
      selectedClientId = selectedClientByPhone.id;
    } else if (selectedClientByName && typeof selectedClientByName !== "string") {
      selectedClientId = selectedClientByName.id;
    }

    const data = selectedClientId ? { ...desiredValues, client: selectedClientId } : desiredValues;
    //@ts-ignore
    createApplication({ officeId: String(officeId) || String(id), data });
  };

  return (
    <div className={`${styles.addApplication} container`}>
      <Helmet>
        <title>Создать заявку</title>
      </Helmet>
      <Container overflow>
        <SwitchTransition>
          <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
            {isError ? (
              <ErrorResult code={isError} />
            ) : (
              <div className={styles.inner}>
                <BackLink to={`/applications/${officeId}/`} />

                <form onSubmit={submitHandle} className={styles.form}>
                  <h2>Новая заявка</h2>

                  <div className={styles.formList}>
                    <InputList
                      values={values}
                      setSelectedClientByName={setSelectedClientByName}
                      setSelectedClientByPhone={setSelectedClientByPhone}
                      setSelectedClientByEmail={setSelectedClientByEmail}
                      changeHandle={changeHandle}
                      formId={""}
                    />
                  </div>

                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!isFormValid || isLoading}
                    isFullWidth
                    loader={isLoading}>
                    Создать заявку
                  </Button>
                </form>
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </Container>
    </div>
  );
};

export default AddApplication;
