export const loginInputsData = [
  {
    id: 1,
    value: "",
    placeholder: "Почта",
    type: "email",
    field: "email",
    label: "Почта",
    required: true,
    inputSize: "big",
  },
  {
    id: 2,
    value: "",
    placeholder: "Пароль",
    withRecovery: true,
    type: "password",
    field: "password",
    label: "Пароль",
    required: true,
    inputSize: "big",
  },
];
export const inputsPassData = [
  {
    id: 1,
    value: "",
    placeholder: "Пароль",
    type: "password",
    field: "password",
    required: true,
    minLength: 8,
  },
  {
    id: 2,
    value: "",
    placeholder: "Повторите пароль",
    type: "password",
    field: "confirmPassword",
    required: true,
  },
];
export const passRecoveryData = [
  {
    id: 1,
    value: "",
    field: "email",
    placeholder: "Почта",
    type: "email",
    required: true,
  },
];
