import cn from "classnames";

import styles from "@/blocks/Info/Info.module.scss";

const Info = ({
  officeNumber,
  createdDate,
  districtFullName,
  noMargin,
  className,
  isUser,
}: {
  officeNumber: number;
  createdDate?: string;
  districtFullName?: string;
  noMargin?: boolean;
  className?: string;
  isUser?: boolean;
}) => {
  return (
    <div className={cn(styles.info, className, { [styles.noMargin]: noMargin })}>
      <div>№{officeNumber}</div>
      <div>{`${isUser ? "Создан" : "Создана"} ${createdDate}`}</div>
      <div>{districtFullName}</div>
    </div>
  );
};

export default Info;
