import { LockIcon, UnLockIcon } from "@/assets/icons";
import { Tombler } from "@/ui/index";

import styles from "./ActionLock.module.scss";

const ActionLock: Blocks.AddLock = ({ isChecked, changeHandle, field, id }) => {
  return (
    <div className={styles.lock}>
      <div className={`${styles.lockIcon} ${isChecked ? styles.active : styles.notActive}`}>
        {isChecked ? <UnLockIcon /> : <LockIcon />}
      </div>
      <div className={styles.content}>
        {isChecked ? (
          <>
            <h6>Активно</h6>
            <p>Нотариальное действие будет доступно для выбора</p>
          </>
        ) : (
          <>
            <h6>Неактивно</h6>
            <p>Нотариальное действие не будет доступно для выбора</p>
          </>
        )}
      </div>
      <Tombler isChecked={isChecked} onChange={changeHandle} id={id} field={field} />
    </div>
  );
};

export default ActionLock;
