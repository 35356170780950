import type { FC } from "react";

import cn from "classnames";
import { Link, useParams } from "react-router-dom";

import { ArrowLeftIcon } from "@/assets/icons";
import { ApplicationStatus } from "@/blocks/index";
import { formatDate } from "@/helpers/utils";
import { ApplicationDate, Avatar, Complexity } from "@/ui/index";
import Priority from "@/ui/Priority/Priority";

import styles from "./ApplicationCard.module.scss";
import ActionIcon from "../ActionIcon/ActionIcon";

const ApplicationCard: FC<Model.ApplicationCard> = ({
  action,
  status,
  createdAt,
  client,
  executor,
  small,
  routerState,
  target,
  ...other
}) => {
  const { officeId, id } = useParams();
  return (
    <div className={cn(styles.card, { [styles.small]: small })}>
      <Link
        to={`/applications/${officeId || id}/application/${other.id}`}
        state={routerState}
        className={styles.link}
        target={target}
        // onClick={() =>
        //   window.scrollTo({
        //     top: 0,
        //     behavior: "smooth",
        //   })
        // }
      />
      <ActionIcon id={Number(action.id)} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.contentDetail}>
            <span>№ {other.id}</span>
            <span>Создана {formatDate(createdAt, true)}</span>
          </div>
          <h6>{action.name}</h6>
          <div className={styles.contentDetail}>
            <Complexity actionType={status.actionType} />
            <ApplicationStatus status={status?.applicationStatus} />
            {status.executionDate && <ApplicationDate executionDate={status.executionDate} />}
            <Priority priority={status.priority} />
          </div>
        </div>
        <div className={styles.clientsBlock}>
          <div className={styles.client}>
            <Avatar size="small" fullName={client} />
            <div className={styles.clientContent}>
              <h6>{client}</h6>
              <p>Клиент</p>
            </div>
          </div>

          <div className={styles.client}>
            {executor ? (
              <>
                <Avatar size="small" imageUrl={executor?.avatar} fullName={executor?.fullName} />
                <div className={styles.clientContent}>
                  <h6>{executor?.fullName}</h6>
                  <p>{executor?.jobTitle}</p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.arrow}>
        <ArrowLeftIcon />
      </div>
    </div>
  );
};

export default ApplicationCard;
