// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Loader_spin-anim__yY2us {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Loader_fadeIn__m6WJP {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Loader_fadeOut__lCkAq {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes Loader_pulse__cf\\+Zn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.Loader_loader__tBTIj {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.Loader_spiner__Ny7pE {
  width: 64px;
  height: 64px;
  border: 4px solid;
  border-color: #922fb5 transparent #922fb5 transparent;
  border-radius: 50%;
  animation: Loader_spin-anim__yY2us 0.6s linear infinite;
}
.Loader_spiner__Ny7pE.Loader_sm__VAd7s {
  width: 40px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/ui/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AA8BF;;AA3BA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,qDAAA;EACA,kBAAA;EACA,uDAAA;AA8BF;AA5BE;EACE,WAAA;EACA,YAAA;AA8BJ","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.loader {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.spiner {\n  width: 64px;\n  height: 64px;\n  border: 4px solid;\n  border-color: $main transparent $main transparent;\n  border-radius: 50%;\n  animation: spin-anim 0.6s linear infinite;\n\n  &.sm {\n    width: 40px;\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__tBTIj`,
	"spiner": `Loader_spiner__Ny7pE`,
	"spin-anim": `Loader_spin-anim__yY2us`,
	"sm": `Loader_sm__VAd7s`,
	"fadeIn": `Loader_fadeIn__m6WJP`,
	"fadeOut": `Loader_fadeOut__lCkAq`,
	"pulse": `Loader_pulse__cf+Zn`
};
export default ___CSS_LOADER_EXPORT___;
