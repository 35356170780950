// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes CreateForms_spin-anim__hKglj {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes CreateForms_fadeIn__fBT6i {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes CreateForms_fadeOut__zzL4I {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes CreateForms_pulse__NKMDE {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.CreateForms_formWrapper__lMzaU {
  width: 100%;
}
.CreateForms_formWrapper__lMzaU:nth-child(1), .CreateForms_formWrapper__lMzaU:nth-child(2) {
  margin-bottom: 40px;
}
.CreateForms_formWrapper__lMzaU .CreateForms_inputList__\\+P640 {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.CreateForms_formWrapper__lMzaU .CreateForms_inputListaddress__0xZhM {
  flex-wrap: wrap;
  justify-content: space-between;
}
.CreateForms_formWrapper__lMzaU .CreateForms_col__t9AyL {
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/CreateOfficeForms/CreateForms.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACI,WAAA;AA8BJ;AA7BI;EAEI,mBAAA;AA8BR;AA3BI;EACI,aAAA;EACA,SAAA;EACA,eAAA;AA6BR;AA3BI;EACI,eAAA;EACA,8BAAA;AA6BR;AA1BI;EACI,aAAA;EACA,SAAA;AA4BR","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.formWrapper {\n    width: 100%;\n    &:nth-child(1),\n    &:nth-child(2) {\n        margin-bottom: 40px;\n    }\n\n    .inputList {\n        display: flex;\n        gap: 24px;\n        flex-wrap: wrap;\n    }\n    .inputListaddress {\n        flex-wrap: wrap;\n        justify-content: space-between;\n    }\n\n    .col {\n        display: flex;\n        gap: 20px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `CreateForms_formWrapper__lMzaU`,
	"inputList": `CreateForms_inputList__+P640`,
	"inputListaddress": `CreateForms_inputListaddress__0xZhM`,
	"col": `CreateForms_col__t9AyL`,
	"spin-anim": `CreateForms_spin-anim__hKglj`,
	"fadeIn": `CreateForms_fadeIn__fBT6i`,
	"fadeOut": `CreateForms_fadeOut__zzL4I`,
	"pulse": `CreateForms_pulse__NKMDE`
};
export default ___CSS_LOADER_EXPORT___;
