import cn from "classnames";
import { useState, type FC, useEffect } from "react";
import { Link } from "react-router-dom";

import { getUserByJwt, getInitials } from "@/helpers/index";

import styles from "./Profile.module.scss";

const Profile: FC<Blocks.Profile> = ({ fullName, role, img, disabled }) => {
  const profileRole = role && role.length > 0 && role[0];

  const [link, setLink] = useState("");
  const userData = getUserByJwt();

  useEffect(() => {
    userData && setLink(`/offices/${userData.officeId}/employees/${userData.id}`);
  }, [userData]);

  return (
    <Link to={link} className={cn({ [styles.disabled]: disabled })}>
      <div className={styles.profile}>
        <div className={styles.bio}>
          <div className={styles.name}>{fullName}</div>
          {profileRole && <div className={cn(styles.role, role ? styles.admin : "")}>{profileRole.name}</div>}
        </div>
        <div className={styles.imageWrap}>
          {img ? (
            <img src={img} alt={fullName} className={styles.avatarImg} />
          ) : (
            <div className={styles.avatarSvg}>
              {profileRole && profileRole.id === 5 ? "A" : getInitials(String(fullName))}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Profile;
