import cn from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { PlusIcon } from "@/assets/icons";
import { RolePermissions, ErrorResult } from "@/blocks/index";
import Container from "@/layout/Container/Container";
import { SideBar } from "@/layout/index";
import { useAppSelector, useActions } from "@/store/hooks";
import { useFetchRolesQuery } from "@/store/services/roles.query";
import { Button, Loader } from "@/ui/index";

import styles from "./RolesAndAccess.module.scss";

const RolesAndAccess = () => {
  const [menu, setMenu] = useState<Model.MenuItem[]>([]);
  const { pathname } = useLocation();
  const { roles, loading, createdRole } = useAppSelector((state) => state.roles);
  const [isError, setIsError] = useState<number | null>(null);
  const { setRoles, setCreatedRole } = useActions();
  const navigate = useNavigate();
  const fetchRoles = useFetchRolesQuery();

  useEffect(() => {
    setCreatedRole(null);
  }, []);

  useEffect(() => {
    if (fetchRoles.isSuccess && fetchRoles.data) {
      const fetch = fetchRoles.data as Model.Role[];

      setRoles(fetch);
    }
    if (fetchRoles.error) {
      if ((fetchRoles.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchRoles.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchRoles.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchRoles]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      const items: Model.MenuItem[] = [];
      roles.map(({ id, name }) => items.push({ id, title: name, path: `${pathname}?role=${id}` }));
      setMenu(items);
    }
  }, [roles]);

  const addRole = () => {
    navigate("/roles-and-access?role=create");
  };

  return (
    <main className={styles.roles}>
      <Helmet>
        <title>Роли</title>
      </Helmet>
      <div className={cn("container", styles.container)}>
        <h2>Роли</h2>
        <div className={styles.wrapper}>
          <Container>
            <div className={styles.inner}>
              <SwitchTransition>
                <CSSTransition key={isError ? "error" : "content"} timeout={250} classNames="fade">
                  {isError ? (
                    <div className={styles.errorWrap}>
                      <ErrorResult code={isError} />
                    </div>
                  ) : (
                    <SwitchTransition>
                      <CSSTransition key={fetchRoles.isFetching ? "content" : "loader"} timeout={300} classNames="fade">
                        {fetchRoles.isFetching ? (
                          <Loader />
                        ) : (
                          <>
                            {roles && roles.length > 0 ? (
                              <>
                                <SideBar menu={menu} isTabs scrollbar>
                                  <div className={styles.button}>
                                    <Button
                                      size="small"
                                      loader={loading.add}
                                      disabled={createdRole !== null}
                                      onClick={addRole}>
                                      <PlusIcon />
                                      Добавить роль
                                    </Button>
                                  </div>
                                </SideBar>
                                <div className={styles.content}>
                                  <RolePermissions />
                                </div>
                              </>
                            ) : (
                              <div className={styles.empty}>
                                <div>Ни одной роли не существует</div>
                                <Button loader={loading.create} disabled={createdRole !== null} onClick={addRole}>
                                  <PlusIcon />
                                  Добавить роль
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </CSSTransition>
                    </SwitchTransition>
                  )}
                </CSSTransition>
              </SwitchTransition>
            </div>
          </Container>
        </div>
      </div>
    </main>
  );
};

export default RolesAndAccess;
