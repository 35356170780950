import cn from "classnames";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { ShieldIcon } from "@/assets/icons";
import { getValueFromArray } from "@/helpers/form";
import { useCreatePasswordMutation, useCheckPasswordVerifyMutation } from "@/store/services/auth.query";
import { Button, InputField, Loader } from "@/ui/index";

import styles from "./AuthForm.module.scss";
import { inputsPassData } from "./mockData";

export const PassCreateForm = () => {
  const { state, search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [values, setValues] = useState<InputData[]>(inputsPassData);
  const [createPassword, create] = useCreatePasswordMutation();
  const [checkPasswordVerify, verify] = useCheckPasswordVerifyMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isRedirect = !state || !state.from;
  const showPage = !isRedirect || search;

  useEffect(() => {
    if (!showPage) {
      navigate("/auth");
    }

    if (!isRedirect) {
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    }

    if (search) {
      // eslint-disable-next-line prefer-destructuring
      const token: string = search.split("=")[1];
      setToken(token);
      checkPasswordVerify({ token });
    }
  }, []);

  useEffect(() => {
    const { isError, isSuccess } = verify;
    if (isError) {
      navigate("/auth");
    }
    if (isSuccess) {
      setIsLoading(false);
    }
  }, [verify]);

  useEffect(() => {
    const { isError, isSuccess, error } = create;
    if (isError) {
      // @ts-ignore
      enqueueSnackbar(error?.message || "При отправке запроса возникла ошибка!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    if (isSuccess) {
      navigate("/auth");
      enqueueSnackbar("Новый пароль сохранен!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [create]);

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
  };

  const onSubmitHandle = (e: React.SyntheticEvent) => {
    if (values[0].value === values[1].value) {
      const desiredValues: { password: string } = getValueFromArray(values);
      token !== "" && createPassword({ token, password: desiredValues.password });
    } else {
      setValues(
        values.map((item) =>
          item.field === "confirmPassword" ? { ...item, helperText: "Пароли не совпадают", invalid: true } : item,
        ),
      );
    }
    e.preventDefault();
  };

  return (
    <>
      {showPage && (
        <div className={styles.form}>
          <Helmet>
            <title>Придумайте пароль</title>
          </Helmet>
          <div className={cn(styles.loader, { [styles.active]: isLoading })}>
            <Loader />
          </div>
          <form className={cn(styles.form, styles.createForm)} onSubmit={onSubmitHandle}>
            <div className={styles.icon}>
              <ShieldIcon />
            </div>
            <div>
              <h4 className={styles.subtitle}>Придумайте новый пароль</h4>
              <p className={styles.text}>Пароль должен содержать не менее 8 символов</p>
            </div>
            {values.map(({ id, ...other }) => (
              <InputField id={id} key={id} {...other} onChange={changeHandle} />
            ))}
            <Button type="submit" loader={create.isLoading}>
              Сохранить новый пароль
            </Button>
          </form>
        </div>
      )}
    </>
  );
};
