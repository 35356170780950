import { WarningIcon } from "@/assets/icons";

import styles from "./Clue.module.scss";

export const Clue = ({ text }: { text: string }) => (
  <div className={styles.wrapper}>
    <WarningIcon />
    {text}
  </div>
);
