import type { RootState } from "..";
import type { PayloadAction, ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

import { formatPhoneNumber, getDateString, getDefaultStatus } from "@/helpers/index";

import { fetchScans, createScan, deleteScan } from "../services/scansService";

const initialState: ClientsSlice = {
  clients: [] as Model.Client[],
  client: {} as Model.ClientResponse,
  members: [] as Model.Member[],
  cards: [] as Model.LinkedPerson[],
  scans: { loading: false, data: null },
  appicationCount: 0,
};

const ClientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, { payload }: PayloadAction<Model.Client[]>) => {
      state.clients = payload.map((el) => ({
        ...el,
        status: getDefaultStatus(el.status as Status),
        createdAt: getDateString(el.createdAt as unknown as Date),
      }));
    },
    setClient: (state, { payload }: PayloadAction<Model.ClientResponse>) => {
      state.client = {
        ...payload,
        status: getDefaultStatus(payload.status as Status),
        createdAt: payload.createdAt && getDateString(new Date(payload.createdAt)),
        dateOfBirth: payload.dateOfBirth,
        phoneNumber: payload.phoneNumber ? formatPhoneNumber(payload.phoneNumber) : "",
      };
    },
    setMembers: (state, { payload }: PayloadAction<Model.Member[]>) => {
      state.members = payload;
    },
    setCards: (state, { payload }: PayloadAction<Model.LinkedPerson[]>) => {
      state.cards = payload.map((el) => ({
        ...el,
        status: getDefaultStatus(el.status as Status),
        createdAt: getDateString(el.createdAt as unknown as Date),
      }));
    },
    setAppicationCount: (state, { payload }: PayloadAction<number>) => {
      state.appicationCount = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ClientsSlice>) => {
    builder
      .addCase(fetchScans.pending, (state: ClientsSlice) => {
        state.scans.loading = true;
      })
      .addCase(fetchScans.fulfilled, (state: ClientsSlice, { payload }: PayloadAction<Response.Document[]>) => {
        state.scans = { loading: false, data: payload };
      })
      .addCase(fetchScans.rejected, (state: ClientsSlice) => {
        state.scans.loading = false;
      })
      .addCase(createScan.pending, (state: ClientsSlice, action) => {
        if (state.scans.data) {
          const scans = state.scans.data?.map((item) => {
            let newItem = item;
            if (action.meta.arg.data.get("document") === String(item.id)) newItem = { ...newItem, loading: true };
            return newItem;
          });

          state.scans = { ...state.scans, data: scans, error: null };
        }
      })
      .addCase(createScan.fulfilled, (state: ClientsSlice, action) => {
        if (state.scans.data) {
          const scans = state.scans.data?.map((item) => {
            let newItem = item;
            if (action.meta.arg.data.get("document") === String(item.id)) {
              const newScans = [...item.scans, ...action.payload];
              newItem = { ...newItem, scans: newScans, loading: false };
            }
            return newItem;
          });

          state.scans = { ...state.scans, data: scans };
        }
      })
      .addCase(createScan.rejected, (state: ClientsSlice, action) => {
        if (state.scans.data) {
          const scans = state.scans.data?.map((item) => {
            let newItem = item;
            if (action.meta.arg.data.get("document") === String(item.id)) {
              newItem = { ...newItem, loading: false };
            }
            return newItem;
          });

          state.scans = {
            ...state.scans,
            data: scans,
            error:
              //@ts-ignore
              action?.payload?.response?.data?.errors[0]?.detail ||
              "При загрузке файлов на сервер произошла ошибка. Попробуйте снова",
          };
        }
      })
      .addCase(deleteScan.pending, (state: ClientsSlice, action) => {
        if (state.scans.data) {
          const scans = state.scans.data?.map((item) => {
            let newItem = item;
            if (action.meta.arg.documentId === item.id) {
              const newScans = item.scans.map((el) => {
                let newEl = el;
                if (action.meta.arg.scanId === newEl.id) {
                  newEl = { ...newEl, loading: true };
                }
                return newEl;
              });
              newItem = { ...newItem, scans: newScans };
            }
            return newItem;
          });

          state.scans = { ...state.scans, data: scans, error: null };
        }
      })
      .addCase(deleteScan.fulfilled, (state: ClientsSlice, action) => {
        if (state.scans.data) {
          const scans = state.scans.data?.map((item) => {
            let newItem = item;
            if (action.meta.arg.documentId === item.id) {
              const newScans = item.scans.filter((el) => action.meta.arg.scanId !== el.id);
              newItem = { ...newItem, scans: newScans };
            }
            return newItem;
          });
          state.scans = { ...state.scans, data: scans };
        }
      })
      .addCase(deleteScan.rejected, (state: ClientsSlice, action) => {
        if (state.scans.data) {
          const scans = state.scans.data?.map((item) => {
            let newItem = item;
            if (action.meta.arg.documentId === item.id) {
              const newScans = item.scans.map((el) => {
                let newEl = el;
                if (action.meta.arg.scanId === newEl.id) {
                  newEl = { ...newEl, loading: false };
                }
                return newEl;
              });
              newItem = { ...newItem, scans: newScans };
            }
            return newItem;
          });

          state.scans = {
            ...state.scans,
            data: scans,
            error:
              //@ts-ignore
              action?.payload?.response?.data?.errors[0]?.detail ||
              "При попытке удалить файл произошла ошибка. Попробуйте снова",
          };
        }
      });
  },
});
export const office = (state: RootState) => state.user;
export const { setClients, setClient, setMembers, setCards, setAppicationCount } = ClientsSlice.actions;
export default ClientsSlice.reducer;
