import { useEffect, useState } from "react";

import FormWrapper from "../FormWrapper";

const InfoForm = ({
  infoData,
  updateFields,
}: {
  infoData: InputData[];
  updateFields: (fields: Partial<CreateOfficeData>) => void;
}) => {
  const [values, setValues] = useState<InputData[]>(infoData);
  useEffect(() => {
    setValues(infoData);
  }, [infoData]);
  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
    updateFields({ info: newInputProps });
  };
  return <FormWrapper title="Создать нотариальную контору" values={values} changeHandle={changeHandle} />;
};

export default InfoForm;
