import type { FC } from "react";

import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Select } from "@/ui/index";

import styles from "./Sort.module.scss";

const getSelectedValue = (param: string | null, options: Option[]) => {
  let selectedValue = null;
  if (param) {
    const foundOption = options.find((option) => String(option.value) === param);
    selectedValue = foundOption || null;
  }

  return selectedValue;
};

const Sort: FC<Blocks.Sort> = ({ options, className, paramName = "sort" }) => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    getSelectedValue(searchParams.get(paramName), options),
  );
  const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get("page")!) || 1);

  const handleSelectChange = (val: { field: string; value: string | number; id: number | string }) => {
    const foundOption = options.find((option) => option.value === val.value);
    foundOption && setSelectedOption(foundOption);

    if (val.value && val.value !== "undefined") {
      searchParams.set(paramName, val.value.toString());
    } else {
      searchParams.delete(paramName);
    }
    if (currentPage > 1) {
      searchParams.set("page", "1");
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    setCurrentPage(parseInt(searchParams.get("page")!) || 1);
  }, [search]);

  return (
    <Select
      options={options}
      onSelect={handleSelectChange}
      defaultValue={selectedOption ? selectedOption : options[0]}
      className={cn(className, styles.sort)}
      id={0}
      value={""}
      field={""}
    />
  );
};

export default Sort;
