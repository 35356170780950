import { useNavigate } from "react-router-dom";

import { Button, Modal } from "@/ui/index";

import styles from "./ModalSaveData.module.scss";

const ModalSaveData = ({
  setShowModal,
  showModal,
  navigateLink,
}: {
  setShowModal: (val: boolean) => void;
  showModal: boolean;
  navigateLink: string;
}) => {
  const navigate = useNavigate();

  const backToOfficeHandle = () => {
    setShowModal(false);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "inherit";
      body.style.paddingRight = "0";
    }

    navigate(navigateLink);
  };
  return (
    <Modal title="Если выйти, то изменения не будут сохранены" show={showModal} setShow={setShowModal}>
      <div className={styles.modal}>
        <div className={styles.buttons}>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Не выходить
          </Button>
          <Button variant="secondary" onClick={backToOfficeHandle}>
            Да, выйти
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSaveData;
