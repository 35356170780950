import type { FC } from "react";

import { BagIcon2, EmailIcon, MapIcon, PhoneIcon, PhoneIcon2, Draft2Icon } from "@/assets/icons";
import { formatPhoneNumber } from "@/helpers/index";

import styles from "./AddressBlock.module.scss";

const AddressBlock: FC<AddressModel.AddressData> = ({ address, email, phone, role, className, mobilePhone, inn }) => {
  const addressItem = {
    ...(address !== null && address
      ? {
          icon: <MapIcon />,
          action: (
            <div className={styles.line}>
              {`г.${address.city}, ул.${address.street}, д.${address.house}, офис ${address.office}`}
            </div>
          ),
          className: styles.name,
        }
      : {}),
  };
  const cardBottom = [
    phone ? { icon: <PhoneIcon />, action: formatPhoneNumber(String(phone)) } : null,
    mobilePhone ? { icon: <PhoneIcon2 />, action: formatPhoneNumber(String(mobilePhone)) } : null,
    { icon: <EmailIcon />, action: email },
    address ? { ...addressItem } : null,
    role ? { icon: <BagIcon2 />, action: role } : null,
    inn ? { icon: <Draft2Icon className={styles.iconsmall} />, action: `ИНН ${inn}` } : null,
  ];

  return (
    <div className={`${styles.address} ${className}`}>
      {cardBottom.map((el, idx) =>
        el ? ( // @ts-ignore
          <span key={idx} className={el?.className}>
            {el?.icon} {el?.action}
          </span>
        ) : null,
      )}
    </div>
  );
};

export default AddressBlock;
