import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFetchEmployeesQuery } from "@/store/services/employee.query";

export const useEmployeeOptions = (officeId: number) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [modalSearch, setModalSearch] = useState("");
  const [employeeOptions, setEmployeeOptions] = useState<Option[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Option>({} as Option);
  const fetchedEmployees = useFetchEmployeesQuery({ officeId, query: modalSearch.length > 0 ? `?${modalSearch}` : "" });

  useEffect(() => {
    if (fetchedEmployees.isSuccess && fetchedEmployees.data) {
      const response = fetchedEmployees.data as Response.EmployeeResponse;
      setPagination({
        count: response.count,
        page: 1,
        previous: response.previous,
        next: response.next,
      });
      const options: Option[] = [];
      response.results.forEach((el) => {
        options.push({
          id: Number(el.id),
          name: String(el.fullName),
          value: Number(el.id),
          suggestion: { supervisorPhone: el.phoneNumber },
        });
      });

      if (options.length > 0) {
        setEmployeeOptions([...employeeOptions, ...options]);
        setSelectedEmployee(options[0]);
      }
    }
    fetchedEmployees.isError && navigate("/not-found");
  }, [fetchedEmployees]);

  const scrollHandler = () => {
    if (pagination.next && !fetchedEmployees.isFetching) {
      const searchStr = pagination.next.split("?").at(-1);

      if (searchStr) {
        setModalSearch(searchStr);
      }
    }
  };

  return { employeeOptions, selectedEmployee, scrollHandler };
};

export default useEmployeeOptions;
