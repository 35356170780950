import type { FC } from "react";

import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { CopyIcon } from "@/assets/icons";
import { useFetchAppicationDocumentsListQuery } from "@/store/services/application.query";
import { Modal, Button, Loader } from "@/ui/index";

import styles from "./ApplicationDocumentsList.module.scss";

const ApplicationDocumentsList: FC<{
  show: boolean;
  setShow: (val: boolean) => void;
  officeId: string;
  applicationId: string;
}> = ({ show, setShow, officeId, applicationId }) => {
  const [list, setList] = useState<string[]>([]);
  const fetchAppicationDocumentsList = useFetchAppicationDocumentsListQuery({
    officeId: Number(officeId),
    applicationId: Number(applicationId),
  });

  useEffect(() => {
    if (fetchAppicationDocumentsList.isSuccess && fetchAppicationDocumentsList.data) {
      setList(fetchAppicationDocumentsList.data as string[]);
    }
  }, [fetchAppicationDocumentsList]);

  const copyList = () => {
    navigator.clipboard // eslint-disable-next-line
      .writeText(list.join(`\n`))
      .then(() => {
        enqueueSnackbar("Список документов успешно скопирован", {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch(() => {
        enqueueSnackbar("При попытке скопировать текст, что-то пошло не так. Попробуйте снова", {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <Modal show={show} setShow={setShow} size="lg" className={styles.modal}>
      <div className={styles.modalTop}>
        <h4>Вам необходимо прикрепить следующие документы</h4>
      </div>
      <div className={styles.modalInner}>
        <SwitchTransition>
          <CSSTransition
            key={fetchAppicationDocumentsList.isLoading ? "loader" : "content"}
            timeout={250}
            classNames="fade">
            {fetchAppicationDocumentsList.isLoading ? (
              <div className={styles.loaderWrap}>
                <Loader />
              </div>
            ) : (
              <>
                <div className={styles.listWr}>
                  <ul className={styles.list}>
                    {list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <Button variant="primary" onClick={copyList} className={styles.button}>
                  <CopyIcon />
                  Копировать
                </Button>
              </>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Modal>
  );
};

export default ApplicationDocumentsList;
