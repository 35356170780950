import { Fragment } from "react";

import { Loader } from "@/ui/index";

import styles from "./Preloader.module.scss";

type LoaderProps = {
  active: boolean;
  children?: JSX.Element;
};
const Preloader = ({ active, children }: LoaderProps) => {
  return (
    <div className={styles.wrap}>
      <div className={`${styles.preloader} ${active ? styles.active : ""}`}>
        <Loader />
      </div>
      {active ? null : <Fragment>{children}</Fragment>}
    </div>
  );
};

export default Preloader;
