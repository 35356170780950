import { useState } from "react";

import { InputField } from "@/ui/index";

import styles from "./Search.module.scss";

const Search = ({
  onChange,
  placeholder,
  className,
}: {
  onChange?: InputOnChange;
  placeholder: string;
  className?: string;
}) => {
  const [query, setQuery] = useState<string | number>("");

  const changeHandle: InputOnChange = (newVal) => {
    setQuery(newVal.value);
    onChange && onChange(newVal);
  };
  return (
    <>
      <InputField
        id={1}
        type="search"
        field="search"
        value={query}
        placeholder={placeholder}
        onChange={changeHandle}
        inputSize="small"
        iconLeft={false}
        className={`${styles.search} ${className}`}
        autoComplete="off"
      />
    </>
  );
};

export default Search;
