import cn from "classnames";

import { Loader } from "@/ui/Loader";

import styles from "./Application.module.scss";

const ApplicationSkeleton = () => {
  return (
    <div className={cn(styles.loaderWrap, styles.content)}>
      <div className={cn(styles.inner, styles.loaderContent)}>
        <div className={styles.loaderBlock}>
          <div className={cn(styles.skeleton, styles.h40, styles.w40)} />
          <div className={cn(styles.skeleton, styles.h26, styles.w220)} />
        </div>
        <div className={styles.loaderPleloader}>
          <Loader />
        </div>
      </div>
      <div className={styles.history}>
        <div className={styles.loaderHistory}>
          <div className={styles.loaderHistoryWrap}>
            <div className={cn(styles.skeleton, styles.h21, styles.w120)} />
          </div>
          <div className={styles.loaderHistoryDesc}>
            <div className={cn(styles.skeleton, styles.h16, styles.w90)} />
            <div className={styles.loaderHistoryUser}>
              <div className={cn(styles.skeleton, styles.h16, styles.w160)} />
              <div className={cn(styles.skeleton, styles.round, styles.h20, styles.w20)} />
            </div>
          </div>
        </div>
        <div className={styles.loaderHistory}>
          <div className={styles.loaderHistoryWrap}>
            <div className={cn(styles.skeleton, styles.h21, styles.w120)} />
          </div>
          <div className={styles.loaderHistoryDesc}>
            <div className={cn(styles.skeleton, styles.h16, styles.w90)} />
            <div className={styles.loaderHistoryUser}>
              <div className={cn(styles.skeleton, styles.h16, styles.w160)} />
              <div className={cn(styles.skeleton, styles.round, styles.h20, styles.w20)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSkeleton;
