export const tabsData = [
  {
    id: 1,
    text: "Новые",
    notific: 4,
    link: "/offices?status=new",
    status: "new",
  },
  {
    id: 2,
    text: "Активные",
    notific: 3,
    link: "/offices?status=active",
    status: "active",
  },
  {
    id: 3,
    text: "Неактивные",
    notific: 4,
    link: "/offices?status=deactive",
    status: "deactive",
  },
];
export const optionsData = [
  { value: "", name: "Сортировать" },
  { value: "recent", name: "Сначала добавленные недавно" },
  { value: "oldest", name: "Сначала добавленные давно" },
];
