export const employeesData: Model.Employee[] = [
  {
    id: 1,
    image: "/link_to_img1",
    fullName: "Архипов Станислав Никитевич",
    email: "usermailname@mail.ru",
    role: [
      {
        id: 0,
        name: "Нотариус",
      },
    ],
    createdAt: "21.08.2022",
    status: {
      type: "active",
      name: "активный",
    },
    phoneNumber: "+79009999999",
  },
  {
    id: 2,
    image: "/link_to_img2",
    fullName: "Semyon Anisimov",
    email: "Semyon@mail.ru",
    role: [
      {
        id: 0,
        name: "Нотариус",
      },
    ],
    createdAt: "21.08.2022",
    status: {
      type: "active",
      name: "активный",
    },
    phoneNumber: "+79009999999",
  },
];
export const employeesOptions1 = [
  { value: "", name: "Сортировать по" },
  { value: "-created_at", name: "Зарегистрированы недавно" },
  { value: "created_at", name: "Зарегистрированы давно" },
  {
    value: "-is_active",
    name: "Сначала Активные",
  },
  {
    value: "is_active",
    name: "Сначала Неактивные",
  },
  {
    value: "full_name",
    name: "По алфавиту А-Я",
  },
  {
    value: "-full_name",
    name: "По алфавиту Я-А",
  },
];
