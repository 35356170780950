import { priorityMockdata } from "@/blocks/ClientCard/mockData";
import { types as typesData } from "@/pages/AddApplication/mockdata";

export const applicationsData = [
  { value: "default", name: "Все заявки" },
  { value: "undefined", name: "Без сортировки" },
];

export const executors = [
  { name: "Исполнитель", value: "" },
  { name: "C исполнителем", value: "true" },
  { name: "Без исполнителя", value: "false" },
  { name: "Все исполнители", value: "undefined" },
];

export const types = [
  { name: "Критерий сложности", value: "" },
  { name: "Все критерии", value: "undefined" },
  ...typesData,
];

export const priorities = [
  { name: "Приоритет", value: "" },
  { name: "Все приоритеты", value: "undefined" },
  ...priorityMockdata,
];
