import { useErrorBoundary } from "react-error-boundary";

import { ErrorResult } from "@/blocks/index";
import { Button } from "@/ui/index";

import styles from "./ErrorFallBack.module.scss";

const ErrorFallback = () => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className={`container ${styles.errorFallback}`}>
      <ErrorResult title="Что-то пошло не так..." subtext="Мы извиняемся за неудобства." />
      <Button onClick={resetBoundary} variant="primary">
        Попробовать снова
      </Button>
    </div>
  );
};

export default ErrorFallback;
