import { Endpoints } from "@/api/endpoints";

import $api from "../api";

export const DocumentService = {
  async createDocument({
    clientId,
    data,
  }: {
    clientId: number | string;
    data: Model.Document;
  }): Promise<Model.Document | { errors: ErrorObject[] }> {
    const sendData = data;
    const res = await $api.post<Model.Document | { errors: ErrorObject[] }>(
      `${Endpoints.CLIENTS}${clientId}/document/`,
      sendData,
    );
    return res.data;
  },
  async fetchDocuments({
    clientId,
    query,
  }: {
    clientId: number | string;
    query: string;
  }): Promise<Response.DocumentsResponse | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.CLIENTS}${clientId}/document/${query}`);
    return res.data;
  },
  async deleteDocument({
    clientId,
    documentId,
  }: {
    documentId: number;
    clientId: number | string;
  }): Promise<ResponseSuccess | { errors: ErrorObject[] }> {
    const res = await $api.delete<ResponseSuccess | { errors: ErrorObject[] }>(
      `${Endpoints.CLIENTS}${clientId}/document/${documentId}/update/`,
    );
    return res.data;
  },
  async updateDocument({
    clientId,
    documentId,
    data,
  }: {
    clientId: number | string;
    documentId: number;
    data: Model.Document;
  }): Promise<Model.Document | { errors: ErrorObject[] }> {
    const res = await $api.put<Model.Document | { errors: ErrorObject[] }>(
      `${Endpoints.CLIENTS}${clientId}/document/${documentId}/update/`,
      data,
    );
    return res.data;
  },
};
