import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { DocumentCard } from "@/blocks/index";
import { highlightMatch } from "@/helpers/utils";
import { useAddAppicationClientReprasentativeMutation } from "@/store/services/application.query";
import { useLazyFetchMembersQuery } from "@/store/services/client.query";
import { Button, InputField } from "@/ui/index";

import styles from "./ApplicationRepresentativeForm.module.scss";

export const ApplicationRepresentativeForm = ({
  isEdit,
  editMember,
  clientId,
  memberId,
  officeId,
  applicationId,
  backLink,
}: Blocks.ApplicationRepresentativeForm) => {
  const [currentValue, setCurrentValue] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Model.Member | null>(null);
  const [clients, setClients] = useState<Model.Member[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [trigger, triggeredClientsData] = useLazyFetchMembersQuery();
  const [addReprasentative, addReprasentativeState] = useAddAppicationClientReprasentativeMutation();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setSelectedSuggestion(editMember);
    setCurrentValue("");
    setIsDirty(!editMember);
  }, [clientId]);

  useEffect(() => {
    setSelectedSuggestion(editMember);
  }, [isEdit, editMember]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const queryString = `?&search=${searchQuery}`;
      trigger({ clientId: Number(clientId), query: queryString });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (triggeredClientsData.isSuccess && triggeredClientsData.data) {
      setClients((triggeredClientsData.data as Response.MemberResponse)?.results);
    }
  }, [triggeredClientsData]);

  useEffect(() => {
    if (addReprasentativeState.isSuccess) {
      enqueueSnackbar("Представитель успешно добавлен", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setIsDirty(false);
    }
    if (addReprasentativeState.error && (addReprasentativeState.error as ErrorObject[])) {
      enqueueSnackbar((addReprasentativeState.error as ErrorObject[])[0].detail, {
        variant: "error",
      });
    }
  }, [addReprasentativeState]);

  const onInputChange: InputOnChange = (newVal) => {
    setCurrentValue(newVal.value.toString());
    setShowSuggestions(true);
    setSearchQuery(newVal.value.toString().toLowerCase());
  };

  const filterSuggestions = () => {
    return clients.filter(
      (client) =>
        String(client.linkedPerson.name)?.toLowerCase().includes(currentValue.toLocaleLowerCase()) && showSuggestions,
    );
  };

  const handleSuggestionClick = (suggestion: Model.Member) => {
    setCurrentValue(String(suggestion.linkedPerson.name));

    setShowSuggestions(false);
    setSelectedSuggestion(suggestion);
  };

  const handleEdit = () => {
    setSelectedSuggestion(null);
    setCurrentValue("");
    setShowSuggestions(false);
    setIsDirty(true);
  };

  const saveRepresentative = () => {
    if (selectedSuggestion && selectedSuggestion.id) {
      addReprasentative({ representativeId: selectedSuggestion.id, memberId, applicationId, officeId });
    }
  };

  return (
    <div className={styles.wrapper}>
      <h5>Представитель</h5>
      <div className={styles.searchInput}>
        {!selectedSuggestion && isEdit && (
          <InputField
            id={7}
            value={currentValue}
            label="ФИО представителя"
            onChange={onInputChange}
            placeholder="Иванов Иван Иванович"
            type="search"
            field="linkedPerson"
            required={true}
            hasLabelMark={true}
          />
        )}
        {!selectedSuggestion && !isEdit && <div className={styles.empty}>Представитель не назначен</div>}
        {showSuggestions && currentValue.trim().length > 0 && (
          <div className={styles.searchListWpapper}>
            <ul className={styles.searchList}>
              {filterSuggestions().length === 0 ? <li className={styles.searchItem}>Ничего не найдено</li> : null}
              {filterSuggestions().map((suggestion, index) => (
                <li
                  className={styles.searchItem}
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  dangerouslySetInnerHTML={{
                    __html: highlightMatch(String(suggestion.linkedPerson.name), currentValue, styles.highlight),
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
      <TransitionGroup component={null}>
        {selectedSuggestion && (
          <CSSTransition key="card" classNames="fade" timeout={250}>
            <DocumentCard
              inApp
              number={selectedSuggestion.number}
              name={selectedSuggestion.name}
              issuedBy={selectedSuggestion.issuedBy}
              registrationDate={selectedSuggestion.registrationDate}
              registrationDepartment={selectedSuggestion.registrationDepartment}
              registrationNumber={selectedSuggestion.registrationNumber}
              linkedPerson={selectedSuggestion.linkedPerson}
              isMember={true}
              editHandle={isEdit ? handleEdit : undefined}
              target="_blank"
              backLink={backLink}
            />
          </CSSTransition>
        )}
        {isEdit && isDirty && (
          <CSSTransition key="button" classNames="fade" timeout={250}>
            <Button
              variant="primary"
              onClick={saveRepresentative}
              className={styles.button}
              disabled={!selectedSuggestion || addReprasentativeState.isLoading}
              loader={addReprasentativeState.isLoading}>
              Сохранить
            </Button>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};
