import classNames from "classnames";
import { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { Tombler, TimePicker } from "@/ui/index";

import styles from "./ScheduleForm.module.scss";
import FormWrapper from "../FormWrapper";

const ScheduleForm = ({
  scheduleArray,
  updateFields,
}: {
  scheduleArray: Schedule.ScheduleItem[];
  updateFields: (fields: Partial<CreateOfficeData>) => void;
}) => {
  const [scheduleData, setScheduleData] = useState<Schedule.ScheduleItem[]>(scheduleArray);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setScheduleData(scheduleArray);
  }, [scheduleArray]);

  useEffect(() => {
    if (isChanged) {
      updateFields({ schedule: scheduleData });
      setIsChanged(false);
    }
  }, [isChanged]);

  const changeHandle = (
    newValue: { field: string; value: boolean | string | number; id: number | string },
    itemId: number,
  ) => {
    let newData = scheduleData.map((item) =>
      item.id === itemId
        ? {
            ...item,
            invalid: false,
            helperText: undefined,
            available: newValue.field === "tombler" ? Boolean(newValue.value) : item.available,
            timeSlots: item.timeSlots.map((timeSlot) => {
              let newSlot =
                `time_${timeSlot.field}${itemId}` === newValue.id
                  ? { ...timeSlot, value: newValue.value, invalid: false, helperText: undefined }
                  : timeSlot;
              const splitedValue = String(newSlot.value).split(":");
              if (String(newSlot.value).trim() !== "") {
                if (Number(splitedValue[0]) > 23 || Number(splitedValue[1]) > 59) {
                  newSlot = { ...newSlot, invalid: true, helperText: "Введите корректное значение" };
                }
              }
              return newSlot;
            }),
          }
        : item,
    );

    newData = newData.map((item) => {
      let newItem = item;
      const startSplit = String(newItem.timeSlots[0].value).split(":");
      const endSplit = String(newItem.timeSlots[1].value).split(":");
      if (Number(startSplit[0]) >= Number(endSplit[0])) {
        if (Number(startSplit[0]) === Number(endSplit[0]) && Number(startSplit[1]) < Number(endSplit[1])) {
          newItem = { ...newItem, invalid: false, helperText: undefined };
        } else {
          newItem = {
            ...newItem,
            invalid: true,
            helperText: "Время окончания не может начинаться раньше начала, либо совпадать",
          };
        }
      }
      return newItem;
    });

    setScheduleData(newData);

    setIsChanged(true);
  };

  return (
    <FormWrapper title="Создать нотариальную контору" subtitle="График работы">
      <ul className={styles.list}>
        {scheduleData.map((item) => (
          <li className={classNames(styles.item, { [styles.invalid]: item.invalid })} key={item.id}>
            <div className={styles.day}>{item.day}</div>
            <Tombler
              field="tombler"
              label={item.available ? "Работает" : "Выходной"}
              isChecked={item.available}
              id={item.id}
              onChange={(newVal) => changeHandle(newVal, item.id)}
            />
            {item.timeSlots.map((other) => (
              <TimePicker
                id={`time_${other.field}${item.id}`}
                key={`time_${other.field}${item.id}`}
                onChange={(newVal) => changeHandle(newVal, item.id)}
                disabled={!item.available}
                className={styles.scheduleItem}
                value={`${other.value}`}
                field={other.field}
              />
            ))}
            <TransitionGroup component={null}>
              {item.invalid && (
                <CSSTransition key="notooltip" timeout={300} classNames="fade">
                  <span className={styles.errorText} title={item.helperText}>
                    {item.helperText}
                  </span>
                </CSSTransition>
              )}
            </TransitionGroup>
          </li>
        ))}
      </ul>
    </FormWrapper>
  );
};

export default ScheduleForm;
