import type { ChangeEvent } from "react";

import { useState, useEffect } from "react";

import styles from "./RadioGroup.module.scss";

interface RadioGroupProps {
  options: Option[];
  defaultValue?: string | number;
  field: string;
  id: string | number;
  onChange: InputOnChange;
}

export const RadioGroup = ({ options, field, id, onChange, defaultValue }: RadioGroupProps) => {
  // @ts-ignore
  const [value, setValue] = useState<string | number>(defaultValue ? defaultValue : options[0].id);

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setValue(target.id);
    onChange({ value: target.id, field, id });
  };

  return (
    <div className={styles.wrapper}>
      {options.map(({ id, name }) => (
        <label key={id} className={styles.container}>
          {name}
          <input
            type="radio"
            name={field} // @ts-ignore
            id={id}
            checked={id === value}
            onChange={changeValue}
          />
          <span className={styles.checkmark}></span>
        </label>
      ))}
    </div>
  );
};
