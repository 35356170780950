import { useEffect, useState } from "react";

import { LogoIcon, Newspaper } from "@/assets/icons";
import { getDecodedOfficeId, getTokensFromStorage } from "@/helpers/index";
import { Button } from "@/ui/index";

import styles from "./ErrorResult.module.scss";

export const ErrorResult = ({ url, title, subtext, button, code }: Blocks.ErrorResult) => {
  const token = getTokensFromStorage();
  const officeId = getDecodedOfficeId(String(token?.access));
  const [content, setContent] = useState<{ title: string; subtext?: string }>();

  const urlGenerate = () => {
    const link = officeId ? `/offices/${officeId}` : "/";

    return link;
  };

  useEffect(() => {
    switch (code) {
      case 403:
        setContent({ title: "Доступ запрещен", subtext: "Для расширений полномочий обратитесь к администратору" });
        break;
      case 404:
        setContent({ title: "Страница не найдена, либо еще не создана" });
        break;
      case 500:
        setContent({ title: "Сервер не отвечает", subtext: "Мы извиняемся за неудобства." });
        break;
      default:
        setContent({ title: "Что-то пошло не так...", subtext: "Мы извиняемся за неудобства." });
        break;
    }
  }, [code]);

  return (
    <div className={styles.root}>
      <Newspaper className={styles.newspapper} />
      <LogoIcon className={styles.logo} />
      <h6 className={styles.text}>{title || content?.title}</h6>
      {subtext || (content?.subtext && <h6 className={styles.text}>{subtext || content?.subtext}</h6>)}
      <Button href={url ? url : urlGenerate()} bordered className={styles.button}>
        {button ? button : "Перейти на главную"}
      </Button>
    </div>
  );
};

export default ErrorResult;
