export const downloadRequest = (blob: Blob, name?: string) => {
  const downloadUrl = window.URL.createObjectURL(blob);
  const linkUrl = document.createElement("a");

  linkUrl.download = `${name ? name : downloadUrl}.${blob.type.split("/").at(-1)}`;
  linkUrl.href = downloadUrl;
  document.body.appendChild(linkUrl);
  linkUrl.click();
  document.body.removeChild(linkUrl);
  linkUrl.remove();
};
