// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Sort_spin-anim__-WGKz {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Sort_fadeIn__Nu1Lu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Sort_fadeOut__oXNpe {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes Sort_pulse__wjE-K {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.Sort_sort__w3W9t {
  min-width: 165px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/Sort/Sort.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,gBAAA;AA8BF","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.sort {\n  min-width: 165px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sort": `Sort_sort__w3W9t`,
	"spin-anim": `Sort_spin-anim__-WGKz`,
	"fadeIn": `Sort_fadeIn__Nu1Lu`,
	"fadeOut": `Sort_fadeOut__oXNpe`,
	"pulse": `Sort_pulse__wjE-K`
};
export default ___CSS_LOADER_EXPORT___;
