import type { SyntheticEvent } from "react";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { getDecodedOfficeId, getTokensFromStorage, getUserByJwt, getValueFromArray } from "@/helpers/index";
import { useActions } from "@/store/hooks";
import { useLoginMutation } from "@/store/services/auth.query";
import { Button, InputField } from "@/ui/index";

import styles from "./AuthForm.module.scss";
import { loginInputsData } from "./mockData";

export const LoginForm = () => {
  const [values, setValues] = useState<InputData[]>(loginInputsData);
  const navigate = useNavigate();
  const [login, loginData] = useLoginMutation();
  const { setUser } = useActions();

  useEffect(() => {
    const token = getTokensFromStorage();
    if (loginData.isSuccess) {
      if (token) {
        const tokenDecode = getUserByJwt(token?.access);
        setUser(tokenDecode);
      }
      const officeId = getDecodedOfficeId(String(token?.access));
      navigate(`/offices/${officeId ? Number(officeId) : ""}`);
    }

    if (loginData.error) {
      console.log((loginData.error as ErrorObject[])[0].detail);
      setValues((prev) =>
        prev.map((el) =>
          el.field === "password"
            ? {
                ...el,
                invalid: true,
                helperText: (loginData.error as ErrorObject[])[0].detail,
              }
            : el,
        ),
      );
    }
  }, [loginData]);

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
    window.sessionStorage.setItem(
      "user",
      JSON.stringify([
        { field: "email", value: newInputProps[0].value },
        { field: "password", value: newInputProps[1].value },
      ]),
    );
  };

  const onSubmitHandle = (event: SyntheticEvent) => {
    event.preventDefault();
    const desiredValues: Request.Login = getValueFromArray(values);
    login(desiredValues);
  };

  return (
    <>
      <Helmet>
        <title>Вход</title>
      </Helmet>
      <form className={`${styles.form} ${styles.login}`} autoComplete="off" onSubmit={onSubmitHandle}>
        <h2>Вход</h2>
        {values.map(({ id, ...other }) => (
          <InputField id={id} key={id} {...other} onChange={changeHandle} className={styles.input} />
        ))}
        <Button type="submit" loader={loginData.isLoading} disabled={loginData.isLoading}>
          Войти
        </Button>
        <div className={styles.questionText}>
          <span>Нет нотариальной конторы?</span>
        </div>
        <Button bordered href="/create-office">
          Добавить нотариальную контору
        </Button>
      </form>
    </>
  );
};
