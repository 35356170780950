import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ArrowLeft2Icon, ReloadIcon } from "@/assets/icons";
import { ApplicationStatus } from "@/blocks/index";
import { formatDate } from "@/helpers/utils";
import { useFetchApplicationHistoryQuery } from "@/store/services/application.query";
import { Avatar } from "@/ui/index";

import styles from "./HistoryBlock.module.scss";

const HistoryBlock = () => {
  const { officeId, applicationId } = useParams();
  const [histories, setHistories] = useState<Model.ApplicationHistory[]>([]);

  const fetchHistory = useFetchApplicationHistoryQuery({
    officeId: Number(officeId),
    applicationId: Number(applicationId),
  });

  useEffect(() => {
    if (fetchHistory.isSuccess && fetchHistory.data) {
      setHistories(fetchHistory.data as Model.ApplicationHistory[]);
    }
  }, [fetchHistory]);

  return (
    <div className={styles.historyBlock}>
      <h6>
        <ReloadIcon /> <span>История</span>
      </h6>
      {histories.map((history, index) => {
        return (
          <div className={styles.state} key={index}>
            <div className={styles.top}>
              <div className={styles.statuses}>
                {history.oldHistory ? (
                  <>
                    <ApplicationStatus status={history.oldHistory} />
                    <ArrowLeft2Icon className={styles.arrowIcon} />
                  </>
                ) : null}
                <ApplicationStatus status={history.historyType} />
              </div>
              {history.commentary ? <p>{history.commentary}</p> : null}
            </div>
            <div className={styles.detail}>
              <p className={styles.date}>{formatDate(history.createdAt, true)}</p>
              <p>
                {history.owner?.name}
                {history.owner ? <Avatar size="sx" imageUrl={history.owner.avatar} fullName={history.owner.name} /> : null}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HistoryBlock;
