// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes NotaryActions_spin-anim__8YqZ2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes NotaryActions_fadeIn__7BD8r {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes NotaryActions_fadeOut__IVeLI {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes NotaryActions_pulse__XvFgn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.NotaryActions_notaryActions__WfE1R {
  gap: 20px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
}

.NotaryActions_wrapper__FC2-2 {
  margin-top: 19px !important;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/pages/NotaryActions/NotaryActions.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,kCAAA;EACA,sBAAA;AA8BF;;AA3BA;EACE,2BAAA;EACA,WAAA;AA8BF","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../assets/css/_animations';\n@import '../../assets/css/_colors';\n@import '../../assets/css/_vars';\n@import '../../assets/css/_mixins';\n@import '../../assets/css/_flex';\n\n.notaryActions {\n  gap: 20px;\n  display: grid;\n  grid-auto-flow: row;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(5, 1fr);\n  grid-auto-flow: column;\n}\n\n.wrapper {\n  margin-top: 19px !important;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notaryActions": `NotaryActions_notaryActions__WfE1R`,
	"wrapper": `NotaryActions_wrapper__FC2-2`,
	"spin-anim": `NotaryActions_spin-anim__8YqZ2`,
	"fadeIn": `NotaryActions_fadeIn__7BD8r`,
	"fadeOut": `NotaryActions_fadeOut__IVeLI`,
	"pulse": `NotaryActions_pulse__XvFgn`
};
export default ___CSS_LOADER_EXPORT___;
