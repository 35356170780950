import cn from "classnames";
import { useState, useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { AddDocumentOrMemberForm, ErrorResult, EmptyState } from "@/blocks/index";
import { PAGE_LIMIT } from "@/shared/constants";
import { useActions, useAppSelector, useUserPermissions } from "@/store/hooks";
import { useFetchDocumentsQuery } from "@/store/services/document.query";
import { Modal, Pagination } from "@/ui/index";
import { Loader } from "@/ui/Loader";

import DocumentCard from "./DocumentCard";
import styles from "./Documents.module.scss";

const Documents = () => {
  // style="overflow: hidden;padding-right: 15px;" data-initial-overflow="" data-initial-padding=""
  const { clientId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentDocument, setCurrentDocument] = useState<Model.Document>({} as Model.Document);
  const [isError, setIsError] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setDocuments } = useActions();
  const fetchedDocuments = useFetchDocumentsQuery(
    { clientId: Number(clientId), query: `?page=${currentPage}` },
    { refetchOnMountOrArgChange: true },
  );
  const { documents } = useAppSelector((state) => state.document);
  const permissions = useUserPermissions();

  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
  }, [search]);

  useEffect(() => {
    if (fetchedDocuments.isSuccess && fetchedDocuments.data) {
      const fetch = fetchedDocuments.data as Response.DocumentsResponse;
      setDocuments(fetch.results);
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
    }
    if (fetchedDocuments.error) {
      if ((fetchedDocuments.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchedDocuments.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchedDocuments.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchedDocuments]);

  useEffect(() => {
    setIsLoading(fetchedDocuments.isFetching);
  }, [fetchedDocuments.isFetching]);

  const modalHandle = (value: boolean) => {
    setShowModal(value);
    if (value) {
      setIsModalOpen(value);
    } else {
      setTimeout(() => {
        setIsModalOpen(value);
      }, 250);
    }
  };

  const editHandle = (id: number | string) => {
    modalHandle(true);
    const editDocument = documents.find((el) => String(el.id) === id);
    editDocument && setCurrentDocument(editDocument);
  };

  const removeDocument = () => {
    if (pagination.count - 1 <= PAGE_LIMIT && currentPage !== 1) {
      searchParams.set("page", `${currentPage - 1}`);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <div className={styles.documents}>
        <SwitchTransition>
          <CSSTransition key={isLoading ? "loader" : "content"} timeout={250} classNames="fade">
            {isLoading ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <SwitchTransition>
                <CSSTransition key={isError ? "nocontent" : "content"} timeout={250} classNames="fade">
                  {isError ? (
                    <ErrorResult code={isError} />
                  ) : (
                    <SwitchTransition>
                      <CSSTransition key={documents?.length === 0 ? "empty" : "content"} timeout={250} classNames="fade">
                        {documents?.length === 0 ? (
                          <div className={styles.loader}>
                            <EmptyState text="ни одного документа" />
                          </div>
                        ) : (
                          <div className={styles.documentsList}>
                            {documents.map((value) => (
                              <DocumentCard
                                key={value.id}
                                {...value}
                                editHandle={permissions?.customUpdateClient ? editHandle : undefined}
                                onRemove={permissions?.customUpdateClient ? removeDocument : undefined}
                              />
                            ))}
                            {pagination.count > PAGE_LIMIT && (
                              <div className={cn("office__container", styles.pagination)}>
                                <Pagination
                                  totalPages={Math.ceil(pagination.count / PAGE_LIMIT)}
                                  currentPage={currentPage}
                                  previous={pagination.previous}
                                  next={pagination.next}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </CSSTransition>
                    </SwitchTransition>
                  )}
                </CSSTransition>
              </SwitchTransition>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>

      <Modal show={showModal} setShow={modalHandle} size="lg" className={styles.modal} notCentered={true}>
        <div className={styles.modalTop}>
          <h4>Редактировать документ</h4>
        </div>
        <div className={styles.modalInner}>
          {isModalOpen && (
            <AddDocumentOrMemberForm
              setShowModal={modalHandle}
              isDocument={true}
              documentData={currentDocument}
              isEdit={true}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default Documents;
