// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes AddClient_spin-anim__H1aHK {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes AddClient_fadeIn__dNmuy {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes AddClient_fadeOut__HHh5x {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes AddClient_pulse__WaCQy {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.AddClient_addClient__4rfGj {
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-bottom: 52px;
}

.AddClient_addClientHead__m5yBC {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;
  gap: 24px;
}

.AddClient_title__P3z63 {
  font-family: "SF Pro Display Medium", sans-serif;
  font-size: 1.5rem;
  line-height: 1.65rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/pages/AddClient/AddClient.module.scss","webpack://./src/assets/css/_vars.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACC,aAAA;EACA,QAAA;EACA,sBAAA;EACA,oBAAA;AA8BD;;AA3BA;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,SAAA;AA8BD;;AA3BA;EACC,gDClBmB;EDmBnB,iBAAA;EACA,oBAAA;AA8BD","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../assets/css/_animations';\n@import '../../assets/css/_colors';\n@import '../../assets/css/_vars';\n@import '../../assets/css/_mixins';\n@import '../../assets/css/_flex';\n\n.addClient {\n\tdisplay: flex;\n\tgap: 8px;\n\tflex-direction: column;\n\tpadding-bottom: 52px;\n}\n\n.addClientHead {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tpadding: 32px;\n\tgap: 24px;\n}\n\n.title {\n\tfont-family: $fontDefaultMedium;\n\tfont-size: 1.5rem;\n\tline-height: 1.65rem;\n}","$xl: 1180px !important;\n$heightOfficeDetailContent: calc(100vh - 124px + 16px * 2 - 102px - 40px * 3);\n\n$fontDefault: \"SF Pro Display Regular\", sans-serif; //400\n$fontDefaultMedium: \"SF Pro Display Medium\", sans-serif; //500\n$fontDefaultSemibold: \"SF Pro Display Semibold\", sans-serif; //600\n$fontDefaultBold: \"SF Pro Display Bold\", sans-serif; //700\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addClient": `AddClient_addClient__4rfGj`,
	"addClientHead": `AddClient_addClientHead__m5yBC`,
	"title": `AddClient_title__P3z63`,
	"spin-anim": `AddClient_spin-anim__H1aHK`,
	"fadeIn": `AddClient_fadeIn__dNmuy`,
	"fadeOut": `AddClient_fadeOut__HHh5x`,
	"pulse": `AddClient_pulse__WaCQy`
};
export default ___CSS_LOADER_EXPORT___;
