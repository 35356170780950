import cn from "classnames";
import { type FC } from "react";
import { Link, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { EditIcon } from "@/assets/icons";
import { AddressBlock, Info } from "@/blocks/index";
import { Avatar, Button, IconButton, Status } from "@/ui/index";

import styles from "./ClientCard.module.scss";

type ClientCardSimpleProps = {
  handleEdit?: (val: string) => void;
  isLink?: boolean;
  relateCardHandle?: (id: number, relate: string) => void;
  className?: string;
  loading?: boolean;
  routerState?: object;
  target?: string;
  onFollow?: () => void;
} & Model.LinkedPerson;

const ClientCardSimple: FC<ClientCardSimpleProps> = ({
  className,
  name,
  phoneNumber,
  email,
  handleEdit,
  isLink,
  id,
  status,
  createdAt,
  related,
  relateCardHandle,
  loading,
  routerState,
  target,
  onFollow,
}) => {
  const params = useParams();

  return (
    <div className={cn(styles.cardWr, styles.simpleCard)}>
      <div className={cn(styles.card, className && className)}>
        <Link
          className={styles.link}
          to={`/offices/${params.officeId || params.id}/clients/${id}`}
          state={routerState}
          target={target}
          onClick={() => {
            if (onFollow) onFollow();
            // window.scrollTo({
            //   top: 0,
            //   behavior: "smooth",
            // });
          }}
        />
        <div className={styles.wrapper}>
          <div className={styles.cardContent}>
            <Avatar imageUrl={""} fullName={name} size="medium" />
            <div className={styles.cardInfo}>
              {isLink ? <Info officeNumber={Number(id) ?? 0} createdDate={createdAt} isUser /> : null}
              <h6 className={styles.title}>
                <span>{name}</span> {status && <Status type={status.type} name={status.name} size="small" />}
              </h6>
              <AddressBlock email={String(email)} mobilePhone={phoneNumber} className={styles.cardAddress} />
            </div>
          </div>
          {isLink && relateCardHandle && (
            <div className={styles.relateButton}>
              <SwitchTransition>
                <CSSTransition key={related ? "related" : "norelated"} timeout={250} classNames="fade">
                  <Button
                    type="button"
                    size="medium"
                    variant={related ? "secondary" : "primary"}
                    onClick={() => relateCardHandle(Number(id), related ? "remove" : "add")}
                    loader={loading}>
                    {related ? "Отвязать" : "Привязать"}
                  </Button>
                </CSSTransition>
              </SwitchTransition>
            </div>
          )}
          {handleEdit && (
            <IconButton className={styles.editIcon} icon={<EditIcon />} onClick={() => handleEdit(String(name))} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientCardSimple;
