import cn from "classnames";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { AddEditClientForm, ErrorResult } from "@/blocks/index";
import { Container } from "@/layout/Container";
import { useUserPermissions } from "@/store/hooks";
import { useCreateClientMutation } from "@/store/services/client.query";
import { BackLink } from "@/ui/index";

import styles from "./AddClient.module.scss";

const AddClient = () => {
  const navigate = useNavigate();
  const { officeId, id } = useParams();
  const [errors, setErrors] = useState<ErrorObject[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentPersonPosition, setPersonPosition] = useState<string>("");
  const [createClient, createdClient] = useCreateClientMutation();
  const title = "Добавить клиента";
  const [isError, setIsError] = useState<number | null>(null);
  const permissions = useUserPermissions();

  useEffect(() => {
    setIsError(permissions?.customEmployeesSection ? null : 403);
  }, [permissions?.customEmployeesSection]);

  useEffect(() => {
    if (createdClient.isError) {
      if ((createdClient.error as ErrorResponse).data?.errors) {
        const {
          data: { errors },
        } = createdClient.error as ErrorResponse;

        if (errors[0].attr !== "nonFieldErrors") {
          setErrors(errors);
        } else if (errors[0].attr === "nonFieldErrors" || errors[0].attr === "birthCertificate") {
          enqueueSnackbar(errors[0].detail, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } else if (createdClient.error as ErrorResponse) {
        enqueueSnackbar("При выполнении запроса на сервер что-то пошло не так. Попробуйте снова", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }

    if (createdClient.isSuccess && createdClient.data) {
      setIsSuccess(true);
      navigate(`/offices/${officeId}/clients?position=${currentPersonPosition}`);
    }
  }, [createdClient]);

  const senData = (formData: FormData, position: string) => {
    createClient({ clientData: formData, officeId: Number(officeId) });
    setPersonPosition(position);
  };

  const onReset = () => {
    navigate(`/offices/${Number(id) || Number(officeId)}/clients`);
  };

  return (
    <div className={cn(styles.addClient, "container")}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SwitchTransition>
        <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
          {isError ? (
            <ErrorResult code={isError} />
          ) : (
            <>
              <Container className={styles.addClientHead}>
                <BackLink to={`/offices/${officeId}/clients`} />
                <h4 className={styles.title}>{title}</h4>
              </Container>
              <Container overflow>
                <AddEditClientForm
                  senData={senData}
                  isLoading={createdClient.isLoading}
                  isSuccess={isSuccess}
                  errors={errors}
                  onReset={onReset}
                  formId="formId_addClient"
                  error={createdClient.isError}
                />
              </Container>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default AddClient;
