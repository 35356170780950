import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ActionCard, ErrorResult } from "@/blocks/index";
// import { useUserPermissions } from "@/store/hooks";
import { useFetchActionsQuery } from "@/store/services/action.query";

import styles from "./NotaryActions.module.scss";

const NotaryActions = () => {
  const [actions, setActions] = useState<Model.Action[]>([]);
  const [isError, setIsError] = useState<number | null>(null);
  // const permissions = useUserPermissions();
  const fetchActions = useFetchActionsQuery();

  useEffect(() => {
    if (fetchActions.isSuccess && fetchActions.data) {
      const actionsArray = [...(fetchActions.data as Model.Action[])];
      const sortedActions = actionsArray.sort((a, b) => Number(a.id) - Number(b.id));
      setActions(sortedActions);
    }
    if (fetchActions.error) {
      if ((fetchActions.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchActions.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchActions.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchActions]);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Нотариальные действия</title>
      </Helmet>
      <SwitchTransition>
        <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
          {isError ? (
            <ErrorResult code={isError} />
          ) : (
            <div className={`${styles.notaryActions} container`}>
              {actions.map((el, index) => (
                <ActionCard key={index} {...el} />
              ))}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default NotaryActions;
