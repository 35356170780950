import type { FC } from "react";

import cn from "classnames";

import { BagIcon2, EmailIcon, MapIcon, PhoneIcon, TimeIcon, UserIcon2 } from "@/assets/icons";
import styles from "@/blocks/List/List.module.scss";

const Icon = ({ icon }: { icon?: string }) => {
  if (!icon) {
    return null;
  }
  switch (icon) {
    case "email":
      return <EmailIcon />;
    case "location":
      return <MapIcon />;
    case "phone":
      return <PhoneIcon />;
    case "time":
      return <TimeIcon />;
    case "bag":
      return <BagIcon2 />;
    case "user":
      return <UserIcon2 />;
    default:
      return null;
  }
};

const List: FC<Blocks.List> = ({ title, children, list, textFlex, textReverse, colClassName }) => {
  return (
    <div className={styles.list}>
      <h3>{title}</h3>
      <div>
        {list ? (
          <ul className={styles.listUl}>
            {list.map((el) => (
              <li className={cn(styles.item, el.icon && styles.icon, el.icon === "hasIcon" && styles.icon)} key={el.id}>
                <Icon icon={el.icon} />
                <div className={cn(colClassName, { [styles.flex]: textFlex, [styles.reverse]: textReverse })}>
                  {el.subtext && <span>{el.subtext}</span>}
                  {el.icon === "phone" || el.icon === "email" ? (
                    <a href={el.icon === "phone" ? `tel:${el.text}` : `mailto:${el.text}`}>{el.text}</a>
                  ) : (
                    <p>{el.text}</p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default List;
