import { TransitionGroup, CSSTransition } from "react-transition-group";

import styles from "./Uploader.module.scss";
import UploaderItem from "./UploaderItem";

const UploaderFiles = ({ files, deleteHandle }: { files: Model.Scan[]; deleteHandle?: (scanId: number) => void }) => {
  return (
    <div className={styles.files}>
      <TransitionGroup component={null}>
        {files.map((file, index) => {
          const delay = index * 100;
          return (
            <CSSTransition key={file.id} classNames="slide" timeout={300} unmountOnExit>
              <UploaderItem scan={file} handleFileDelete={deleteHandle} id={file.id} delay={delay} />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default UploaderFiles;
