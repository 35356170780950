import type { FormEvent } from "react";

import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { DraftIcon, DownloadIcon, ArchivedIcon } from "@/assets/icons";
import { downloadRequest } from "@/helpers/downloadRequest";
import { Container } from "@/layout/Container";
import {
  useFetchAppDocumentsQuery,
  useLazyDownloadAppDocumentsQuery,
  useDeleteAppScanMutation,
  useUploadAppDocumentsMutation,
} from "@/store/services/application.query";
import { UploaderFiles, Button, Tombler, Uploader } from "@/ui/index";

import styles from "./ApplicationDocumnets.module.scss";
import ClientBlick from "../ClientBlick/ClientBlick";

export const ApplicationDocumnets = ({
  officeId,
  applicationId,
  name,
  withSwitch,
  isArchive,
  onChange,
}: {
  officeId: string;
  applicationId: string;
  name: string;
  withSwitch?: boolean;
  isArchive?: boolean;
  onChange?: (val: boolean) => void;
}) => {
  const [documents, setDocuments] = useState<Model.FetchDocument[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const fetchDocuments = useFetchAppDocumentsQuery({
    officeId: Number(officeId),
    applicationId: Number(applicationId),
    query: "?type=archive",
  });
  const [downloadDocuments, downloadedDocumnets] = useLazyDownloadAppDocumentsQuery();
  const [uploadDocuments, uploadedDocuments] = useUploadAppDocumentsMutation();
  const [deleteScan, deletedScan] = useDeleteAppScanMutation();

  useEffect(() => {
    if (fetchDocuments && fetchDocuments.isSuccess && fetchDocuments.data) {
      const data = fetchDocuments.data as Model.FetchDocument[];
      setDocuments(data);
    }
  }, [fetchDocuments]);

  useEffect(() => {
    if (deletedScan.isSuccess && deletedScan.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [deletedScan]);

  useEffect(() => {
    if (uploadedDocuments.isSuccess && uploadedDocuments.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }

    if (uploadedDocuments.isError && (uploadedDocuments.error as ErrorObject[])) {
      enqueueSnackbar((uploadedDocuments.error as ErrorObject[])[0].detail, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [uploadedDocuments]);

  useEffect(() => {
    if (downloadedDocumnets.isSuccess && downloadedDocumnets.data && !downloadedDocumnets.isFetching) {
      downloadRequest(downloadedDocumnets.data as Blob, name);
    }

    if (downloadedDocumnets.isError && downloadedDocumnets.error) {
      let error = "При выполнении запроса что-то пошло не так..";
      if (downloadedDocumnets.error as Model.Error) {
        error = (downloadedDocumnets.error as Model.Error).message;
      } else if (downloadedDocumnets.error as ErrorSimpleResponse) {
        error = (downloadedDocumnets.error as ErrorSimpleResponse)?.errors[0]?.detail;
      } else if (downloadedDocumnets.error as ErrorObject[]) {
        error = (downloadedDocumnets.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [downloadedDocumnets]);

  const download = () => {
    if (!downloadedDocumnets.isFetching) {
      downloadDocuments({
        officeId: Number(officeId),
        applicationId: Number(applicationId),
        query: "?type=archive",
      });
    }
  };

  const checkedHandle: Checkbox["onChange"] = ({ value }) => {
    setIsChecked(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleFileChange = (event: FormEvent<HTMLFormElement> | null, documentId: string, data?: File[]) => {
    if (event) {
      event.preventDefault();
    }
    const formData = new FormData();
    if (data) {
      data.forEach((file) => {
        formData.append("scanners", file);
      });
    } else if (event) {
      const elem = event.target; // @ts-ignore
      const value = elem.querySelector("textarea[name='description']")?.value || "";
      formData.append("description", value);
    }
    formData.append("document", documentId);

    uploadDocuments({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      data: formData,
    });
  };

  const deleteScanHandle = (scanId: number) => {
    deleteScan({ officeId: Number(officeId), applicationId: Number(applicationId), scanId });
  };

  return (
    <Container>
      <ClientBlick
        text={isArchive ? "Сдать в архив" : "Загруженные документы"}
        icon={isArchive ? <ArchivedIcon /> : <DraftIcon />}
        element={
          documents.length > 0 ? (
            <div className={styles.buttons}>
              {withSwitch && (
                <Tombler
                  id={1}
                  field="archived"
                  onChange={checkedHandle}
                  isChecked={isChecked}
                  className={styles.tombler}
                />
              )}
              {!isArchive && (
                <Button
                  variant="secondary"
                  type="button"
                  size="small"
                  onClick={download}
                  disabled={downloadedDocumnets.isFetching}>
                  <DownloadIcon /> Скачать все
                </Button>
              )}
            </div>
          ) : undefined
        }
      />
      <TransitionGroup component={null}>
        {(isChecked || !withSwitch) && (
          <CSSTransition key="form" classNames="fade" timeout={250}>
            <div>
              {documents && documents.length > 0 ? (
                <>
                  {documents.map((el) => (
                    <div key={el.id}>
                      <div className={styles.wrapper}>
                        {el.scans.length > 0 && (
                          <div className={styles.uploadFiles}>
                            <UploaderFiles files={el.scans} deleteHandle={deleteScanHandle} />
                          </div>
                        )}
                        <div className={styles.uploadButtons}>
                          <Uploader
                            multiple
                            size="lg"
                            onChange={(data) => handleFileChange(null, String(el.id), data)}
                            isSuccess={uploadedDocuments.isSuccess}
                            isError={uploadedDocuments.isError}
                            isLoading={uploadedDocuments.isLoading}
                            accept=".pdf, .doc, .docx, .xls, .xlsx"
                            text="Загрузить документ"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className={styles.empty}>Документы отсутствуют</div>
              )}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </Container>
  );
};
