import cn from "classnames";

import { Container } from "@/layout/index";
import { useUserPermissions } from "@/store/hooks";

import styles from "./HeadOffice.module.scss";

export const HeadOfficeSkeleton = () => {
  const permissions = useUserPermissions();
  return (
    <div className={styles.loaderRoot}>
      <Container overflow flat>
        <div className={styles.loaderWrap}>
          {permissions?.customUpdateNotary && <div className={cn(styles.skeleton, styles.h40, styles.w40)} />}
          <div className={styles.loaderContent}>
            <div className={styles.loaderBlock}>
              <div className={styles.loaderRow}>
                <div className={cn(styles.skeleton, styles.h26, styles.w280)} />
                <div className={cn(styles.skeleton, styles.h26, styles.w90)} />
              </div>
              <div className={styles.loaderRow}>
                <div className={cn(styles.skeleton, styles.h18, styles.w90)} />
                <div className={cn(styles.skeleton, styles.h18, styles.w120)} />
                <div className={cn(styles.skeleton, styles.h18, styles.w160)} />
              </div>
            </div>
            {permissions?.customUpdateNotary && (
              <div className={cn(styles.loaderRow, styles.g8)}>
                <div className={cn(styles.skeleton, styles.h54, styles.w120)} />
                <div className={cn(styles.skeleton, styles.h54, styles.w160)} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeadOfficeSkeleton;
