import cn from "classnames";

import { CircleCheckedIcon, Warning2Icon, ArrowDownIcon } from "@/assets/icons";

import styles from "./DocumentsGroupName.module.scss";

export const DocumentsGroupName = ({
  length,
  name,
  required,
  showArrow,
  onClick,
}: {
  length: number;
  required?: boolean;
  name: string;
  onClick?: () => void;
  showArrow?: boolean;
}) => (
  <h6 className={styles.title} onClick={onClick}>
    <div className={styles.titleIcon}>
      {length > 0 ? <CircleCheckedIcon /> : <Warning2Icon className={cn(required ? styles.required : "")} />}
    </div>
    {name}
    {onClick && length > 0 && <ArrowDownIcon className={cn(styles.arrow, { [styles.show]: showArrow })} />}
  </h6>
);
