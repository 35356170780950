import type { SyntheticEvent } from "react";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { getValueFromArray } from "@/helpers/index";
import { useActions } from "@/store/hooks";
import { usePasswordRecoveryMutation } from "@/store/services/auth.query";
import { BackLink, Button, InputField } from "@/ui/index";

import styles from "./AuthForm.module.scss";
import { passRecoveryData } from "./mockData";
import { SuccessLetter } from "./SuccessLetter";

export const PassRecoveryForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<InputData[]>(passRecoveryData);
  const [successShow, setSuccessShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { setSentEmail } = useActions();

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = email.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setEmail(newInputProps);
    newVal.value !== "" && setDisabled(false);
  };
  const [passwordRecovery, { data, error, isSuccess, isLoading }] = usePasswordRecoveryMutation();

  useEffect(() => {
    if (error) {
      setErrorMessage((error as ErrorObject[])[0].detail);
      setEmail([
        ...email.map((item) =>
          item.field === "email"
            ? {
                ...item,
                invalid: true,
              }
            : item,
        ),
      ]);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setSuccessShow(true);
      navigate("/auth/success");
    }
  }, [data, isSuccess]);

  const onSubmitHandle = (e: SyntheticEvent) => {
    e.preventDefault();

    const desiredValues: Request.PasswordRecovery = getValueFromArray(email);
    setSentEmail(desiredValues.email);
    passwordRecovery(desiredValues);
  };
  return (
    <>
      <Helmet>
        <title>Восстановление пароля</title>
      </Helmet>
      {successShow ? (
        <SuccessLetter />
      ) : (
        <form className={`${styles.form} ${styles.recoveryForm}`} onSubmit={onSubmitHandle}>
          <BackLink to="/auth" />
          <h4 className={styles.subtitle}>Восстановление пароля</h4>
          <p className={styles.text}>
            Введите почту, к которой привязан аккаунт. Мы отправим на неё письмо со ссылкой для восстановления пароля
          </p>

          {email.map(({ id, ...other }) => (
            <InputField id={id} key={id} {...other} onChange={changeHandle} className={styles.input} />
          ))}
          <Button variant={email ? "primary" : "blue"} type="submit" disabled={disabled} loader={isLoading}>
            Отправить письмо
          </Button>
          {errorMessage && (
            <div className={styles.errorMessage}>
              <span className={styles.error}>{errorMessage}</span>
            </div>
          )}
        </form>
      )}
    </>
  );
};
