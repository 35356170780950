import cn from "classnames";
import { useEffect, useState, type ChangeEvent, useRef } from "react";

import { LoadIcon } from "@/assets/icons";
import { Loader } from "@/ui/Loader";

import styles from "./Uploader.module.scss";

const Uploader: Uploader = ({
  isSuccess,
  isError,
  multiple,
  size,
  onChange,
  accept,
  text = "Загрузить файл(ы)",
  isLoading,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSuccess || isError) {
      setSelectedFiles([]);

      if (input.current) {
        input.current.value = "";
      }
    }
  }, [isSuccess, isError]);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) return;

    let newFiles: File[] = multiple ? [...selectedFiles] : []; // Reset files if not multiple

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const hasFile = newFiles.some((item) => item.name === file.name);
      if (!hasFile) {
        newFiles.push(file);
      }
    }

    if (!multiple && newFiles.length > 1) {
      newFiles = newFiles.slice(-1);
    }

    setSelectedFiles(newFiles);
    onChange && onChange(newFiles);
  };

  return (
    <div className={cn(styles.uploader, size)}>
      <div className={cn(styles.button)}>
        <input
          type="file"
          ref={input}
          accept={accept}
          onChange={onFileChange}
          multiple={multiple}
          className={styles.input}
        />
        <LoadIcon />
        <label className={styles.text}>{text}</label>
        {isLoading !== undefined && (
          <div className={cn(styles.loader, { [styles.show]: isLoading })}>
            <Loader size="sm" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Uploader;
