// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes AddAndEditEmployee_spin-anim__mRqgb {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes AddAndEditEmployee_fadeIn__H0zIH {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes AddAndEditEmployee_fadeOut__-S2NI {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes AddAndEditEmployee_pulse__otnLU {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.AddAndEditEmployee_addEmployee__Mzaco {
  min-height: 100vh;
  padding-bottom: 40px;
}
.AddAndEditEmployee_addEmployee__Mzaco > div {
  overflow-y: inherit;
}

.AddAndEditEmployee_inner__gJ\\+ml {
  display: flex;
  padding: 64px 32px;
  gap: 232px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/pages/AddAndEditEmployee/AddAndEditEmployee.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACC,iBAAA;EACA,oBAAA;AA8BD;AA7BC;EACC,mBAAA;AA+BF;;AA3BA;EACC,aAAA;EACA,kBAAA;EACA,UAAA;AA8BD","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../assets/css/_animations';\n@import '../../assets/css/_colors';\n@import '../../assets/css/_vars';\n@import '../../assets/css/_mixins';\n@import '../../assets/css/_flex';\n\n.addEmployee {\n\tmin-height: 100vh;\n\tpadding-bottom: 40px;\n\t& > div {\n\t\toverflow-y: inherit;\n\t}\n}\n\n.inner {\n\tdisplay: flex;\n\tpadding: 64px 32px;\n\tgap: 232px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addEmployee": `AddAndEditEmployee_addEmployee__Mzaco`,
	"inner": `AddAndEditEmployee_inner__gJ+ml`,
	"spin-anim": `AddAndEditEmployee_spin-anim__mRqgb`,
	"fadeIn": `AddAndEditEmployee_fadeIn__H0zIH`,
	"fadeOut": `AddAndEditEmployee_fadeOut__-S2NI`,
	"pulse": `AddAndEditEmployee_pulse__otnLU`
};
export default ___CSS_LOADER_EXPORT___;
