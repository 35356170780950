import cn from "classnames";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { EditIcon2 } from "@/assets/icons";
import { useLazyFetchClientsQuery } from "@/store/services/client.query";
import { useLazyFetchEmployeesQuery } from "@/store/services/employee.query";
import { InputField } from "@/ui/index";

import styles from "./SearchClientByPhone.module.scss";

const highlightMatch = (text: string, query: string) => {
  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedQuery, "gi");
  return text.replace(regex, (match) => `<span class="${styles.highlight}">${match}</span>`);
};

const SearchClientByPhone: Blocks.SearchClient = ({
  isEmployeee,
  field,
  required,
  hasLabelMark,
  className,
  setSelectedClient,
  label,
  placeholder,
  mask,
  type,
  value,
  personPosition,
  onChange,
  invalid,
  helperText,
  withNameAndField,
  byField,
  withLink,
}) => {
  const { officeId, id } = useParams();
  const [currentValue, setCurrentValue] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Model.Client | Model.Employee | null>(null);
  const [clients, setClients] = useState<Model.Client[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const wrapper = useRef<HTMLDivElement>(null);
  const [triggerClients, triggeredClientsData] = useLazyFetchClientsQuery();
  const [triggerEmployees, triggeredEmployeesData] = useLazyFetchEmployeesQuery();
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [modalSearch, setModalSearch] = useState("");
  const container = useRef<HTMLDivElement>(null);
  const containerWr = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (searchQuery.length > 0 && searchQuery !== "+7 ") {
      const queryString = `?search=${searchQuery}${personPosition ? `&position=${personPosition}` : ""}${modalSearch.length > 0 ? `&page=${modalSearch}` : ""}`;
      const param = { officeId: Number(officeId) || Number(id), query: queryString };
      isEmployeee ? triggerEmployees(param) : triggerClients(param);
    }
  }, [searchQuery, modalSearch]);

  useEffect(() => {
    if (triggeredClientsData.isSuccess && triggeredClientsData.data) {
      const response = triggeredClientsData.data as Response.ClientsResponse;
      setPagination({
        count: response.count,
        page: 1,
        previous: response.previous,
        next: response.next,
      });
      if (JSON.stringify(clients) !== JSON.stringify(response.results))
        setClients(modalSearch.length > 0 ? [...clients, ...response.results] : response.results);
    }
  }, [triggeredClientsData]);

  useEffect(() => {
    if (triggeredEmployeesData.isSuccess && triggeredEmployeesData.data) {
      const response = triggeredEmployeesData.data as Response.ClientsResponse;
      setPagination({
        count: response.count,
        page: 1,
        previous: response.previous,
        next: response.next,
      });
      if (JSON.stringify(clients) !== JSON.stringify(response.results))
        setClients(modalSearch.length > 0 ? [...clients, ...response.results] : response.results);
    }
  }, [triggeredEmployeesData]);

  useEffect(() => {
    if (selectedSuggestion) {
      if (!withLink) {
        if ((selectedSuggestion as Model.Client)[field as keyof Model.Client]) setSelectedClient(selectedSuggestion);
        if ((selectedSuggestion as Model.Employee)[byField as keyof Model.Employee]) setSelectedClient(selectedSuggestion);
      }
    } else {
      setSelectedClient(currentValue);
    }
  }, [selectedSuggestion, currentValue]);

  const handleClick = (event: Event) => {
    if (!wrapper.current?.contains(event.target as any)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [showSuggestions]);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(String(value));
    }
  }, [value]);

  const onInputChange: InputOnChange = (newVal) => {
    setCurrentValue(newVal.value.toString());
    setShowSuggestions(true);
    setSearchQuery(newVal.value.toString().toLowerCase());
    setClients([]);
    setModalSearch("");
    setPagination({ count: 0, page: 1 });
    onChange(newVal);
    if (selectedSuggestion) {
      setSelectedSuggestion(null);
    }
  };

  const handleSuggestionClick = (suggestion: Model.Client | Model.Employee) => {
    //@ts-ignore
    const newVal = byField ? suggestion[byField] : suggestion.phoneNumber;
    setCurrentValue(String(newVal));
    setShowSuggestions(false);
    setSelectedSuggestion(suggestion);
    setSearchQuery("");
    setClients([]);
    setPagination({ count: 0, page: 1 });
    setModalSearch("");
    if (withLink) {
      window.open(
        //@ts-ignore
        `${window.location.origin}/offices/${suggestion.notary}/clients/${suggestion.id}`,
        "_blank",
      );
    }
  };

  const handleEdit = (name: string) => {
    setCurrentValue(name);
    setSelectedSuggestion(null);
    setShowSuggestions(false);
    setSearchQuery("");
    setClients([]);
    setPagination({ count: 0, page: 1 });
    setModalSearch("");
  };

  const onScroll = () => {
    if (pagination.next && !(triggeredEmployeesData.isLoading || triggeredClientsData.isLoading)) {
      const search = pagination.next.split("?").at(-1);

      const object = search // eslint-disable-next-line
        ? JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) =>
            key === "" ? value : decodeURIComponent(value),
          )
        : undefined;
      if (object && object.page) {
        setModalSearch(object.page);
      }
    }
  };

  const scrollHandler = () => {
    if (container.current && containerWr.current) {
      const height = containerWr.current.offsetHeight;
      const screenHeight = container.current.offsetHeight;
      const scrolled = container.current.scrollTop;
      const threshold = height - screenHeight / 4;
      const position = scrolled + screenHeight;

      if (position >= threshold) {
        onScroll();
      }
    }
  };

  return (
    <div className={cn(styles.searchInput, className)} ref={wrapper}>
      {!selectedSuggestion && (
        <InputField
          id={7}
          value={currentValue}
          label={label}
          onChange={onInputChange}
          onFocus={() => setShowSuggestions(true)}
          placeholder={placeholder}
          type={field === "email" ? "email" : "search"}
          field={field}
          required={required}
          hasLabelMark={hasLabelMark}
          mask={mask}
          invalid={invalid}
          helperText={helperText}
        />
      )}
      {currentValue !== "+7 " && showSuggestions && (
        <div className={styles.selectListWr} ref={container} onScroll={scrollHandler}>
          <ul className={styles.searchList} ref={containerWr}>
            {isEmployeee && currentValue.length > 0 && clients.length === 0 ? (
              <li className={cn(styles.searchItem, styles.empty)}>Ничего не найдено</li>
            ) : null}
            {currentValue.length > 0 &&
              clients.map((suggestion, index) => (
                <li
                  className={styles.searchItem}
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  dangerouslySetInnerHTML={{
                    __html: highlightMatch(
                      withNameAndField
                        ? `${(suggestion as Model.Employee).fullName || String((suggestion as Model.Client).name)}, ${
                            // @ts-ignore
                            suggestion[field]
                          }`
                        : (suggestion as Model.Employee).fullName || String((suggestion as Model.Client).name),
                      currentValue,
                    ),
                  }}
                />
              ))}
          </ul>
        </div>
      )}
      {selectedSuggestion && (
        <div className={styles.selectedInput}>
          <InputField
            id={7}
            value={currentValue}
            readOnly={isEmployeee}
            label={label}
            onChange={onInputChange}
            onFocus={() => setShowSuggestions(true)}
            placeholder={placeholder}
            type={type}
            field={field}
            required={required}
            hasLabelMark={hasLabelMark}
            mask={mask}
            invalid={invalid}
            helperText={helperText}
          />
          {isEmployeee && <EditIcon2 onClick={() => handleEdit((selectedSuggestion as Model.Employee).fullName)} />}
        </div>
      )}
    </div>
  );
};

export default SearchClientByPhone;
