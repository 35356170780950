import { Endpoints } from "@/api/endpoints";
import { getValueFromArray } from "@/helpers/index";

import $api from "../api";

export const OfficeService = {
  async verifyOffice(sendData: Info.FormInfo): Promise<Info.FormInfoResponse | { errors: ErrorObject[] }> {
    const res = await $api.post<Info.FormInfoResponse & { error: ErrorObject }>(`${Endpoints.NOTARIES}verify/`, sendData);
    return res.data;
  },

  async createOfficeFullInfo(officeData: CreateOfficeData): Promise<Office.OfficeFullResponce | { errors: ErrorObject[] }> {
    const infodata: Info.FormInfo = getValueFromArray(officeData.info);
    const notary: AddressModel.Address = getValueFromArray(officeData.notary);
    const fias: AddressModel.Address = getValueFromArray(officeData.fias);
    const notary_office: AddressModel.Address = getValueFromArray(officeData.notaryOffice);
    const sendData = {
      ...infodata,
      address: {
        notary,
        fias,
        notary_office,
      },
      schedule: officeData.schedule.map((el) => ({
        day: el.day,
        isWork: el.available,
        started_at: el.timeSlots[0].value,
        finished_at: el.timeSlots[1].value,
      })),
    };
    const res = await $api.post<Office.OfficeFullResponce & { error: ErrorObject }>(Endpoints.NOTARIES, sendData);
    return res.data;
  },

  async fetchOffices(
    query: string,
  ): Promise<Office.OfficeResponceData | { errors: ErrorObject[] } | { error: ErrorResponse }> {
    const res = await $api.get(`${Endpoints.FETCH_OFFICES}${query}`);
    return res.data;
  },

  async fetchFullInfo(
    id: number,
  ): Promise<Office.OfficeFullResponce | { errors: ErrorObject[] } | { error: ErrorResponse }> {
    const res = await $api.get(`${Endpoints.FETCH_OFFICES}${id}/info/`);
    return res.data;
  },

  async activateOffice({ isActive, id }: ActiveOffice): Promise<ActiveOffice | { errors: ErrorObject[] }> {
    const res = await $api.patch(`${Endpoints.FETCH_OFFICES}${id}/activate/`, { is_active: isActive });
    return res.data;
  },

  async editOfficeInfo(data: EditOfficeInfo): Promise<EditOfficeInfo | { errors: ErrorObject[] }> {
    const info: Pick<
      Office.OfficeFullResponce,
      "districtShortName" | "email" | "phoneNumber" | "fullName" | "inn" | "license"
    > = getValueFromArray(data.info);
    const sendData = {
      ...info,
      schedule: [
        ...data.schedule.map((el) => {
          return {
            day: el.day,
            isWork: el.available,
            startedAt: el.timeSlots[0].value,
            finishedAt: el.timeSlots[1].value,
          };
        }),
      ],
      address: {
        fias: getValueFromArray(data.addresses.find((item) => item.addressType === "fias")?.address),
        notary: getValueFromArray(data.addresses.find((item) => item.addressType === "notary")?.address),
        notaryOffice: getValueFromArray(data.addresses.find((item) => item.addressType === "notaryOffice")?.address),
      },
    };
    const res = await $api.put<EditOfficeInfo & { error: ErrorObject }>(`${Endpoints.NOTARIES}${data.id}/`, sendData);
    return res.data;
  },
};
