import { TrialIcon } from "@/assets/icons";
import { useAppSelector, useUser, useUserIsSystemAdmin } from "@/store/hooks";
import Button from "@/ui/Button/Button";

import styles from "./SuccessForm.module.scss";

const CreateFormSuccess = () => {
  const { succesEmail } = useAppSelector((state) => state.office);
  const user = useUser();
  const isSystemAdmin = useUserIsSystemAdmin();

  return (
    <div className={styles.success}>
      <div className={styles.inner}>
        <div className={styles.img}>
          <TrialIcon />
        </div>
        <h4>{isSystemAdmin ? "Заявка успешно зарегистрирована" : "Ваша заявка отправлена"}</h4>
        <p>
          {isSystemAdmin ? (
            <>
              Активируйте заявку, что бы на почту <span className={styles.email}> {succesEmail}</span> пришло письмо с
              логином и паролем для входа в систему
            </>
          ) : (
            <>
              В случае успешной проверки заявки администратором, на почту
              <span className={styles.email}> {succesEmail}</span> придёт письмо с логином и паролем для входа в систему
            </>
          )}
        </p>
        <Button href={user && user.role ? "/offices" : "/auth"}>Понятно</Button>
      </div>
    </div>
  );
};

export default CreateFormSuccess;
