import type { ChangeEvent, FC } from "react";

import { CameraIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import { getInitials } from "@/helpers/index";
import { useActions, useUserId } from "@/store/hooks";
import { useUpdateImageMutation } from "@/store/services/employee.query";

import styles from "./Avatar.module.scss";

export const Avatar: FC<Model.Avatar> = ({ imageUrl, fullName, size, editable }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { officeId, employeeId } = useParams();
  const currentUserId = useUserId();
  const [updateImage, updatedImage] = useUpdateImageMutation();
  const { setUserAvatar } = useActions();

  useEffect(() => {
    if (updatedImage.isSuccess && updatedImage.data && "image" in updatedImage.data) {
      setUserAvatar(updatedImage.data.image);

      setTimeout(() => {
        setIsEdit(false);
        setIsLoading(false);
      }, 1200);
    }
  }, [updatedImage]);

  const changeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files[0]) {
      const formData = new FormData();
      formData.append("image", files[0]);
      setIsLoading(true);
      updateImage({
        officeId: Number(officeId),
        employeeId: Number(employeeId),
        data: formData,
      });
    }
  };

  const handleHover = (shouldEdit: boolean) => {
    if (editable) {
      setIsEdit(shouldEdit);
    }
  };

  return (
    <>
      <label
        className={cn(styles.root, styles[size], editable && styles.editable)}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}>
        <TransitionGroup>
          {currentUserId === Number(employeeId) && (isEdit || isLoading) && (
            <CSSTransition key="uploader" timeout={250} classNames="fade">
              <div className={styles.uploaderWrapper}>
                <input type="file" className={styles.input} onChange={changeHandle} disabled={isLoading} />
                <SwitchTransition>
                  <CSSTransition key="uploader" timeout={250} classNames="fade">
                    {isLoading ? <div className={styles.loader} /> : <CameraIcon className={styles.svg} />}
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        <div className={cn(styles.wrapper, (imageUrl === null || !imageUrl) && styles.noImage)}>
          {imageUrl === null || !imageUrl ? (
            <div className={styles.name}>{fullName && getInitials(fullName)}</div>
          ) : (
            <img className={styles.img} src={imageUrl} alt="Avatar" />
          )}
        </div>
      </label>
    </>
  );
};
