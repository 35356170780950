import cn from "classnames";

import { Container } from "@/layout/index";

import styles from "./DetailHead.module.scss";

export const DetailHeadSkeleton = () => {
  return (
    <div className={styles.loaderRoot}>
      <Container overflow flat>
        <div className={styles.loaderWrap}>
          <div className={cn(styles.skeleton, styles.h40, styles.w40)} />
          <div className={cn(styles.skeleton, styles.h54, styles.w54, styles.round)} />
          <div className={styles.loaderContent}>
            <div className={styles.loaderBlock}>
              <div className={styles.loaderRow}>
                <div className={cn(styles.skeleton, styles.h26, styles.w280)} />
                <div className={cn(styles.skeleton, styles.h26, styles.w90)} />
              </div>
              <div className={styles.loaderRow}>
                <div className={cn(styles.skeleton, styles.h18, styles.w90)} />
                <div className={cn(styles.skeleton, styles.h18, styles.w120)} />
              </div>
            </div>
            <div className={styles.loaderRow24}>
              <div className={cn(styles.skeleton, styles.h36, styles.w120)} />
              <div className={cn(styles.skeleton, styles.h36, styles.w36)} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DetailHeadSkeleton;
