import { CheckedCalendarIcon } from "@/assets/icons";

import styles from "./ApplicationDate.module.scss";

const ApplicationDate = ({ executionDate }: { executionDate: Model.ExecutionDate }) => {
  return (
    <div className={`${styles.date} ${executionDate.expired ? styles.onn : styles.off}`}>
      <CheckedCalendarIcon />
      {typeof executionDate === "string" ? executionDate : executionDate.date}
    </div>
  );
};

export default ApplicationDate;
