import { Endpoints } from "@/api/endpoints";

import $api from "../api";

const config = {
  headers: {
    "Content-Type": "multipart/form-data", // Change content type here
  },
};

export const ApplicationService = {
  async fetchApplications({
    officeId,
    query,
    clientId,
    employeeId,
  }: {
    officeId: number | string;
    query: string;
    clientId?: number;
    employeeId?: number;
  }): Promise<Response.ApplicationsResponse | { errors: ErrorObject[] } | { error: ErrorResponse }> {
    const res = await $api.get(
      `${clientId ? `${Endpoints.CLIENTS}${clientId}` : `${Endpoints.NOTARIES}${officeId}`}${
        employeeId ? `/employees/${employeeId}` : ""
      }/applications/${query}`,
    );
    return res.data;
  },
  async createApplication({
    data,
    officeId,
  }: {
    data: Model.Application;
    officeId: number | string;
  }): Promise<Model.Application | { errors: ErrorObject[] }> {
    const res = await $api.post(`${Endpoints.NOTARIES}${officeId}/applications/`, data);
    return res.data;
  },
  async getApplicationActions(officeId: number | string): Promise<Model.Action[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/actions/`);
    return res.data;
  },
  async fetchApplicationDetail({
    officeId,
    applicationId,
  }: {
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Model.ApplicationDetail | { errors: ErrorObject[] } | { error: ErrorResponse }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/detail/`);
    return res.data;
  },
  async patchApplication({
    officeId,
    applicationId,
    data,
  }: {
    officeId: number | string;
    applicationId: number | string;
    data: {
      termApplication: Model.ApplicationDetail["termApplication"];
      transferPermission: Model.ApplicationDetail["transferPermission"];
    };
  }): Promise<Model.ApplicationDetail | { errors: ErrorObject[] } | { error: ErrorResponse }> {
    const res = await $api.patch(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/`, data);
    return res.data;
  },
  async fetchApplicationHistory({
    officeId,
    applicationId,
  }: {
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Model.ApplicationHistory[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/history/`);
    return res.data;
  },
  async createExecutor({
    data,
    officeId,
    applicationId,
  }: {
    data: Model.Executor;
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Model.Executor | { errors: ErrorObject[] }> {
    const res = await $api.post(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/executor/`, data);
    return res.data;
  },
  async fetchExecutor({
    officeId,
    applicationId,
  }: {
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Model.Executor | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/executor/`);
    return res.data;
  },
  async fetchApplicationClients({
    officeId,
    applicationId,
  }: {
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Model.ApplicationClient[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/`);
    return res.data;
  },
  async createApplicationClient({
    data,
    officeId,
    applicationId,
    clientId,
    memberType,
  }: {
    data: FormData;
    officeId: number | string;
    applicationId: number | string;
    clientId?: number | string;
    memberType: number | string;
  }): Promise<Model.ApplicationClient | { errors: ErrorObject[] }> {
    const res = await $api.post(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/?${
        memberType === "" ? "" : `member_type=${memberType}`
      }${clientId ? `&client_id=${clientId}` : ""}`,
      data,
      config,
    );
    return res.data;
  },
  async changeApplicationClient({
    data,
    officeId,
    applicationId,
    clientId,
    memberId,
  }: {
    data: FormData;
    officeId: number | string;
    applicationId: number | string;
    clientId?: number | string;
    memberId: number;
  }): Promise<Model.ApplicationClient | { errors: ErrorObject[] }> {
    const res = await $api.put(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/?member_id=${memberId}${
        clientId ? `&client_id=${clientId}` : ""
      }`,
      data,
      config,
    );
    return res.data;
  },

  async deleteApplicationClient({
    officeId,
    applicationId,
    memberId,
  }: {
    officeId: number | string;
    applicationId: number | string;
    memberId: number;
  }): Promise<Model.ApplicationClient | Model.Error> {
    const res = await $api.delete(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/`,
      config,
    );
    return res.data;
  },
  async updatePriority({
    officeId,
    applicationId,
    priority,
  }: {
    officeId: number | string;
    applicationId: number | string;
    priority: number;
  }): Promise<ResponseSuccess | { errors: ErrorObject[] }> {
    const res = await $api.post(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/priority/`, { priority });
    return res.data;
  },
  async fetchDocumentsApp({
    officeId,
    applicationId,
    query,
  }: {
    officeId: number | string;
    applicationId: number | string;
    query?: string;
  }): Promise<Model.FetchDocument[] | { errors: ErrorObject[] }> {
    const res = await $api.get(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/documents/${query ? query : ""}`,
    );
    return res.data;
  },
  async downloadDocumentsApp({
    officeId,
    applicationId,
    query,
  }: {
    officeId: number | string;
    applicationId: number | string;
    query?: string;
  }): Promise<Blob | { errors: ErrorObject[] }> {
    const res = await $api.get(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/documents/application/download/${query}`,
      { responseType: "blob" },
    );
    return res.data;
  },
  async fetchDocuments({
    memberId,
    officeId,
    applicationId,
  }: {
    memberId: number;
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Model.FetchDocumentWithChecked[] | { errors: ErrorObject[] }> {
    const res = await $api.get(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/documents/`,
    );
    return res.data;
  },
  async downloadMemberDocuments({
    memberId,
    officeId,
    applicationId,
  }: {
    memberId: number;
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Blob | { errors: ErrorObject[] }> {
    const res = await $api.get(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/documents/download/`,
      { responseType: "blob" },
    );
    return res.data;
  },
  async loadDocumentsFromCard({
    memberId,
    officeId,
    applicationId,
    documentId,
    documentType,
  }: {
    memberId: number;
    officeId: number | string;
    applicationId: number | string;
    documentId: number;
    documentType: number;
  }): Promise<Model.Error | { error: Model.Error }> {
    const res = await $api.post(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/documents/import/`,
      { documentId, documentType },
    );
    return res.data;
  },
  async uploadsDocumentsApp({
    officeId,
    applicationId,
    data,
    query,
  }: {
    officeId: number | string;
    applicationId: number | string;
    data: FormData;
    query?: string;
  }): Promise<Model.Error | { errors: ErrorObject[] }> {
    const res = await $api.post(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/documents/${query ? query : ""}`,
      data,
      config,
    );
    return res.data;
  },
  async uploadsDocuments({
    memberId,
    officeId,
    applicationId,
    data,
  }: {
    memberId: number;
    officeId: number | string;
    applicationId: number | string;
    data: FormData;
  }): Promise<Model.Error | { errors: ErrorObject[] }> {
    const res = await $api.post(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/documents/`,
      data,
      config,
    );
    return res.data;
  },
  async deleteScanApp({
    officeId,
    applicationId,
    scanId,
  }: {
    officeId: number | string;
    applicationId: number | string;
    scanId: number;
  }): Promise<Model.Error | { errors: ErrorObject[] }> {
    const res = await $api.delete(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/documents/${scanId}/`);
    return res.data;
  },
  async deleteScan({
    memberId,
    officeId,
    applicationId,
    scanId,
  }: {
    memberId: number;
    officeId: number | string;
    applicationId: number | string;
    scanId: number;
  }): Promise<Model.Error | { errors: ErrorObject[] }> {
    const res = await $api.delete(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/documents/${scanId}/`,
    );
    return res.data;
  },
  async applyApplication({
    officeId,
    applicationId,
    data,
  }: {
    officeId: number | string;
    applicationId: number | string;
    data: {
      commentary?: string;
      historyType: number;
    };
  }): Promise<Model.Error | { errors: ErrorObject[] }> {
    const res = await $api.post(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/next/`, data);
    return res.data;
  },
  async changeClientMeet({
    officeId,
    applicationId,
    memberId,
  }: {
    officeId: number | string;
    applicationId: number | string;
    memberId: number | string;
  }): Promise<Model.ApplicationClient | { errors: ErrorObject[] }> {
    const res = await $api.patch(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/meeting/`,
    );
    return res.data;
  },

  async fetchAppicationDocumentsList({
    officeId,
    applicationId,
  }: {
    officeId: number | string;
    applicationId: number | string;
  }): Promise<string[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/documents/list/`);
    return res.data;
  },

  async addAppicationClientReprasentative({
    officeId,
    applicationId,
    memberId,
    representativeId,
  }: {
    officeId: number | string;
    applicationId: number | string;
    memberId: number | string;
    representativeId: number | string;
  }): Promise<Response.MemberResponse | { errors: ErrorObject[] }> {
    const res = await $api.post(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/representative/${representativeId}/`,
    );
    return res.data;
  },

  async getDocumentsDeadline({
    officeId,
    applicationId,
  }: {
    officeId: number | string;
    applicationId: number | string;
  }): Promise<Response.DocumentsDeadline | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/deadline/`);
    return res.data;
  },

  async changeDocumentsDeadline({
    officeId,
    applicationId,
    data,
  }: {
    officeId: number | string;
    applicationId: number | string;
    data: Response.DocumentsDeadline;
  }): Promise<Response.DocumentsDeadline | { errors: ErrorObject[] }> {
    const res = await $api.put(`${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/deadline/`, data);
    return res.data;
  },

  async changeDocumentsCheckboxes({
    officeId,
    applicationId,
    memberId,
    data,
  }: {
    officeId: number | string;
    applicationId: number | string;
    memberId: number | string;
    data: object;
  }): Promise<Response.DocumentsDeadline | { errors: ErrorObject[] }> {
    const res = await $api.patch(
      `${Endpoints.NOTARIES}${officeId}/applications/${applicationId}/clients/${memberId}/checkboxes/`,
      data,
    );
    return res.data;
  },
};
