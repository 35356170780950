import cn from "classnames";
import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import { PlusIcon, WhatsuppIcon } from "@/assets/icons";
import {
  AddDocumentOrMemberForm,
  Applications,
  DetailHead,
  DetailHeadSkeleton,
  Documents,
  LinkCards,
  LinkCardsModal,
  Scans,
  Search,
  Members,
  UpdateClient,
  ErrorResult,
} from "@/blocks/index";
import { formatPhoneNumber } from "@/helpers/index";
import { Container } from "@/layout/index";
import { watsappDomen } from "@/shared/constants";
import { useAppSelector, useActions, useUserPermissions } from "@/store/hooks";
import { useFetchClientQuery, useFetchRelatedCardsUpdateMutation, clientApi } from "@/store/services/client.query";
import { Button, Loader, Modal, Tab } from "@/ui/index";

import styles from "./Client.module.scss";
import { filtersArray, individualPerson, legalPerson, soleProprietorshipArray } from "./mockData";

const createButton = (onClickAction: () => void, buttonText: string) => (
  <Button type="button" variant="primary" size="small" onClick={onClickAction}>
    <PlusIcon /> {buttonText}
  </Button>
);

const updateArrayValues = (
  array: ObjectTypeGeneric<Model.Client>[],
  backendObject: any,
): ObjectTypeGeneric<Model.Client>[] => {
  const newArr = array.map((item) => {
    if (Object.prototype.hasOwnProperty.call(backendObject, item.value)) {
      if (item.value === "passportSeries") {
        return {
          ...item,
          key: "Серия и номер паспорта",
          value: `${backendObject.passportSeries} ${backendObject.passportNumber}`,
        };
      }
      if (item.key === "СНИЛС") {
        const regexpValue = backendObject[item.value].replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1-$2-$3 $4");

        return { ...item, value: regexpValue };
      }

      return { ...item, value: backendObject[item.value] };
    } else if (item.value === "legalRepresentativeName" && !backendObject[item.value]) {
      return null;
    }

    return item;
  });

  // @ts-ignore
  return newArr.filter((item) => item !== null);
};

const Client = () => {
  const { officeId, clientId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [personPosition, setPersonPostion] = useState<string>("");
  const [filters, setFilters] = useState<TabData[]>(filtersArray);
  const [currentFilter, setCurrentFilter] = useState<string>("");
  const [info, setInfo] = useState<ObjectTypeGeneric<Model.Client>[]>([]);
  const [hide, setHide] = useState(true);
  const { client, appicationCount } = useAppSelector((state) => state.client);
  const fetchClient = useFetchClientQuery(
    { officeId: Number(officeId), clientId: Number(clientId) },
    { refetchOnMountOrArgChange: true },
  );
  const { setClient } = useActions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [isError, setIsError] = useState<number | null>(null);
  const [updateList] = useFetchRelatedCardsUpdateMutation();
  const dispatch = useDispatch();
  const permissions = useUserPermissions();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setHide(true);
  }, [officeId, clientId]);

  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
  }, [search]);

  useEffect(() => {
    if (fetchClient.isSuccess && fetchClient.data) {
      setClient(fetchClient.data as Model.ClientResponse);
      const newPostion = (fetchClient.data as Model.ClientResponse).personPosition.position;
      setPersonPostion(newPostion);

      if (newPostion === "individualPerson") {
        setInfo(updateArrayValues([...individualPerson], fetchClient.data as Model.ClientResponse));
      }

      if (newPostion === "legalPerson") {
        setInfo(updateArrayValues([...legalPerson], fetchClient.data as Model.ClientResponse));
        setFilters(filtersArray);
      }

      if (newPostion === "soleProprietorship") {
        setInfo(updateArrayValues([...soleProprietorshipArray], fetchClient.data as Model.ClientResponse));
      }

      if (newPostion === "individualPerson" || newPostion === "soleProprietorship") {
        const excludedValues = ["applications", "scans", "cards"];
        const newFilters = filtersArray.filter((el) => excludedValues.includes(el.value));
        setFilters(newFilters);
      }

      setHide(false);
    }

    if (fetchClient.error) {
      if ((fetchClient.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchClient.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchClient.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchClient]);

  useEffect(() => {
    setFilters(
      filters.map((item) => {
        let newItem = item;
        if (item.value === "applications") {
          newItem = { ...item, count: appicationCount };
        }
        return newItem;
      }),
    );
  }, [appicationCount]);

  useEffect(() => {
    const param = queryParams.get("filters");
    setCurrentFilter(param ? param : filters[0].value);
  }, [search]);

  useEffect(() => {
    if (!isEdit) {
      fetchClient.refetch();
    }
  }, [isEdit]);

  const modalHandle = (value: boolean) => {
    setShowModal(value);

    if (value) {
      setIsModalOpen(value);
    } else {
      setTimeout(() => {
        setIsModalOpen(value);
        setSearchQuery("");
        dispatch(clientApi.util.invalidateTags(["LinkedCards"]));
      }, 250);
    }
  };

  const buttonHandle = () => {
    modalHandle(true);
  };
  const buttonMap: Record<string, any> = {
    cards: {
      addButton: createButton(() => buttonHandle(), "Привязать карточки"),
    },
    documents: {
      addButton: createButton(() => buttonHandle(), "Добавить документ"),
    },
    members: {
      addButton: createButton(() => buttonHandle(), "Добавить представителя"),
    },
  };
  const currentButton = buttonMap[currentFilter];

  const filterComponents: { [key: string]: React.ReactNode } = {
    applications: (
      <CSSTransition key="applications" timeout={250} classNames="fade">
        <Applications scrollTo={700} target="_blank" />
      </CSSTransition>
    ),
    scans: (
      <CSSTransition key="scans" timeout={250} classNames="fade">
        <Scans />
      </CSSTransition>
    ),
    cards: (
      <CSSTransition key="cards" timeout={250} classNames="fade">
        <LinkCards backLink={`/offices/${officeId}/clients/${clientId}?filters=cards`} />
      </CSSTransition>
    ),
    documents: (
      <CSSTransition key="documents" timeout={250} classNames="fade">
        <Documents />
      </CSSTransition>
    ),
    members: (
      <CSSTransition key="members" timeout={250} classNames="fade">
        <Members backLink={`/offices/${officeId}/clients/${clientId}?filters=members`} />
      </CSSTransition>
    ),
  };

  const changeHandle: InputOnChange = (val) => {
    setSearchQuery(val.value.toString());
  };

  const updateClient = (data: Model.ClientResponse) => {
    setClient(data);
    updateList({ clientId: Number(clientId), page: currentPage });
  };

  return (
    <div className={`${styles.client} container`} id={`card${client.id}`}>
      <SwitchTransition>
        <CSSTransition key={isError ? "error" : "content"} timeout={250} classNames="fade">
          {isError ? (
            <div className={styles.errorWrap}>
              <ErrorResult code={isError} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                {Object.keys(client).length > 0 && (
                  <DetailHead
                    status={client.status as Status}
                    name={String(client.name)}
                    date={String(client.createdAt)}
                    number={Number(client.id)}
                    image={client.image}
                    id={Number(clientId)}
                    backLink={`/offices/${officeId}/clients?position=${personPosition}`}
                    setIsEdit={setIsEdit}
                    priory={client.priority}
                    permission={permissions?.customUpdateClient}
                  />
                )}
                <TransitionGroup component={null}>
                  {(hide || fetchClient.isFetching) && (
                    <CSSTransition key="skeleton" timeout={250} classNames="fade">
                      <DetailHeadSkeleton />
                    </CSSTransition>
                  )}
                </TransitionGroup>
              </div>
              <SwitchTransition>
                <CSSTransition key={hide || fetchClient.isFetching ? "loader" : "content"} timeout={250} classNames="fade">
                  {hide || fetchClient.isFetching ? (
                    <Loader />
                  ) : (
                    <>
                      {isEdit ? (
                        <Container overflow>
                          <UpdateClient client={client} setIsEdit={setIsEdit} onSaved={updateClient} />
                        </Container>
                      ) : (
                        <Container>
                          <ul className={styles.info}>
                            {info.map(
                              (el) =>
                                el.value !== undefined &&
                                el.value !== null &&
                                String(el.value).trim() !== "" && (
                                  <li key={el.id} className={styles.infoItem}>
                                    <p className={cn(styles.infoKey, personPosition === "legalPerson" && styles.bigWidth)}>
                                      {el.key}
                                    </p>
                                    {el.key === "Почта" ? (
                                      <a href={`mailto:${el.value}`}>{el.value}</a>
                                    ) : el.key === "Телефон" ? (
                                      <div className={styles.links}>
                                        <a href={`tel:${el.value}`}>{formatPhoneNumber(el.value)}</a>
                                        <a
                                          href={`${watsappDomen}/${el.value}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className={styles.whatsapp}>
                                          <WhatsuppIcon />
                                        </a>
                                      </div>
                                    ) : el.key === "Табельный номер" || el.key === "Номер конторы" ? (
                                      <p>{officeId}</p>
                                    ) : el.key === "Физическое лицо" && client.linkedPerson ? (
                                      <Link
                                        to={`/offices/${officeId}/clients/${
                                          (client.linkedPerson as Model.LinkedPerson).id || client.linkedPerson
                                        }`}
                                        state={{
                                          prev: `/offices/${officeId}/clients/${clientId}?filters=${currentFilter}`,
                                        }}>
                                        {el.value}
                                      </Link>
                                    ) : (
                                      <p>{el.value}</p>
                                    )}
                                  </li>
                                ),
                            )}
                          </ul>
                        </Container>
                      )}
                      <Container>
                        <div className={styles.filter}>
                          <div
                            className={cn(styles.filterlist, {
                              [styles.borderNone]: currentFilter === "cards" || currentFilter === "applications",
                            })}>
                            <div className={styles.filterButtons}>
                              <div className={styles.filterButtonsList}>
                                {filters.map(({ ...other }, idx) => (
                                  <Tab
                                    key={idx}
                                    {...other}
                                    active={currentFilter}
                                    link={`/offices/${officeId}/clients/${clientId}?filters=${other.value}`}
                                  />
                                ))}
                              </div>
                              {currentButton && permissions?.customUpdateClient ? currentButton.addButton : null}
                            </div>
                          </div>
                          <div className={styles.content}>{filterComponents[currentFilter]}</div>
                        </div>
                      </Container>
                    </>
                  )}
                </CSSTransition>
              </SwitchTransition>
              <Modal
                show={showModal}
                setShow={modalHandle}
                isScroll={currentFilter === "cards" && true}
                size="lg"
                className={cn(styles.modal, currentFilter === "scans" ? styles.scanModal : styles[currentFilter])}
                notCentered={true}>
                <div className={styles.modalTop}>
                  <h4>
                    {currentFilter === "cards"
                      ? "Привязать карточки"
                      : currentFilter === "documents"
                        ? "Новый учредительный документ"
                        : "Добавить представителя"}
                  </h4>

                  {currentFilter === "cards" && isModalOpen && (
                    <Search
                      placeholder="ФИО клиента, телефон или почта"
                      className={styles.filtersSearch}
                      onChange={changeHandle}
                    />
                  )}
                </div>
                {currentFilter === "cards" && isModalOpen && (
                  <LinkCardsModal
                    backLink={`/offices/${officeId}/clients/${clientId}?filters=cards`}
                    setShowModal={modalHandle}
                    searchQuery={searchQuery}
                  />
                )}
                {currentFilter === "documents" && isModalOpen && (
                  <AddDocumentOrMemberForm setShowModal={modalHandle} isDocument={true} />
                )}
                {currentFilter === "members" && isModalOpen && (
                  <AddDocumentOrMemberForm setShowModal={modalHandle} isDocument={false} />
                )}
              </Modal>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Client;
