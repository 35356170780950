import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";
import { EmployeeService } from "@/services/EmployeesService";
import { UserService } from "@/services/UserService";

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  tagTypes: ["Employee", "User"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    fetchEmployees: builder.query<
      Response.EmployeeResponse | { errors: ErrorObject[] },
      { officeId: number; query: string }
    >({
      queryFn: async ({ officeId, query }) => {
        try {
          const res = await EmployeeService.fetchEmployees({ officeId, query });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Employee"],
    }),
    fetchEmployee: builder.query<
      Model.Employee | { errors: ErrorObject[] } | { error: ErrorResponse },
      { officeId: number; employeeId: number }
    >({
      queryFn: async ({ officeId, employeeId }) => {
        try {
          const res = await EmployeeService.fetchEmployee({ officeId, employeeId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Employee", "User"],
    }),
    createEmployee: builder.mutation<Model.Employee | { errors: ErrorObject[] }, { id: number; data: Model.Employee }>({
      queryFn: async ({ id, data }) => {
        try {
          const res = await EmployeeService.createEmployee({ id, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
    updateImage: builder.mutation<
      { image: string } | { errors: ErrorObject[] },
      {
        officeId: number;
        employeeId: number;
        data: FormData;
      }
    >({
      queryFn: async ({ officeId, employeeId, data }) => {
        try {
          const res = await EmployeeService.updateImage({ officeId, employeeId, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Employee"],
    }),
    updateEmployee: builder.mutation<
      Model.Employee | { errors: ErrorObject[] },
      {
        officeId: number;
        employeeId: number;
        data: Model.Employee;
      }
    >({
      queryFn: async ({ officeId, employeeId, data }) => {
        try {
          const res = await EmployeeService.updateEmployee({ officeId, employeeId, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
    updateUser: builder.mutation<Model.User | { errors: ErrorObject[] }, Model.UpdateUser>({
      queryFn: async (data) => {
        try {
          const res = await UserService.updateUser(data);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Employee"],
    }),
  }),
});

export const {
  useFetchEmployeesQuery,
  useCreateEmployeeMutation,
  useUpdateImageMutation,
  useUpdateEmployeeMutation,
  useFetchEmployeeQuery,
  useUpdateUserMutation,
  useLazyFetchEmployeesQuery,
} = employeeApi;
