import cn from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { PlusIcon } from "@/assets/icons";
import { OfficeCard, Search, Sort, ErrorResult, EmptyState } from "@/blocks/index";
import { getDecodedOfficeId, getTokensFromStorage } from "@/helpers/index";
import { PAGE_LIMIT } from "@/shared/constants";
import { useActions, useAppSelector, useUserIsSystemAdmin } from "@/store/hooks/index";
import { useFetchOfficesQuery } from "@/store/services/office.query";
import { Pagination, Loader, Button, Tab } from "@/ui/index";

import { optionsData } from "./mockData";
import styles from "./Office.module.scss";

const Office = () => {
  const { setOffices, setFilters, setNewStatus } = useActions();
  const { offices, filters } = useAppSelector((state) => state.office);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isError, setIsError] = useState<number | null>(null);
  const [currentTab, setCurrentTab] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: currentPage });
  const [sort, setSort] = useState(queryParams.get("sort"));
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [queryString, setQueryString] = useState<string>("");
  const { data, error, isFetching, isSuccess } = useFetchOfficesQuery(queryString);
  const isSystemAdmin = useUserIsSystemAdmin();
  const navigate = useNavigate();
  const token = getTokensFromStorage();

  useEffect(() => {
    if (!isSystemAdmin) {
      const officeId = getDecodedOfficeId(String(token?.access));
      navigate(`/offices/${officeId ? Number(officeId) : ""}`);
    }
  }, [isSystemAdmin]);

  useEffect(() => {
    const orderBy = sort !== "" && sort !== null ? `order_by=${sort === "recent" ? "-created_at" : "created_at"}` : "";
    const isActive = currentTab === "" ? "is_active=" : `is_active=${currentTab === "True" ? "True" : "False"}`;
    const search = searchQuery.length > 0 ? `&search=${searchQuery}` : "";
    const newString = [orderBy, isActive, search].filter(Boolean);
    const queryString = newString.length > 0 ? `?page=${currentPage}&${newString.join("&")}` : `?page=${currentPage}`;

    setQueryString(queryString);
  }, [queryParams, currentPage, sort, currentTab]);

  useEffect(() => {
    if (isSuccess && data) {
      const fetch = data as Office.OfficeResponceData;
      if (fetch.filters.length > 0) {
        setFilters(fetch.filters[0].options);
      }
      setOffices(fetch.results);
      if (currentTab === "") {
        setNewStatus(fetch.results.length > 0);
      }
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    const param = queryParams.get("status");
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
    setCurrentTab(param ? param : "");
    setSort(queryParams.get("sort"));
  }, [search]);

  useEffect(() => {
    if (error) {
      if ((error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((error as ErrorResponse).status === 404 ? 404 : 500);
      }
    } else {
      setIsError(null);
    }
  }, [error]);

  const changeHandle: InputOnChange = (val) => {
    setSearchQuery(val.value.toString());
  };

  return (
    <main className={styles.office}>
      <Helmet>
        <title>Нотариальные конторы</title>
      </Helmet>
      <div className={`office__container ${styles.topContainer} `}>
        <h2>Нотариальные конторы</h2>
        <div className={styles.office__tabs}>
          {filters.length > 0 &&
            filters.map(({ ...other }, idx) => (
              <Tab
                key={idx}
                {...other}
                active={currentTab}
                notific={filters[0].name}
                link={`/offices?status=${other.value}`}
              />
            ))}
        </div>
        <div className={styles.filters}>
          <Search onChange={(val) => changeHandle(val)} placeholder="Название конторы, адрес" />
          <Sort options={optionsData} className={styles.sort} />
          <Button href="/create-office" size="small">
            <PlusIcon />
            Добавить контору
          </Button>
        </div>
      </div>
      <div className={`office__container ${styles.gridContainer} `}>
        <SwitchTransition>
          <CSSTransition key={isFetching ? "loader" : "content"} timeout={250} classNames="fade">
            {isFetching ? (
              <div className={styles.loaderWrap}>
                <Loader />
              </div>
            ) : (
              <SwitchTransition>
                <CSSTransition key={isError ? "error" : "content"} timeout={250} classNames="fade">
                  {isError ? (
                    <ErrorResult code={isError} url="/offices" button="Перейти к списку контор" />
                  ) : (
                    <SwitchTransition>
                      <CSSTransition key={offices?.length > 0 ? "list" : "empty"} timeout={250} classNames="fade">
                        {offices.length > 0 ? (
                          <div className={`office__container ${styles.gridContainer} `}>
                            {offices.map((office) => (
                              <OfficeCard
                                key={office.id}
                                {...office}
                                routerState={{ prev: `/offices/${currentTab ? `?status=${currentTab}` : ""}` }}
                              />
                            ))}
                            {pagination.count !== 0 && pagination.count > PAGE_LIMIT && (
                              <div className={cn("office__container", styles.pagination)}>
                                <Pagination
                                  totalPages={Math.ceil(pagination.count / PAGE_LIMIT)}
                                  currentPage={pagination.page}
                                  previous={pagination.previous}
                                  next={pagination.next}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <EmptyState
                            text={`${
                              currentTab === "" ? "новых" : currentTab === "True" ? "активных" : "неактивных"
                            } контор `}
                            isTrial
                          />
                        )}
                      </CSSTransition>
                    </SwitchTransition>
                  )}
                </CSSTransition>
              </SwitchTransition>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </main>
  );
};

export default Office;
