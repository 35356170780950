import type { FC } from "react";

import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";

import { useApplyApplicationMutation } from "@/store/services/application.query";
import { Modal, Textarea, Button } from "@/ui/index";

import styles from "./CancelApplication.module.scss";

const CancelApplication: FC<{
  show: boolean;
  setShow: (val: boolean) => void;
  officeId: string;
  applicationId: string;
}> = ({ show, setShow, officeId, applicationId }) => {
  const [value, setValue] = useState("");
  const [applyApplication, appliedAppliation] = useApplyApplicationMutation();

  useEffect(() => {
    if (appliedAppliation.isSuccess && appliedAppliation.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setValue("");
      setShow(false);
    }
    if (appliedAppliation.isError) {
      const message = appliedAppliation.error
        ? (appliedAppliation.error as Model.Error).message
        : "При отправке запроса произошла ошибка";

      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [appliedAppliation]);

  const changeHandle: Textarea["onChange"] = ({ value }) => {
    setValue(String(value));
  };

  const cancelApp = () => {
    const commentary = value.trim() === "" ? undefined : value;
    applyApplication({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      data: {
        commentary,
        historyType: 4,
      },
    });
  };

  return (
    <Modal show={show} setShow={setShow} size="lg" className={styles.modal}>
      <div className={styles.modalTop}>
        <h4>Отмена заявки</h4>
        <div className={styles.description}>Оставьте комментарий с причиной отмены заявки.</div>
      </div>
      <div className={styles.modalInner}>
        <Textarea
          id={1}
          rows={7}
          field="description"
          value={value}
          onChange={changeHandle}
          placeholder="Опишите причину отмены заявки"
          className={styles.input}
        />
        <Button variant="primary" disabled={value.length < 1} onClick={cancelApp}>
          Отправить
        </Button>
      </div>
    </Modal>
  );
};

export default CancelApplication;
