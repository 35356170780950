import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SwitchTransition, TransitionGroup, CSSTransition } from "react-transition-group";

import { EmployeeCard, ListHead, EmptyState, ErrorResult } from "@/blocks/index";
import { PAGE_LIMIT } from "@/shared/constants";
import { useActions, useAppSelector, useUserPermissions } from "@/store/hooks";
import { useFetchEmployeesQuery } from "@/store/services/employee.query";
import { Pagination } from "@/ui/index";
import { Loader } from "@/ui/Loader";

import styles from "./Employee.module.scss";
import { employeesOptions1 } from "./mockData";

const Employees = () => {
  const { officeId, id } = useParams();
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isError, setIsError] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { office } = useAppSelector((state) => state.office);
  const { employees } = useAppSelector((state) => state.employees);
  const permissions = useUserPermissions();
  const fetchedEmployees = useFetchEmployeesQuery(
    {
      officeId: Number(officeId) || Number(id),
      query: `${search}${searchQuery.length > 0 ? `${search.length > 0 ? "&" : "?"}search=${searchQuery}` : ""}`,
    },
    { refetchOnMountOrArgChange: true },
  );
  const { setEmployees } = useActions();

  useEffect(() => {
    if (fetchedEmployees.isSuccess && fetchedEmployees.data) {
      const fetch = fetchedEmployees.data as Response.EmployeeResponse;
      setEmployees(fetch.results);
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
    }
    if (fetchedEmployees.error) {
      if ((fetchedEmployees.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchedEmployees.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchedEmployees.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchedEmployees]);

  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
  }, [search]);

  const changeHandle: InputOnChange = (val) => {
    setSearchQuery(val.value.toString());

    if (currentPage > 1) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={styles.employees}>
      <ListHead
        title="Сотрудники "
        count={office.employees}
        options={employeesOptions1}
        button={
          permissions?.customCreateEmployeePermission
            ? {
                href: `/offices/${office.id}/add-employee`,
                children: "Добавить сотрудника",
              }
            : undefined
        }
        onChange={(val) => changeHandle(val)}
      />
      <div className={cn(styles.employeesList)}>
        <SwitchTransition>
          <CSSTransition key={fetchedEmployees.isFetching ? "loader" : "content"} timeout={250} classNames="fade">
            {fetchedEmployees.isFetching ? (
              <Loader />
            ) : (
              <div className={styles.clientList}>
                <SwitchTransition>
                  <CSSTransition key={isError ? "error" : "content"} timeout={250} classNames="fade">
                    {isError ? (
                      <ErrorResult code={isError} />
                    ) : (
                      <>
                        <SwitchTransition>
                          <CSSTransition key={employees?.length > 0 ? "list" : "empty"} timeout={250} classNames="fade">
                            {employees?.length > 0 ? (
                              <>
                                <TransitionGroup component={null}>
                                  {employees?.map((value) => (
                                    <CSSTransition key={value.id} classNames="slide" timeout={300} unmountOnExit>
                                      <EmployeeCard {...value} />
                                    </CSSTransition>
                                  ))}
                                </TransitionGroup>
                                {pagination.count > PAGE_LIMIT && (
                                  <div className={cn("office__container", styles.pagination)}>
                                    <Pagination
                                      totalPages={Math.ceil(pagination.count / PAGE_LIMIT)}
                                      currentPage={currentPage}
                                      previous={pagination.previous}
                                      next={pagination.next}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <EmptyState text="сотрудников" />
                            )}
                          </CSSTransition>
                        </SwitchTransition>
                      </>
                    )}
                  </CSSTransition>
                </SwitchTransition>
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};
export default Employees;
