import cn from "classnames";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Collapse, initTE } from "tw-elements";

import { EditIcon } from "@/assets/icons";
import { AddressBlock, Info, UpdateClient } from "@/blocks/index";
import { useLazyFetchClientQuery, useUpdatePriorityMutation } from "@/store/services/client.query";
import { Avatar, Select, Status, Loader } from "@/ui/index";

import styles from "./ClientCard.module.scss";
import { getPriority, priorityMockdata } from "./mockData";

const ClientCard: Blocks.ClientCard = ({
  name,
  image,
  id,
  phoneNumber,
  email,
  status,
  createdAt,
  priority,
  inn,
  isLinkCard,
  noOverflow,
  canEdit,
}) => {
  const params = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [priorities, setPriorities] = useState<Option[]>([]);
  const [currentClient, setCurrentClient] = useState<Model.ClientResponse | null>(null);
  const [selectedOption, setSelectedOption] = useState<Option>({ ...getPriority[Number(priority)] });

  const [updatePriority] = useUpdatePriorityMutation();
  const [trigger, triggerUserData] = useLazyFetchClientQuery();

  useEffect(() => {
    initTE({ Collapse }, { allowReinits: true });
  }, []);

  useEffect(() => {
    if (priority) {
      setSelectedOption({ ...getPriority[Number(priority)] });
      setPriorities(priorityMockdata.filter((el) => el.value !== priority));
    }
  }, [priority]);

  useEffect(() => {
    if (isEdit) {
      trigger({ officeId: Number(params.id), clientId: Number(id) });
    }
  }, [isEdit]);

  useEffect(() => {
    if (triggerUserData.isSuccess && triggerUserData.data) {
      setCurrentClient(triggerUserData.data as Model.ClientResponse);
    }
  }, [triggerUserData]);

  const handleSelectChange = (val: { field: string; value: string | number; id: number | string }) => {
    const foundOption = priorityMockdata.find((option) => option.value === val.value);
    foundOption && setSelectedOption(foundOption);

    updatePriority({
      officeId: Number(params.id),
      clientId: Number(id),
      priority: Number(val.value),
    });
  };

  const editHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    const button = e.currentTarget as HTMLButtonElement;
    document.querySelector(`#card${id}`)?.scrollIntoView({
      behavior: "smooth",
    });
    setIsEdit(true);
    if (isEdit) {
      button.setAttribute("aria-expanded", "true");
    } else {
      button.setAttribute("aria-expanded", "false");
    }
  };
  return (
    <div className={cn(styles.cardWr, { [styles.isEdit]: isEdit, [styles.noOverflow]: noOverflow })}>
      <div className={styles.card} id={`card${id}`}>
        <Link
          className={styles.link}
          to={`/offices/${params.id}/clients/${id}`}
          // onClick={() =>
          //   window.scrollTo({
          //     top: 0,
          //     behavior: "smooth",
          //   })
          // }
        />
        <div className={styles.wrapper}>
          <div className={styles.cardContent}>
            <Avatar imageUrl={image} fullName={name} size="medium" />
            <div className={styles.cardInfo}>
              <Info officeNumber={Number(id) ?? 0} createdDate={createdAt} isUser />
              <h6>
                <span>{currentClient?.name || name}</span>{" "}
                {status && <Status type={status.type} name={status.name} size="small" />}
              </h6>
              <AddressBlock
                email={String(currentClient?.email || email)}
                mobilePhone={currentClient?.phoneNumber || phoneNumber}
                inn={currentClient?.inn || inn}
                className={styles.cardAddress}
              />
            </div>
          </div>
          <div className={styles.cardRightSide}>
            {!isLinkCard && (
              <Select
                id={id}
                value={Number(priority)}
                field={"priority"}
                options={priorities}
                defaultValue={selectedOption}
                onSelect={handleSelectChange}
                className={styles.select}
                withDots={true}
              />
            )}

            {!isLinkCard && canEdit && (
              <button
                type="button"
                id={`button-collapse${id}`}
                className={styles.editIcon}
                data-te-collapse-init
                data-te-collapse-collapsed
                data-te-target={`#collapse${id}`}
                aria-expanded="false"
                aria-controls={`collapse${id}`}
                onClick={(e) => editHandle(e, Number(id))}>
                <EditIcon />
              </button>
            )}
          </div>
        </div>
        <div
          id={`collapse${id}`}
          className={cn(isEdit ? "visible" : "hidden", styles.editFormWrap)}
          data-te-collapse-item
          aria-labelledby={`card${id}`}
          data-te-parent="#accordionExample">
          <div className={styles.collapseContent}>
            <SwitchTransition>
              <CSSTransition
                key={triggerUserData.isFetching || !currentClient ? "loader" : "content"}
                timeout={250}
                classNames="fade">
                {triggerUserData.isFetching || !currentClient ? (
                  <div className={styles.loaderWrap}>
                    <Loader />
                  </div>
                ) : (
                  <div>
                    {isEdit && <UpdateClient client={currentClient} setIsEdit={setIsEdit} onSaved={setCurrentClient} />}
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
