export const filtersArray = [
  { value: "individualPerson", name: "Физ. лица", link: "" },
  { value: "legalPerson", name: "Юр. лица", link: "" },
  { value: "soleProprietorship", name: "ИП", link: "" },
];

export const clientOptions = [
  {
    value: "",
    name: "Сортировать по",
  },
  {
    value: "created_at",
    name: "Зарегистрированы давно",
  },
  {
    value: "-created_at",
    name: "Зарегистрированы недавно",
  },
  {
    value: "is_active",
    name: "Сначала Неактивные",
  },
  {
    value: "-is_active",
    name: "Сначала Активные",
  },
  {
    value: "priority",
    name: "Сначала высокий приоритет",
  },
  {
    value: "-priority",
    name: "Сначала низкий приоритет",
  },
];
