import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";

import { DocumentService } from "./../../services/Document.Service";

export const documentApi = createApi({
  reducerPath: "documentApi",
  tagTypes: ["Document"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    createDocument: builder.mutation<
      Model.Document | { errors: ErrorObject[] },
      { data: Model.Document; clientId: number | string }
    >({
      queryFn: async ({ data, clientId }) => {
        try {
          const res = await DocumentService.createDocument({ data, clientId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Document"],
    }),
    fetchDocuments: builder.query<
      Response.DocumentsResponse | { errors: ErrorObject[] },
      { clientId: number | string; query: string }
    >({
      queryFn: async ({ clientId, query }) => {
        try {
          const res = await DocumentService.fetchDocuments({ clientId, query });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Document"],
    }),
    deleteDocument: builder.mutation<
      ResponseSuccess | { errors: ErrorObject[] },
      {
        documentId: number;
        clientId: number | string;
      }
    >({
      queryFn: async ({ documentId, clientId }) => {
        try {
          const res = await DocumentService.deleteDocument({
            documentId,
            clientId,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Document"],
    }),
    updateDocument: builder.mutation<
      Model.Document | { errors: ErrorObject[] },
      {
        clientId: number | string;
        documentId: number;
        data: Model.Document;
      }
    >({
      queryFn: async ({ clientId, documentId, data }) => {
        try {
          const res = await DocumentService.updateDocument({ clientId, documentId, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Document"],
    }),
  }),
});

export const { useFetchDocumentsQuery, useCreateDocumentMutation, useDeleteDocumentMutation, useUpdateDocumentMutation } =
  documentApi;
