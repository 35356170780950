import cn from "classnames";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SwitchTransition, TransitionGroup, CSSTransition } from "react-transition-group";

import { ClientCardSimple, ErrorResult, EmptyState } from "@/blocks/index";
import { getDateString, getDefaultStatus } from "@/helpers/utils";
import { PAGE_LIMIT } from "@/shared/constants";
import { useUserPermissions } from "@/store/hooks";
import { useFetchRelatedCardsQuery, useRelateCardMutation, clientApi } from "@/store/services/client.query";
import { Pagination, Loader } from "@/ui/index";

import styles from "./LinkCards.module.scss";
const LinkCards = ({ searchQuery, backLink }: { searchQuery?: string; backLink?: string }) => {
  const { clientId } = useParams();
  const [isError, setIsError] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [cards, setCards] = useState<Model.LinkedPerson[]>([]);
  const [relateId, setRelateId] = useState<number>();
  const fetchedCards = useFetchRelatedCardsQuery(
    { clientId: Number(clientId), page: currentPage },
    { refetchOnMountOrArgChange: true },
  );
  const [relateCard, relatedCard] = useRelateCardMutation();
  const dispatch = useDispatch();
  const permissions = useUserPermissions();

  useEffect(() => {
    if (relatedCard.isSuccess) {
      dispatch(clientApi.util.invalidateTags(["LinkedCards"]));
      if (relatedCard.originalArgs?.query.includes("remove")) {
        if (pagination.count - 1 <= PAGE_LIMIT && currentPage !== 1) {
          searchParams.set("page", `${currentPage - 1}`);
          setSearchParams(searchParams);
        }
      }
    }

    if (relatedCard.error) {
      const { message } = relatedCard.error as Model.Error;
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [relatedCard]);

  useEffect(() => {
    if (searchQuery !== undefined) {
      if (currentPage > 1) {
        searchParams.set("page", "1");
        setSearchParams(searchParams);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
  }, [search]);

  useEffect(() => {
    if (fetchedCards.isSuccess && fetchedCards.data) {
      const fetch = fetchedCards.data as Response.CardsResponse;

      setCards(fetch.results);
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
    }

    if (fetchedCards.error) {
      if ((fetchedCards.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchedCards.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchedCards.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchedCards]);

  useEffect(() => {
    setIsLoading(fetchedCards.isFetching);
  }, [fetchedCards.isFetching]);

  const relateCardHandle = (id: number, relate: string) => {
    setRelateId(id);
    relateCard({ clientId: Number(clientId), query: `?${relate}=${id}` });
  };

  return (
    <div className={styles.linkCards}>
      <SwitchTransition>
        <CSSTransition key={isLoading ? "loader" : "content"} timeout={250} classNames="fade">
          {isLoading ? (
            <Loader />
          ) : (
            <SwitchTransition>
              <CSSTransition key={isError ? "nocontent" : "content"} timeout={250} classNames="fade">
                {isError ? (
                  <ErrorResult code={isError} />
                ) : (
                  <div className={styles.cardsList}>
                    <SwitchTransition>
                      <CSSTransition key={cards.length === 0 ? "empty" : "content"} timeout={250} classNames="fade">
                        {cards.length === 0 ? (
                          <EmptyState text="ни одной привязанной карточки" absolute />
                        ) : (
                          <div className={styles.listWrapper}>
                            <TransitionGroup component={null}>
                              {cards?.length > 0 &&
                                cards.map(({ status, createdAt, id, ...value }) => (
                                  <CSSTransition
                                    key={id}
                                    classNames={fetchedCards.isFetching ? "fade" : "slide"}
                                    timeout={300}
                                    unmountOnExit>
                                    <ClientCardSimple
                                      status={getDefaultStatus(status as Status)}
                                      createdAt={getDateString(createdAt as unknown as Date)}
                                      {...value}
                                      id={id}
                                      isLink
                                      relateCardHandle={permissions?.customUpdateClient ? relateCardHandle : undefined}
                                      loading={relatedCard.isLoading && id === relateId}
                                      routerState={{ prev: backLink }}
                                    />
                                  </CSSTransition>
                                ))}
                            </TransitionGroup>
                            {pagination.count && pagination.count > PAGE_LIMIT && (
                              <div className={cn("office__container", styles.pagination)}>
                                <Pagination
                                  totalPages={Math.ceil(pagination.count / PAGE_LIMIT)}
                                  currentPage={currentPage}
                                  previous={pagination.previous}
                                  next={pagination.next}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </CSSTransition>
                    </SwitchTransition>
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
export default LinkCards;
