// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes CommentBlock_spin-anim__Q2t6- {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes CommentBlock_fadeIn__vmMKM {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes CommentBlock_fadeOut__1h-J0 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes CommentBlock_pulse__gthdC {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.CommentBlock_comment__5lHJd {
  display: flex;
  padding: 0 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/CommentBlock/CommentBlock.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;AA8BF","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.comment {\n  display: flex;\n  padding: 0 32px 32px 32px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 10px;\n  align-self: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `CommentBlock_comment__5lHJd`,
	"spin-anim": `CommentBlock_spin-anim__Q2t6-`,
	"fadeIn": `CommentBlock_fadeIn__vmMKM`,
	"fadeOut": `CommentBlock_fadeOut__1h-J0`,
	"pulse": `CommentBlock_pulse__gthdC`
};
export default ___CSS_LOADER_EXPORT___;
