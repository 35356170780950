import cn from "classnames";

import styles from "./Application.module.scss";

const ApplicationHeadSkeleton = () => {
  return (
    <div className={styles.loaderWrap}>
      <div className={cn(styles.skeleton, styles.h40, styles.w40)} />
      <div className={styles.loaderContent}>
        <div className={styles.loaderContentRoot}>
          <div className={styles.loaderBlock}>
            <div className={cn(styles.skeleton, styles.h18, styles.w160)} />
            <div className={cn(styles.skeleton, styles.h26, styles.w560)} />
            <div className={styles.loaderStatuses}>
              <div className={cn(styles.skeleton, styles.h26, styles.w90)} />
              <div className={cn(styles.skeleton, styles.h26, styles.w120)} />
              <div className={cn(styles.skeleton, styles.h26, styles.w90)} />
              <div className={cn(styles.skeleton, styles.h26, styles.w120)} />
            </div>
          </div>
          <div className={styles.loaderBlock}>
            <div className={cn(styles.skeleton, styles.h18, styles.w120)} />
            <div className={cn(styles.skeleton, styles.h21, styles.w280)} />
          </div>
        </div>
        <div className={styles.loaderStatuses}>
          <div className={cn(styles.skeleton, styles.h36, styles.w120)} />
          <div className={cn(styles.skeleton, styles.h36, styles.w36)} />
          <div className={cn(styles.skeleton, styles.h36, styles.w36)} />
        </div>
      </div>
    </div>
  );
};

export default ApplicationHeadSkeleton;
