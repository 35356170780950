import { ClientCardSimple } from "@/blocks/index";

import styles from "./ApplicationRepresentativeForm.module.scss";

export const ApplicationLegalRepresentativeForm = ({
  linkedPerson,
  backLink,
}: Blocks.ApplicationLegalRepresentativeForm) => {
  return (
    <div className={styles.wrapper}>
      <h5>Законный представитель</h5>
      {linkedPerson && (
        <ClientCardSimple
          className={styles.linkedPersonCard}
          name={linkedPerson.name}
          phoneNumber={linkedPerson.phoneNumber}
          email={linkedPerson.email}
          id={linkedPerson.id}
          target="_blank"
          routerState={{ prev: backLink }}
        />
      )}
    </div>
  );
};
