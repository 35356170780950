import type { FC } from "react";

import cn from "classnames";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Datepicker } from "tw-elements";

import { formatDate } from "@/helpers/utils";

import styles from "./Datepicker.module.scss";

const DatePicker: FC<Input> = ({
  value,
  hasLabelMark,
  label,
  id,
  disabled,
  field,
  onChange,
  required,
  placeholder,
  className,
  inputSize,
  dateProps,
  invalid,
  helperText,
}) => {
  const [currentValue, setCurrentValue] = useState<string | number>();
  const [isInvalid, setIsInvalid] = useState(invalid ? invalid : false);
  const [errorMessage, setErrorMessage] = useState(helperText ? helperText : "");
  const myInput = useRef<HTMLInputElement>(null);

  const limitDateRange = (limitFutureMonths: number, limitPastMonths: number) => {
    const currentDate = new Date();
    let futureYear = currentDate.getFullYear();
    let futureMonth = currentDate.getMonth() + limitFutureMonths;

    if (futureMonth > 11) {
      futureMonth -= 12;
      futureYear += 1;
    }

    const min = new Date(currentDate.getFullYear(), currentDate.getMonth() - limitPastMonths, 1);
    const max = new Date(futureYear, futureMonth + 1, 0);
    return { min, max };
  };

  const dateValidator = (val: string) => {
    const dateRegex =
      /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    return dateRegex.test(val);
  };

  useLayoutEffect(() => {
    setCurrentValue(value);

    if (myInput.current !== null) {
      const { max, min } = limitDateRange(Number(dateProps?.limitFutureMonths), Number(dateProps?.limitPastMonths));
      const init = async () => {
        await new Datepicker(myInput.current, {
          monthsFull: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ],
          monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
          weekdaysNarrow: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          okBtnText: "Ok",
          clearBtnText: "Очистить",
          cancelBtnText: "Отменить",
          format: "dd.mm.yyyy",
          title: "Выберите дату",
          placeholder,
          disableFuture: Boolean(dateProps?.disableFuture),
          disablePast: Boolean(dateProps?.disablePast),
          max: new Date(max),
          min: new Date(min),
        });

        if (myInput.current !== null) {
          myInput.current.dispatchEvent(new Event("input", { bubbles: true }));

          myInput.current.addEventListener("dateChange.te.datepicker", (event: any) => {
            setCurrentValue(formatDate(event.date));
          });

          myInput.current.addEventListener("close.te.datepicker", (event: any) => {
            if (event?.srcElement?.children[0]?.value === "") {
              setCurrentValue("");
            }
          });
        }
      };
      init();
    }
  }, []);

  useEffect(() => {
    if (invalid !== undefined) {
      setIsInvalid(invalid);
    }
  }, [invalid]);

  useEffect(() => {
    if (helperText) {
      setErrorMessage(helperText);

      if (helperText.length > 0) {
        setIsInvalid(true);
      }
    }
  }, [helperText]);

  useEffect(() => {
    if (currentValue !== undefined && currentValue !== value) {
      onChange({ field, value: currentValue, id });
      const valid = dateValidator(String(currentValue));
      if (valid) {
        setIsInvalid(false);
      } else {
        setIsInvalid(true);
        setErrorMessage("Неверный формат");
      }
    }
  }, [currentValue]);

  const changeValue = (event: { target: { name: string; value: string; id: string | number } }) => {
    let v = event.target.value.replace(/\D/g, "").slice(0, 8);
    if (v.length >= 5) {
      v = `${v.slice(0, 2)}.${v.slice(2, 4)}.${v.slice(4)}`;
    } else if (v.length >= 3) {
      v = `${v.slice(0, 2)}.${v.slice(2)}`;
    }

    const input = myInput.current?.querySelector("input");

    if (input) input.value = v;

    setCurrentValue(v);
  };

  const datePickerClasses = cn(
    styles.datePicker,
    className && styles[className],
    className && className,
    inputSize && styles[inputSize],
  );

  return (
    <div className={datePickerClasses} data-is-error={isInvalid}>
      {label ? <label className={cn(styles.label, (hasLabelMark || required) && styles.labelMarked)}>{label}</label> : null}
      <div className={cn(styles.inputWrap, "fadeIn", isInvalid && styles.error)} data-te-input-wrapper-init ref={myInput}>
        <input
          type="text"
          name={field}
          defaultValue={value}
          placeholder={String(placeholder)}
          required={required}
          disabled={disabled}
          className={styles.input}
          onChange={changeValue}
          // data-te-datepicker-toggle-ref
        />
      </div>
      <TransitionGroup component={null}>
        {isInvalid && (
          <CSSTransition key="errorText" timeout={250} classNames="fade">
            <span className={styles.errorText} title={errorMessage}>
              {errorMessage}
            </span>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default DatePicker;
