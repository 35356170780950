import { useEffect, useState } from "react";

import { Tombler, TimePicker } from "@/ui/index";

import styles from "./ScheduleForm.module.scss";
import FormWrapper from "../FormWrapper";

const ScheduleForm = ({
  scheduleArray,
  updateFields,
}: {
  scheduleArray: Schedule.ScheduleItem[];
  updateFields: (fields: Partial<CreateOfficeData>) => void;
}) => {
  const [scheduleData, setScheduleData] = useState<Schedule.ScheduleItem[]>(scheduleArray);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setScheduleData(scheduleArray);
  }, [scheduleArray]);

  useEffect(() => {
    if (isChanged) {
      updateFields({ schedule: scheduleData });
      setIsChanged(false);
    }
  }, [isChanged]);

  const changeHandle = (
    newValue: { field: string; value: boolean | string | number; id: number | string },
    itemId: number,
  ) => {
    setScheduleData((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? {
              ...item,
              available: newValue.field === "tombler" ? Boolean(newValue.value) : item.available,
              timeSlots: item.timeSlots.map((timeSlot) => {
                return `time_${timeSlot.field}${itemId}` === newValue.id
                  ? { ...timeSlot, value: newValue.value }
                  : timeSlot;
              }),
            }
          : item,
      ),
    );

    setIsChanged(true);

    // updateFields({ schedule: scheduleArray });
  };

  return (
    <FormWrapper title="Создать нотариальную контору" subtitle="График работы">
      <ul className={styles.list}>
        {scheduleData.map((item) => (
          <li className={styles.item} key={item.id}>
            <div className={styles.day}>{item.day}</div>
            <Tombler
              field="tombler"
              label={item.available ? "Работает" : "Выходной"}
              isChecked={item.available}
              id={item.id}
              onChange={(newVal) => changeHandle(newVal, item.id)}
            />
            {item.timeSlots.map((other) => (
              <TimePicker
                id={`time_${other.field}${item.id}`}
                key={`time_${other.field}${item.id}`}
                onChange={(newVal) => changeHandle(newVal, item.id)}
                disabled={!item.available}
                className={styles.scheduleItem}
                value={`${other.value}`}
                field={other.field}
              />
            ))}
          </li>
        ))}
      </ul>
    </FormWrapper>
  );
};

export default ScheduleForm;
