import type { NavigateFunction } from "react-router-dom";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";
import { RolesService } from "@/services/RolesService";

export const rolesApi = createApi({
  reducerPath: "rolesApi",
  tagTypes: ["Roles"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    fetchRoles: builder.query<Model.Role[] | Model.Error, void>({
      queryFn: async () => {
        try {
          const res = await RolesService.fetchRoles();
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Roles"],
    }),
    fetchRoleDetail: builder.query<Model.CurrentRole | Model.Error, number | string>({
      queryFn: async (id) => {
        try {
          const res = await RolesService.fetchRoleDetail(id);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Roles"],
    }),
    fetchPermissions: builder.query<Model.Permission[] | Model.Error, void>({
      queryFn: async () => {
        try {
          const res = await RolesService.fetchPermissions();

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Roles"],
    }),
    fetchNotifications: builder.query<Model.Permission[] | Model.Error, void>({
      queryFn: async () => {
        try {
          const res = await RolesService.fetchNotifications();
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Roles"],
    }),
    createRole: builder.mutation<
      Model.CurrentRole | Model.Error,
      {
        params: Reducer.UpdatePermissions;
        navigate: NavigateFunction;
      }
    >({
      queryFn: async ({ params, navigate }) => {
        try {
          const res = await RolesService.createRole(params);
          navigate(`/roles-and-access?role=${(res as Model.CurrentRole).id}`);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Roles"],
    }),
    updateRole: builder.mutation<
      Model.CurrentRole | Model.Error,
      {
        id: number | string;
        params: Reducer.UpdatePermissions;
      }
    >({
      queryFn: async ({ params, id }) => {
        try {
          const res = await RolesService.updateRole({ params, id });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Roles"],
    }),
    removeRole: builder.mutation<Model.Error, { id: number | string; navigate: NavigateFunction }>({
      queryFn: async ({ id, navigate }) => {
        try {
          const res = await RolesService.removeRole(id);
          navigate("/roles-and-access");
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Roles"],
    }),
    fetchNotAdminRoles: builder.query<Model.Role[] | Model.Error, number>({
      queryFn: async (officeId) => {
        try {
          const res = await RolesService.fetchNotAdminRoles(officeId);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Roles"],
    }),
  }),
});

export const {
  useFetchRolesQuery,
  useFetchRoleDetailQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useRemoveRoleMutation,
  useFetchNotAdminRolesQuery,
  useLazyFetchPermissionsQuery,
  useLazyFetchNotificationsQuery,
} = rolesApi;
