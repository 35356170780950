import { Newspaper, LogoIcon } from "@/assets/icons";
import styles from "@/blocks/EmptyResult/EmptyResult.module.scss";

const EmptyResult: Model.EmptyResult = ({ text = "Нет ни одной заявки" }) => {
  const { root, imgDiv, textDiv, textDivText } = styles;
  return (
    <div className={root}>
      <div className={imgDiv}>
        <Newspaper />
        <LogoIcon />
      </div>
      <div className={textDiv}>
        <p className={textDivText}>{text}</p>
      </div>
    </div>
  );
};

export default EmptyResult;
