import type { FC } from "react";

import cn from "classnames";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { DeleteIcon, EditIcon2 } from "@/assets/icons";
import { Info } from "@/blocks/index";
import { Container } from "@/layout/index";
import { useUserId } from "@/store/hooks";
import { useDeleteClientMutation, useUpdatePriorityMutation } from "@/store/services/client.query";
import { Avatar, BackLink, Button, IconButton, Modal, Select, Status } from "@/ui/index";

import styles from "./DetailHead.module.scss";
import { getPriority, priorityMockdata } from "../ClientCard/mockData";

export const DetailHead: FC<Blocks.DetailHead> = ({
  editLink,
  status,
  name,
  number,
  date,
  image,
  id,
  isDelete,
  backLink,
  priory,
  setIsEdit,
  permission,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { officeId, clientId } = useParams();
  const isUserId = useUserId();
  const [priorities, setPriorities] = useState<Option[]>(priorityMockdata);
  const [selectedOption, setSelectedOption] = useState<Option | null>({ ...getPriority[Number(priory)] });
  const [showModal, setShowModal] = useState(false);
  const [currentBackLink, setCurrentBackLink] = useState(backLink);

  const [updatePriority, updatedClient] = useUpdatePriorityMutation();
  const [deleteClient, deletedClient] = useDeleteClientMutation();

  useEffect(() => {
    if (priory) {
      setSelectedOption({ ...getPriority[Number(priory)] });
      setPriorities(priorityMockdata.filter((el) => el.value !== priory));
    }
  }, [priory]);

  useEffect(() => {
    deletedClient.isSuccess && navigate(currentBackLink);

    if (deletedClient.error) {
      const { message } = deletedClient.error as Model.Error;
      message &&
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 4000,
        });
      setShowModal(false);
    }
  }, [deletedClient]);

  useEffect(() => {
    if (state && state.prev) {
      setCurrentBackLink(state.prev);
    } else if (currentBackLink !== backLink) {
      setCurrentBackLink(backLink);
    }
  }, [state, backLink]);

  const handleSelectChange = (val: { field: string; value: string | number; id: number | string }) => {
    const foundOption = priorityMockdata.find((option) => option.value === val.value);
    foundOption && setSelectedOption(foundOption);

    updatePriority({
      officeId: Number(officeId),
      clientId: Number(clientId),
      priority: Number(val.value),
    });
  };

  const deleteClientHandle = () => {
    deleteClient({ officeId: Number(officeId), clientId: Number(clientId) });
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "inherit";
      body.style.paddingRight = "0";
    }
  };

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Container overflow={true}>
        <div className={styles.inner}>
          <BackLink to={currentBackLink} />
          <Avatar size="big" imageUrl={image} fullName={name} editable={true} />
          <div className={styles.content}>
            <div className={styles.name}>
              <h4 className={cn({ [styles.withStatus]: status })} title={name}>
                {name}
              </h4>
              {status && <Status type={status.type} name={status?.name} size="big" />}
            </div>
            <Info officeNumber={number} createdDate={date} isUser />
          </div>
          {priory ? (
            <Select
              id={id}
              value={priory}
              field="priority"
              options={priorities}
              defaultValue={selectedOption}
              onSelect={handleSelectChange}
              className={styles.select}
              withDots={true}
            />
          ) : null}
          <div className={styles.buttons}>
            {permission && (
              <>
                {(permission || isUserId === id) && (
                  <IconButton icon={<EditIcon2 />} href={editLink} onClick={() => setIsEdit && setIsEdit(true)} />
                )}
                {isDelete && <IconButton icon={<DeleteIcon />} onClick={() => setShowModal(true)} />}
              </>
            )}
          </div>
        </div>
      </Container>
      <Modal title="Вы действительно хотите удалить карточку клиента?" show={showModal} setShow={setShowModal}>
        <div className={styles.modal}>
          <div className={styles.description}>Это действие нельзя будет отменить</div>
          <div className={styles.buttons}>
            <Button variant="primary" onClick={() => setShowModal(false)}>
              Нет, не удалять
            </Button>
            <Button variant="secondary" loader={deletedClient.isLoading} onClick={deleteClientHandle}>
              Да, удалить
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailHead;
