import type { FormEvent } from "react";

import cn from "classnames";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowIcon, CirclePlusIcon } from "@/assets/icons";
import { ActionLock } from "@/blocks/index";
import { getValueFromArray, updatedInputsData } from "@/helpers/form";
import { Container } from "@/layout/index";
import {
  useEditActionMutation,
  useFetchActionQuery,
  useFetchActionTypesQuery,
  useFetchDocumentTypesQuery,
} from "@/store/services/action.query";
import { BackLink, Button, InputField, Loader, Modal, Select, Tombler } from "@/ui/index";

import styles from "./ActionEdit.module.scss";
import { actionData, blockValues } from "./mockData";

const checkEmptyDocuments = (blocks: Blocks.ActionBlock[]) => {
  const newBlocks: Blocks.ActionBlock[] = blocks.map((block) => {
    let isError = false;
    const modifiedDocs = block.documents.map((document) => {
      const modifiedBlock = document.block.map((el) => {
        let newItem = el;
        if (el.field === "documentType" && String(el.value).trim() === "") {
          isError = true;
          newItem = {
            ...el,
            invalid: true,
            helperText: "Выберите тип документа",
          };
        }
        return newItem;
      });
      return { ...document, block: modifiedBlock };
    });
    const newBlock = { ...block, isErrorBlock: isError };
    return { ...newBlock, documents: modifiedDocs };
  });

  const isError = newBlocks.some((block) => block.isErrorBlock);

  return { isError, newBlocks };
};

const ActionEdit = () => {
  const { actionId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const myRef = useRef<HTMLInputElement>(null);

  const [showModal, setShowModal] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [actionTypes, setActionTypes] = useState<Option[]>([]);
  const [documentTypes, setDocumentTypes] = useState<Option[]>([]);
  const [values, setValues] = useState<InputData[]>([]);
  const [actionBlocks, setActionBlocks] = useState<Blocks.ActionBlock[]>([]);
  const fetchAction = useFetchActionQuery(Number(actionId));
  const fetchActionTypes = useFetchActionTypesQuery(Number(actionId));
  const fetchDocumentTypes = useFetchDocumentTypesQuery();
  const [editAction, editedAction] = useEditActionMutation();

  const { isError, newBlocks } = checkEmptyDocuments(actionBlocks);

  useEffect(() => {
    if (fetchActionTypes.isSuccess && fetchActionTypes.data) {
      setActionTypes((fetchActionTypes.data as Option[]).map((option) => ({ name: option.name, value: option.value })));
    }
  }, [fetchActionTypes]);

  useEffect(() => {
    if (fetchDocumentTypes.isSuccess && fetchDocumentTypes.data) {
      const newTypes = [] as Option[];

      newTypes.push({ name: "Тип документа", value: "" });
      (fetchDocumentTypes.data as Option[]).forEach((option) => {
        newTypes.push({
          name: option.name,
          value: Number(option.id),
        });
      });

      setDocumentTypes(newTypes);
    }
  }, [fetchDocumentTypes]);

  useEffect(() => {
    if (fetchAction.isSuccess && fetchAction.data) {
      const { blocks, actionName, isActive, actionType } = fetchAction.data as Model.Action;

      setValues(updatedInputsData(actionData, { actionName, isActive, actionType }));

      const updatedActionBlocksData = blocks?.map((block) => {
        const newDocuments = block.documents.map((docBlock) => ({
          id: docBlock.id,
          block: updatedInputsData(blockValues, docBlock),
        }));

        return {
          id: block.id,
          blockName: block.blockName,
          documents: newDocuments,
        };
      });

      updatedActionBlocksData && setActionBlocks(updatedActionBlocksData);

      setTimeout(() => {
        setLoading(false);
      }, 250);
    }
  }, [fetchAction]);

  useEffect(() => {
    if (editedAction.isSuccess && editedAction.data) {
      enqueueSnackbar("Изменения сохранены!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [editedAction]);

  const changeHandle = (
    newVal: { field: string; value: boolean | string | number; id: number | string },
    blockIndex: number,
    docIndex: number,
    inputIndex: number,
    blockName: string,
  ) => {
    if (blockName === "actionTopBlock") {
      setValues(
        values.map((item) => {
          if (item.field === newVal.field) {
            return {
              ...item,
              value: newVal.field === "isActive" ? Number(newVal.value) : String(newVal.value),
            };
          }
          return item;
        }),
      );
    }
    if (blockName === "actionBlock") {
      const updatedActionBlocks = [...actionBlocks];
      updatedActionBlocks[blockIndex].documents[docIndex].block[inputIndex] = {
        ...updatedActionBlocks[blockIndex].documents[docIndex].block[inputIndex], // @ts-ignore
        value: newVal.value,
      };
      setActionBlocks(updatedActionBlocks);
    }
    setIsDirty(true);
  };

  const addDocumentHandle = (blockId: number, docId: number | null) => {
    const updatedActionBlocks = actionBlocks.map((block) => {
      if (block.id === blockId) {
        const updatedDocuments = [
          ...block.documents,
          {
            id: docId,
            block: blockValues.map((item) =>
              item.field === "documentType"
                ? { ...item, defaultOption: { name: "Тип документа", value: "" }, isNewInput: true }
                : { ...item, isNewInput: true },
            ),
          },
        ];
        return {
          ...block,
          documents: updatedDocuments,
        };
      }
      return block;
    });

    setActionBlocks(updatedActionBlocks);
    setIsDirty(true);
  };

  const saveData = () => {
    const desiredAction: Partial<Model.Action> = getValueFromArray(values);
    const desiredBlocks: Model.ActionBlock[] = actionBlocks.map((block) => {
      return {
        ...block,
        documents: block.documents.map((document) => {
          const newDocument = {} as Model.ActionDocument;
          document.block.forEach((input) => {
            if (input.field === "documentName") {
              newDocument.documentName = String(input.value);
            }
            if (input.field === "required") {
              newDocument.required = Boolean(input.value);
            }
            if (input.field === "documentType") {
              newDocument.documentType = Number(input.value) === 0 ? undefined : Number(input.value);
            }
            if (input.isNewInput === undefined || !input.isNewInput) {
              newDocument.id = document.id;
            }
          });
          return newDocument;
        }),
      };
    });

    editAction({
      data: {
        actionName: String(desiredAction.actionName),
        isActive: Boolean(desiredAction.isActive),
        actionType: String(desiredAction.actionType),
        blocks: desiredBlocks,
      },
      actionId: Number(actionId),
    });

    setTimeout(() => {
      setLoading(true);
    }, 100);
    setIsDirty(false);
    setShowModal(false);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setActionBlocks(newBlocks);
    isError == false && setIsSave(true);
    if (isError == false) {
      setShowModal(true);
    }
  };

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const backToOfficeLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowModal(true);
    if (!isDirty) {
      navigate("/notary-actions/");
    }
  };

  const onFormReset = () => {
    setShowModal(true);
    if (!isDirty) {
      navigate("/notary-actions/");
    }
  };

  const onReset = () => {
    setShowModal(false);
    navigate("/notary-actions/");
  };

  return (
    <Container className={`${styles.actionEdit} container`}>
      <Helmet>
        <title>Редактировать нотариальное действие</title>
      </Helmet>

      <div className={styles.actionEditInner}>
        <BackLink to="/notary-actions/" onClick={backToOfficeLink} />
        <div className={styles.formContent}>
          <h2>
            Редактировать нотариальное <br />
            действие
          </h2>
          {loading ? (
            <div className={styles.loaderWrap}>
              <Loader />
            </div>
          ) : (
            <form onSubmit={onSubmit} className={styles.form}>
              {values.map((input, inputIndex) => (
                <Fragment key={inputIndex}>
                  {input.type === "radio" ? (
                    <ActionLock
                      isChecked={Boolean(input.value)}
                      changeHandle={(value) => changeHandle(value, 0, 0, inputIndex, "actionTopBlock")}
                      field={input.field}
                      id={input.id}
                    />
                  ) : null}
                  {input.type === "text" ? (
                    <InputField
                      {...input}
                      onChange={(value) => changeHandle(value, 0, 0, inputIndex, "actionTopBlock")}
                      className={input.className && styles[input.className]}
                    />
                  ) : null}
                  {input.type === "select" ? (
                    <Select
                      onSelect={(value) => changeHandle(value, 0, 0, inputIndex, "actionTopBlock")}
                      defaultValue={actionTypes.find((item) => item.value === input.value) || actionTypes[0]}
                      size="big"
                      className={input.className && styles.select}
                      {...input}
                      value={input.value ? input.value : actionTypes[0].value}
                      options={actionTypes}
                      required={input.required}
                    />
                  ) : null}
                </Fragment>
              ))}
              <div id="accordionExample">
                {actionBlocks.length > 0 &&
                  actionBlocks.map((block, blockIndex) => {
                    return (
                      <div key={blockIndex} className={styles.accordion}>
                        <h6
                          className={cn(
                            styles.accordionHeader,
                            activeIndex === blockIndex ? styles.active : "",
                            block.isErrorBlock ? styles.errorBlock : "",
                          )}
                          onClick={() => handleClick(blockIndex)}>
                          {block.blockName} <ArrowIcon />
                        </h6>
                        <div
                          className={`${styles.accordionContent} ${
                            activeIndex === blockIndex ? styles.show : styles.hide
                          }`}>
                          {block.documents.map((document, docIndex) => (
                            <div key={docIndex} ref={myRef}>
                              <div className={styles.document}>
                                {document.block.map((el, inputIndex) => (
                                  <Fragment key={inputIndex}>
                                    {el.type === "select" && documentTypes?.length > 0 ? (
                                      <Select
                                        onSelect={(value) =>
                                          changeHandle(value, blockIndex, docIndex, inputIndex, "actionBlock")
                                        }
                                        defaultValue={
                                          documentTypes.find((item) => item.value === el.value) || documentTypes[0]
                                        }
                                        size="big"
                                        {...el}
                                        // value={el.value}
                                        className={styles.select}
                                        options={documentTypes}
                                      />
                                    ) : el.type === "radio" ? (
                                      <Tombler
                                        {...el}
                                        field={`${el.field}_${blockIndex}_${docIndex}_${inputIndex}`}
                                        isChecked={Boolean(el.value)}
                                        onChange={(value) =>
                                          changeHandle(value, blockIndex, docIndex, inputIndex, "actionBlock")
                                        }
                                        isLabelRight={true}
                                        className={styles.tombler}
                                      />
                                    ) : (
                                      <InputField
                                        {...el}
                                        onChange={(value) =>
                                          changeHandle(value, blockIndex, docIndex, inputIndex, "actionBlock")
                                        }
                                      />
                                    )}
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          ))}
                          <div>
                            <button
                              className={styles.addButton}
                              onClick={() =>
                                addDocumentHandle(
                                  block.id,
                                  block.documents.length > 0 ? block.documents[block.documents.length - 1]?.id : null,
                                )
                              }>
                              <CirclePlusIcon />
                              Добавить документы
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.buttons}>
                <Button type="button" variant="secondary" onClick={onFormReset}>
                  Отменить
                </Button>
                <Button type="submit" variant="primary" loader={editedAction.isLoading}>
                  Сохранить
                </Button>
              </div>
            </form>
          )}
        </div>
        <Modal
          title={isSave ? "Вы уверены, что хотите сохранить изменения?" : "Если выйти, то изменения не будут сохранены"}
          show={showModal}
          setShow={setShowModal}>
          <div className={styles.modal}>
            <div className={styles.buttons}>
              <Button variant="primary" onClick={() => (isSave ? saveData() : setShowModal(false))}>
                {isSave ? "Да, сохранить" : "Не выходить"}
              </Button>
              <Button variant="secondary" type="button" onClick={onReset}>
                {isSave ? "Нет, выйти" : "Да, выйти"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </Container>
  );
};

export default ActionEdit;
