import { Endpoints } from "@/api/endpoints";

import $api from "../api";

export const ActionService = {
  async fetchActions(): Promise<Model.Action[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.FETCH_ACTIONS}`);
    return res.data;
  },
  async fetchAction(actionId: number): Promise<Model.Action | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.FETCH_ACTIONS}${actionId}`);
    return res.data;
  },
  async editAction({
    actionId,
    data,
  }: {
    actionId: number;
    data: Model.Action;
  }): Promise<Model.Action | { errors: ErrorObject[] }> {
    const res = await $api.put(`${Endpoints.FETCH_ACTIONS}${actionId}/`, data);
    return res.data;
  },
  async fetchActionTypes(): Promise<Option[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.FETCH_ACTIONS}types/`);
    return res.data;
  },
  async fetchAppicationStatuses(id: number): Promise<Option[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${id}/applications/status/`);
    return res.data;
  },
  async fetchDocumentTypes(): Promise<Option[] | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.FETCH_ACTIONS}document/types/`);
    return res.data;
  },
};
