import cn from "classnames";
import { useSnackbar } from "notistack";
import { useEffect, type FC } from "react";
import { useParams } from "react-router-dom";

import { DeleteIcon, EditIcon, FileIcon } from "@/assets/icons";
import { ClientCardSimple } from "@/blocks/index";
import { useDeleteMemberMutation } from "@/store/services/client.query";
import { useDeleteDocumentMutation } from "@/store/services/document.query";
import { Confirm } from "@/ui/Confirm";
import { IconButton } from "@/ui/index";

import styles from "./Documents.module.scss";

const DocumentCard: FC<Model.Document> = ({
  name,
  issuedBy,
  registrationDate,
  registrationDepartment,
  registrationNumber,
  id,
  isMember,
  linkedPerson,
  editHandle,
  backLink,
  date,
  number,
  onRemove,
  target,
  inApp,
}) => {
  const { clientId } = useParams();
  const [deleteDocument, deletedDocument] = useDeleteDocumentMutation();
  const [deleteMember, deletedMember] = useDeleteMemberMutation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if ((deletedDocument.isSuccess || deletedMember.isSuccess) && onRemove) {
      onRemove();
    }
    if (deletedDocument.error || deletedMember.error) {
      const { message } = (isMember ? deletedMember.error : deletedDocument.error) as Model.Error;
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [deletedDocument, deletedMember]);

  const deleteHandle = (id: number) => {
    isMember
      ? deleteMember({ clientId: Number(clientId), memberId: id })
      : deleteDocument({ clientId: Number(clientId), documentId: id });

    setTimeout(() => {
      const body = document.querySelector("body[data-initial-overflow]");

      if (body) {
        body.removeAttribute("data-initial-overflow");
        body.removeAttribute("data-initial-padding");
        body.removeAttribute("style");
      }
    }, 350);
  };

  return (
    <div className={cn(styles.card, { [styles.app]: inApp })}>
      <div className={styles.cardInner}>
        {isMember ? null : (
          <div className={styles.fileIcon}>
            <FileIcon />
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.topInfo}>
            <span>№ {number}</span>
            {isMember ? null : <span>{date}</span>}
          </div>
          <h6 className={styles.cardTitle}>{name}</h6>
          <ul className={styles.contentList}>
            <li>
              <span>Кем принят/выдан</span> {issuedBy}
            </li>
            {registrationDate && (
              <li>
                <span>Дата регистрации</span> {registrationDate}
              </li>
            )}
            {registrationDepartment && (
              <li>
                <span>Орган регистрации</span> {registrationDepartment}
              </li>
            )}
            {registrationNumber && (
              <li>
                <span>Номер регистрации</span> {registrationNumber}
              </li>
            )}
          </ul>
        </div>
        {editHandle && (
          <IconButton icon={inApp ? <DeleteIcon /> : <EditIcon />} onClick={() => editHandle(String(inApp ? name : id))} />
        )}
        {!inApp && onRemove && (
          <Confirm
            title={`Вы действительно хотите удалить ${isMember ? "представителя" : "документ"}?`}
            description="Это действие нельзя будет отменить"
            buttonNames={["Нет, не удалять", "Да, удалить"]}
            onConfirm={() => deleteHandle(Number(id))}>
            <IconButton icon={<DeleteIcon />} />
          </Confirm>
        )}
      </div>
      {isMember && linkedPerson && (
        <ClientCardSimple
          className={styles.linkedPersonCard}
          name={linkedPerson.name}
          phoneNumber={linkedPerson.phoneNumber}
          email={linkedPerson.email}
          id={linkedPerson.id}
          routerState={{ prev: backLink }}
          target={target}
        />
      )}
    </div>
  );
};

export default DocumentCard;
