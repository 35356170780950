import type { FormEvent } from "react";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import { ApplicationStatus, ErrorResult } from "@/blocks/index";
import { useAppSelector, useActions } from "@/store/hooks";
import {
  useFetchRoleDetailQuery,
  useLazyFetchNotificationsQuery,
  useLazyFetchPermissionsQuery,
} from "@/store/services/roles.query";
import { Button, Checkbox, InputField, Loader, Modal, Tombler } from "@/ui/index";

import styles from "./RolePermissions.module.scss";

const getData = (arr: Model.Permission[]) => {
  const checkededs: number[] = [];
  const hoursArr: { id: number; hour: number }[] = [];
  arr.map(({ checked, id, subSettings, hours }) => {
    if (subSettings && subSettings.length > 0) {
      const newSub = subSettings.filter((item) => item.checked);
      newSub.map((item) => checkededs.push(item.id));
    }
    if (hours) {
      hoursArr.push({ id, hour: hours });
    }

    return checked ? checkededs.push(id) : null;
  });

  return { checkededs, hours: hoursArr };
};

export const RolePermissions = () => {
  const { roles, currentRole, createdRole, loading, error, showSuccess } = useAppSelector((state) => state.roles);
  const [params] = useSearchParams();
  const paramRole = params.get("role");
  const [data, setData] = useState<Model.CurrentRole | null | undefined>(currentRole);
  const [roleId, setRoleId] = useState<string | null | number>(paramRole ? paramRole : roles ? roles[0].id : null);
  const [mainLoader, setMainLoader] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    updateRole,
    setCreatedRole,
    setCurrentRole,
    createRole,
    setRoles,
    removeRole,
    setShowSuccess,
    setLoading,
    setPermissions,
    setNotifications,
  } = useActions();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [fetchPermissions, fetchPermissionsState] = useLazyFetchPermissionsQuery();
  const [fetchNotifications, fetchNotificationsState] = useLazyFetchNotificationsQuery();

  const fetchRolePermissions = useFetchRoleDetailQuery(roleId && roleId !== "create" ? roleId : roles ? roles[0].id : "", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (fetchRolePermissions.isSuccess && fetchRolePermissions.data) {
      const fetch = fetchRolePermissions.data as Model.CurrentRole;

      setCurrentRole(fetch);
    }
  }, [fetchRolePermissions]);

  useEffect(() => {
    // eslint-disable-next-line
    if (roleId != paramRole) {
      setShowError(false);
      const newRoleId = paramRole ? paramRole : roles ? roles[0].id : "";
      setRoleId(newRoleId);
    }
  }, [paramRole]);

  useEffect(() => {
    if (roleId === "create") {
      setLoading({ ...loading, add: true });
      fetchPermissions();
      fetchNotifications();
    }
  }, [roleId]);

  useEffect(() => {
    if (fetchPermissionsState.isSuccess && fetchPermissionsState.data) {
      const newRoles = roles ? roles : [];
      const role = { id: "create", name: "Новая роль" };
      const newPermissions = fetchPermissionsState.data as Model.Permission[];
      setPermissions(newPermissions);
      if (roles?.filter((item) => item.id === "create").length === 0) {
        setRoles([...newRoles, role]);
      }
      setCreatedRole({ ...role, permissions: newPermissions, notifications: createdRole?.notifications || [] });
      setLoading({ ...loading, add: fetchNotificationsState.isFetching });
    }
  }, [fetchPermissionsState]);

  useEffect(() => {
    if (fetchNotificationsState.isSuccess && fetchNotificationsState.data) {
      const newRoles = roles ? roles : [];
      const role = { id: "create", name: "Новая роль" };
      const newPermissions = fetchNotificationsState.data as Model.Permission[];
      setNotifications(newPermissions);
      if (roles?.filter((item) => item.id === "create").length === 0) {
        setRoles([...newRoles, role]);
      }
      setCreatedRole({ ...role, permissions: createdRole?.permissions || [], notifications: newPermissions || [] });
      setLoading({ ...loading, add: fetchPermissionsState.isFetching });
    }
  }, [fetchNotificationsState]);

  useEffect(() => {
    if (error) {
      if (error.status === 404 || error.status === 500) {
        setShowError(true);
      } else {
        enqueueSnackbar(error?.message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } else {
      setShowError(false);
    }
  }, [error]);

  useEffect(() => {
    if (mainLoader) {
      setTimeout(() => {
        setData(roleId === "create" ? createdRole : currentRole);
      }, 250);
    } else {
      setData(roleId === "create" ? createdRole : currentRole);
    }
    if (showError && roleId === "create" && createdRole) {
      setShowError(false);
    }
  }, [currentRole, createdRole, roleId, mainLoader]);

  useEffect(() => {
    if (mainLoader && !loading.main) {
      setTimeout(() => {
        setMainLoader(loading.main);
      }, 250);
    } else {
      setMainLoader(loading.main);
    }
  }, [loading.main]);

  useEffect(() => {
    if (showSuccess) {
      enqueueSnackbar("Данные успешно обновлены!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setShowSuccess(false);
    }
  }, [showSuccess]);

  const saveData = (event: FormEvent) => {
    event.preventDefault();

    if (data) {
      const { id, name, permissions, notifications } = data;
      const permissionsData = getData(permissions);
      const notificationsData = getData(notifications);

      const params = {
        name,
        permissions: permissionsData.checkededs,
        notifications: notificationsData.checkededs,
        hours: [...notificationsData.hours, ...permissionsData.hours],
      };

      if (roleId === "create") {
        createRole({ params, navigate });
      } else {
        updateRole({ id, params });
        setCurrentRole(data);
      }
    }
  };

  const removeData = () => {
    if (roleId) {
      if (roleId === "create") {
        navigate("/roles-and-access");
        const newRoles = roles ? roles : [];
        setRoles(newRoles.filter(({ id }) => id !== roleId));
        setCreatedRole(null);
      } else {
        removeRole({ id: roleId, navigate });
        setShowModal(false);
      }
    }
  };

  const changeData: (
    value: {
      field: string;
      value: string | number | string[] | boolean;
      id: number | string;
      code?: string;
    },
    section?: string,
  ) => void = ({ field, value, code }, section) => {
    if (data) {
      if (field === "name") {
        setData({ ...data, name: String(value) });
      } else {
        const settings: Model.Permission[] = [];

        // @ts-ignore
        data[section].map(({ codename, checked, subSettings, ...other }) => {
          let item;
          if (code && field !== "hours" && codename === code && subSettings.length > 0) {
            item = {
              codename,
              checked, // @ts-ignore
              subSettings: subSettings.map((item) =>
                item.codename === field ? { ...item, checked: Boolean(value) } : item,
              ),
              ...other,
            };
          } else if (field === "hours" && codename === code) {
            item = { ...other, codename, checked: checked || false, subSettings, hours: value };
          } else {
            item =
              field === codename
                ? {
                    codename,
                    checked: Boolean(value),
                    subSettings:
                      value === false && subSettings // @ts-ignore
                        ? subSettings.map((item) => ({ ...item, checked: false }))
                        : subSettings,
                    ...other,
                  }
                : { codename, checked: checked || false, subSettings, ...other };
          }
          // @ts-ignore
          return settings.push(item);
        });

        // @ts-ignore
        setData({ ...data, [section]: settings });
      }
    }
  };

  return (
    <SwitchTransition>
      <CSSTransition key={fetchRolePermissions.isFetching ? "loader" : "content"} timeout={250} classNames="fade">
        {fetchRolePermissions.isFetching || fetchPermissionsState.isFetching || fetchNotificationsState.isFetching ? (
          <Loader />
        ) : (
          <form className={styles.form} onSubmit={saveData}>
            <SwitchTransition>
              <CSSTransition key={showError ? "error" : "result"} timeout={250} classNames="fade">
                {showError ? (
                  <div className={styles.error}>
                    <ErrorResult
                      title={
                        error?.status === 404
                          ? "Данная роль не найдена, либо не существует"
                          : "При выполнении запроса что-то пошло не так..."
                      }
                      url="/roles-and-access"
                      button="Перейти к списку ролей"
                    />
                  </div>
                ) : (
                  <>
                    {data && (
                      <>
                        <div className={styles.content}>
                          <div className={styles.permission}>
                            <div className={styles.title}>Наименование</div>
                            <div className={styles.block}>
                              <InputField
                                id={Number(data.id)}
                                field="name"
                                value={data.name}
                                onChange={changeData}
                                required
                                className={styles.input}
                              />
                            </div>
                          </div>
                          <div className={styles.permission}>
                            <div className={styles.title}>Доступы</div>
                            <div className={styles.block}>
                              {data?.permissions &&
                                data.permissions.map(({ id, checked, codename, name }) => (
                                  <div key={id}>
                                    <Checkbox
                                      id={id}
                                      field={codename}
                                      isChecked={checked || false}
                                      label={name}
                                      onChange={(value) => changeData(value, "permissions")}
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className={styles.permission}>
                            <div className={styles.title}>Уведомления</div>
                            <div className={styles.block}>
                              {data?.notifications &&
                                data.notifications.map(({ id, checked, codename, name, subSettings, byTime, hours }) => (
                                  <div key={id}>
                                    <Checkbox
                                      id={id}
                                      field={codename}
                                      isChecked={checked || false}
                                      label={name}
                                      onChange={(value) => changeData(value, "notifications")}
                                    />
                                    <TransitionGroup component={null}>
                                      {byTime && checked && (
                                        <CSSTransition key={`${codename}byTyme`} classNames="fade" timeout={250}>
                                          <div className={styles.wrapper}>
                                            <div className={styles.placeholder}>
                                              Через сколько часов присылать уведомление
                                            </div>
                                            <InputField
                                              id={id}
                                              field="hours"
                                              value={hours || "0"}
                                              onChange={(value) =>
                                                changeData({ ...value, code: codename }, "notifications")
                                              }
                                              required
                                              className={styles.hours}
                                            />
                                          </div>
                                        </CSSTransition>
                                      )}
                                      {subSettings && checked && subSettings.length > 0 && (
                                        <CSSTransition key={codename + id} classNames="fade" timeout={250}>
                                          <div className={styles.wrapper}>
                                            <div className={styles.placeholder}>
                                              В каких статусах будет приходить уведомление
                                            </div>
                                            <div className={styles.subSettings}>
                                              {subSettings.map((item) => (
                                                <div key={item.id} className={styles.subSettingsItem}>
                                                  <ApplicationStatus status={{ id: item.statusId, name: item.name }} />
                                                  <Tombler
                                                    id={item.id}
                                                    field={item.codename}
                                                    isChecked={item.checked || false}
                                                    onChange={(value) =>
                                                      changeData({ ...value, code: codename }, "notifications")
                                                    }
                                                  />
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </CSSTransition>
                                      )}
                                    </TransitionGroup>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <Modal title="Вы действительно хотите удалить роль?" show={showModal} setShow={setShowModal}>
                          <div className={styles.modal}>
                            <div className={styles.description}>Это действие нельзя будет отменить</div>
                            <div className={styles.buttons}>
                              <Button variant="primary" onClick={() => setShowModal(false)}>
                                Нет, не удалять
                              </Button>
                              <Button variant="secondary" loader={loading.remove} onClick={removeData}>
                                Да, удалить
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <div className={styles.action}>
                          <Button
                            variant="secondaryRed"
                            onClick={roleId === "create" ? removeData : () => setShowModal(true)}>
                            {roleId === "create" ? "Отмена" : "Удалить роль"}
                          </Button>
                          <div className={styles.actionBasic}>
                            {roleId !== "create" && (
                              <Button variant="secondary" onClick={() => setData(currentRole)}>
                                Сбросить изменения
                              </Button>
                            )}
                            <Button type="submit" variant="primary" loader={loading.save}>
                              {roleId === "create" ? "Добавить" : "Сохранить изменения"}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </CSSTransition>
            </SwitchTransition>
          </form>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default RolePermissions;
