import type { FC, FormEvent } from "react";

import cn from "classnames";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useDeleteScanMutation,
  useLoadDocumentsFromCardMutation,
  useUploadDocumentsMutation,
} from "@/store/services/application.query";
import { Button, UploadFromCard, Uploader, UploaderFiles, Textarea, DocumentsGroupName } from "@/ui/index";

import styles from "./LoadDocuments.module.scss";

const LoadDocumentsGroup: FC<UI.LoadDocumentsGroup> = ({ memberId, isEdit, document, index }) => {
  const { officeId, applicationId } = useParams();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [loadDocumentsFromCard, loadedDocumentsFromCard] = useLoadDocumentsFromCardMutation();
  const [uploadDocuments, uploadedDocuments] = useUploadDocumentsMutation();
  const [deleteScan, deletedScan] = useDeleteScanMutation();

  useEffect(() => {
    if (deletedScan.isSuccess && deletedScan.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
        description: "Фото профиля успешно удалено!",
      });
    }
  }, [deletedScan]);

  useEffect(() => {
    if (uploadedDocuments.isSuccess && uploadedDocuments.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }

    if (uploadedDocuments.isError && (uploadedDocuments.error as ErrorObject[])) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((uploadedDocuments.error as Model.Error)?.message) {
        error = (uploadedDocuments.error as Model.Error).message;
      } else if ((uploadedDocuments.error as ErrorSimpleResponse)?.errors) {
        error = (uploadedDocuments.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (uploadedDocuments.error as ErrorObject[]) {
        error = (uploadedDocuments.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [uploadedDocuments]);

  useEffect(() => {
    if (loadedDocumentsFromCard.isSuccess && loadedDocumentsFromCard.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }

    if (loadedDocumentsFromCard.isError && loadedDocumentsFromCard.error) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((loadedDocumentsFromCard.error as Model.Error)?.message) {
        error = (loadedDocumentsFromCard.error as Model.Error).message;
      } else if ((loadedDocumentsFromCard.error as ErrorSimpleResponse)?.errors) {
        error = (loadedDocumentsFromCard.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (loadedDocumentsFromCard.error as ErrorObject[]) {
        error = (loadedDocumentsFromCard.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [loadedDocumentsFromCard]);

  const addDocumentsFromCard = (documentId: number, documentType: number) => {
    loadDocumentsFromCard({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      memberId,
      documentId,
      documentType,
    });
  };

  const handleClick = (docIndex: number) => {
    setActiveIndex(activeIndex === docIndex ? null : docIndex);
  };

  const handleFileChange = (event: FormEvent<HTMLFormElement> | null, documentId: string, data?: File[]) => {
    if (event) {
      event.preventDefault();
    }
    const formData = new FormData();
    if (data) {
      data.forEach((file) => {
        formData.append("scanners", file);
      });
    } else if (event) {
      const elem = event.target; // @ts-ignore
      const value = elem.querySelector("textarea[name='description']")?.value || "";
      formData.append("description", value);
    }
    formData.append("document", documentId);

    uploadDocuments({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      memberId,
      data: formData,
      documentId,
    });
  };

  const deleteScanHandle = (scanId: number) => {
    deleteScan({ officeId: Number(officeId), applicationId: Number(applicationId), memberId, scanId });
  };

  return (
    <div className={styles.accordion} key={document.id}>
      <div className={styles.accordionbutton}>
        <DocumentsGroupName
          name={document.name}
          required={document.required}
          length={document.scans.length}
          onClick={() => handleClick(index)}
          showArrow={activeIndex === index}
        />
      </div>
      {!isEdit && document.withDescription && document.description && (
        <div className={styles.description}>
          <div className={styles.descriptionTitle}>Описание</div>
          {document.description.trim().length > 0 ? (
            <div>{document.description}</div>
          ) : (
            <div className={styles.empty}>Описание отсутствует</div>
          )}
        </div>
      )}
      <div
        className={cn(
          styles.accordionContent,
          activeIndex === index || document.scans.length === 0 ? styles.show : styles.hide,
        )}>
        <div className={styles.uploadFiles}>
          <UploaderFiles files={document.scans} deleteHandle={isEdit ? deleteScanHandle : undefined} />
        </div>
        {isEdit && (
          <div className={cn(styles.uploadButtons, { [styles.withDesc]: document.withDescription })}>
            <Uploader
              multiple={!document.withDescription}
              size="lg"
              onChange={(data) => handleFileChange(null, String(document.id), data)}
              isSuccess={uploadedDocuments.isSuccess}
              isError={uploadedDocuments.isError}
              isLoading={uploadedDocuments.isLoading && uploadedDocuments.originalArgs?.documentId === String(document.id)}
              accept=".pdf, .doc, .docx, image/*"
              text={document.withDescription ? "Загрузить документ" : undefined}
            />
            {document.withDescription ? (
              <form onSubmit={(e) => handleFileChange(e, String(document.id))} className={styles.form} autoComplete="off">
                <div className={styles.text}>или</div>
                <Textarea
                  id={1}
                  value={document?.description || ""}
                  field="description"
                  placeholder="Описание"
                  required
                  className={styles.input}
                />
                <Button type="submit" variant="primary">
                  Сохранить
                </Button>
              </form>
            ) : (
              <UploadFromCard
                size="lg"
                onClick={() => addDocumentsFromCard(document.id, document.documentType)}
                isSuccess={false}
                isLoading={false}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadDocumentsGroup;
