export const actionData: InputData[] = [
  {
    id: 1,
    field: "isActive",
    type: "radio",
    value: 0,
  },
  {
    id: 2,
    field: "actionName",
    type: "text",
    label: "Наименование",
    placeholder: "Наименование",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
  },
  {
    id: 3,
    field: "actionType",
    type: "select",
    label: "Критерий сложности",
    placeholder: "Критерий сложности",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    defaultOption: {} as Option,
  },
];

export const blockValues = [
  {
    id: 1,
    field: "documentName",
    type: "text",
    placeholder: "Наименование",
    required: true,
    value: "",
    className: "col12",
  },
  {
    id: 2,
    field: "documentType",
    type: "select",
    placeholder: "Наименование",
    required: true,
    value: "",
    className: "col12",
    defaultOption: {} as Option,
  },
  {
    id: 3,
    field: "required",
    type: "radio",
    label: "Обязательные документы",
    value: 0,
    className: "col12",
  },
];
