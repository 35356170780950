import { useNavigate } from "react-router-dom";

import { EditIcon2, LockSmallIcon } from "@/assets/icons";
import { ActionIcon } from "@/blocks/index";

import styles from "./ActionCard.module.scss";

const ActionCard = ({
  actionName,
  isActive,
  id,
}: {
  actionName: string;
  icon?: string;
  isActive: boolean;
  id?: number;
}) => {
  const navigate = useNavigate();

  const editHandle = () => {
    navigate(`/notary-actions/edit/${id}`);
  };
  return (
    <div className={styles.card}>
      {isActive ? null : (
        <span className={styles.lock}>
          <LockSmallIcon />
        </span>
      )}
      <ActionIcon id={Number(id)} />
      <div className={styles.title}>{actionName}</div>
      <button className={styles.editButton} onClick={editHandle}>
        <EditIcon2 />
      </button>
    </div>
  );
};

export default ActionCard;
