import { phoneMask } from "@/shared/constants";

export const radioButtons = [
  {
    id: 1,
    value: "",
    field: "personPosition",
    type: "radio",
    options: [
      { name: "Юр. лицо", value: 1, id: "legalPerson" },
      { name: "ИП", value: 0, id: "soleProprietorship" },
      { name: "Физ. лицо", value: 0, id: "individualPerson" },
    ],
  },
];
export const individualPersonArray: InputData[] = [
  {
    id: 1,
    field: "name",
    type: "text",
    label: "ФИО клиента",
    placeholder: "Иванов Иван Иванович",
    required: true,
    value: "",
    className: "col12",
    pattern: "letters",
  },
  {
    id: 7,
    field: "inn",
    label: "ИНН",
    placeholder: "123456789123",
    required: true,
    value: "",
    className: "col6",
    minLength: 12,
    maxLength: 12,
    mask: "999999999999",
  },
  {
    id: 6,
    field: "snils",
    label: "СНИЛС",
    placeholder: "123-456-789 00",
    required: true,
    value: "",
    className: "col6",
    minLength: 11,
    mask: "999-999-999 99",
  },
  {
    id: 2,
    field: "placeOfBirth",
    type: "text",
    label: "Место рождения",
    placeholder: "Тверская область, город Раменское, наб. Косиора, 32",
    required: true,
    value: "",
    className: "col8",
    pattern: "russianN",
  },
  {
    id: 3,
    field: "dateOfBirth",
    type: "date",
    label: "Дата рождения",
    placeholder: "12.08.2022",
    required: true,
    value: "",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
    className: "col4",
  },
  {
    id: 4,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон",
    placeholder: phoneMask,
    required: true,
    value: "",
    className: "col6",
    mask: phoneMask,
  },
  {
    id: 5,
    field: "email",
    type: "email",
    label: "Электронная почта",
    placeholder: "usermailname@mail.ru",
    required: true,
    value: "",
    className: "col6",
  },
  {
    id: 8,
    field: "passportSeries",
    label: "Серия",
    placeholder: "1234",
    required: true,
    value: "",
    className: "col4",
    minLength: 4,
    maxLength: 4,
    mask: "9999",
  },
  {
    id: 9,
    field: "passportNumber",
    type: "number",
    label: "Номер",
    placeholder: "123456",
    required: true,
    value: "",
    className: "col4",
    minLength: 6,
    maxLength: 6,
    mask: "999999",
  },
  {
    id: 10,
    field: "passportCode",
    type: "text",
    label: "Код подразделения",
    placeholder: "123-456",
    required: true,
    value: "",
    className: "col4",
    minLength: 6,
    maxLength: 7,
    mask: "999-999",
    pattern: "passportCode",
  },
  {
    id: 11,
    field: "passportIssuedBy",
    type: "text",
    label: "Кем выдан паспорт",
    placeholder: "Отделением УФМС России по Алтайскому краю в г. Барнауле",
    required: true,
    value: "",
    className: "col12",
    pattern: "russianN",
  },
  {
    id: 12,
    field: "address",
    label: "Адрес регистрации",
    placeholder: "Тверская область, город Раменское, наб. Косиора, 32",
    required: true,
    value: "",
    className: "col8",
    type: "text",
    pattern: "russianN",
  },
  {
    id: 13,
    field: "passportIssueDate",
    type: "date",
    label: "Дата выдачи паспорта",
    placeholder: "01.01.2022",
    required: true,
    value: "",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
    className: "col4",
  },
];
export const soleProprietorshipArray: InputData[] = [
  {
    id: 1,
    field: "name",
    type: "text",
    label: "Наименование",
    placeholder: "Индивидуальный предприниматель Иванов Иван Иванович",
    required: true,
    value: "",
    className: "col12",
    pattern: "russian",
  },
  {
    id: 4,
    field: "inn",
    label: "ИНН",
    placeholder: "12345678912",
    required: true,
    value: "",
    className: "col6",
    minLength: 12,
    maxLength: 12,
    mask: "999999999999",
    type: "dadata",
    suggest: "party",
    personType: "INDIVIDUAL",
  },
  {
    id: 5,
    field: "ogrn",
    type: "text",
    label: "ОГРНИП",
    placeholder: "316861700133226",
    required: true,
    value: "",
    className: "col6",
    minLength: 15,
    maxLength: 15,
    mask: "999999999999999",
  },
  {
    id: 2,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон",
    placeholder: phoneMask,
    required: true,
    value: "",
    className: "col6",
    hasLabelMark: true,
    mask: phoneMask,
  },
  {
    id: 3,
    field: "email",
    type: "email",
    label: "Электронная почта",
    placeholder: "usermailname@mail.ru",
    required: true,
    value: "",
    className: "col6",
  },
  {
    id: 6,
    field: "registrationDepartment",
    type: "text",
    label: "Орган регистрации",
    placeholder: "Орган регистрации",
    required: true,
    value: "",
    className: "col12",
    pattern: "rrussianN",
  },
  {
    id: 7,
    field: "linkedPerson",
    type: "search",
    label: "Физическое лицо",
    placeholder: "Иванов Иван Иванович",
    required: true,
    value: "",
    className: "col12",
    pattern: "russian",
    ageLimit: 14,
  },
  {
    id: 8,
    field: "registrationDate",
    type: "date",
    label: "Дата регистрации ИП",
    placeholder: "01.01.2020",
    required: true,
    value: "",
    className: "col6",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
  },
];
export const legalPersonArray: InputData[] = [
  {
    id: 1,
    field: "name",
    type: "text",
    label: "Наименование",
    placeholder: "Наименование компании",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    pattern: "russianN",
  },
  {
    id: 9,
    field: "inn",
    type: "dadata",
    suggest: "party",
    personType: "LEGAL",
    label: "ИНН",
    placeholder: "1234567891",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
    minLength: 10,
    maxLength: 10,
    mask: "9999999999",
  },
  {
    id: 7,
    field: "ogrn",
    type: "dadata",
    suggest: "party",
    personType: "LEGAL",
    label: "ОГРН",
    placeholder: "1234567891234",
    required: true,
    value: "",
    hasLabelMark: true,
    minLength: 13,
    maxLength: 13,
    mask: "9999999999999",
    className: "col4",
  },
  {
    id: 10,
    field: "kpp",
    type: "dadata",
    suggest: "party",
    personType: "LEGAL",
    label: "КПП",
    placeholder: "123456789",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
    minLength: 9,
    maxLength: 9,
    mask: "999999999",
  },
  {
    id: 2,
    field: "legalForm",
    type: "selectsearch",
    label: "Организационно-правовая форма",
    placeholder: "Тип ОПФ",
    required: true,
    value: "1",
    hasLabelMark: true,
    className: "col12",
    pattern: "russian",
  },
  {
    id: 3,
    field: "address",
    type: "text",
    label: "Адрес",
    placeholder: "Тверская область, город Раменское, наб. Косиора, 32",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    pattern: "russianN",
  },
  {
    id: 4,
    field: "addressFias",
    type: "text",
    label: "Адрес ФИАС",
    placeholder: "Тверская область, город Раменское, наб. Косиора, 32",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    pattern: "russianN",
  },
  {
    id: 5,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон организации",
    placeholder: phoneMask,
    required: true,
    value: "",
    hasLabelMark: true,
    mask: phoneMask,
    className: "col6",
  },
  {
    id: 6,
    field: "email",
    type: "email",
    label: "Электронная почта организации",
    placeholder: "mycompanyname@mail.ru",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col6",
  },
];

export const birthCertificationArray: InputData[] = [
  {
    id: 8,
    field: "birthCertificateNumber",
    label: "Номер документа",
    placeholder: "123456",
    required: true,
    value: "",
    className: "col4",
    minLength: 6,
    maxLength: 30,
  },
  {
    id: 9,
    field: "birthRegistrationNumber",
    label: "Номер актовой записи",
    placeholder: "8451",
    required: true,
    value: "",
    className: "col4",
    minLength: 4,
    maxLength: 30,
    mask: "999999999999999999999999999999",
  },
  {
    id: 13,
    field: "birthRegistrationDate",
    type: "date",
    label: "Дата выдачи",
    placeholder: "01.01.2022",
    required: true,
    value: "",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
    className: "col4",
  },
  {
    id: 12,
    field: "birthIssuedBy",
    label: "Орган регистрации",
    placeholder: "Московский городской ЗАГС, Управления ЗАГС г. Москва",
    required: true,
    value: "",
    className: "col8",
    type: "text",
    pattern: "russianN",
  },
  {
    id: 13,
    field: "birthIssueDate",
    type: "date",
    label: "Дата актовой записи",
    placeholder: "01.01.2022",
    required: true,
    value: "",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
    className: "col4",
  },
];
