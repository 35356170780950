import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";

import { DeadlineIcon } from "@/assets/icons";
import { downloadRequest } from "@/helpers/downloadRequest";
import { Container } from "@/layout/Container";
import { useGetDocumentsDeadlineQuery, useChangeDocumentsDeadlineMutation } from "@/store/services/application.query";
import { Button, Datepicker } from "@/ui/index";

import styles from "./DeadlineForDocuments.module.scss";
import ClientBlick from "../ClientBlick/ClientBlick";

export const DeadlineForDocuments = ({
  officeId,
  applicationId,
  isEdit,
}: {
  officeId: string;
  applicationId: string;
  isEdit?: boolean;
}) => {
  const [input, setInput] = useState<InputData>({
    type: "date",
    value: "",
    field: "deadline",
    id: 1,
    dateProps: { disablePast: true, limitPastMonths: 1495 },
    placeholder: "01.01.2020",
    required: true,
  });
  const fetchDeadline = useGetDocumentsDeadlineQuery({
    officeId: Number(officeId),
    applicationId: Number(applicationId),
  });
  const [changeDocumnetsDeadline, changedDocumnetsDeadline] = useChangeDocumentsDeadlineMutation();

  useEffect(() => {
    if (fetchDeadline.isSuccess && fetchDeadline.data) {
      const data = fetchDeadline.data as Response.DocumentsDeadline;
      setInput({ ...input, value: data.submissionDeadline });
    }
  }, [fetchDeadline]);

  useEffect(() => {
    if (changedDocumnetsDeadline.isSuccess && changedDocumnetsDeadline.data) {
      enqueueSnackbar("Срок подачи документов успешно изменен!", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }

    if (changedDocumnetsDeadline.isError && changedDocumnetsDeadline.error) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((changedDocumnetsDeadline.error as Model.Error)?.message) {
        error = (changedDocumnetsDeadline.error as Model.Error).message;
      } else if ((changedDocumnetsDeadline.error as ErrorSimpleResponse)?.errors) {
        error = (changedDocumnetsDeadline.error as ErrorSimpleResponse)?.errors[0]?.detail;
      } else if (changedDocumnetsDeadline.error as ErrorObject[]) {
        error = (changedDocumnetsDeadline.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [changedDocumnetsDeadline]);

  const changeHandle = (newVal: {
    field: string;
    value: string | number;
    id: string | number;
    suggestion?: { [name: string]: any };
  }) => {
    setInput({ ...input, value: newVal.value });
  };

  const submitData = () => {
    const data = { submissionDeadline: String(input.value) };

    changeDocumnetsDeadline({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      data,
    });
  };

  return (
    <Container>
      <ClientBlick
        text="Крайний срок подачи документов"
        icon={<DeadlineIcon />}
        element={
          <Datepicker {...input} value={input.value} disabled={!isEdit} className={styles.input} onChange={changeHandle} />
        }
      />
      {isEdit && (
        <div className={styles.wrapper}>
          <Button
            type="submit"
            variant="primary"
            disabled={changedDocumnetsDeadline.isLoading}
            loader={changedDocumnetsDeadline.isLoading}
            onClick={submitData}>
            Сохранить
          </Button>
        </div>
      )}
    </Container>
  );
};
