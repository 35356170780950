import { Fragment } from "react";

import InputsSpreader from "@/blocks/InputsSpreader/InputsSpreader";

import styles from "./InputsList.module.scss";

const InputList: Blocks.InputList = ({
  legalForms,
  selectedLegalForm,
  values,
  changeHandle,
  titleBeforeField,
  title,
  clientOptions,
  selectedClient,
  formId,
  setSelectedClientByPhone,
  setSelectedClientByEmail,
  setSelectedClientByName,
  personPosition,
  setSearchValue,
  changeSearchSelect,
  member,
  isEdit,
  withLink,
  setLinkedPerson,
  onSelectClose,
  onScroll,
}) => (
  <div className={styles.formList}>
    {values.map(({ ...other }, idx: number) => {
      if (other.type === "select") {
        console.log("other.type", other.listSize);
      }
      return (
        <Fragment key={idx}>
          {title && titleBeforeField && titleBeforeField === other.field && <h6>{title}</h6>}
          <InputsSpreader
            onChange={changeHandle}
            setSelectedClientByPhone={setSelectedClientByPhone}
            setSelectedClientByEmail={setSelectedClientByEmail}
            setSelectedClientByName={setSelectedClientByName}
            setLinkedPerson={setLinkedPerson}
            personPosition={personPosition}
            formId={formId}
            legalForms={legalForms}
            selectedLegalForm={selectedLegalForm}
            clientOptions={clientOptions}
            selectedClient={selectedClient}
            className={other.className && styles[other.className]}
            setSearchValue={setSearchValue}
            member={member}
            isEdit={isEdit}
            withLink={withLink}
            changeSearchSelect={changeSearchSelect}
            onSelectClose={onSelectClose}
            onScroll={onScroll}
            {...other}
          />
        </Fragment>
      );
    })}
  </div>
);

export default InputList;
