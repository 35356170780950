import { Endpoints } from "@/api/endpoints";

import $api from "../api";

export const EmployeeService = {
  async fetchEmployees({
    officeId,
    query,
  }: {
    officeId: number;
    query: string;
  }): Promise<Response.EmployeeResponse | { errors: ErrorObject[] }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/employees/${query}`);
    return res.data;
  },
  async fetchEmployee({
    officeId,
    employeeId,
  }: {
    officeId: number;
    employeeId: number;
  }): Promise<Model.Employee | { errors: ErrorObject[] } | { error: ErrorResponse }> {
    const res = await $api.get(`${Endpoints.NOTARIES}${officeId}/employees/${employeeId}/`);
    return res.data;
  },
  async createEmployee({
    id,
    data,
  }: {
    id: number;
    data: RequestEmployee;
  }): Promise<Model.Employee | { errors: ErrorObject[] }> {
    const { role, status, supervisor, ...other } = data;
    const newStatus = String(status) === "" ? false : String(data.status) === "true";

    const sendData = {
      isActive: newStatus,
      role: String(role) === "" ? 1 : Number(data.role),
      supervisor: supervisor === undefined ? supervisor : Number(supervisor),
      ...other,
    };

    const res = await $api.post<Model.Employee | { errors: ErrorObject[] }>(
      `${Endpoints.NOTARIES}${id}/employees/`,
      sendData,
    );
    return res.data;
  },
  async updateImage({
    officeId,
    employeeId,
    data,
  }: {
    officeId: number;
    employeeId: number;
    data: FormData;
  }): Promise<{ image: string } | { errors: ErrorObject[] }> {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await $api.patch<{ image: string } | { errors: ErrorObject[] }>(
      `${Endpoints.NOTARIES}${officeId}/employees/${employeeId}/`,
      data,
      config,
    );
    return res.data;
  },
  async updateEmployee({
    officeId,
    employeeId,
    data,
  }: {
    officeId: number;
    employeeId: number;
    data: Model.Employee;
  }): Promise<Model.Employee | { errors: ErrorObject[] }> {
    const { status, role, supervisor, ...other } = data;

    let newStatus;

    if (typeof status !== "string" && status !== null) {
      newStatus = status.type;
    } else {
      newStatus = status;
    }
    const sendData = {
      isActive: newStatus,
      role: Number(role),
      supervisor: Number(supervisor),
      ...other,
    };

    const res = await $api.put<Model.Employee | { errors: ErrorObject[] }>(
      `${Endpoints.NOTARIES}${officeId}/employees/${employeeId}/`,
      sendData,
    );
    return res.data;
  },
};
