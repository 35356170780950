import { Listbox, Transition } from "@headlessui/react";
import cn from "classnames";
import { Fragment, useEffect, useState, useRef, type FC } from "react";

import { ArrowIcon, ArrowUp2Icon } from "@/assets/icons";
import { Search } from "@/blocks/index";
import { useClickOutside } from "@/shared/hooks";

import styles from "./Select.module.scss";

const SelectWithSearch: FC<SelectSearch> = ({
  options,
  onSelect,
  defaultValue,
  className,
  size,
  label,
  id,
  field,
  hasLabelMark,
  unactive,
  helperText,
  invalid,
  withDots,
  changeSearchSelect,
  onClose,
  placeholder,
}) => {
  const [selected, setSelected] = useState<Option | null>(defaultValue || null);
  const [isInvalid, setIsInvalid] = useState(invalid ? invalid : false);
  const [errorMessage, setErrorMessage] = useState(helperText ? helperText : "");
  const [showSelect, setShowSelect] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (invalid !== isInvalid && invalid !== undefined) {
      setIsInvalid(invalid);
    }
  }, [invalid]);

  useEffect(() => {
    if (helperText && helperText !== errorMessage) {
      setErrorMessage(helperText);
    }
    if (helperText && helperText.length > 0) {
      setIsInvalid(true);
    }
  }, [helperText]);

  useEffect(() => {
    if (defaultValue !== selected) {
      defaultValue && setSelected(defaultValue);
    }
  }, [defaultValue]);

  const changeHandle = (option: Option) => {
    setSelected(option);
    onSelect({ value: option.value, field, id: Number(id) ?? 0, suggestion: option.suggestion });
  };

  const hideSelect = () => {
    setShowSelect(false);
    onClose();
  };

  const searchHandle: InputOnChange = (val) => {
    changeSearchSelect(val.value);
  };

  useClickOutside({ wrapper, onClose: hideSelect, isShow: showSelect });

  const selectClasses = cn(
    styles.selectWrap,
    className && styles[className],
    unactive && styles.unactive,
    className && className,
  );

  return (
    <div className={selectClasses}>
      {label && (
        <label
          htmlFor={`id_${id ? Number(id) : "select"}`}
          className={cn(styles.label, hasLabelMark && styles.hasLabelMark)}>
          {label}
        </label>
      )}
      <Listbox value={selected} onChange={changeHandle}>
        {({ open }) => (
          <div className={cn(styles.select)} ref={wrapper}>
            <Listbox.Button
              className={cn(styles.btn, size && styles[size], { [styles.active]: open, [styles.withDots]: withDots })}
              onClick={() => {
                setShowSelect(true);
              }}>
              {withDots ? (
                <span className={cn(styles.priority, selected && styles[`priority${selected?.value}`])}></span>
              ) : null}
              <span className={cn({ [styles.placeholder]: !selected?.name })}> {selected?.name || placeholder}</span>
              {withDots ? <ArrowUp2Icon /> : <ArrowIcon />}
            </Listbox.Button>

            <Transition
              show={showSelect}
              as={Fragment}
              enter="transition duration-500 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-500 opacity-100"
              leave="transition duration-750 ease-out"
              leaveFrom="transform scale-500 opacity-100"
              leaveTo="transform scale-95 opacity-0">
              <div className={styles.selectListWr}>
                <Search placeholder="Тип" className={styles.search} onChange={searchHandle} />
                <Listbox.Options className={styles.selectList} static={true}>
                  {options &&
                    options
                      ?.filter((el) => el.value !== "")
                      .map((option: Option, index) => (
                        <Listbox.Option
                          key={`${option.value}-${index}`}
                          className={styles.selectItem}
                          value={option}
                          onClick={hideSelect}>
                          {withDots ? (
                            <span className={cn(styles.priority, option && styles[`priority${option?.value}`])}></span>
                          ) : null}
                          {option.name}
                        </Listbox.Option>
                      ))}
                </Listbox.Options>
              </div>
            </Transition>
          </div>
        )}
      </Listbox>
      {isInvalid && <span className={styles.errorText}> {errorMessage}</span>}
    </div>
  );
};

export default SelectWithSearch;
