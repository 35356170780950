import cn from "classnames";
import { memo, useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group"; //@ts-ignore
import useWebSocket from "react-use-websocket";

import { baseWssUrl } from "@/api/index";
import { BellIcon, CloseIcon, ScalesIcon, PaperIcon, GroupIcon, CaseIcon } from "@/assets/icons";
import { getDecodedOfficeId, getTokensFromStorage } from "@/helpers/index";
import { formatDate } from "@/helpers/utils";
import { useClickOutside } from "@/hooks/useClickOutside";
import { useUser } from "@/store/hooks";
import { useGetNotificationsMutation } from "@/store/services/auth.query";
import { Button, IconButton, Loader } from "@/ui/index";

import styles from "./Notifications.module.scss";

export const Notifications = ({ iconStyle }: { iconStyle?: string }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [notifications, setNotifications] = useState<Model.Notification[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const token = getTokensFromStorage();
  const officeId = getDecodedOfficeId(String(token?.access));
  const [fetchNotifications, fetchNotificationsState] = useGetNotificationsMutation();
  useClickOutside({ isShow: showDropdown, onClose: closeDropdown, wrapper: dropdownRef });
  const container = useRef<HTMLDivElement>(null);
  const containerWr = useRef<HTMLDivElement>(null);
  const user = useUser();
  const [socketUrl, setSocketUrl] = useState<string | null>(
    user?.role && (user.role[0].codeName || user.role[0].code_name) ? `${baseWssUrl}/detail/token=${token?.access}` : null,
  );

  const { sendJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("WebSocket connection opened.");
    },
    onClose: () => {
      console.log("WebSocket connection closed.");
    },
    shouldReconnect: (closeEvent: any) => {
      console.log("WebSocket connection reconected.", closeEvent);
      return true;
    },
    onMessage: (event: WebSocketEventMap["message"]) => processMessages(event),
  });

  const сhangeSocketUrl = useCallback((codeName?: string) => {
    setSocketUrl(codeName ? `${baseWssUrl}/detail/${codeName}/?token=${token?.access}` : null);
  }, []);

  useEffect(() => {
    if (fetchNotificationsState.isSuccess && fetchNotificationsState.data && !fetchNotificationsState.isLoading) {
      const response = fetchNotificationsState.data as Response.NotificationsResponse;

      setNotifications([...notifications, ...response.results]);

      setPagination({
        count: response.count,
        page: 1,
        previous: response.previous,
        next: response.next,
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    }
  }, [fetchNotificationsState]);

  useEffect(() => {
    if (user && user?.id) {
      setNotifications([]);
      fetchNotifications("");
      сhangeSocketUrl(user?.role && (user.role[0].codeName || user.role[0].code_name));
      setIsLoading(true);
    } else {
      сhangeSocketUrl();
    }
  }, [user?.id]);

  const dropdownHandle = () => {
    if (showDropdown) {
      closeDropdown();
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  function processMessages(event: { data: string }) {
    const response = JSON.parse(event.data);

    if (response.result) {
      setNotifications([]);
      fetchNotifications("");
      setIsLoading(true);
    }
  }

  function closeDropdown() {
    setShowDropdown(false);
  }

  const deleteNotice = (id: number) => {
    const config = {
      type: "readMessage",
      notificationId: id,
    };
    sendJsonMessage(config);
  };

  const readAllHandler = () => {
    const config = {
      type: "readAll",
    };
    sendJsonMessage(config);
  };

  const getIcon = (type: string) => {
    let icon;
    switch (type) {
      case "app":
        icon = <PaperIcon />;
        break;
      case "office":
        icon = <ScalesIcon />;
        break;
      case "client":
        icon = <GroupIcon />;
        break;
      case "employee":
        icon = <CaseIcon />;
        break;
    }
    return icon;
  };

  const modalScrolled = () => {
    if (container.current && containerWr.current) {
      const height = containerWr.current.offsetHeight;
      const screenHeight = container.current.offsetHeight;
      const scrolled = container.current.scrollTop;
      const threshold = height - screenHeight / 4;
      const position = scrolled + screenHeight;

      if (position >= threshold && pagination.next && !fetchNotificationsState.isLoading) {
        const searchStr = pagination.next.split("?").at(-1);

        if (searchStr) {
          fetchNotifications(`?${searchStr}`);
        }
      }
    }
  };

  return (
    <>
      <div
        className={cn(iconStyle, styles.notice, {
          [styles.show]: showDropdown,
          [styles.active]: pagination.count > 0,
        })}
        onClick={dropdownHandle}>
        <BellIcon />
      </div>
      <TransitionGroup component={null}>
        {showDropdown && (
          <CSSTransition key="dropdown" timeout={250} classNames="fade">
            <div ref={dropdownRef} className={styles.dropdown}>
              <div className={styles.wrapper}>
                <div className={styles.header}>
                  <div className={styles.title}>
                    Уведомления <span>({pagination.count})</span>
                  </div>
                  <div className={styles.close} onClick={closeDropdown}>
                    <CloseIcon />
                  </div>
                </div>
                <div className={styles.scrollbar} ref={container} onScroll={modalScrolled}>
                  <SwitchTransition>
                    <CSSTransition key={isLoading ? "loader" : "content"} timeout={250} classNames="fade">
                      {isLoading ? (
                        <div className={styles.loader}>
                          <Loader />
                        </div>
                      ) : (
                        <SwitchTransition>
                          <CSSTransition key={notifications.length > 0 ? "list" : "empty"} timeout={250} classNames="fade">
                            {notifications.length > 0 ? (
                              <div className={styles.list} ref={containerWr}>
                                <TransitionGroup component={null}>
                                  {notifications.map(({ id, message, date, accompaniment }) => (
                                    <CSSTransition key={id} timeout={250} classNames="fade">
                                      <div className={styles.item}>
                                        <Link
                                          className={styles.link}
                                          to={`/${accompaniment.type === "app" ? "applications/" : "offices/"}${accompaniment.type === "office" ? `${accompaniment.id}` : officeId}${accompaniment.type === "office" ? "" : `/${accompaniment.type === "app" ? "application" : `${accompaniment.type}s`}/${accompaniment.id}`}`}
                                        />
                                        <div className={styles.wrap}>
                                          <div className={styles.left}>
                                            <div className={styles.icon}>{getIcon(accompaniment.type)}</div>
                                            <div className={styles.content}>
                                              <div className={styles.message}>
                                                {message}{" "}
                                                {accompaniment.status && <span>{`"${accompaniment.status.name}"`}</span>}
                                              </div>
                                              <div className={styles.date}>{formatDate(date, true)}</div>
                                            </div>
                                          </div>
                                        </div>
                                        <IconButton
                                          icon={<CloseIcon />}
                                          className={styles.button}
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            deleteNotice(id);
                                          }}
                                        />
                                      </div>
                                    </CSSTransition>
                                  ))}
                                </TransitionGroup>
                              </div>
                            ) : (
                              <div className={styles.empty}>Уведомления отсутствуют</div>
                            )}
                          </CSSTransition>
                        </SwitchTransition>
                      )}
                    </CSSTransition>
                  </SwitchTransition>
                </div>
                <Button size="small" variant="white" className={styles.readAll} onClick={readAllHandler}>
                  Прочитать все
                </Button>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default memo(Notifications);
