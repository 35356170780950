import { useNavigate, useParams } from "react-router-dom";

import { PlusIcon } from "@/assets/icons";
import { Sort } from "@/blocks/index";
import { Button } from "@/ui/index";

import styles from "./KanbanList.module.scss";

const KanbanList = ({ actions }: { actions: Option[] }) => {
  const navigate = useNavigate();
  const { officeId, id } = useParams();

  return (
    <div className={styles.kanban}>
      <div className={styles.contentHead}>
        <Sort options={actions} className={styles.sort} />
        <Button variant="primary" size="small" onClick={() => navigate(`/applications/${officeId || id}/add-application`)}>
          <PlusIcon /> Создать заявку
        </Button>
      </div>
      <div className={styles.kanbanList}>{/* {applications.map((el)=><KanbanCard name={el.name} />)} */}</div>
    </div>
  );
};

export default KanbanList;
