import type { FC } from "react";

import cn from "classnames";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import styles from "./TimePicker.module.scss";

const TimePicker: FC<TimePicker> = ({
  id,
  type,
  label,
  required,
  value,
  onChange,
  onFocus,
  disabled,
  placeholder,
  className,
  inputSize,
  field,
  invalid,
  helperText,
  name,
  pattern,
  autoComplete,
  readOnly,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(invalid ? invalid : false);
  const [errorMessage, setErrorMessage] = useState(helperText ? helperText : "");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    if (value === "" && isDirty && required && !disabled) {
      setIsInvalid(true);
      setErrorMessage("Это обязательное поле");
    } else {
      setIsInvalid(false);
      setErrorMessage("");
    }
  }, [value]);

  useEffect(() => {
    if (invalid !== isInvalid && invalid !== undefined) {
      setIsInvalid(invalid);
      if (helperText && helperText.length > 0) {
        setErrorMessage(helperText);
      }
    }
  }, [invalid]);

  useEffect(() => {
    if (helperText && helperText !== errorMessage) {
      setErrorMessage(helperText);
    }
    if (helperText && helperText.length > 0) {
      setIsInvalid(true);
    }
  }, [helperText]);

  const blurhandle = () => {
    setIsDirty(true);
  };

  const changeValue = (event: { target: { name: string; value: string; id: string | number } }) => {
    const { name, value, id } = event.target;
    setCurrentValue(value);
    setIsInvalid(false);
    onChange({ field: name, value, id });
  };

  const inputClasses = cn(
    styles.wrap,
    disabled && styles.disabled,
    isInvalid && styles.error,
    inputSize && styles[inputSize],
  );

  const inputWrapClasses = cn(
    styles.input,
    className && styles[className],
    className && className,
    inputSize && styles[inputSize],
  );

  return (
    <div className={inputWrapClasses} data-is-error={isInvalid}>
      <div className={inputClasses}>
        <InputMask
          mask="99:99"
          name={field ? field : name}
          value={currentValue}
          onChange={changeValue}
          onBlur={blurhandle}
          onFocus={onFocus}
          maskChar={null}
          placeholder={placeholder}
          className={styles.field}
          required={required}
          disabled={disabled}
        />
      </div>
      <TransitionGroup component={null}>
        {isInvalid && (
          <CSSTransition key="notooltip" timeout={300} classNames="fade">
            <div>
              <span className={styles.errorText} title={errorMessage}>
                {errorMessage}
              </span>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default TimePicker;
