import { Helmet } from "react-helmet-async";

import { ErrorResult } from "@/blocks/index";

import styles from "./PageNotFound.module.scss";

const PageNotFound = () => {
  return (
    <div className={styles.pageNotFound} id="pageNotFound">
      <Helmet>
        <title>Страница не найдена</title>
      </Helmet>
      <ErrorResult />
      <p className={styles.bottom}>© 2023. Все права защищены</p>
    </div>
  );
};

export default PageNotFound;
