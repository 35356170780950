import cn from "classnames";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SwitchTransition, TransitionGroup, CSSTransition } from "react-transition-group";

import { clientOptions, filtersArray } from "@/blocks/Clients/mockData";
import { ListHead, EmptyState, ClientCard, ErrorResult } from "@/blocks/index";
import { PAGE_LIMIT } from "@/shared/constants";
import { useActions, useAppSelector, useUserPermissions } from "@/store/hooks";
import { useFetchClientsQuery } from "@/store/services/client.query";
import { Loader, Pagination, Tab } from "@/ui/index";

import styles from "./Clients.module.scss";

const Clients = () => {
  const { officeId, id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters] = useState(filtersArray);
  const [isError, setIsError] = useState<number | null>(null);
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [currentTab, setCurrentTab] = useState<string>(queryParams.get("position") || "individualPerson");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const permissions = useUserPermissions();
  const { office } = useAppSelector((state) => state.office);
  const { clients } = useAppSelector((state) => state.client);

  const ordering = queryParams.get("ordering");

  const fetchedClients = useFetchClientsQuery({
    officeId: Number(officeId) || Number(id),
    query: `?position=${currentTab}&page=${currentPage}${ordering ? `&ordering=${ordering}` : ""}${
      searchQuery.length > 0 ? `&search=${searchQuery}` : ""
    }`,
  });

  const { setClients } = useActions();

  useEffect(() => {
    if (fetchedClients.isSuccess && fetchedClients.data) {
      const fetch = fetchedClients.data as Response.ClientsResponse;

      setClients(fetch.results);
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
    }

    if (fetchedClients.error) {
      if ((fetchedClients.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchedClients.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchedClients.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchedClients]);

  useLayoutEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
    setCurrentTab(queryParams.get("position") || "individualPerson");
  }, [search]);

  const changeHandle: InputOnChange = (val) => {
    setSearchQuery(val.value.toString());

    if (currentPage > 1) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={styles.clients}>
      <ListHead
        title="Клиенты"
        count={office.clients}
        options={clientOptions}
        placeholder="ФИО, ИНН, телефон или Email"
        button={{ href: `/offices/${office.id}/add-client`, children: "Добавить клиента" }}
        onChange={(val) => changeHandle(val)}>
        <div className={styles.clientsTabs}>
          {filters.map((tab, idx) => (
            <Tab
              key={idx}
              active={currentTab}
              link={`/offices/${office.id}/clients?position=${tab.value}`}
              value={tab.value}
              name={tab.name}
              count={0}
            />
          ))}
        </div>
      </ListHead>
      <SwitchTransition>
        <CSSTransition key={fetchedClients.isFetching ? "loader" : "content"} timeout={250} classNames="fade">
          {fetchedClients.isFetching ? (
            <div className={styles.loaderWrap}>
              <Loader />
            </div>
          ) : (
            <>
              <div className={styles.clientList} id="accordionExample">
                <SwitchTransition>
                  <CSSTransition key={isError ? "error" : "content"} timeout={250} classNames="fade">
                    {isError ? (
                      <ErrorResult code={isError} />
                    ) : (
                      <>
                        <SwitchTransition>
                          <CSSTransition key={clients?.length > 0 ? "list" : "empty"} timeout={250} classNames="fade">
                            {clients?.length > 0 ? (
                              <>
                                <TransitionGroup component={null}>
                                  {clients?.map((value) => (
                                    <CSSTransition key={value.id} classNames="slide" timeout={300} unmountOnExit>
                                      <ClientCard {...value} noOverflow canEdit={permissions?.customUpdateClient} />
                                    </CSSTransition>
                                  ))}
                                </TransitionGroup>
                                {pagination.count > PAGE_LIMIT && (
                                  <div className={cn("office__container", styles.pagination)}>
                                    <Pagination
                                      totalPages={Math.ceil(pagination.count / PAGE_LIMIT)}
                                      currentPage={currentPage}
                                      previous={pagination.previous}
                                      next={pagination.next}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <EmptyState text="клиентов" />
                            )}
                          </CSSTransition>
                        </SwitchTransition>
                      </>
                    )}
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Clients;
