import cn from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { ArrowLeftIcon, ArrowRightIcon } from "@/assets/icons";
import usePagination from "@/hooks/usePagination";

import styles from "./Pagination.module.scss";

export interface UsePaginationItem {
  onClick: React.ReactEventHandler;
  type: "page" | "first" | "last" | "next" | "previous" | "start-ellipsis" | "end-ellipsis";
  page: number | null;
  selected: boolean;
  disabled: boolean;
}

const Pagination: React.FC<Model.Pagination> = ({ currentPage, totalPages, scrollTo = 150 }) => {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const getPageLink = (page: number) => {
    queryParams.set("page", page.toString());
    return `${pathname}?${queryParams.toString()}`;
  };

  const scrollTop = () => {
    window.scrollTo({ top: scrollTo, behavior: "smooth" });
  };

  const { items } = usePagination({ page: currentPage, count: totalPages });

  return (
    <nav className={styles.pagination}>
      <ul className={styles.list}>
        {items.map((item) => (
          <>
            {item.type === "page" && (
              <li key={item.page} className={cn(styles.item, { [styles.active]: item.selected })}>
                <Link to={getPageLink(Number(item.page))} onClick={scrollTop}>
                  {item.page}
                </Link>
              </li>
            )}
            {item.type === "previous" && (
              <li key={item.type + item.page} className={cn(styles.item, styles.nav, { [styles.disabled]: item.disabled })}>
                <Link to={getPageLink(item.page)} onClick={scrollTop}>
                  <ArrowLeftIcon />
                </Link>
              </li>
            )}
            {(item.type === "end-ellipsis" || item.type === "start-ellipsis") && (
              <li key={item.type} className={cn(styles.item, styles.nav)}>
                ...
              </li>
            )}
            {item.type === "next" && (
              <li key={item.type + item.page} className={cn(styles.item, styles.nav, { [styles.disabled]: item.disabled })}>
                <Link to={getPageLink(item.page)} onClick={scrollTop}>
                  <ArrowRightIcon />
                </Link>
              </li>
            )}
          </>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
