import { memo } from "react";

import { EditIcon } from "@/assets/icons";
import { AddressBlock } from "@/blocks/AddressBlock";
import { Info } from "@/blocks/Info";
import { Status, Button } from "@/ui/index";

import styles from "./OfficeCard.module.scss";
import OfficeNumbers from "./OfficeNumbers";

const OfficeCard = ({
  phoneNumber,
  email,
  address,
  officeNumber,
  createdDate,
  id,
  districtFullName,
  status,
  applications,
  employees,
  clients,
  chamberName,
  routerState,
}: {
  address: AddressModel.Address;
  routerState?: object;
} & Omit<Office.Card, "address">) => {
  return (
    <div className={styles.officeCard}>
      <div className={styles.top}>
        <div className={styles.content}>
          <Info officeNumber={officeNumber} createdDate={createdDate} districtFullName={districtFullName} />
          <h4>
            {chamberName}
            <Status size="big" type={status.type} name={status.name} />
          </h4>
          <div className="officeCard__content_bottom">
            <OfficeNumbers applications={applications} employees={employees} clients={clients} />
          </div>
        </div>
        <Button variant="secondary" size="small" href={`/offices/${id}`} routerState={routerState}>
          <EditIcon />
          Редактировать
        </Button>
      </div>
      <AddressBlock phone={phoneNumber} email={email} address={address} />
    </div>
  );
};

export default memo(OfficeCard);
