import cn from "classnames";
import { Helmet } from "react-helmet-async";

import { PlusIcon } from "@/assets/icons";
import { Sort, Search } from "@/blocks/index";
import { useUserPermissions } from "@/store/hooks";
import { Button } from "@/ui/index";

import styles from "./ListHead.module.scss";

const ListHead: Blocks.ListHead = ({
  title,
  count,
  options,
  button,
  onChange,
  children,
  paramName = "ordering",
  additionalFilters,
  placeholder = "ФИО, телефон или Email",
  className,
}) => {
  const permissions = useUserPermissions();

  return (
    <div className={styles.listHead}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h4 className={styles.title}>
        {title} <span>{count}</span>
      </h4>
      {children}
      <div className={cn(styles.filter, className)}>
        <Search placeholder={placeholder} onChange={onChange} className={cn({ [styles.search]: additionalFilters })} />
        <div className={styles.filterLeft}>
          {additionalFilters}
          <Sort options={options} className={styles.select} paramName={paramName} />
          {button && permissions?.customCreateClient && (
            <Button type="button" variant="primary" size="small" href={button.href} className={styles.button}>
              <PlusIcon />
              {button.children}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListHead;
