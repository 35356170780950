export const getAppicationStatusNameById = (id: number) => {
  let text = "";

  switch (id) {
    case 1:
      text = "Отдать в работу";
      break;
    case 2:
      text = "Готов к оформлению";
      break;
    case 5:
      text = "Подписать";
      break;
    case 6:
      text = "Завершена";
      break;
    case 7:
      text = "Переместить в архив";
      break;
    default:
      text = "";
      break;
  }

  return text;
};
