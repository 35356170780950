import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";

import {
  ClientBlick,
  AddEditClientForm,
  LoadDocuments,
  ClientCardSimple,
  ClientMeet,
  ApplicationRepresentativeForm,
  ApplicationLegalRepresentativeForm,
} from "@/blocks/index";
import { useCreateApplicationClientMutation, useChangeApplicationClientMutation } from "@/store/services/application.query";

import styles from "./ApplicationClientForm.module.scss";

type FuncData = (formData: FormData, clientId?: number | string, isNew?: boolean) => void;

const ApplicationClientForm: Blocks.ApplicationClientForm = ({
  deleteHandle,
  formId,
  member,
  clientBlockText,
  memberTypeId,
  permissions,
  status,
}) => {
  const { officeId, applicationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [currentMember, setCurrentMember] = useState(member);
  const [errors, setErrors] = useState<ErrorObject[] | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCreatedClient, setIsCreatedClient] = useState<boolean>(false);
  const [createdClient, setCreatedClient] = useState({ name: "", phoneNumber: "", email: "", id: "" });
  const backLink = `/applications/${officeId}/application/${applicationId}`;
  const [createApplicationClient, createdApplicationClient] = useCreateApplicationClientMutation();
  const [changeApplicationClient, changedApplicationClient] = useChangeApplicationClientMutation();

  useEffect(() => {
    if (member) {
      const { client, isAccepted } = member as Model.ApplicationMember;
      setCreatedClient({
        name: String(client?.name),
        phoneNumber: String(client?.phoneNumber),
        email: String(client?.email),
        id: String(client?.id),
      });
      setCurrentMember({
        ...member,
        client: {
          ...member.client,
          personPosition:
            member.client?.personPosition === undefined
              ? {
                  position: "individualPerson",
                  name: "Физ. лицо",
                }
              : member.client.personPosition,
        },
      });

      setIsCreatedClient(isAccepted);
    }
  }, [member]);

  useEffect(() => {
    if (createdApplicationClient.isError && createdApplicationClient.error) {
      setErrors(createdApplicationClient.error as ErrorObject[]);
    }
    if (createdApplicationClient.isSuccess && createdApplicationClient.data) {
      enqueueSnackbar("Клиент для заявки создан", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setIsSuccess(true);
      setIsCreatedClient(true);
      if (deleteHandle) deleteHandle(formId);
    }
  }, [createdApplicationClient]);

  useEffect(() => {
    if (changedApplicationClient.isError && changedApplicationClient.error) {
      setErrors(changedApplicationClient.error as ErrorObject[]);
    }
    if (changedApplicationClient.isSuccess && changedApplicationClient.data) {
      enqueueSnackbar("Данные клиента успешно изменены", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setIsCreatedClient(true);
      setIsSuccess(true);
    }
  }, [changedApplicationClient]);

  const sendData: FuncData = (formData, clientId) => {
    setErrors(null);
    if (member?.id) {
      changeApplicationClient({
        data: formData,
        officeId: Number(officeId),
        applicationId: Number(applicationId),
        clientId,
        memberId: Number(member.id),
      });
    } else {
      createApplicationClient({
        data: formData,
        officeId: Number(officeId),
        applicationId: Number(applicationId),
        clientId,
        memberType: memberTypeId,
      });
    }
  };

  const handleEdit = () => {
    setIsCreatedClient(false);
  };

  const onReset = () => {
    setTimeout(() => {
      setIsCreatedClient(true);
      setErrors(null);
    }, 250);
  };

  const changePosition = (position: string) => {
    if (currentMember) {
      setCurrentMember({
        ...currentMember,
        client: {
          ...currentMember.client,
          personPosition: { name: currentMember.client?.personPosition?.name || "", position },
        },
      });
    }
  };

  return (
    <div className={`${styles.applicationClientForm} ${isCreatedClient ? null : styles.clickedClient}`}>
      <ClientBlick text={clientBlockText} deleteHandle={deleteHandle} formId={formId} />
      {currentMember?.client && (
        <>
          <SwitchTransition>
            <CSSTransition key={isCreatedClient ? "card" : "form"} classNames="fade" timeout={250}>
              {isCreatedClient ? (
                <div className={styles.createdClient}>
                  <ClientCardSimple
                    className={styles.createdClientCard}
                    handleEdit={permissions?.editMember ? handleEdit : undefined}
                    name={createdClient.name}
                    phoneNumber={createdClient.phoneNumber}
                    email={createdClient.email}
                    id={createdClient.id}
                    target="_blank"
                    routerState={{ prev: backLink }}
                  />
                  <ClientMeet
                    checked={currentMember.personalMeeting}
                    officeId={String(officeId)}
                    applicationId={String(applicationId)}
                    memberId={currentMember.id}
                  />
                </div>
              ) : (
                <AddEditClientForm
                  senData={(data, memeberType, selectedClient) =>
                    sendData(data, selectedClient || currentMember.client?.id)
                  }
                  isLoading={createdApplicationClient.isLoading}
                  isSuccess={isSuccess}
                  errors={errors}
                  onReset={onReset}
                  client={currentMember.client}
                  isEditForm={true}
                  formId={`formId_${currentMember.id}`}
                  changePosition={changePosition}
                  inApp
                />
              )}
            </CSSTransition>
          </SwitchTransition>
          {currentMember.client.personPosition?.position === "legalPerson" && (
            <ApplicationRepresentativeForm
              isEdit={permissions?.editMember}
              editMember={currentMember.representative}
              clientId={Number(currentMember.client.id)}
              officeId={String(officeId)}
              applicationId={String(applicationId)}
              memberId={currentMember.id}
              backLink={backLink}
            />
          )}
          {currentMember.client.personPosition?.position === "individualPerson" &&
            currentMember.client.legalRepresentative && (
              <ApplicationLegalRepresentativeForm // @ts-ignore
                linkedPerson={currentMember.client.legalRepresentative}
                backLink={backLink}
              />
            )}
        </>
      )}
      <TransitionGroup component={null}>
        {(currentMember?.client === null || currentMember?.client === undefined) && (
          <CSSTransition key="form" classNames="fade" timeout={250}>
            <AddEditClientForm
              senData={(formData, memeberType, selectedClient) => sendData(formData, selectedClient)}
              isLoading={createdApplicationClient.isLoading}
              isSuccess={isSuccess}
              errors={errors}
              onReset={() => deleteHandle && deleteHandle(formId)}
              formId={`formid_${formId}`}
              inApp
              hasReset
            />
          </CSSTransition>
        )}
        {currentMember && (
          <CSSTransition key="document" classNames="fade" timeout={250}>
            <LoadDocuments
              memberId={currentMember.id}
              isEdit={permissions?.loadDocuments}
              name={currentMember.client?.name}
              downloadAll={status?.id !== 1}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default ApplicationClientForm;
