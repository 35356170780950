// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes ClientMeet_spin-anim__K2g2v {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ClientMeet_fadeIn__cquxJ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ClientMeet_fadeOut__DNabG {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes ClientMeet_pulse__i366s {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.ClientMeet_clientMeet__6IwO9 {
  display: flex;
  padding: 32px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 86px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/ClientMeet/ClientMeet.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACI,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;AA8BJ","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.clientMeet {\n    display: flex;\n    padding: 32px;\n    justify-content: space-between;\n    align-items: center;\n    gap: 8px;\n    height: 86px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientMeet": `ClientMeet_clientMeet__6IwO9`,
	"spin-anim": `ClientMeet_spin-anim__K2g2v`,
	"fadeIn": `ClientMeet_fadeIn__cquxJ`,
	"fadeOut": `ClientMeet_fadeOut__DNabG`,
	"pulse": `ClientMeet_pulse__i366s`
};
export default ___CSS_LOADER_EXPORT___;
