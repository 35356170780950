import cn from "classnames";
import { Link } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import styles from "./IconButton.module.scss";

const IconButton: IconButton = ({ disabled, icon, href, onClick, loading, className }) => {
  return (
    <>
      {href ? (
        <Link to={href} className={cn(styles.btn, className)}>
          {icon}
        </Link>
      ) : (
        <button type="button" disabled={disabled || loading} className={cn(styles.btn, className)} onClick={onClick}>
          <SwitchTransition mode="out-in">
            <CSSTransition key={loading ? "load" : "icon"} timeout={300} classNames="fade">
              {loading ? <div className={styles.loader} /> : icon}
            </CSSTransition>
          </SwitchTransition>
        </button>
      )}
    </>
  );
};

export default IconButton;
