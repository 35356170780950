import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useUpdateClientMutation } from "@/store/services/client.query";

import AddEditClientForm from "../AddEditClientForm/AddEditClientForm";

const UpdateClient: Blocks.UpdateClient = ({ client, setIsEdit, onSaved }) => {
  const { officeId, id } = useParams();
  const { pathname } = useLocation();
  const lastItem = pathname.split("/").at(-1);
  const [errors, setErrors] = useState<ErrorObject[] | null>(null);

  const [updateClient, updatedClient] = useUpdateClientMutation();

  const hideCollapse = () => {
    document.querySelector(`#collapse${client.id}`)?.removeAttribute("data-te-collapse-show");

    const button = document.querySelector(`#button-collapse${client.id}`);

    if (button) {
      button.setAttribute("data-te-collapse-collapsed", "");
      button.setAttribute("aria-expanded", "false");
    }

    setIsEdit(false);
  };

  useEffect(() => {
    if (updatedClient.isSuccess && updatedClient.data) {
      if (lastItem === "clients") {
        document.querySelector(`#card${client.id}`)?.scrollIntoView({
          behavior: "smooth",
        });
      } else {
        // navigate(`/offices/${officeId}/clients/${Number(client.id)}`);
      }

      enqueueSnackbar("Изменение успешно сохранилось", {
        variant: "success",
        autoHideDuration: 3000,
      });

      if (onSaved) onSaved(updatedClient.data as Model.ClientResponse);

      hideCollapse();
    }

    if (updatedClient.isError) {
      if ((updatedClient.error as ErrorResponse).data?.errors) {
        const {
          data: { errors },
        } = updatedClient.error as ErrorResponse;

        if (errors[0].attr !== "nonFieldErrors") {
          setErrors(errors);
        } else if (errors[0].attr === "nonFieldErrors" || errors[0].attr === "birthCertificate") {
          enqueueSnackbar(errors[0].detail, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } else if (updatedClient.error as ErrorResponse) {
        enqueueSnackbar("При выполнении запроса на сервер что-то пошло не так. Попробуйте снова", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
  }, [updatedClient]);

  const senData = (formData: FormData) => {
    setErrors(null);
    updateClient({
      officeId: Number(officeId) || Number(id),
      clientId: Number(client?.id),
      data: formData,
    });
  };

  const onReset = () => {
    document.querySelector(`#card${client.id}`)?.scrollIntoView({
      behavior: "smooth",
    });
    hideCollapse();
    setTimeout(() => {
      setErrors(null);
    }, 250);
  };
  return (
    <>
      <AddEditClientForm
        senData={senData}
        isLoading={updatedClient.isLoading}
        isSuccess={updatedClient.isSuccess}
        errors={errors}
        client={client}
        onReset={onReset}
        isEditForm={true}
        formId={`formId_${Number(client.id)}`}
      />
    </>
  );
};

export default UpdateClient;
