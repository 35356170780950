import { Endpoints } from "@/api/endpoints";

import $api from "../api";

export const UserService = {
  async getUser(): Promise<Model.User | { errors: ErrorObject[] }> {
    const res = await $api.get<Model.User & { error: ErrorObject }>(`${Endpoints.GET_USER}`);
    return res.data;
  },

  async updateUser(data: Model.UpdateUser): Promise<Model.User | { errors: ErrorObject[] }> {
    const res = await $api.put<Model.User & { error: ErrorObject }>(`${Endpoints.GET_USER}`, data);
    return res.data;
  },

  async getUserPermissions(): Promise<Model.User | { errors: ErrorObject[] }> {
    const res = await $api.get<Model.User & { error: ErrorObject }>(`${Endpoints.GET_USER}permissions/`);
    return res.data;
  },
  async getUserNotifications(query: string): Promise<Response.NotificationsResponse | { errors: ErrorObject[] }> {
    const res = await $api.get<Response.NotificationsResponse & { error: ErrorObject }>(
      `${Endpoints.GET_USER}notifications/${query}`,
    );
    return res.data;
  },
};
