export const inputs = [
  {
    id: 1,
    value: "",
    field: "transferPermission",
    type: "radioGroup",
    defaultValue: "withRight",
    options: [
      { name: "Без права передоверия", value: 1, id: "withoutRight" },
      { name: "С правом передоверия", value: 0, id: "withRight" },
    ],
  },
  {
    id: 4,
    field: "termApplication",
    label: "Срок доверенности",
    placeholder: "Установите срок доверенности",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
  },
];
