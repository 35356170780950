// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes EmployeeModal_spin-anim__wN2ZW {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes EmployeeModal_fadeIn__99\\+2g {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes EmployeeModal_fadeOut__Tb4-f {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes EmployeeModal_pulse__SUlSX {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.EmployeeModal_modalInner__y3XZP {
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  min-height: 652px;
  margin-top: 64px;
}
.EmployeeModal_modalInner__y3XZP > form {
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
}

.EmployeeModal_modal__5HLZg {
  padding: 64px 32px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/EmployeeModal/EmployeeModal.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACC,WAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AA8BD;AA7BC;EACC,kBAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;EACA,2BAAA;AA+BF;;AA5BA;EACC,kBAAA;AA+BD","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.modalInner {\n\twidth: 100%;\n\tdisplay: flex;\n\tposition: relative;\n\theight: 100%;\n\tmin-height: 652px;\n\tmargin-top: 64px;\n\t&>form {\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 50%;\n\t\tright: 50%;\n\t\ttransform: translateX(-50%);\n\t}\n}\n.modal {\n\tpadding: 64px 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalInner": `EmployeeModal_modalInner__y3XZP`,
	"modal": `EmployeeModal_modal__5HLZg`,
	"spin-anim": `EmployeeModal_spin-anim__wN2ZW`,
	"fadeIn": `EmployeeModal_fadeIn__99+2g`,
	"fadeOut": `EmployeeModal_fadeOut__Tb4-f`,
	"pulse": `EmployeeModal_pulse__SUlSX`
};
export default ___CSS_LOADER_EXPORT___;
