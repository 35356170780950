export const infoData = [
  { id: 1, key: "Телефон", value: "phoneNumber" },
  { id: 2, key: "Почта", value: "email" },
  { id: 3, key: "Номер конторы", value: "officeId" },
  { id: 4, key: "Должность", value: "jobTitle" },
  { id: 1, key: "Отдел", value: "department" },
  { id: 2, key: "Роль", value: "role" },
  { id: 3, key: "Руководитель", value: "supervisor" },
  { id: 4, key: "Тел.  руководителя", value: "supervisorPhone" },
];
