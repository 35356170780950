import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { PlusIcon } from "@/assets/icons";
import { executors, types, priorities } from "@/blocks/Applications/mockdata";
import { Sort, Search, ApplicationCard, EmptyResult, EmptyState, ErrorResult } from "@/blocks/index";
import { PAGE_LIMIT } from "@/shared/constants";
import { useUserPermissions } from "@/store/hooks";
import { Button, Pagination, Loader } from "@/ui/index";

import styles from "./ApplicationList.module.scss";

const ApplicationList = ({
  actions,
  setSearchQuery,
  paginationProps,
  statuses,
  loading,
  applications,
}: {
  actions: Option[];
  statuses: Option[];
  setSearchQuery: (val: string) => void;
  paginationProps: PaginationProps;
  loading: boolean;
  applications: Model.ApplicationCard[];
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { officeId, id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const permissions = useUserPermissions();
  const [isError, setIsError] = useState<number | null>(null);

  useEffect(() => {
    setIsError(permissions?.customListApplications ? null : 403);
  }, [permissions?.customListApplications]);

  const changeHandle: InputOnChange = (val) => {
    const value = val.value.toString();
    setSearchQuery(value);
    setCurrentSearch(value);

    if (paginationProps.page > 1) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={styles.content}>
      <SwitchTransition>
        <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
          {isError ? (
            <ErrorResult code={isError} />
          ) : (
            <>
              <div className={styles.filter}>
                <div className={styles.filters}>
                  <Search
                    placeholder="Номер заявки, исполнитель, клиент или дата создания"
                    className={styles.search}
                    onChange={changeHandle}
                  />
                  {permissions?.customCreateApplications && (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => navigate(`/applications/${officeId || id}/add-application`)}>
                      <PlusIcon /> Создать заявку
                    </Button>
                  )}
                </div>
                <div className={styles.filters}>
                  <Sort options={statuses} className={styles.sort} paramName="status" />
                  <Sort options={actions} className={styles.sort} paramName="action" />
                  <Sort options={executors} className={styles.sort} paramName="executor" />
                  <Sort options={types} className={styles.sort} paramName="type" />
                  <Sort options={priorities} className={styles.sort} paramName="priority" />
                </div>
              </div>
              <SwitchTransition>
                <CSSTransition key={loading ? "loader" : "content"} timeout={250} classNames="fade">
                  {loading ? (
                    <div className={styles.loaderWrap}>
                      <Loader />
                    </div>
                  ) : (
                    <SwitchTransition>
                      <CSSTransition key={applications?.length > 0 ? "list" : "empty"} timeout={250} classNames="fade">
                        {applications.length > 0 ? (
                          <>
                            <div className={styles.list}>
                              {applications.map((el) => (
                                <ApplicationCard {...el} key={el.id} />
                              ))}
                            </div>
                            {paginationProps.count > PAGE_LIMIT && (
                              <div className={`office__container ${styles.pagination} `}>
                                <Pagination
                                  totalPages={Math.ceil(paginationProps.count / PAGE_LIMIT)}
                                  currentPage={paginationProps.page}
                                  previous={paginationProps.previous}
                                  next={paginationProps.next}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={styles.emptyWrap}>
                            {currentSearch.length > 0 || search.length > 0 ? (
                              <EmptyResult text="По указанным параметрам нет данных" />
                            ) : (
                              <EmptyState text="заявок" />
                            )}
                          </div>
                        )}
                      </CSSTransition>
                    </SwitchTransition>
                  )}
                </CSSTransition>
              </SwitchTransition>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default ApplicationList;
