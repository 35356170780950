import cn from "classnames";
import { useState, useEffect, type FC } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ApplicationCard, ErrorResult, EmptyState, ListHead, Search, Sort } from "@/blocks/index";
import { PAGE_LIMIT } from "@/shared/constants";
import { useAppSelector, useActions } from "@/store/hooks";
import { useFetchApplicationStatusesQuery } from "@/store/services/action.query";
import { useFetchApplicationsQuery, useGetApplicationActionsQuery } from "@/store/services/application.query";
import { Pagination } from "@/ui/index";
import { Loader } from "@/ui/Loader";

import styles from "./Applications.module.scss";
import { executors, types, priorities } from "./mockdata";

const Applications: FC<{ smallCard?: boolean; scrollTo?: number; target?: string }> = ({ smallCard, scrollTo, target }) => {
  const { officeId, id, clientId, employeeId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [actions, setActions] = useState<Option[]>([
    { name: "Нотариальное действие", value: "" },
    { name: "Все действия", value: "undefined" },
  ]);
  const [statuses, setStatuses] = useState<Option[]>([
    { name: "Статус", value: "" },
    { name: "Все статусы", value: "undefined" },
  ]);
  const [isError, setIsError] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { applicationCount } = useAppSelector((state) => state.application);
  const [applications, setApplications] = useState<Model.ApplicationCard[]>([]);
  const { setCount, setTotalCount, setAppicationCount } = useActions();
  const backLink = `/offices/${officeId || id}/${
    clientId ? `clients/${clientId}` : employeeId ? `employees/${employeeId}` : "applications"
  }/${search}`;

  const action = queryParams.get("action");
  const status = queryParams.get("status");
  const executor = queryParams.get("executor");
  const type = queryParams.get("type");
  const priority = queryParams.get("priority");

  const fetchedApplications = useFetchApplicationsQuery(
    {
      officeId: Number(officeId) || Number(id),
      clientId: Number(clientId),
      employeeId: Number(employeeId),
      query:
        employeeId || clientId
          ? `?page=${currentPage}${searchQuery.length > 0 ? `&search=${searchQuery}` : ""}${
              action?.length ? `&action=${action}` : ""
            }${status?.length ? `&status=${status}` : ""}${executor?.length ? `&executor=${executor}` : ""}${
              type?.length ? `&type=${type}` : ""
            }${priority?.length ? `&priority=${priority}` : ""}`
          : `${search}${searchQuery.length > 0 ? `${search.length > 0 ? "&" : "?"}search=${searchQuery}` : ""}`,
    },
    { refetchOnMountOrArgChange: true },
  );
  const fetchedActions = useGetApplicationActionsQuery(Number(officeId) || Number(id));
  const fetchStatuses = useFetchApplicationStatusesQuery(Number(officeId) || Number(id));

  useEffect(() => {
    if (fetchedActions.isSuccess && fetchedActions.data) {
      const actions = (fetchedActions.data as Model.Action[]).map((el) => ({ name: el.actionName, value: Number(el.id) }));
      setActions((prev) => [...prev, ...actions]);
    }
  }, [fetchedActions]);

  useEffect(() => {
    if (fetchStatuses.isSuccess && fetchStatuses.data) {
      const statuses = (fetchStatuses.data as Option[]).map(({ id, name }) => ({ name, value: Number(id) }));
      setStatuses((prev) => [...prev, ...statuses]);
    }
  }, [fetchStatuses]);

  useEffect(() => {
    if (fetchedApplications.isSuccess && fetchedApplications.data && !fetchedApplications.isFetching) {
      const fetch = fetchedApplications.data as Response.ApplicationsResponse;

      setApplications(fetch?.results);
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
      setCount(fetch.count);

      if (employeeId) setTotalCount(fetch.totalCount);
      if (clientId) setAppicationCount(fetch.totalCount);
    }

    if (fetchedApplications.error) {
      if ((fetchedApplications.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchedApplications.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchedApplications.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchedApplications]);

  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
  }, [search]);

  useEffect(() => {
    setIsLoading(fetchedApplications.isFetching);
  }, [fetchedApplications.isFetching]);

  const changeHandle: InputOnChange = (val) => {
    setSearchQuery(val.value.toString());

    if (currentPage > 1) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={cn(styles.root, { [styles.nosmall]: !smallCard && !employeeId })}>
      {smallCard ? (
        <ListHead
          title="Заявки"
          count={applicationCount}
          options={actions}
          paramName="action"
          onChange={changeHandle}
          placeholder="Номер заявки, исполнитель, клиент или дата создания"
          className={styles.headFilters}
          additionalFilters={
            <>
              {statuses.length > 1 && <Sort options={statuses} className={styles.sort} paramName="status" />}
              <Sort options={executors} className={styles.sort} paramName="executor" />
              <Sort options={types} className={styles.sort} paramName="type" />
              <Sort options={priorities} className={styles.sort} paramName="priority" />
            </>
          }
        />
      ) : (
        <div className={styles.header}>
          <Search
            placeholder={`Поиск по номеру заявки, ${employeeId ? "клиенту или" : "исполнителю и"} дате создания`}
            className={styles.search}
            onChange={changeHandle}
          />
          <div className={styles.filters}>
            {actions.length > 1 && <Sort options={actions} className={styles.sort} paramName="action" />}
            {statuses.length > 1 && <Sort options={statuses} className={styles.sort} paramName="status" />}
            {clientId && <Sort options={executors} className={styles.sort} paramName="executor" />}
            {<Sort options={types} className={styles.sort} paramName="type" />}
            {<Sort options={priorities} className={styles.sort} paramName="priority" />}
          </div>
        </div>
      )}
      <div className={styles.wpapper}>
        <SwitchTransition>
          <CSSTransition key={isLoading ? "loader" : "content"} timeout={250} classNames="fade">
            {isLoading ? (
              <Loader />
            ) : (
              <SwitchTransition>
                <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
                  {isError ? (
                    <ErrorResult code={isError} />
                  ) : (
                    applications && (
                      <SwitchTransition>
                        <CSSTransition key={applications.length > 0 ? "list" : "empty"} timeout={250} classNames="fade">
                          {applications.length > 0 ? (
                            <div className={styles.applicationsList}>
                              {applications?.map((value) => (
                                <ApplicationCard
                                  key={value.id}
                                  id={value.id}
                                  createdAt={value.createdAt}
                                  action={value.action}
                                  status={value.status}
                                  client={value.client}
                                  executor={value.executor}
                                  small={smallCard}
                                  routerState={{ prev: backLink }}
                                  target={target}
                                />
                              ))}

                              {pagination.count > PAGE_LIMIT && (
                                <div className={cn("office__container", styles.pagination)}>
                                  <Pagination
                                    totalPages={Math.ceil(pagination.count / PAGE_LIMIT)}
                                    currentPage={pagination.page}
                                    previous={pagination.previous}
                                    next={pagination.next}
                                    scrollTo={scrollTo}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <EmptyState text="заявок" />
                          )}
                        </CSSTransition>
                      </SwitchTransition>
                    )
                  )}
                </CSSTransition>
              </SwitchTransition>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default Applications;
