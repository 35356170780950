import { phoneMask } from "@/shared/constants";
export const infoInputsData = [
  {
    id: 1,
    field: "districtFullName",
    type: "text",
    label: "Округ нотариуса",
    placeholder: "Например «Волгодонский (городской) нотариальный округ»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 2,
    field: "districtShortName",
    type: "text",
    label: "Округ кратко",
    placeholder: "Например «Волгодонский (городской) нотариальный округ»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 3,
    field: "chamberName",
    type: "text",
    label: "Наименование конторы",
    placeholder: "Например «НОТАРИАЛЬНАЯ КОНТОРА»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 4,
    field: "fullName",
    type: "text",
    label: "ФИО нотариуса",
    placeholder: "ФИО нотариуса",
    required: true,
    value: "",
    className: "col8",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 5,
    field: "inn",
    type: "text",
    label: "ИНН",
    placeholder: "123456789123",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
    minLength: 12,
    maxLength: 12,
    pattern: "numbers",
  },
  {
    id: 6,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон нотариальной конторы",
    placeholder: phoneMask,
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    mask: phoneMask,
  },
  {
    id: 7,
    field: "license",
    type: "text",
    label: "Лицензия Нотариуса",
    placeholder: "12345678912",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    maxLength: 25,
  },
  {
    id: 8,
    field: "email",
    type: "email",
    label: "Электронная почта",
    placeholder: "Email",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
  },
];
export const addressInputsData = [
  {
    id: 1,
    field: "region",
    type: "text",
    label: "Регион / Район",
    placeholder: "Например «Московская область»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 2,
    field: "city",
    type: "text",
    label: "Город / Населённый пункт",
    placeholder: "Например «Москва»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 3,
    field: "street",
    type: "text",
    label: "Улица",
    placeholder: "Например «Большой Афанасьевский переулок»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    pattern: "russian",
  },
  {
    id: 4,
    field: "house",
    type: "text",
    label: "Дом",
    placeholder: "112/2",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
  },
  {
    id: 5,
    field: "office",
    type: "text",
    label: "Офис",
    placeholder: "52",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
  },
  {
    id: 6,
    field: "postCode",
    type: "text",
    label: "Почтовый индекс",
    placeholder: "145785",
    minLength: 6,
    maxLength: 6,
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
    mask: "999999",
  },
];

export const scheduleInputsData = [
  {
    id: 1,
    day: "Понедельник",
    field: "monday",
    available: true,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
  {
    id: 2,
    day: "Вторник",
    field: "tuesday",
    available: true,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
  {
    id: 3,
    day: "Среда",
    field: "wednesday",
    available: true,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
  {
    id: 4,
    day: "Четверг",
    field: "thirsday",
    available: true,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
  {
    id: 5,
    day: "Пятница",
    field: "friday",
    available: true,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
  {
    id: 6,
    day: "Суббота",
    field: "saturday",
    available: false,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
  {
    id: 7,
    day: "Воскресенье",
    field: "sunday",
    available: false,
    timeSlots: [
      { id: 1, value: "09:00", field: "start", required: true },
      { id: 2, value: "18:00", field: "end", required: true },
    ],
  },
];

export const initialFormData = {
  info: infoInputsData,
  notary: addressInputsData,
  fias: addressInputsData,
  notaryOffice: addressInputsData,
  schedule: scheduleInputsData,
};
