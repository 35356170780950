import { Container } from "@/layout/Container";
import { Textarea } from "@/ui/index";

import styles from "./CommentBlock.module.scss";
import ClientBlick from "../ClientBlick/ClientBlick";

const CommentBlock = ({ value, onChange }: { value: string; onChange: Textarea["onChange"] }) => {
  return (
    <Container>
      <ClientBlick text={"Комментарий"} isComment={true} />
      <div className={styles.comment}>
        <Textarea
          id={1}
          rows={3}
          field="description"
          value={value}
          onChange={onChange}
          placeholder="Необязательный комментарий"
          className={styles.input}
        />
      </div>
    </Container>
  );
};

export default CommentBlock;
