import { phoneMask } from "@/shared/constants";

export const employee = [
  {
    id: 1,
    field: "fullName",
    type: "text",
    label: "ФИО сотрудника",
    placeholder: "ФИО сотрудника",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
  },
  {
    id: 2,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон",
    placeholder: phoneMask,
    required: true,
    value: "",
    hasLabelMark: true,
    mask: phoneMask,
    className: "col6",
  },
  {
    id: 3,
    field: "role",
    label: "Роль",
    required: true,
    value: "",
    className: "col6",
    hasLabelMark: true,
  },
  {
    id: 4,
    field: "status",
    label: "Статуc",
    required: true,
    value: "true",
    className: "col6",
    hasLabelMark: true,
  },
  {
    id: 5,
    field: "jobTitle",
    type: "text",
    label: "Должность",
    placeholder: "Например «Администратор»",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col6",
  },
  {
    id: 6,
    field: "email",
    type: "email",
    label: "Электронная почта",
    placeholder: "Email",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col6",
  },
  {
    id: 7,
    field: "department",
    type: "text",
    label: "Отдел",
    placeholder: "Название отдела",
    value: "",
    className: "col6",
  },
  {
    id: 8,
    field: "supervisor",
    type: "text",
    label: "Руководитель",
    placeholder: "Выберите руководителя",
    value: "",
    className: "col6",
  },
  {
    id: 9,
    field: "supervisorPhone",
    type: "tel",
    label: "Телефон руководителя",
    placeholder: phoneMask,
    value: "",
    mask: phoneMask,
    className: "col6",
  },
];

export const statusOptions = [
  { value: "true", name: "Активный" },
  { value: "false", name: "Неактивный" },
];

export const listOfFieldsForSelfEdit = ["fullName", "phoneNumber", "email"];
