import cn from "classnames";

import { TwoManagers, TrialIcon } from "@/assets/icons";

import styles from "./EmptyState.module.scss";

const EmptyState = ({ text, isTrial, absolute }: { text: string; isTrial?: boolean; absolute?: boolean }) => {
  return (
    <div className={cn(styles.emptyState, { [styles.isTrial]: isTrial, [styles.absolute]: absolute })}>
      <div className={styles.icon}>{isTrial ? <TrialIcon /> : <TwoManagers />}</div>
      <p className={styles.text}>Нет {text}</p>
    </div>
  );
};

export default EmptyState;
