import cn from "classnames";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TEDropdown, TEDropdownToggle, TEDropdownMenu, TEDropdownItem, TERipple } from "tw-elements-react";

import { AdminExitIcon, VerticalDotsIcon } from "@/assets/icons";
import { ApplicationStatus, AssignExecutor, CancelApplication, ApplicationDocumentsList } from "@/blocks/index";
import { formatDate } from "@/helpers/utils";
import { useUpdatePriorityMutation } from "@/store/services/application.query";
import { ApplicationDate, BackLink, Complexity, Select } from "@/ui/index";

import styles from "./Application.module.scss";
import ApplicationHeadSkeleton from "./ApplicationHeadSkeleton";
import { getPriority, priorityMockdata } from "../ClientCard/mockData";

const ApplicationHead = ({
  application,
  loading,
  error,
}: {
  application: Model.ApplicationDetail;
  loading: boolean;
  error: number | null;
}) => {
  const { officeId, applicationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [priorities, setPriorities] = useState<Option[]>([]);
  const [currentBackLink, setCurrentBackLink] = useState(`/applications/${officeId}`);
  const currectPriority = application ? application.status?.priority : 0;
  const [selectedOption, setSelectedOption] = useState<Option>({ ...getPriority[currectPriority] });
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);

  const [updatePriority, updatedPriority] = useUpdatePriorityMutation();

  useEffect(() => {
    if (updatedPriority.isSuccess && updatedPriority.data) {
      enqueueSnackbar("Приоритет изменён", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [updatedPriority]);

  useEffect(() => {
    if (currectPriority) {
      setSelectedOption({ ...getPriority[currectPriority] });
      setPriorities(priorityMockdata.filter((el) => el.value !== currectPriority));
    }
  }, [currectPriority]);

  useEffect(() => {
    if (state && state.prev) {
      setCurrentBackLink(state.prev);
    } else {
      setCurrentBackLink(`/applications/${officeId}`);
    }
  }, [state]);

  const handleSelectChange = (val: { field: string; value: string | number; id: number | string }) => {
    const foundOption = priorityMockdata.find((option) => option.value === val.value);
    foundOption && setSelectedOption(foundOption);

    updatePriority({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      priority: Number(val.value),
    });
  };

  const assignPerformerHandle = () => {
    setShowModal(true);
  };

  return (
    <>
      <Helmet>
        <title>{application.action ? application.action?.name : "Заявка"}</title>
      </Helmet>
      {error === null && (
        <div className={styles.head}>
          <SwitchTransition>
            <CSSTransition key={loading ? "loader" : "content"} timeout={250} classNames="fade">
              {loading ? (
                <ApplicationHeadSkeleton />
              ) : (
                <>
                  <BackLink to={currentBackLink} />
                  <div className={styles.content}>
                    <div className={styles.date}>
                      Заявка №{application.id} от {formatDate(application.createdAt, true)}
                    </div>
                    <h4>{application.action?.name}</h4>
                    <div className={styles.statuses}>
                      {application.status && (
                        <>
                          <Complexity actionType={application.status.actionType} />
                          <ApplicationStatus status={application.status.applicationStatus} />
                          {application.status.executionDate && (
                            <ApplicationDate executionDate={application.status.executionDate} />
                          )}
                        </>
                      )}
                    </div>

                    <div className={styles.performers}>
                      <div className={styles.author}>
                        <p>Автор</p>
                        <p>{application.author}</p>
                      </div>
                      {application.executor ? (
                        <div className={styles.author}>
                          <p>Исполнитель</p>
                          <p>{application.executor.name}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <Select
                      id={application.id}
                      value={currectPriority}
                      field={"priority"}
                      options={priorities}
                      defaultValue={selectedOption}
                      onSelect={handleSelectChange}
                      className={styles.select}
                      disabled={!application.permissions?.editPriority}
                      title="Приоритет"
                    />
                    <button
                      className={styles.button}
                      type="button"
                      onClick={assignPerformerHandle}
                      disabled={!application.permissions?.editExecutionDate && !application.permissions?.editExecutor}
                      title="Добавить исполнителя и дату исполнения">
                      <AdminExitIcon />
                    </button>
                    <TEDropdown>
                      <TERipple>
                        <TEDropdownToggle className={styles.button} title="Копировать документы или отменить заявку">
                          <VerticalDotsIcon />
                        </TEDropdownToggle>
                      </TERipple>
                      <TEDropdownMenu className={styles.menu} alignment="end">
                        <TEDropdownItem className={styles.menuItem} onClick={() => setShowDocumentsModal(true)}>
                          Сформировать список необходимых документов
                        </TEDropdownItem>
                        <TEDropdownItem
                          className={cn(styles.menuItem, {
                            [styles.disabled]: !application.permissions?.changeStatus,
                          })}
                          onClick={() => setShowCancelModal(true)}>
                          Отменить заявку
                        </TEDropdownItem>
                      </TEDropdownMenu>
                    </TEDropdown>
                    {officeId && applicationId && (
                      <>
                        <ApplicationDocumentsList
                          show={showDocumentsModal}
                          setShow={setShowDocumentsModal}
                          officeId={officeId}
                          applicationId={applicationId}
                        />
                        <CancelApplication
                          show={showCancelModal}
                          setShow={setShowCancelModal}
                          officeId={officeId}
                          applicationId={applicationId}
                        />
                      </>
                    )}
                  </div>
                  <AssignExecutor
                    showModal={showModal}
                    setShowModal={setShowModal}
                    data={{
                      executionDate: application.status.executionDate,
                      executor: application.executor,
                      observer: application.observer,
                    }}
                  />
                </>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      )}
    </>
  );
};

export default ApplicationHead;
