import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

import { formatPhoneNumber, getDateString, getDefaultStatus } from "@/helpers/index";

const initialState: EmployeeSlice = {
  employees: [] as Model.Employee[],
  employee: {} as Model.Employee,
  appTotalCount: 0,
};

const EmployeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setEmployees: (state, { payload }: PayloadAction<Model.Employee[]>) => {
      state.employees = payload.map((el) => ({
        ...el,
        status: getDefaultStatus(el.status as Status),
        createdAt: getDateString(el.createdAt as unknown as Date),
      }));
    },
    setEmployee: (state, { payload }: PayloadAction<Model.Employee>) => {
      state.employee = {
        ...payload,
        status: getDefaultStatus(payload.status as Status),
        createdAt: payload.createdAt && getDateString(new Date(payload.createdAt)),
        phoneNumber: formatPhoneNumber(payload.phoneNumber),
        supervisor: payload.supervisor,
      };
    },
    setTotalCount: (state, { payload }: PayloadAction<number>) => {
      state.appTotalCount = payload;
    },
  },
});

export const { setEmployees, setEmployee, setTotalCount } = EmployeeSlice.actions;
export default EmployeeSlice.reducer;
