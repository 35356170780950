import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";
import { OfficeService } from "@/services/OfficeService";

export const officeApi = createApi({
  reducerPath: "officeApi",
  tagTypes: ["Office"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    verifyOffice: builder.mutation<Info.FormInfoResponse | { errors: ErrorObject[] }, Info.FormInfo>({
      queryFn: async (data) => {
        try {
          const res = await OfficeService.verifyOffice(data);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Office"],
    }),
    createOfficeFullInfo: builder.mutation<Office.OfficeFullResponce | { errors: ErrorObject[] }, CreateOfficeData>({
      queryFn: async (sendData) => {
        try {
          const res = await OfficeService.createOfficeFullInfo(sendData);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Office"],
    }),
    fetchOffices: builder.query<Office.OfficeResponceData | { errors: ErrorObject[] } | { error: ErrorResponse }, string>({
      queryFn: async (query) => {
        try {
          const res = await OfficeService.fetchOffices(query);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Office"],
    }),
    fetchFullInfo: builder.query<Office.OfficeFullResponce | { errors: ErrorObject[] } | { error: ErrorResponse }, number>({
      queryFn: async (id) => {
        try {
          const res = await OfficeService.fetchFullInfo(id);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Office"],
    }),
    activateOffice: builder.mutation<ActiveOffice | { errors: ErrorObject[] }, ActiveOffice>({
      queryFn: async ({ isActive, id }) => {
        try {
          const res = await OfficeService.activateOffice({ isActive, id });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Office"],
    }),
    editOfficeInfo: builder.mutation<EditOfficeInfo | { errors: ErrorObject[] }, EditOfficeInfo>({
      queryFn: async (data) => {
        try {
          const res = await OfficeService.editOfficeInfo(data);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Office"],
    }),
  }),
});

export const {
  useCreateOfficeFullInfoMutation,
  useVerifyOfficeMutation,
  useActivateOfficeMutation,
  useFetchFullInfoQuery,
  useLazyFetchFullInfoQuery,
  useFetchOfficesQuery,
  useEditOfficeInfoMutation,
  useLazyFetchOfficesQuery,
} = officeApi;
