import type { FormEvent } from "react";

import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SearchClientByPhone } from "@/blocks/index";
import { getValueFromArray } from "@/helpers/form";
import { useCreateExecutorMutation } from "@/store/services/application.query";
import { Button, Datepicker, Modal } from "@/ui/index";

import styles from "./AssignExecutor.module.scss";
import { executorData } from "./mockdata";

const AssignExecutor = ({
  showModal,
  setShowModal,
  data,
}: {
  setShowModal: (val: boolean) => void;
  showModal: boolean;
  data: {
    executionDate: Model.ExecutionDate;
    executor?: Model.ApplicationDetail["executor"];
    observer?: Model.ApplicationDetail["observer"];
  };
}) => {
  const { officeId, applicationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState<InputData[]>(executorData);
  const [selectedObserver, setSelectedObserver] = useState<Model.Client | Model.Employee | string>("");
  const [selectedExecutor, setSelectedExecutor] = useState<Model.Client | Model.Employee | string>("");
  const [createExecutor, createdExecutor] = useCreateExecutorMutation();

  useEffect(() => {
    const arr = Object.entries(data);
    const newInputProps = values.map((item) => {
      const newVal = arr.find((el) => el[0] === item.field);

      return {
        ...item, // @ts-ignore
        value: newVal ? (item.field === "executionDate" ? newVal[1]?.date : newVal[1]?.name || "") : item.value,
        invalid: false,
      };
    });

    setValues(newInputProps);

    if (data.executor) {
      // @ts-ignore
      setSelectedExecutor(data.executor);
    }
    if (data.observer) {
      // @ts-ignore
      setSelectedObserver(data.observer);
    }
  }, [data]);

  useEffect(() => {
    if (selectedExecutor !== null && typeof selectedExecutor !== "string") {
      // @ts-ignore
      const { fullName, name } = selectedExecutor as Model.Employee;
      const newInputs = values.map((el) => (el.field === "executor" ? { ...el, value: fullName || name } : el));
      setValues(newInputs);
    }
  }, [selectedExecutor]);

  useEffect(() => {
    if (selectedObserver !== null && typeof selectedObserver !== "string") {
      // @ts-ignore
      const { fullName, name } = selectedObserver as Model.Employee;
      const newInputs = values.map((el) => (el.field === "observer" ? { ...el, value: fullName || name } : el));
      setValues(newInputs);
    }
  }, [selectedObserver]);

  useEffect(() => {
    if (createdExecutor.isSuccess && createdExecutor.data) {
      enqueueSnackbar("Исполнитель успешно назначен", {
        variant: "warning",
        autoHideDuration: 3000,
      });
      setShowModal(false);
      setValues(executorData);
    }
    if (createdExecutor.isError) {
      enqueueSnackbar((createdExecutor.error as ErrorObject[])[0].detail, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [createdExecutor]);

  const submitHandle = (e: FormEvent) => {
    e.preventDefault();
    const desiredValues: Model.Executor = getValueFromArray(values);
    const { observer, executor, ...other } = desiredValues;
    const { id } = selectedExecutor as Model.Employee;

    createExecutor({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      data: { executor: Number(id), observer: (selectedObserver as Model.Employee).id, ...other },
    });
  };

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
  };

  const setSelectedClient = (suggestion: Model.Client | Model.Employee | string, field: string) => {
    if (field === "executor") {
      setSelectedExecutor(suggestion);
    } else {
      setSelectedObserver(suggestion);
    }
  };

  return (
    <Modal title="Назначить исполнителя" show={showModal} setShow={setShowModal} className={styles.modal}>
      <form onSubmit={submitHandle} className={styles.form}>
        <div className={styles.formList}>
          {values.map((input) => {
            return (
              <Fragment key={input.id}>
                {input.type === "date" ? (
                  <Datepicker {...input} onChange={changeHandle} />
                ) : (
                  <SearchClientByPhone
                    isEmployeee={true}
                    onChange={changeHandle}
                    byField="fullName"
                    setSelectedClient={(suggestion) => setSelectedClient(suggestion, input.field)}
                    {...input}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        <Button
          type="submit"
          variant="primary"
          isFullWidth
          loader={createdExecutor.isLoading}
          disabled={createdExecutor.isLoading}>
          Назначить
        </Button>
      </form>
    </Modal>
  );
};

export default AssignExecutor;
