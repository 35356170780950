import type { FC } from "react";

import cn from "classnames";

import { ComplexityIcon, ComplexityIcon1, ComplexityIcon2, ComplexityIcon3 } from "@/assets/icons";

import styles from "./Complexity.module.scss";

const Complexity: FC<{ actionType: Model.ActionType }> = ({ actionType }) => {
  let complexityIcon;

  switch (actionType?.value) {
    case "notSelected":
      complexityIcon = <ComplexityIcon />;
      break;
    case 1:
      complexityIcon = <ComplexityIcon1 />;
      break;
    case 2:
      complexityIcon = <ComplexityIcon2 />;
      break;
    case 3:
      complexityIcon = <ComplexityIcon3 />;
      break;
    default:
      complexityIcon = null;
      break;
  }

  return (
    <div className={cn(styles.complexity, styles[`type-${actionType?.value}`])}>
      {complexityIcon} {actionType?.name}
    </div>
  );
};

export default Complexity;
