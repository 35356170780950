import cn from "classnames";

import styles from "./Container.module.scss";

export const Container: Layout.Container = ({ children, overflow, className, flat }) => {
  return (
    <div className={cn(styles.wrapper, className, { [styles.overflow]: overflow, [styles.flat]: flat })}>{children}</div>
  );
};

export default Container;
