import type { ReactElement } from "react";

import { ChatIcon, DeleteIcon, UserIcon2 } from "@/assets/icons";
import { IconButton } from "@/ui/index";

import styles from "./ClientBlick.module.scss";

const ClientBlick = ({
  text,
  deleteHandle,
  formId,
  isComment,
  icon,
  element,
}: {
  text: string;
  deleteHandle?: (formId: number) => void;
  formId?: number;
  isComment?: boolean;
  icon?: ReactElement;
  element?: ReactElement;
}) => {
  const handleDeleteClick = () => {
    if (deleteHandle && formId) {
      deleteHandle(formId);
    }
  };

  return (
    <div className={styles.clientBlick}>
      <div className={`${styles.icon} ${isComment ? styles.commentIcon : ""}`}>
        {icon ? icon : isComment ? <ChatIcon /> : <UserIcon2 />}
      </div>
      <h4>{text}</h4>
      {deleteHandle && <IconButton icon={<DeleteIcon />} onClick={handleDeleteClick}></IconButton>}
      {element && element}
    </div>
  );
};

export default ClientBlick;
