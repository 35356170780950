import type { FormEvent } from "react";

import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { SearchWithSuggestions } from "@/blocks/index";
import { addedErrorOnField, getValueFromArray, updatedInputsData } from "@/helpers/form";
import { useCreateMemberMutation, useUpdateMemberMutation } from "@/store/services/client.query";
import { useCreateDocumentMutation, useUpdateDocumentMutation } from "@/store/services/document.query";
import { Button, Datepicker, InputField } from "@/ui/index";

import styles from "./AddDocumentOrMemberForm.module.scss";
import { document, member } from "./mockData";

const AddDocumentOrMemberForm: Blocks.AddDocumentOrMember = ({
  setShowModal,
  isDocument,
  memberData,
  documentData,
  isEdit,
}) => {
  const { clientId } = useParams();
  const [values, setValues] = useState<InputData[]>(isDocument ? document : member);
  const [linkedPersonId, setLinkedPersonId] = useState<number | string | null>();
  const [editMember, setEditMember] = useState<Model.Client | null>(null);

  const [createDocument, createdDocument] = useCreateDocumentMutation();
  const [updateDocument, updatedDocument] = useUpdateDocumentMutation();

  const [createMember, createdMember] = useCreateMemberMutation();
  const [updateMember, updatedMember] = useUpdateMemberMutation();

  useEffect(() => {
    if (documentData && isEdit) {
      setValues(updatedInputsData(values, documentData));
    }
  }, [documentData, isEdit]);

  useEffect(() => {
    if (memberData && isEdit) {
      memberData.linkedPerson && setLinkedPersonId(memberData.linkedPerson.id);
      setEditMember(memberData.linkedPerson);
      setValues(updatedInputsData(values, memberData));
    }
  }, [memberData, isEdit]);

  useEffect(() => {
    if (createdDocument.isSuccess && createdDocument.data) {
      setShowModal(false);
      setValues(document);
      setLinkedPersonId(null);
    }
    if (createdDocument.error) {
      setValues(addedErrorOnField(createdDocument.error as ErrorObject[], values));
    }
  }, [createdDocument]);

  useEffect(() => {
    if (updatedDocument.isSuccess && updatedDocument.data) {
      setValues(document);
      setShowModal(false);
    }
    if (updatedDocument.error) {
      setValues(addedErrorOnField(updatedDocument.error as ErrorObject[], values));
    }
  }, [updatedDocument]);

  useEffect(() => {
    if (createdMember.isSuccess && createdMember.data) {
      setValues(member);
      setLinkedPersonId(null);
      setShowModal(false);
    }
    if (createdMember.error) {
      setValues(addedErrorOnField(createdMember.error as ErrorObject[], values));
    }
  }, [createdMember]);

  useEffect(() => {
    if (updatedMember.isSuccess && updatedMember.data) {
      setShowModal(false);
      setValues(member);
    }
    if (updatedMember.error) {
      setValues(addedErrorOnField(updatedMember.error as ErrorObject[], values));
    }
  }, [updatedMember]);

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
  };

  const onReset = () => {
    isDocument ? setValues(document) : setValues(member);
    setShowModal(false);
    setLinkedPersonId(null);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const desiredDocument: Model.Document = getValueFromArray(values);
    const desiredMember: Model.Member = getValueFromArray(values);

    if (isDocument) {
      !isEdit && createDocument({ clientId: String(clientId), data: desiredDocument });

      if (isEdit && documentData) {
        updateDocument({
          clientId: String(clientId),
          data: { ...desiredDocument },
          documentId: Number(documentData.id),
        });
      }
    }

    if (!isDocument && linkedPersonId) {
      if (!isEdit) {
        createMember({
          clientId: String(clientId),
          data: { ...desiredMember, linkedPerson: linkedPersonId },
        });
      }

      if (isEdit && memberData) {
        updateMember({
          clientId: String(clientId),
          data: { ...desiredMember, linkedPerson: linkedPersonId },
          memberId: Number(memberData.id),
        });
      }
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.formList}>
        {values?.map((input, idx) => (
          <Fragment key={idx}>
            {input.type === "date" ? (
              <Datepicker {...input} onChange={changeHandle} className={input.className && styles[input.className]} />
            ) : (
              <InputField {...input} onChange={changeHandle} className={input.className && styles[input.className]} />
            )}
          </Fragment>
        ))}

        {!isDocument && (
          <>
            <h6 className={styles.headline}>добавьте физ. лицо</h6>
            <SearchWithSuggestions
              className={styles.col12}
              setLinkedPersonId={setLinkedPersonId}
              isEdit={isEdit}
              editMember={editMember}
              linkedPersonId={linkedPersonId}
              label="Выберите физ. лицо"
              placeholder="ФИО клиента"
              ageLimit={14}
            />
          </>
        )}
      </div>
      <div className={styles.buttons}>
        <Button
          type="submit"
          variant="primary"
          disabled={
            createdDocument.isLoading || updatedDocument.isLoading || createdMember.isLoading || updatedMember.isLoading
          }
          loader={
            createdDocument.isLoading || updatedDocument.isLoading || createdMember.isLoading || updatedMember.isLoading
          }>
          {isEdit ? "Сохранить" : "Добавить"}
        </Button>
        <Button type="button" variant="secondary" onClick={onReset}>
          Отменить
        </Button>
      </div>
    </form>
  );
};

export default AddDocumentOrMemberForm;
