export const priorityMockdata = [
  {
    id: 3,
    name: "Низкий",
    value: 3,
  },
  {
    id: 2,
    name: "Средний",
    value: 2,
  },
  {
    id: 1,
    name: "Высокий",
    value: 1,
  },
];

export const getPriority: Record<number, Option> = {
  1: {
    id: 1,
    name: "Высокий",
    value: 1,
  },
  2: {
    id: 2,
    name: "Средний",
    value: 2,
  },
  3: {
    id: 3,
    name: "Низкий",
    value: 3,
  },
};
