import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";
import { ActionService } from "@/services/ActionService";

export const actionApi = createApi({
  reducerPath: "actionApi",
  tagTypes: ["Action"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    fetchActions: builder.query<Model.Action[] | { errors: ErrorObject[] }, void>({
      queryFn: async () => {
        try {
          const res = await ActionService.fetchActions();
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Action"],
    }),
    fetchAction: builder.query<Model.Action | { errors: ErrorObject[] }, number>({
      queryFn: async (actionId) => {
        try {
          const res = await ActionService.fetchAction(actionId);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Action"],
    }),
    editAction: builder.mutation<
      Model.Action | { errors: ErrorObject[] },
      {
        data: Model.Action;
        actionId: number;
      }
    >({
      queryFn: async ({ data, actionId }) => {
        try {
          const res = await ActionService.editAction({ data, actionId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Action"],
    }),
    fetchActionTypes: builder.query<Option[] | { errors: ErrorObject[] }, number>({
      queryFn: async () => {
        try {
          const res = await ActionService.fetchActionTypes();
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Action"],
    }),
    fetchDocumentTypes: builder.query<Option[] | { errors: ErrorObject[] }, void>({
      queryFn: async () => {
        try {
          const res = await ActionService.fetchDocumentTypes();
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Action"],
    }),
    fetchApplicationStatuses: builder.query<Option[] | { errors: ErrorObject[] }, number>({
      queryFn: async (id) => {
        try {
          const res = await ActionService.fetchAppicationStatuses(id);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Action"],
    }),
  }),
});

export const {
  useFetchActionsQuery,
  useFetchActionQuery,
  useEditActionMutation,
  useFetchActionTypesQuery,
  useFetchDocumentTypesQuery,
  useFetchApplicationStatusesQuery,
} = actionApi;
