// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes ErrorFallBack_spin-anim__4uBlR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ErrorFallBack_fadeIn__w8sug {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ErrorFallBack_fadeOut__YrmC2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes ErrorFallBack_pulse__6euNZ {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.ErrorFallBack_errorFallback__Zg1Ew {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ErrorFallBack_errorFallback__Zg1Ew a {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/ErrorFallback/ErrorFallBack.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA5BA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AA8BF;AA5BE;EACE,mBAAA;AA8BJ","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.errorFallback {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  a {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorFallback": `ErrorFallBack_errorFallback__Zg1Ew`,
	"spin-anim": `ErrorFallBack_spin-anim__4uBlR`,
	"fadeIn": `ErrorFallBack_fadeIn__w8sug`,
	"fadeOut": `ErrorFallBack_fadeOut__YrmC2`,
	"pulse": `ErrorFallBack_pulse__6euNZ`
};
export default ___CSS_LOADER_EXPORT___;
