import type { FC } from "react";

import { InputField } from "@/ui/index";

import styles from "./CreateForms.module.scss";

const FormWrapper: FC<FormWrapper> = ({ title, values, changeHandle, subtitle, children }) => {
  return (
    <div className={styles.formWrapper}>
      {title && <h2>{title}</h2>}
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
      <div className={`${styles.inputList} ${styles.inputListAddress}`}>
        {values &&
          changeHandle &&
          values?.map(({ id, ...other }) => <InputField id={id} key={id} {...other} onChange={changeHandle} />)}
      </div>
      {children}
    </div>
  );
};
export default FormWrapper;
