import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFetchLegalFormsQuery } from "@/store/services/client.query";

export const useLegalFormOptions = (search?: string | number) => {
  const navigate = useNavigate();

  const [legalFormOptions, setLegalFormOptions] = useState<Option[]>([]);

  const fetchLegalForms = useFetchLegalFormsQuery(search);

  useEffect(() => {
    if (fetchLegalForms.isSuccess && fetchLegalForms.data) {
      const options = (fetchLegalForms.data as Response.LegalFormsResponse)?.results.map((el) => ({
        name: el.name,
        value: el.id,
      }));

      setLegalFormOptions(options.length > 0 ? options : [{ name: "", value: "" }]);
    }

    fetchLegalForms.isError && navigate("/not-found");
  }, [fetchLegalForms]);

  return { legalFormOptions };
};

export default useLegalFormOptions;
