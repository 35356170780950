import type { FormEvent } from "react";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EmployeeForm } from "@/blocks/index";
import { updatedInputsData, addedErrorOnField, getValueFromArray } from "@/helpers/form";
import useEmployeeOptions from "@/hooks/useEmployeOptions";
import { employee } from "@/pages/AddAndEditEmployee/mockData";
import { useUpdateEmployeeMutation, useFetchEmployeeQuery } from "@/store/services/employee.query";
import { BackLink, Modal } from "@/ui/index";

import styles from "./EmployeeModal.module.scss";

const EmployeeModal = ({
  officeId,
  employeeId,
  showEmployeeModal,
  setShowModal,
}: {
  officeId: number;
  employeeId: number | null;
  showEmployeeModal: boolean;
  setShowModal: (value: boolean) => void;
}) => {
  const [values, setValues] = useState<InputData[]>(employee);
  const [selectedRole, setSelectedRole] = useState({} as Option);
  const [selectedStatus, setSelectedStatus] = useState({} as Option);
  const [loading, setLoading] = useState(true);
  const { employeeOptions } = useEmployeeOptions(officeId);

  const navigate = useNavigate();
  const [updateEmployee, updatedEmployee] = useUpdateEmployeeMutation();
  const fetchEmployee = useFetchEmployeeQuery({ officeId: Number(officeId), employeeId: Number(employeeId) });

  useEffect(() => {
    if (fetchEmployee) {
      const roles: Model.Role[] = (fetchEmployee.data as Model.Employee)?.role;
      roles && setSelectedRole({ name: roles[0].name, value: roles[0].id });

      const status = (fetchEmployee.data as Model.Employee)?.status;
      status && setSelectedStatus({ name: status.name, value: status.type });

      const newInputes = updatedInputsData(values, fetchEmployee.data as Model.Employee).map((el) =>
        roles && el.field === "role" ? { ...el, value: roles[0].id } : el,
      );
      setValues(newInputes);
      setTimeout(() => {
        setLoading(false);
      }, 150);
    }
  }, [fetchEmployee]);

  useEffect(() => {
    if (updatedEmployee.error) {
      setValues(addedErrorOnField(updatedEmployee.error as ErrorObject[], values));
    }
    updatedEmployee.isSuccess && navigate(`/offices/${officeId}/employees`);
  }, [updatedEmployee]);

  const changeHandle: InputOnChange = (newVal) => {
    const newInputProps = values.map((item) =>
      newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
    );
    setValues(newInputProps);
  };

  const addEmployeeSubmit = (e: FormEvent) => {
    e.preventDefault();
    const desiredValues: Model.Employee = getValueFromArray(values);

    updateEmployee({ officeId: Number(officeId), employeeId: Number(employeeId), data: desiredValues });
  };

  return (
    <Modal show={showEmployeeModal} setShow={setShowModal} size="xl" className={styles.modal}>
      <div className={styles.modalInner}>
        <BackLink to={`/offices/${officeId}/employees/`} />
        <EmployeeForm
          title="Редактировать данные сотрудника"
          values={values}
          changeHandle={changeHandle}
          onSubmit={addEmployeeSubmit}
          isLoading={updatedEmployee.isLoading}
          selectedRole={selectedRole}
          selectedStatus={selectedStatus}
          employeeOptions={employeeOptions}
          loading={loading}
        />
      </div>
    </Modal>
  );
};

export default EmployeeModal;
