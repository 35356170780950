import cn from "classnames";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { ClosedEyeIcon, EyeIcon, SearchIcon, WarningIcon } from "@/assets/icons";
import { declinationNumberMultiple, declinationNumberPrefix } from "@/helpers/index";

import styles from "./InputField.module.scss";

const InputField = ({
  id,
  mask,
  type,
  label,
  required,
  value,
  onChange,
  onFocus,
  disabled,
  placeholder,
  className,
  minLength,
  maxLength,
  inputSize,
  hasLabelMark,
  iconLeft,
  field,
  invalid,
  helperText,
  name,
  isErrorTooltip,
  pattern,
  maskProps,
  autoComplete,
  readOnly,
  withRecovery,
}: Input) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(invalid ? invalid : false);
  const [errorMessage, setErrorMessage] = useState(helperText ? helperText : "");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (invalid !== isInvalid && invalid !== undefined) {
      setIsInvalid(invalid);
      if (helperText && helperText.length > 0) {
        setErrorMessage(helperText);
      }
    }
  }, [invalid]);

  useEffect(() => {
    if (helperText && helperText !== errorMessage) {
      setErrorMessage(helperText);
    }
    if (helperText && helperText.length > 0) {
      setIsInvalid(true);
    }
  }, [helperText]);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    if (value === "" && isDirty && required && !disabled) {
      setIsInvalid(true);
      setErrorMessage("Это обязательное поле");
    } else {
      setIsInvalid(false);
      setErrorMessage("");
    }
  }, [value]);

  const inputClasses = cn(
    styles.wrap,
    disabled && styles.disabled,
    isInvalid && styles.error,
    inputSize && styles[inputSize],
  );

  const inputWrapClasses = cn(
    styles.input,
    className && styles[className],
    className && className,
    inputSize && styles[inputSize],
  );

  const checkValidityOfLength = (value: string) => {
    const valueLength = value.trim().replace(/\*|-| |\$/g, "").length;
    if (valueLength > 0 && (maxLength || minLength)) {
      if (maxLength && valueLength > maxLength) {
        const symbols = `${declinationNumberPrefix(maxLength)} ${declinationNumberMultiple(maxLength, ["символа", "символов"])}`;
        const errorMessage = `Поле должно содержать не более ${maxLength}${symbols}`;
        setErrorMessage(errorMessage);
        setIsInvalid(true);
      } else if (minLength && valueLength < minLength) {
        const symbols = `${declinationNumberPrefix(minLength)} ${declinationNumberMultiple(minLength, ["символа", "символов"])}`;
        const errorMessage = `Поле должно содержать не менее ${minLength}${symbols}`;
        setErrorMessage(errorMessage);
        setIsInvalid(true);
      } else {
        helperText === "" ? setErrorMessage("") : setErrorMessage(String(helperText));
        setIsInvalid(false);
      }
    }
  };

  const checkValidityOfLetters = (value: string) => {
    const russianLettersWithSpaceRegex = /^[А-Яа-яЁё\s-]*$/;
    const symbols = /[<>@#$%^&*~№}{]/;

    if (pattern === "letters" && value.trim() !== "") {
      if (!russianLettersWithSpaceRegex.test(value)) {
        setErrorMessage("Введите только буквы");
        setIsInvalid(true);
      } else if (symbols.test(value)) {
        setErrorMessage("Ввведите только разрешенные символы (знаки препинания)");
        setIsInvalid(true);
      }
    }
  };

  const checkValidityOfRussianLetters = (value: string) => {
    const russianLettersRegex = /[А-Яа-яЁё]/g;
    const latLettersRegex = /[A-Za-z]/g;
    const symbols = /[<>@#$%^&*~№}{]/;
    const symbolsN = /[<>@#$%^&*~}{]/;

    if ((pattern === "russian" || pattern === "russianN") && value.trim() !== "") {
      if (!russianLettersRegex.test(value) || latLettersRegex.test(value)) {
        setErrorMessage("Введите текст на русском");
        setIsInvalid(true);
      } else if ((pattern === "russian" && symbols.test(value)) || (pattern === "russianN" && symbolsN.test(value))) {
        setErrorMessage("Ввведите только разрешенные символы (знаки препинания)");
        setIsInvalid(true);
      }
    }
  };

  const checkValidityOfPassportCode = (value: string) => {
    const passportCodeRegex = /^\d{3}-\d{3}$/;
    if (pattern === "passportCode" && value.trim() !== "") {
      if (!passportCodeRegex.test(value)) {
        setErrorMessage("Введите правильный код подразделения");
        setIsInvalid(true);
      }
    }
  };

  const blurhandle = () => {
    checkValidityOfLength(`${value}`);
    checkValidityOfLetters(`${value}`);
    checkValidityOfPassportCode(`${value}`);
    checkValidityOfRussianLetters(`${value}`);
    setIsDirty(true);
  };

  const togglePasswordHandle = () => {
    setTogglePassword(!togglePassword);
  };

  const changeValue = (event: { target: { name: string; value: string; id: string | number } }) => {
    const { name, value, id } = event.target;
    let newVal = value;
    if (pattern === "numbers") {
      newVal = value.replace(/[^0-9]/g, "");
    }
    setCurrentValue(newVal);
    setIsInvalid(false);
    onChange({ field: name, value: newVal, id });
  };

  return (
    <div className={inputWrapClasses} data-is-error={isInvalid}>
      {label && (
        <label
          className={cn(styles.label, {
            [styles.labelMarked]: hasLabelMark || required,
            [styles.withRecovery]: withRecovery,
          })}>
          <div>{label}</div>
          {type === "password" && withRecovery && (
            <Link className={styles.labelPassword} to="/auth/password-recovery">
              Восстановить пароль
            </Link>
          )}
        </label>
      )}

      <div className={inputClasses}>
        {mask ? (
          <InputMask
            mask={mask}
            name={field ? field : name}
            value={currentValue}
            onChange={changeValue}
            onBlur={blurhandle}
            onFocus={onFocus}
            maskChar={null}
            {...maskProps}
            placeholder={placeholder}
            className={styles.field}
            required={required}
            disabled={disabled}
          />
        ) : (
          <>
            <input
              type={type === "password" || type === "confirmPassowrd" ? (togglePassword ? "text" : "password") : type}
              name={field ? field : name}
              value={currentValue}
              onChange={changeValue}
              onBlur={blurhandle}
              onFocus={onFocus}
              placeholder={placeholder}
              className={styles.field}
              minLength={minLength}
              maxLength={maxLength}
              required={required}
              disabled={disabled}
              id={id?.toString()}
              readOnly={readOnly}
              autoComplete={autoComplete}
            />
            {(type === "password" || type === "confirmPassowrd") && (
              <span onClick={togglePasswordHandle} className={styles.inputIcon}>
                {togglePassword ? <ClosedEyeIcon /> : <EyeIcon />}
              </span>
            )}
            {type === "search" && <SearchIcon className={`${iconLeft ? styles.left : styles.right}`} />}
          </>
        )}
      </div>
      <TransitionGroup component={null}>
        {isInvalid && (
          <CSSTransition key="notooltip" timeout={300} classNames="fade">
            <div className={cn({ [styles.errorTooltip]: isErrorTooltip })}>
              {isErrorTooltip && <WarningIcon />}
              <span className={isErrorTooltip ? styles.errorTooltip : styles.errorText} title={errorMessage}>
                {errorMessage}
              </span>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default InputField;
