import cn from "classnames";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import { HeadOffice, HeadOfficeSkeleton, ErrorResult } from "@/blocks/index";
import { Container, SideBar } from "@/layout/index";
import { useActions, useAppSelector, useUserIsSystemAdmin, useUserPermissions } from "@/store/hooks";
import { useFetchFullInfoQuery } from "@/store/services/office.query";
import { Loader } from "@/ui/index";

import { sidebarMenu } from "./mockdata";
import styles from "./OfficeDetail.module.scss";

const OfficeDetail = () => {
  const { pathname, search } = useLocation();
  const pesmissions = useUserPermissions();
  const [isError, setIsError] = useState<number | null>(null);
  const [shouldTransition, setShouldTransition] = useState(true);
  const [menu, setMenu] = useState<Model.MenuItem[]>([]);

  const { officeId, id } = useParams();
  const { office } = useAppSelector((state) => state.office);
  const isSystemAdmin = useUserIsSystemAdmin();

  const { data, isFetching, isSuccess, error } = useFetchFullInfoQuery(Number(officeId) || Number(id));
  const { setOffice } = useActions();

  useEffect(() => {
    setShouldTransition(
      !["?position=soleProprietorship", "?position=individualPerson", "?position=legalPerson"].includes(search),
    );
  }, [search]);

  useEffect(() => {
    setShouldTransition(true);
  }, [pathname]);

  useEffect(() => {
    setIsError(pesmissions?.customDetailNotary ? null : 403);
  }, [pesmissions?.customDetailNotary]);
  useEffect(() => {
    if (isSuccess && data) {
      setOffice(data as Office.OfficeFullResponce);
    }
    if (error) {
      if ((error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((error as ErrorResponse).status === 404 ? 404 : 500);
      }
    } else {
      setIsError(null);
    }
  }, [isSuccess, data, error]);

  useEffect(() => {
    if (office !== undefined) {
      const items: Model.MenuItem[] = [];
      sidebarMenu.map((item) => {
        const countMap: { [key: string]: number } = {
          clients: office.clients,
          employees: office.employees,
          applications: office.applications,
        };
        const path =
          item.pathName === "info" ? `/offices/${officeId || id}` : `/offices/${officeId || id}/${item.pathName}`;
        items.push({
          ...item,
          path,
          count: countMap[item.pathName],
        });

        return null;
      });
      setMenu(items);
    }
  }, [office]);

  return (
    <SwitchTransition>
      <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
        {isError ? (
          <ErrorResult code={isError} />
        ) : (
          <div className={styles.officeDetail}>
            <div
              className={cn(styles.officeDetailContainer, "container", styles.header, { [styles.admin]: isSystemAdmin })}>
              <HeadOffice
                back="/offices"
                isActive={office.isActive}
                id={office.id}
                status={office.status}
                chamberName={office.chamberName}
                number={office.officeNumber}
                dateCreated={office.createdDate}
                districtFullName={office.districtFullName}
              />
              <TransitionGroup component={null}>
                {isFetching && (
                  <CSSTransition key="skeleton" timeout={250} classNames="fade">
                    <HeadOfficeSkeleton />
                  </CSSTransition>
                )}
              </TransitionGroup>
            </div>
            <div className={cn(styles.officeDetailContainer, styles.officeDetailWrap, "container")}>
              <Container className={styles.officeContent}>
                <div className={styles.inner}>
                  <SideBar menu={menu} loading={isFetching} />
                  <div className={styles.contentWrapper}>
                    <SwitchTransition>
                      <CSSTransition key={isFetching ? "loader" : "content"} timeout={250} classNames="fade">
                        {isFetching ? (
                          <Loader />
                        ) : (
                          <>
                            {shouldTransition ? (
                              <TransitionGroup component={null}>
                                <CSSTransition key={pathname} timeout={250} classNames="fade">
                                  <Outlet />
                                </CSSTransition>
                              </TransitionGroup>
                            ) : (
                              <Outlet />
                            )}
                          </>
                        )}
                      </CSSTransition>
                    </SwitchTransition>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default OfficeDetail;
