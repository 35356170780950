import cn from "classnames";

import styles from "./Loader.module.scss";

export const Loader: Loader = ({ size }) => {
  return (
    <div className={styles.loader}>
      <div className={cn(styles.spiner, { [styles.sm]: size === "sm" })}></div>
    </div>
  );
};

export default Loader;
