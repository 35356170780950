import { useState, type FC } from "react";

import styles from "./Confirm.module.scss";
import { Button } from "../Button";
import { Modal } from "../Modal";

export const Confirm: FC<UI.Confirm> = ({ children, title, description, buttonNames = ["", ""], onConfirm }) => {
  const [show, setShow] = useState(false);

  const secondaryHandle = () => {
    setShow(false);
    onConfirm();
  };

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Modal show={show} setShow={setShow}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            {description && <div className={styles.description}>{description}</div>}
          </div>
          <div className={styles.buttons}>
            <Button onClick={() => setShow(false)}>{buttonNames[0]}</Button>
            <Button variant="secondary" onClick={secondaryHandle}>
              {buttonNames[1]}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
