export const document = [
  {
    id: 1,
    field: "name",
    type: "text",
    label: "Наименование",
    placeholder: "Наименование документа",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
  },
  {
    id: 2,
    field: "issuedBy",
    type: "text",
    label: "Кем принят / выдан",
    placeholder: "Кем принят / выдан",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col8",
  },
  {
    id: 3,
    field: "date",
    type: "date",
    label: "Дата документа",
    placeholder: "01.01.2022",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col4",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
  },
  {
    id: 4,
    field: "number",
    type: "text",
    label: "Номер",
    placeholder: "123456",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col8",
  },
  {
    id: 5,
    field: "registrationDepartment",
    type: "text",
    label: "Орган регистрации",
    placeholder: "Орган регистрации",
    value: "",
    className: "col12",
  },
  {
    id: 6,
    field: "registrationDate",
    type: "date",
    label: "Дата регистрации",
    placeholder: "01.01.2022",
    value: "",
    className: "col4",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
  },
  {
    id: 7,
    field: "registrationNumber",
    type: "text",
    label: "Номер регистрации",
    placeholder: "123456",
    value: "",
    className: "col4",
  },
];

export const member = [
  {
    id: 1,
    field: "name",
    type: "text",
    label: "Название документа на основании которого  действует",
    placeholder: "Наименование документа",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
  },
  {
    id: 2,
    field: "issuedBy",
    type: "text",
    label: "Кем принят / выдан",
    placeholder: "Кем принят / выдан",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col8",
  },
  {
    id: 3,
    field: "number",
    type: "text",
    label: "Номер документа",
    placeholder: "12345678",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col4",
  },
  {
    id: 4,
    field: "registrationDepartment",
    type: "text",
    label: "Орган регистрации",
    placeholder: "Орган регистрации",
    value: "",
    className: "col12",
  },
  {
    id: 5,
    field: "registrationDate",
    type: "date",
    label: "Дата регистрации",
    placeholder: "01.01.2022",
    value: "",
    className: "col4",
    dateProps: { disableFuture: true, limitPastMonths: 1495 },
  },
  {
    id: 6,
    field: "registrationNumber",
    type: "text",
    label: "Номер регистрации",
    placeholder: "123456",
    value: "",
    className: "col4",
  },
];
