// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes ModalSaveData_spin-anim__PSYFm {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ModalSaveData_fadeIn__y0fX5 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ModalSaveData_fadeOut__WX1Jl {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes ModalSaveData_pulse__zIeug {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.ModalSaveData_modal__hY\\+4D .ModalSaveData_buttons__5iC7W button:nth-child(1) {
  margin-bottom: 16px;
  margin-top: 10px;
}
.ModalSaveData_modal__hY\\+4D .ModalSaveData_buttons__5iC7W button,
.ModalSaveData_modal__hY\\+4D .ModalSaveData_buttons__5iC7W a {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_animations.scss","webpack://./src/components/blocks/ModalSaveData/ModalSaveData.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;ECCF;EDCA;IACE,yBAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;ADGA;EACE;IACE,UAAA;ECDF;EDGA;IACE,UAAA;ECDF;AACF;ADIA;EACE;IACE,UAAA;ECFF;EDKA;IACE,YAAA;ECHF;EDMA;IACE,UAAA;ECJF;AACF;AA1BG;EACE,mBAAA;EACA,gBAAA;AA4BL;AAzBG;;EAEE,WAAA;AA2BL","sourcesContent":["@keyframes spin-anim {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n\n  50% {\n    opacity: 0.4;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n","@import '../../../assets/css/_animations';\n@import '../../../assets/css/_colors';\n@import '../../../assets/css/_vars';\n@import '../../../assets/css/_mixins';\n@import '../../../assets/css/_flex';\n\n.modal {\n\t.buttons {\n\t\t\tbutton:nth-child(1) {\n\t\t\t\t\tmargin-bottom: 16px;\n\t\t\t\t\tmargin-top: 10px;\n\t\t\t}\n\n\t\t\tbutton,\n\t\t\ta {\n\t\t\t\t\twidth: 100%;\n\t\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `ModalSaveData_modal__hY+4D`,
	"buttons": `ModalSaveData_buttons__5iC7W`,
	"spin-anim": `ModalSaveData_spin-anim__PSYFm`,
	"fadeIn": `ModalSaveData_fadeIn__y0fX5`,
	"fadeOut": `ModalSaveData_fadeOut__WX1Jl`,
	"pulse": `ModalSaveData_pulse__zIeug`
};
export default ___CSS_LOADER_EXPORT___;
