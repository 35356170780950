import type { FC } from "react";

import { useEffect, useState } from "react";

import FormWrapper from "../FormWrapper";

const AddressForm: FC<Blocks.AddressForm> = ({ notary, fias, notaryOffice, updateFields }) => {
  const [notaryAddress, setNotaryAddress] = useState<InputData[]>(notary);
  const [fiasAddress, setFiasAddress] = useState<InputData[]>(fias);
  const [notaryOfficeAddress, setnotaryOfficeAddress] = useState<InputData[]>(notaryOffice);

  useEffect(() => {
    setNotaryAddress(notary);
    setFiasAddress(fias);
    setnotaryOfficeAddress(notaryOffice);
  }, [notary, fias, notaryOffice]);

  const addressMap: Record<
    string,
    { state: InputData[]; updateFunc: React.Dispatch<React.SetStateAction<InputData[]>>; field: string }
  > = {
    fias: {
      state: fiasAddress,
      updateFunc: setFiasAddress,
      field: "fias",
    },
    notary: {
      state: notaryAddress,
      updateFunc: setNotaryAddress,
      field: "notary",
    },
    notaryOffice: {
      state: notaryOfficeAddress,
      updateFunc: setnotaryOfficeAddress,
      field: "notaryOffice",
    },
  };
  const changeHandle = (
    newVal: {
      field: string;
      value: string | number;
      id: string | number;
    },
    addresType: string,
  ) => {
    if (addresType in addressMap) {
      const { state, updateFunc, field } = addressMap[addresType];

      const newInputProps = state.map((item) =>
        newVal.field === item.field ? { ...item, value: newVal.value, invalid: false } : item,
      );

      updateFunc(newInputProps);
      updateFields({ [field]: newInputProps });
    }
  };

  return (
    <>
      <FormWrapper
        title="Создать нотариальную контору"
        subtitle="Адрес нотариальной конторы"
        values={notaryAddress}
        changeHandle={(val) => changeHandle(val, "notary")}
      />
      <FormWrapper
        subtitle="Адрес нотариуса"
        values={notaryOfficeAddress}
        changeHandle={(val) => changeHandle(val, "notaryOffice")}
      />
      <FormWrapper
        subtitle="Адрес нотариуса (ФИАС)"
        values={fiasAddress}
        changeHandle={(val) => changeHandle(val, "fias")}
      />
    </>
  );
};

export default AddressForm;
