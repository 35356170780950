import cn from "classnames";
import { useEffect, useState, useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import { Applications, DetailHead, DetailHeadSkeleton, EmployeeInfo, ErrorResult } from "@/blocks/index";
import { Container } from "@/layout/Container";
import { useActions, useAppSelector, useUserPermissions } from "@/store/hooks";
import { useFetchEmployeeQuery } from "@/store/services/employee.query";
import { Loader } from "@/ui/index";

import styles from "./Employee.module.scss";

const Employee = () => {
  const { pathname } = useLocation();
  const { officeId, employeeId } = useParams();
  const [isError, setIsError] = useState<number | null>(null);
  const [hide, setHide] = useState(true);
  const { employee, appTotalCount } = useAppSelector((state) => state.employees);
  const fetchEmployee = useFetchEmployeeQuery(
    { officeId: Number(officeId), employeeId: Number(employeeId) },
    { refetchOnMountOrArgChange: true },
  );
  const { setEmployee } = useActions();
  const permissions = useUserPermissions();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setHide(true);
  }, [officeId, employeeId]);

  useEffect(() => {
    setIsError(permissions?.customEmployeesSection ? null : 403);
  }, [permissions?.customEmployeesSection]);

  useEffect(() => {
    if (fetchEmployee.isSuccess && fetchEmployee.data) {
      setEmployee(fetchEmployee.data as Model.Employee);
      setHide(false);
    }
    if (fetchEmployee.error) {
      if ((fetchEmployee.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchEmployee.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchEmployee.error as ErrorResponse).status || 500);
      }
    } else {
      setIsError(null);
    }
  }, [fetchEmployee]);

  return (
    <div className={cn(styles.employee, "container")}>
      <div className={styles.header}>
        {Object.keys(employee).length > 0 && (
          <>
            <DetailHead
              status={employee.status}
              name={employee.fullName}
              date={String(employee.createdAt)}
              number={Number(employee.id)}
              image={employee.image}
              id={Number(employeeId)}
              editLink={`${pathname}/edit-employee`}
              backLink={`/offices/${officeId}/employees`}
              permission={permissions?.customChangeEmployeePermission}
            />
          </>
        )}
        <TransitionGroup>
          {(hide || fetchEmployee.isFetching) && (
            <CSSTransition key="skeleton" timeout={250} classNames="fade">
              <DetailHeadSkeleton />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
      <SwitchTransition>
        <CSSTransition key={hide || fetchEmployee.isFetching ? "loader" : "content"} timeout={250} classNames="fade">
          {hide || fetchEmployee.isFetching ? (
            <Loader />
          ) : (
            <SwitchTransition>
              <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
                {isError ? (
                  <ErrorResult code={isError} />
                ) : (
                  <>
                    {Object.keys(employee).length > 0 && (
                      <EmployeeInfo
                        info={employee}
                        role={employee.role}
                        officeId={Number(officeId)}
                        supervisor={employee.supervisor as Model.Supervisor}
                      />
                    )}
                    <Container>
                      <div className={styles.filters}>
                        <p className={styles.filtersTitle}>Заявки сотрудника ({appTotalCount})</p>
                      </div>
                      <Applications />
                    </Container>
                  </>
                )}
              </CSSTransition>
            </SwitchTransition>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Employee;
