import { BagIcon, BoxIcon, UserIcon } from "@/assets/icons";
import { declinationOfNumber } from "@/helpers/index";

import styles from "./OfficeCard.module.scss";

const OfficeNumbers = ({
  applications,
  employees,
  clients,
}: {
  applications: number;
  employees: number;
  clients: number;
}) => {
  const cardNumbers = [
    {
      iconId: <BoxIcon />,
      title: `${applications} ${declinationOfNumber(applications, ["заявка", "заявки", "заявок"])} в работе`,
    },
    {
      iconId: <BagIcon />,
      title: ` ${employees} ${declinationOfNumber(employees, ["сотрудник", "сотрудника", "сотрудников"])}`,
    },
    {
      iconId: <UserIcon />,
      title: `${clients} ${declinationOfNumber(clients, ["клиент", "клиента", "клиентов"])}`,
    },
  ];
  return (
    <>
      {cardNumbers.map((el, idx) => (
        <div key={idx} className={styles.number}>
          {el.iconId}
          {el.title}
        </div>
      ))}
    </>
  );
};

export default OfficeNumbers;
