import cn from "classnames";
import { useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { declinationOfNumber } from "@/helpers/index";

import styles from "./Textarea.module.scss";

const Textarea = ({
  id,
  label,
  required,
  value,
  onChange,
  disabled,
  placeholder,
  className,
  minLength,
  maxLength,
  inputSize,
  hasLabelMark,
  field,
  invalid,
  helperText,
  name,
  isErrorTooltip,
  pattern,
  autoComplete,
  readOnly,
  rows,
}: Textarea) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(invalid ? invalid : false);
  const [errorMessage, setErrorMessage] = useState(helperText ? helperText : "");

  useEffect(() => {
    if (invalid !== isInvalid && invalid !== undefined) {
      setIsInvalid(invalid);
    }
  }, [invalid]);

  useEffect(() => {
    if (helperText && helperText !== errorMessage) {
      setErrorMessage(helperText);
    }
    if (helperText && helperText.length > 0) {
      setIsInvalid(true);
    }
  }, [helperText]);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const checkValidityOfLength = (value: string) => {
    const valueLength = value.length;
    if (valueLength > 0 && (maxLength || minLength)) {
      if (maxLength && valueLength > maxLength) {
        const symbols = declinationOfNumber(maxLength, ["символ", "символа", "символов"]);
        const errorMessage = `Поле должно содержать не более ${maxLength} ${symbols}`;
        setErrorMessage(errorMessage);
        setIsInvalid(true);
      } else if (minLength && valueLength < minLength) {
        const symbols = declinationOfNumber(minLength, ["символ", "символа", "символов"]);
        const errorMessage = `Поле должно содержать не менее ${minLength} ${symbols}`;
        setErrorMessage(errorMessage);
        setIsInvalid(true);
      } else {
        helperText === "" ? setErrorMessage("") : setErrorMessage(String(helperText));
        setIsInvalid(false);
      }
    }
  };

  const checkValidityOfRussianLetters = (value: string) => {
    const russianLettersRegex = /[А-Яа-я]/g;

    if (pattern === "russian" && value.trim() !== "") {
      if (!russianLettersRegex.test(value)) {
        setErrorMessage("Введите текст на русском");
        setIsInvalid(true);
      }
    }
  };

  const blurhandle = () => {
    checkValidityOfLength(`${value}`);
    checkValidityOfRussianLetters(`${value}`);
  };

  const changeValue = (event: { target: { name: string; value: string; id: string | number } }) => {
    const { name, value, id } = event.target;
    let newVal = value;
    if (pattern === "numbers") {
      newVal = value.replace(/[^0-9]/g, "");
    }
    setCurrentValue(newVal);
    setIsInvalid(false);
    if (onChange) onChange({ field: name, value: newVal, id });
  };

  const inputClasses = cn(
    styles.wrap,
    disabled && styles.disabled,
    isInvalid && styles.error,
    inputSize && styles[inputSize],
  );

  const inputWrapClasses = cn(
    styles.input,
    className && styles[className],
    className && className,
    inputSize && styles[inputSize],
  );

  return (
    <div className={inputWrapClasses} data-is-error={isInvalid}>
      {label && (
        <label
          className={cn(styles.label, {
            [styles.labelMarked]: hasLabelMark || required,
          })}>
          <div>{label}</div>
        </label>
      )}

      <div className={inputClasses}>
        <textarea
          name={field ? field : name}
          value={currentValue}
          onChange={changeValue}
          onBlur={blurhandle}
          placeholder={placeholder}
          className={styles.field}
          minLength={minLength}
          maxLength={maxLength}
          required={required}
          disabled={disabled}
          id={id?.toString()}
          readOnly={readOnly}
          autoComplete={autoComplete}
          rows={rows || 8}
        />
      </div>
      <TransitionGroup component={null}>
        {isInvalid && !isErrorTooltip && (
          <CSSTransition key="notooltip" timeout={300} classNames="fade">
            <span className={styles.errorText} title={errorMessage}>
              {errorMessage}
            </span>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default Textarea;
