import type { FC } from "react";

import {
  CloseCircleIcon,
  CompletedIcon,
  DeleteIcon,
  DraftIcon,
  InProgressIcon,
  MailToIcon,
  PenIcon,
  PostopnedIcon,
  RegisteredIcon,
  SuspendIcon,
  CheckedArrowIcon,
  ArchivedIcon,
  FolderIcon,
} from "@/assets/icons";

import styles from "./ApplicationStatus.module.scss";

const ApplicationStatus: FC<{ status: { id: number; name: string } }> = ({ status }) => {
  let statusIcon;

  switch (status.id) {
    case 1:
      statusIcon = <DraftIcon />;
      break;
    case 2:
    case 16:
      statusIcon = <InProgressIcon />;
      break;
    case 3:
      statusIcon = <PostopnedIcon />;
      break;
    case 4:
      statusIcon = <DeleteIcon />;
      break;
    case 5:
      statusIcon = <CheckedArrowIcon />;
      break;
    case 6:
      statusIcon = <PenIcon />;
      break;
    case 7:
      statusIcon = <CompletedIcon />;
      break;
    case 8:
      statusIcon = <ArchivedIcon />;
      break;
    case 9:
      statusIcon = <MailToIcon />;
      break;
    case 10:
      statusIcon = <SuspendIcon />;
      break;
    case 11:
      statusIcon = <CloseCircleIcon />;
      break;
    case 12:
    case 14:
      statusIcon = <RegisteredIcon />;
      break;
    case 13:
      statusIcon = <MailToIcon />;
      break;
    case 15:
      statusIcon = <FolderIcon />;
      break;
    default:
      statusIcon = null;
      break;
  }
  return (
    <div className={`${styles.statusS} ${styles[`status_${status.id}`]}`}>
      {statusIcon} <span>{status.name}</span>
    </div>
  );
};

export default ApplicationStatus;
