import { useSnackbar } from "notistack";
import { useEffect, useState, type FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";

import { ArrowLeftIcon } from "@/assets/icons";
import { Info } from "@/blocks/Info";
import { useUserIsSystemAdmin } from "@/store/hooks";
import { useActivateOfficeMutation } from "@/store/services/office.query";
import { Status, Button, Tooltip } from "@/ui/index";

import styles from "./HeadOffice.module.scss";

export const HeadOffice: FC<Blocks.HeadOffice> = ({
  id,
  status,
  back,
  chamberName,
  number,
  districtFullName,
  dateCreated,
}) => {
  const [activeOffice, officeActive] = useActivateOfficeMutation();
  const isSystemAdmin = useUserIsSystemAdmin();
  const { state } = useLocation();
  const [currentBackLink, setCurrentBackLink] = useState(back);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (officeActive.isError) {
      // @ts-ignore
      enqueueSnackbar(error?.message || "При отправке запроса возникла ошибка!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    if (officeActive.isSuccess && (officeActive.data as ActiveOffice)?.isActive === true) {
      enqueueSnackbar("Контора зарегистрирована!", {
        variant: "contora",
      });
    }
  }, [officeActive]);

  useEffect(() => {
    if (state && state.prev) {
      setCurrentBackLink(state.prev);
    } else if (currentBackLink !== back) {
      setCurrentBackLink(back);
    }
  }, [state, back]);

  const clickHandle = (type: string) => {
    const newType = type === "active";
    activeOffice({ isActive: newType, id });
  };

  return (
    <div className={styles.headOffice} id="headOffice">
      <Helmet>
        <title>{isSystemAdmin ? chamberName : "Моя контора"}</title>
      </Helmet>
      {isSystemAdmin && (
        <Link to={currentBackLink} className={styles.icon}>
          <ArrowLeftIcon />
        </Link>
      )}
      <div className={styles.col}>
        <h4 className={styles.title}>
          {isSystemAdmin ? (
            <Tooltip text={chamberName} className={styles.titleTooltip}>
              <span className={`${styles.titleText} ${status?.type === "new" ? styles.titleTextShort : null}`}>
                {chamberName}
              </span>
            </Tooltip>
          ) : (
            <span>{chamberName}</span>
          )}

          {status && <Status type={status.type} name={status.name} size="big" />}
        </h4>

        <Info
          officeNumber={number}
          createdDate={dateCreated}
          districtFullName={districtFullName}
          className={styles.info}
          noMargin
        />
      </div>
      {isSystemAdmin && (
        <div className={styles.col}>
          {(status?.type === "deactive" || status?.type === "new") && (
            <Button onClick={() => clickHandle("active")} loader={officeActive.isLoading} disabled={officeActive.isLoading}>
              Активировать
            </Button>
          )}
          {(status?.type === "active" || status?.type === "new") && (
            <Button
              variant="secondary"
              onClick={() => clickHandle("deactive")}
              loader={officeActive.isLoading}
              disabled={officeActive.isLoading}>
              {status.type === "new" ? "Не активировать" : "Деактивировать"}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default HeadOffice;
