import type { FC } from "react";

import { Link, useParams } from "react-router-dom";

import { EditIcon } from "@/assets/icons";
import { Info } from "@/blocks/Info";
import { useUserPermissions } from "@/store/hooks";
import { Status, Avatar } from "@/ui/index";

import styles from "./EmployeeCard.module.scss";
import { AddressBlock } from "../AddressBlock";

const EmployeeCard: FC<Model.Employee> = ({ id, image, createdAt, fullName, status, phoneNumber, email, role }) => {
  const { card, cardWr, cardRight, cardContent, cardInfo, cardAddress, link } = styles;
  const params = useParams();
  const permissions = useUserPermissions();

  return (
    <div className={cardWr}>
      <div className={card}>
        <Link className={link} to={`/offices/${params.id}/employees/${id}`} />
        <div className={cardContent}>
          <Avatar imageUrl={image} fullName={fullName} size="medium" />
          <div className={cardInfo}>
            <Info officeNumber={Number(id) ?? 0} createdDate={createdAt} isUser />
            <h6>
              {fullName} {status && <Status type={status.type} name={status.name} size="small" />}
            </h6>
            <AddressBlock email={email} mobilePhone={phoneNumber} role={role && role[0].name} className={cardAddress} />
          </div>
        </div>
        {permissions?.customChangeEmployeePermission && (
          <Link
            className={cardRight}
            to={`/offices/${params.id}/employees/${id}/quick-edit-employee`}
            onClick={() => window.scrollTo(0, 0)}>
            <EditIcon />
          </Link>
        )}
      </div>
    </div>
  );
};

export default EmployeeCard;
