import type { FormEvent } from "react";

import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { isEmptyObject } from "@/helpers/index";
import { statusOptions } from "@/pages/AddAndEditEmployee/mockData";
import { useActions, useAppSelector, useUserId, useUserPermissions } from "@/store/hooks";
import { Select, InputField, Button, Loader } from "@/ui/index";

import styles from "./EmployeeForm.module.scss";

const EmployeeForm = ({
  onSubmit,
  values,
  changeHandle,
  isLoading,
  onReset,
  isAdd,
  title,
  subtitle,
  selectedRole,
  selectedStatus,
  employeeOptions,
  selectedSupervisor,
  loading,
  onScroll,
}: {
  onSubmit: (e: FormEvent) => void;
  values: InputData[];
  changeHandle: InputOnChange;
  isLoading: boolean;
  onReset?: () => void;
  isAdd?: boolean;
  subtitle?: string | null;
  title: string;
  selectedRole?: Option;
  selectedStatus?: Option;
  employeeOptions: Option[];
  selectedSupervisor?: Option;
  loading: boolean;
  onScroll?: Select["onScroll"];
}) => {
  const { officeId, employeeId } = useParams();
  const userId = useUserId();
  const [roleList, setRoleList] = useState<Option[]>([]);
  const { fetchNotAdminRoles } = useActions();
  const { notAdminRoles } = useAppSelector((state) => state.roles);
  const permissions = useUserPermissions();

  useEffect(() => {
    if (!notAdminRoles && officeId) {
      fetchNotAdminRoles(Number(officeId));
    }
  }, [officeId]);

  useEffect(() => {
    if (notAdminRoles && notAdminRoles.length > 0) {
      const items: Option[] = [];
      notAdminRoles.map(({ id, name }) => items.push({ value: id.toString(), name }));
      setRoleList([...items.filter((role) => role.value !== "5")]);
    }
  }, [notAdminRoles]);

  const getDefaultValue = (field: string) => {
    if (field === "status") {
      return isEmptyObject(selectedStatus) ? selectedStatus : statusOptions[0];
    } else if (field === "role") {
      return isEmptyObject(selectedRole) ? selectedRole : roleList[0];
    } else if (field === "supervisor") {
      return isEmptyObject(selectedSupervisor) ? selectedSupervisor : employeeOptions[0];
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div>
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <SwitchTransition>
        <CSSTransition key={loading ? "loader" : "form"} timeout={250} classNames="fade">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.formList}>
                {values?.map(({ id, ...other }, index) => (
                  <Fragment key={id + index}>
                    {(roleList.length > 0 && other.field === "role") || other.field === "status" ? (
                      <Select
                        options={other.field === "role" ? roleList : statusOptions}
                        onSelect={changeHandle}
                        defaultValue={getDefaultValue(other.field) as Option}
                        size="big"
                        className={other.className && styles[other.className]}
                        value={other.value}
                        field={other.field}
                        label={other.label}
                        id={id}
                        hasLabelMark={other.hasLabelMark}
                      />
                    ) : employeeOptions.length > 0 && other.field === "supervisor" ? (
                      <Select
                        options={employeeOptions}
                        onSelect={changeHandle}
                        defaultValue={getDefaultValue(other.field) as Option}
                        size="big"
                        className={other.className && styles[other.className] && styles.employee}
                        value={other.value}
                        field={other.field}
                        label={other.label}
                        id={id}
                        onScroll={onScroll}
                      />
                    ) : (
                      <InputField
                        id={id}
                        label={employeeId === userId ? "ФИО" : "ФИО сотрудника"}
                        {...other}
                        onChange={changeHandle}
                        className={other.className && styles[other.className]}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
              <div className={styles.buttons}>
                <Button
                  type="submit"
                  variant="primary"
                  loader={isLoading}
                  disabled={
                    (!permissions?.customChangeEmployeePermission && !permissions?.customCreateEmployeePermission) ||
                    isLoading
                  }>
                  {isAdd ? "Добавить сотрудника" : "Сохранить"}
                </Button>
                <Button type="button" variant="secondary" onClick={onReset}>
                  Отменить
                </Button>
              </div>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </form>
  );
};

export default EmployeeForm;
