import cn from "classnames";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ClientCardSimple } from "@/blocks/index";
import { highlightMatch } from "@/helpers/utils";
import { useLazyFetchClientsQuery } from "@/store/services/client.query";
import { InputField } from "@/ui/index";

import styles from "./SearchWithSuggestions.module.scss";

const SearchWithSuggestions: Blocks.SearchWithSuggestions = ({
  className,
  setLinkedPersonId,
  isEdit,
  editMember,
  linkedPersonId,
  label,
  ageLimit,
  placeholder,
  setLinkedPerson,
  invalid,
  helperText,
}) => {
  const { officeId, id } = useParams();
  const [currentValue, setCurrentValue] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Model.Client | null>(null);
  const [clients, setClients] = useState<Model.Client[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [trigger, triggeredClientsData] = useLazyFetchClientsQuery();

  useEffect(() => {
    if (editMember) {
      setSelectedSuggestion(editMember);
    }
  }, [isEdit, editMember]);

  useEffect(() => {
    if (linkedPersonId === null) {
      setSelectedSuggestion(null);
      setShowSuggestions(false);
      setCurrentValue("");
    }
  }, [linkedPersonId]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const queryString = `?position=individualPerson&search=${searchQuery}${ageLimit ? `&ageLimit=${ageLimit}` : ""}`;
      trigger({ officeId: Number(officeId) || Number(id), query: queryString });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (triggeredClientsData.isSuccess && triggeredClientsData.data) {
      setClients((triggeredClientsData.data as Response.ClientsResponse)?.results);
    }
  }, [triggeredClientsData]);

  const onInputChange: InputOnChange = (newVal) => {
    setCurrentValue(newVal.value.toString());
    setShowSuggestions(true);
    setSearchQuery(newVal.value.toString().toLowerCase());
  };

  const filterSuggestions = () => {
    return clients.filter(
      (client) => String(client.name)?.toLowerCase().includes(currentValue.toLocaleLowerCase()) && showSuggestions,
    );
  };

  const handleSuggestionClick = (suggestion: Model.Client) => {
    setCurrentValue(String(suggestion.name));
    setLinkedPersonId(Number(suggestion.id));
    setShowSuggestions(false);
    setSelectedSuggestion(suggestion);
    if (setLinkedPerson) setLinkedPerson(suggestion);
  };

  const handleEdit = (name: string) => {
    setCurrentValue(name);
    setSelectedSuggestion(null);
    setShowSuggestions(false);
  };

  return (
    <div className={cn(styles.searchInput, className && className)}>
      {!selectedSuggestion && (
        <InputField
          id={7}
          value={currentValue}
          label={label}
          onChange={onInputChange}
          placeholder={placeholder}
          type="search"
          className={className}
          field="linkedPerson"
          required={true}
          invalid={invalid}
          helperText={helperText}
          hasLabelMark={true}
        />
      )}
      {showSuggestions && currentValue.trim().length > 0 && (
        <div className={styles.searchListWpapper}>
          <ul className={styles.searchList}>
            {!isEdit && filterSuggestions().length === 0 ? <li className={styles.searchItem}>Ничего не найдено</li> : null}
            {filterSuggestions().map((suggestion, index) => (
              <li
                className={styles.searchItem}
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(String(suggestion.name), currentValue, styles.highlight),
                }}
              />
            ))}
          </ul>
        </div>
      )}
      {selectedSuggestion && (
        <ClientCardSimple
          className={styles.linkedPersonCard}
          handleEdit={handleEdit}
          id={selectedSuggestion.id}
          name={String(selectedSuggestion.name)}
          phoneNumber={String(selectedSuggestion.phoneNumber)}
          email={String(selectedSuggestion.email)}
          target="_blank"
        />
      )}
    </div>
  );
};

export default SearchWithSuggestions;
