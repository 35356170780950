import { BallIcon, CircleCheckedIcon } from "@/assets/icons";

import styles from "./TitleWithIcon.module.scss";

const TitleWithIcon = ({ title, active }: { title: string; active: boolean }) => {
  return (
    <div className={styles.title}>
      {active ? <CircleCheckedIcon /> : <BallIcon />}
      <span>{title}</span>
    </div>
  );
};

export default TitleWithIcon;
