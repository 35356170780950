import cn from "classnames";

import { Container } from "@/layout/Container";

import styles from "./EmployeeInfo.module.scss";
import { infoData } from "./mockData";

type EmployeeWithoutRoleAndStatus = Omit<Model.Employee, "role" | "status" | "supervisor">;

const EmployeeInfo = ({
  info,
  role,
  officeId,
  supervisor,
}: {
  info: EmployeeWithoutRoleAndStatus;
  role: Model.Role[];
  officeId: number;
  supervisor: Model.Supervisor;
}) => {
  const getValue = (obj: ObjectType) => {
    switch (obj.value) {
      case "officeId":
        return officeId || "";
      case "role":
        if (role !== null && role.length > 0) {
          return role[0].name || "";
        }
        return "";
      case "supervisor":
        return supervisor?.fullName || "";
      default: {
        const key = obj.value as keyof EmployeeWithoutRoleAndStatus;
        const value = info[key];
        return String(value === null ? "" : value);
      }
    }
  };

  return (
    <Container>
      <ul className={cn(styles.list)}>
        {infoData.map(
          (obj) =>
            getValue(obj) !== "" && (
              <li key={obj.key} className={styles.item}>
                <p className={styles.keyStyle}>{obj.key}</p>
                <p className={styles.valueStyle}>{getValue(obj)}</p>
              </li>
            ),
        )}
      </ul>
    </Container>
  );
};

export default EmployeeInfo;
