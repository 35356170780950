export const executorData = [
  {
    id: 1,
    field: "executor",
    type: "select",
    label: "Исполнитель",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    placeholder: "Выберите исполнителя",
  },
  {
    id: 2,
    field: "executionDate",
    type: "date",
    label: "Дата исполнения",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    placeholder: "14.08.2022",
    dateProps: { disablePast: true },
  },
  {
    id: 3,
    field: "observer",
    type: "text",
    label: "Наблюдатель",
    value: "",
    className: "col12",
    placeholder: "Выберите сотрудника",
  },
];
