import { Endpoints } from "@/api/endpoints";
import { $api } from "@/api/index";

export const RolesService = {
  async fetchRoles(): Promise<Model.Role[] | Model.Error> {
    const url = Endpoints.FETCH_ROLES;
    const { data } = await $api.get(url);
    return data;
  },

  async fetchRoleDetail(id: number | string): Promise<Model.CurrentRole | Model.Error> {
    const url = `${Endpoints.FETCH_ROLES}${id}/`;
    const { data } = await $api.get(url);
    return data;
  },
  async fetchNotifications(): Promise<Model.Permission[] | Model.Error> {
    const url = Endpoints.FETCH_NOTIFICATIONS;
    const { data } = await $api.get(url);
    return data;
  },
  async fetchPermissions(): Promise<Model.Permission[] | Model.Error> {
    const url = Endpoints.FETCH_PERMISSIONS;
    const { data } = await $api.get(url);
    return data;
  },
  async createRole(params: Reducer.UpdatePermissions): Promise<Model.CurrentRole | Model.Error> {
    const url = Endpoints.FETCH_ROLES;
    const { data } = await $api.post(url, params);

    return data;
  },
  async updateRole({
    id,
    params,
  }: {
    id: number | string;
    params: Reducer.UpdatePermissions;
  }): Promise<Model.CurrentRole | Model.Error> {
    const url = `${Endpoints.FETCH_ROLES}${id}/`;
    const { data } = await $api.put(url, params);
    return data;
  },
  async removeRole(id: number | string): Promise<Model.Error> {
    const url = `${Endpoints.FETCH_ROLES}${id}/`;
    const { data } = await $api.delete(url);
    return data;
  },
  async fetchNotAdminRoles(officeId: number): Promise<Model.Role[] | Model.Error> {
    const url = `${Endpoints.NOTARIES}${officeId}/roles/`;
    const { data } = await $api.get(url);
    return data;
  },
};
