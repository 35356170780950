import cn from "classnames";
import { useSnackbar } from "notistack";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { SwitchTransition, TransitionGroup, CSSTransition } from "react-transition-group";

import { ClientCardSimple, EmptyState } from "@/blocks/index";
import { getDateString, getDefaultStatus } from "@/helpers/utils";
import { useFetchCardsQuery, useRelateCardMutation } from "@/store/services/client.query";
import { Loader } from "@/ui/index";

import styles from "./LinkCards.module.scss";
const LinkCardsModal = ({
  searchQuery,
  backLink,
  setShowModal,
}: {
  searchQuery?: string;
  backLink?: string;
  setShowModal?: (val: boolean) => void;
}) => {
  const { clientId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [querySearch, setQuerySearch] = useState(searchQuery);
  const [isRelated, setIsRelated] = useState(false);
  const [cards, setCards] = useState<Model.LinkedPerson[]>([]);
  const [relateId, setRelateId] = useState<number>();
  const [modalSearch, setModalSearch] = useState("");
  const fetchedCards = useFetchCardsQuery({
    clientId: Number(clientId),
    query:
      modalSearch.length > 0 ? `?${modalSearch}` : querySearch && querySearch.length > 0 ? `?search=${querySearch}` : "",
  });
  const [relateCard, relatedCard] = useRelateCardMutation();
  const isEmpty = cards.length === 0;
  const container = useRef<HTMLDivElement>(null);
  const containerWr = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (relatedCard.isSuccess && relatedCard.data) {
      if (relatedCard.originalArgs?.query.includes("remove")) {
        setCards(
          cards.map((item) => {
            return { ...item, related: item.id === relateId ? false : item.related };
          }),
        );
      } else if (relatedCard.originalArgs?.query.includes("add")) {
        setCards(
          cards.map((item) => {
            return { ...item, related: item.id === relateId ? true : item.related };
          }),
        );
      }
      setIsRelated(true);
    }

    if (relatedCard.error) {
      const { message } = relatedCard.error as Model.Error;
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsRelated(false);
    }
  }, [relatedCard]);

  useEffect(() => {
    if (fetchedCards.endpointName) {
      if (fetchedCards.isSuccess && fetchedCards.data && !isRelated) {
        const response = fetchedCards.data as Response.CardsResponse;
        const currentCards = querySearch && querySearch.length > 0 && modalSearch.length === 0 ? [] : cards;

        if (response.results) {
          if (JSON.stringify(currentCards) !== JSON.stringify(response.results))
            setCards([...currentCards, ...response.results]);
          setPagination({
            count: response.count,
            page: 1,
            previous: response.previous,
            next: response.next,
          });
        }
        // }
      } else if (isRelated) {
        setIsRelated(false);
      }
    }
  }, [fetchedCards]);

  useEffect(() => {
    if (searchQuery !== undefined && fetchedCards.currentData) {
      setModalSearch("");
      setCards([]);

      if (searchQuery.length === 0) {
        fetchedCards.refetch();
      }

      setQuerySearch(searchQuery);
    }
  }, [searchQuery]);

  const relateCardHandle = (id: number, relate: string) => {
    setRelateId(id);
    relateCard({ clientId: Number(clientId), query: `?${relate}=${id}` });
  };

  const modalScrolled = () => {
    if (container.current && containerWr.current) {
      const height = containerWr.current.offsetHeight;
      const screenHeight = container.current.offsetHeight;
      const scrolled = container.current.scrollTop;
      const threshold = height - screenHeight / 4;
      const position = scrolled + screenHeight;

      if (position >= threshold && pagination.next && !fetchedCards.isFetching) {
        const searchStr = pagination.next.split("?").at(-1);

        if (searchStr) {
          setModalSearch(searchStr);
        }
      }
    }
  };

  return (
    <div className={cn(styles.linkCards, styles.modalcards)} ref={container} onScroll={modalScrolled}>
      <SwitchTransition>
        <CSSTransition key={fetchedCards.isLoading ? "loader" : "content"} timeout={250} classNames="fade">
          {fetchedCards.isLoading ? (
            <Loader />
          ) : (
            <div className={styles.cardsList} ref={containerWr}>
              <SwitchTransition>
                <CSSTransition key={isEmpty ? "empty" : "content"} timeout={250} classNames="fade">
                  {isEmpty ? (
                    <EmptyState text="ни одной привязанной карточки" />
                  ) : (
                    <div>
                      <TransitionGroup component={null}>
                        {cards?.length > 0 &&
                          cards.map(({ status, createdAt, id, ...value }, index) => (
                            <CSSTransition key={`${id}-${index}`} classNames="slide" timeout={300} unmountOnExit>
                              <ClientCardSimple
                                status={getDefaultStatus(status as Status)}
                                createdAt={getDateString(createdAt as unknown as Date)}
                                {...value}
                                id={id}
                                isLink={true}
                                relateCardHandle={relateCardHandle}
                                className={styles.modalCard}
                                loading={relatedCard.isLoading && id === relateId}
                                routerState={{ prev: backLink }}
                                onFollow={() => {
                                  if (setShowModal) setShowModal(false);
                                }}
                              />
                            </CSSTransition>
                          ))}
                      </TransitionGroup>

                      <TransitionGroup component={null}>
                        {fetchedCards.isFetching && (
                          <CSSTransition key="modalLoader" classNames="fade" timeout={250}>
                            <div className={styles.modalLoader}>
                              <Loader />
                            </div>
                          </CSSTransition>
                        )}
                      </TransitionGroup>
                    </div>
                  )}
                </CSSTransition>
              </SwitchTransition>
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
export default LinkCardsModal;
