import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";
import { ClientService } from "@/services/ClientService";

export const clientApi = createApi({
  reducerPath: "clientApi",
  tagTypes: ["Client", "LinkedCards", "RelatedCards"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    fetchClients: builder.query<
      Response.ClientsResponse | { errors: ErrorObject[] },
      { officeId: number | string; query: string }
    >({
      queryFn: async ({ officeId, query }) => {
        try {
          const res = await ClientService.fetchClients({ officeId, query });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Client"],
    }),
    fetchClient: builder.query<
      Model.ClientResponse | { errors: ErrorResponse },
      { officeId: number | string; clientId: number | string }
    >({
      queryFn: async ({ officeId, clientId }) => {
        try {
          const res = await ClientService.fetchClient({ officeId, clientId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response,
          };
        }
      },
      providesTags: ["Client"],
    }),
    fetchClientDocument: builder.query<
      Model.Scan[] | { errors: ErrorResponse },
      { officeId: number | string; clientId: number | string }
    >({
      queryFn: async ({ officeId, clientId }) => {
        try {
          const res = await ClientService.fetchClientDocument({ officeId, clientId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response,
          };
        }
      },
      providesTags: ["Client"],
    }),
    createClient: builder.mutation<
      Model.Client | { errors: ErrorObject[] | ErrorResponse },
      { clientData: FormData; officeId: number | string }
    >({
      queryFn: async ({ clientData, officeId }) => {
        try {
          const res = await ClientService.createClient({
            clientData,
            officeId,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response,
          };
        }
      },
      invalidatesTags: ["Client"],
    }),
    updateClient: builder.mutation<
      Model.ClientResponse | { errors: ErrorObject[] | ErrorResponse },
      {
        officeId: number | string;
        clientId: number | string;
        data: FormData;
      }
    >({
      queryFn: async ({ officeId, clientId, data }) => {
        try {
          const res = await ClientService.updateClient({
            officeId,
            clientId,
            data,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response,
          };
        }
      },
    }),
    deleteClient: builder.mutation<
      ResponseSuccess | { error: Model.Error },
      {
        officeId: number | string;
        clientId: number | string;
      }
    >({
      queryFn: async ({ officeId, clientId }) => {
        try {
          const res = await ClientService.deleteClient({ officeId, clientId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data,
          };
        }
      },
      invalidatesTags: ["Client"],
    }),
    updatePriority: builder.mutation<
      Model.Client | { errors: ErrorObject[] },
      {
        officeId: number | string;
        clientId: number | string;
        priority: number;
      }
    >({
      queryFn: async ({ officeId, clientId, priority }) => {
        try {
          const res = await ClientService.updatePriority({
            officeId,
            clientId,
            priority,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Client"],
    }),
    fetchCards: builder.query<
      Response.CardsResponse | { errors: ErrorObject[] },
      { clientId: number | string; query: string; page?: number }
    >({
      queryFn: async ({ clientId, query }) => {
        try {
          const res = await ClientService.fetchCards({ clientId, query });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Client", "LinkedCards"],
    }),
    fetchRelatedCards: builder.query<
      Response.CardsResponse | { errors: ErrorObject[] },
      { clientId: number | string; page: number }
    >({
      queryFn: async ({ clientId, page }) => {
        try {
          const res = await ClientService.fetchCards({ clientId, query: `?related=true&page=${page}` });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Client", "RelatedCards"],
    }),
    fetchRelatedCardsUpdate: builder.mutation<
      Response.CardsResponse | { errors: ErrorObject[] },
      { clientId: number | string; page: number }
    >({
      queryFn: async ({ clientId, page }) => {
        try {
          const res = await ClientService.fetchCards({ clientId, query: `?related=true&page=${page}` });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
    relateCard: builder.mutation<ResponseSuccess | { error: Model.Error }, { query: string; clientId: number | string }>({
      queryFn: async ({ query, clientId }) => {
        try {
          const res = await ClientService.relateCard({ query, clientId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data,
          };
        }
      },
      invalidatesTags: ["RelatedCards"],
    }),
    createMember: builder.mutation<
      Model.Member | { errors: ErrorObject[] },
      {
        data: {
          linkedPerson: number | string;
        } & Omit<Model.Member, "linkedPerson">;
        clientId: number | string;
      }
    >({
      queryFn: async ({ data, clientId }) => {
        try {
          const res = await ClientService.createMember({ data, clientId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Client"],
    }),
    fetchMembers: builder.query<
      Response.MemberResponse | { errors: ErrorObject[] },
      { clientId: number | string; query: string }
    >({
      queryFn: async ({ clientId, query }) => {
        try {
          const res = await ClientService.fetchMembers({ clientId, query });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Client"],
    }),
    updateMember: builder.mutation<
      Model.Member | { errors: ErrorObject[] },
      {
        clientId: number | string;
        memberId: number;
        data: {
          linkedPerson: number | string;
        } & Omit<Model.Member, "linkedPerson">;
      }
    >({
      queryFn: async ({ clientId, memberId, data }) => {
        try {
          const res = await ClientService.updateMember({ clientId, memberId, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Client"],
    }),
    deleteMember: builder.mutation<
      ResponseSuccess | { errors: ErrorObject[] },
      {
        memberId: number;
        clientId: number | string;
      }
    >({
      queryFn: async ({ memberId, clientId }) => {
        try {
          const res = await ClientService.deleteMember({
            memberId,
            clientId,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Client"],
    }),

    fetchScan: builder.query<
      Response.ResponseScan | { errors: ErrorObject[] },
      { clientId: number | string; scanId: number }
    >({
      queryFn: async ({ clientId, scanId }) => {
        try {
          const res = await ClientService.fetchScan({ clientId, scanId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Client"],
    }),

    fetchLegalForms: builder.query<Response.LegalFormsResponse | { errors: ErrorObject[] }, string | number | undefined>({
      queryFn: async (search) => {
        try {
          const res = await ClientService.fetchLegalForms(search);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Client"],
    }),
  }),
});

export const {
  useFetchClientsQuery,
  useLazyFetchClientsQuery,
  useLazyFetchClientDocumentQuery,
  useCreateClientMutation,
  useFetchClientQuery,
  useLazyFetchClientQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useUpdatePriorityMutation,
  useLazyFetchCardsQuery,
  useCreateMemberMutation,
  useFetchMembersQuery,
  useLazyFetchMembersQuery,
  useRelateCardMutation,
  useDeleteMemberMutation,
  useUpdateMemberMutation,
  useFetchScanQuery,
  useFetchCardsQuery,
  useFetchLegalFormsQuery,
  useFetchRelatedCardsUpdateMutation,
  useFetchRelatedCardsQuery,
} = clientApi;
