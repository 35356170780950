import type { FormEvent } from "react";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { EmployeeForm, ModalSaveData } from "@/blocks/index";
import { addedErrorOnField, checkForRussianLetters, getValueFromArray, updatedInputsData } from "@/helpers/form";
import useEmployeeOptions from "@/hooks/useEmployeOptions";
import { Container } from "@/layout/Container";
import { useAppSelector, useUserId, useUserPermissions } from "@/store/hooks";
import {
  useCreateEmployeeMutation,
  useFetchEmployeeQuery,
  useUpdateEmployeeMutation,
  useUpdateUserMutation,
} from "@/store/services/employee.query";
import { useLazyFetchFullInfoQuery } from "@/store/services/office.query";
import { BackLink } from "@/ui/index";

import styles from "./AddAndEditEmployee.module.scss";
import { employee, listOfFieldsForSelfEdit } from "./mockData";

const AddAndEditEmployee = () => {
  const [showModal, setShowModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { officeId, employeeId } = useParams();
  const { pathname } = useLocation();
  const lastItem = pathname.split("/").at(-1);
  const editOrQuickEdit = lastItem === "edit-employee" || lastItem === "quick-edit-employee";
  const navigate = useNavigate();

  const [values, setValues] = useState<InputData[]>(employee);
  const [selectedRole, setSelectedRole] = useState({} as Option);
  const [selectedStatus, setSelectedStatus] = useState({} as Option);
  const [selectedSupervisor, setSelectedSupervisor] = useState({ name: "Выберите руководителя", value: "" });
  const [loading, setLoading] = useState(true);
  const permissions = useUserPermissions();
  const { isError, newInputs } = checkForRussianLetters(values);

  const { office } = useAppSelector((state) => state.office);
  const isUserId = useUserId();

  const [createEmployee, createdEmployee] = useCreateEmployeeMutation();
  const [updateEmployee, updatedEmployee] = useUpdateEmployeeMutation();
  const [updateUser, updatedUser] = useUpdateUserMutation();
  const fetchEmployee = employeeId && useFetchEmployeeQuery({ officeId: Number(officeId), employeeId: Number(employeeId) });
  const [trigger] = useLazyFetchFullInfoQuery();

  const { employeeOptions, scrollHandler } = useEmployeeOptions(Number(officeId));
  const title = editOrQuickEdit ? "Редактировать данные сотрудника" : "Добавление сотрудника";

  useEffect(() => {
    if (editOrQuickEdit && fetchEmployee) {
      const roles: Model.Role[] = (fetchEmployee.data as Model.Employee)?.role;
      roles && setSelectedRole({ name: roles[0].name, value: roles[0].id });
      const status = (fetchEmployee.data as Model.Employee)?.status;
      status && setSelectedStatus({ name: status.name, value: status.type });

      const supervisor = (fetchEmployee.data as Model.Employee)?.supervisor;
      if (supervisor && typeof supervisor !== "string") {
        setSelectedSupervisor({
          name: String(supervisor.fullName),
          value: String(supervisor.id),
        });
      }

      const newInputs = updatedInputsData(values, fetchEmployee.data as Model.Employee).map((el) =>
        roles && el.field === "role" ? { ...el, value: roles[0].id } : el,
      );
      const inputsForSelfEdit = newInputs.filter((el) => listOfFieldsForSelfEdit.includes(el.field));
      if (String(isUserId) === employeeId) {
        setValues(inputsForSelfEdit);
      } else {
        setValues(newInputs);
      }
      setTimeout(() => {
        setLoading(false);
      }, 250);
    }
    if (!editOrQuickEdit) {
      setLoading(false);
    }
  }, [editOrQuickEdit, fetchEmployee]);

  useEffect(() => {
    if (createdEmployee.error) {
      setValues(addedErrorOnField(createdEmployee.error as ErrorObject[], values));
    }

    if (createdEmployee.isSuccess) {
      createdEmployee.data && navigate(`/offices/${officeId}/employees`);
      trigger(office.id);
    }
  }, [createdEmployee]);

  useEffect(() => {
    if (updatedEmployee.error) {
      setValues(addedErrorOnField(updatedEmployee.error as ErrorObject[], values));
    }

    if (updatedEmployee.isSuccess && updatedEmployee.data) {
      enqueueSnackbar(`Данные сотрудника №${employeeId} успешно изменены!`, {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate(`/offices/${officeId}/employees/${lastItem === "edit-employee" ? employeeId : ""}`);
    }
  }, [updatedEmployee]);

  useEffect(() => {
    if (updatedUser.error) {
      setValues(addedErrorOnField(updatedUser.error as ErrorObject[], values));
    }

    if (updatedUser.isSuccess && updatedUser.data) {
      enqueueSnackbar(`Данные сотрудника №${employeeId} успешно изменены!`, {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate(`/offices/${officeId}/employees/${employeeId}`);
    }
  }, [updatedUser]);

  const changeHandle: InputOnChange = ({ value, field, suggestion }) => {
    let supervisorPhone: null | string = null;
    const newInputProps = values.map((item) => {
      let newItem = item;
      if (field === item.field) {
        if (field === "supervisor" && suggestion) {
          supervisorPhone = suggestion?.supervisorPhone;
        }
        newItem = { ...item, value, invalid: false };
      }
      if (supervisorPhone && item.field === "supervisorPhone") {
        newItem = { ...item, value: supervisorPhone, invalid: false };
      }
      return newItem;
    });
    setValues(newInputProps);
    setIsDirty(true);
  };

  const employeeSubmit = (e: FormEvent) => {
    e.preventDefault();
    setValues(newInputs);

    const desiredValues: Model.Employee = getValueFromArray(values);

    if (!isError && permissions?.customChangeEmployeePermission && editOrQuickEdit && isUserId !== Number(employeeId)) {
      const { supervisor, ...other } = desiredValues;
      const supervisorId = typeof supervisor === "object" ? String(supervisor.id) : String(supervisor);

      updateEmployee({
        officeId: Number(officeId),
        employeeId: Number(employeeId),
        data: { supervisor: supervisorId, ...other },
      });
    }

    if (!isError && editOrQuickEdit && Number(isUserId) === Number(employeeId)) {
      updateUser({ ...desiredValues });
    }

    if (!isError && lastItem === "add-employee") {
      const { supervisor, ...other } = desiredValues;
      createEmployee({ id: Number(officeId), data: supervisor ? desiredValues : other });
    }
  };

  const onReset = () => {
    if (lastItem === "add-employee") {
      navigate(`/offices/${officeId}/employees`);
      setValues(employee);
    } else {
      navigate(`/offices/${officeId}/employees/${lastItem === "edit-employee" ? employeeId : ""}`);
    }
  };

  const backToOfficeLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (isDirty) {
      setShowModal(true);
    } else {
      setShowModal(false);
      navigate(`/offices/${officeId}/employees${lastItem === "edit-employee" ? `/${employeeId}` : ""}`);
    }
  };

  return (
    <div className={`${styles.addEmployee} container`}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container overflow>
        <div className={styles.inner}>
          <BackLink
            to={`/offices/${officeId}/employees${lastItem === "edit-employee" ? `/${employeeId}` : ""}`}
            onClick={backToOfficeLink}
          />
          {!loading && (
            <EmployeeForm
              title={title}
              subtitle={editOrQuickEdit ? null : office ? office.chamberName : null}
              values={values}
              changeHandle={changeHandle}
              onSubmit={employeeSubmit}
              isAdd={!editOrQuickEdit}
              isLoading={createdEmployee.isLoading || updatedEmployee.isLoading}
              onReset={onReset}
              selectedRole={selectedRole}
              selectedStatus={selectedStatus}
              employeeOptions={employeeOptions}
              selectedSupervisor={selectedSupervisor}
              loading={loading}
              onScroll={scrollHandler}
            />
          )}
        </div>
      </Container>
      <ModalSaveData
        setShowModal={setShowModal}
        showModal={showModal}
        navigateLink={`/offices/${officeId}/employees${lastItem === "edit-employee" ? `/${employeeId}` : ""}`}
      />
    </div>
  );
};

export default AddAndEditEmployee;
