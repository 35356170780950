import type { RootState } from "..";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

const initialState: ApplicationSlice = {
  applicationCount: 0,
};

const ApplicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setCount: (state, { payload }: PayloadAction<number>) => {
      state.applicationCount = payload;
    },
  },
});
export const office = (state: RootState) => state.user;
export const { setCount } = ApplicationsSlice.actions;
export default ApplicationsSlice.reducer;
