export { ReactComponent as ArrowIcon } from "./arrow.svg";
export { ReactComponent as EyeIcon } from "./eye.svg";
export { ReactComponent as LogoIcon } from "./logo.svg";
export { ReactComponent as BellIcon } from "./bell.svg";
export { ReactComponent as CheckedArrowIcon } from "./checkedArrow.svg";
export { ReactComponent as CirclePlusIcon } from "./circlePlus.svg";
export { ReactComponent as ExitIcon } from "./exit.svg";
export { ReactComponent as EditIcon } from "./edit.svg";
export { ReactComponent as EditIcon2 } from "./editIcon.svg";
export { ReactComponent as PlusIcon } from "./plus.svg";
export { ReactComponent as SearchIcon } from "./search.svg";
export { ReactComponent as TabIcon } from "./tab.svg";
export { ReactComponent as ClosedEyeIcon } from "./closedEye.svg";
export { ReactComponent as ArrowLeftIcon } from "./arrowLeft.svg";
export { ReactComponent as ArrowLeft2Icon } from "./arrowLeft2.svg";
export { ReactComponent as ArrowRightIcon } from "./arrowRight.svg";
export { ReactComponent as BagIcon } from "./bag.svg";
export { ReactComponent as BagIcon2 } from "./bag2.svg";
export { ReactComponent as ShieldIcon } from "./shield.svg";
export { ReactComponent as PlaneIcon } from "./plane.svg";
export { ReactComponent as PaperIcon } from "./paper.svg";
export { ReactComponent as BoxIcon } from "./box.svg";
export { ReactComponent as UserIcon } from "./user.svg";
export { ReactComponent as UserIcon2 } from "./user2.svg";
export { ReactComponent as PhoneIcon } from "./phone.svg";
export { ReactComponent as PhoneIcon2 } from "./phone2.svg";
export { ReactComponent as EmailIcon } from "./email.svg";
export { ReactComponent as MapIcon } from "./map.svg";
export { ReactComponent as TimeIcon } from "./time.svg";
export { ReactComponent as TwoManagers } from "./twoManagers.svg";
export { ReactComponent as InProgressIcon } from "./inProgress.svg";
export { ReactComponent as RegisteredIcon } from "./registered.svg";
export { ReactComponent as FolderIcon } from "./folder.svg";
export { ReactComponent as SentToIcon } from "./sentTo.svg";
export { ReactComponent as PenIcon } from "./pen.svg";
export { ReactComponent as SuspendIcon } from "./suspend.svg";
export { ReactComponent as DraftIcon } from "./draft.svg";
export { ReactComponent as Draft2Icon } from "./draft2.svg";
export { ReactComponent as CompletedIcon } from "./completed.svg";
export { ReactComponent as Newspaper } from "./newspaper.svg";
export { ReactComponent as RoleIcon } from "./role.svg";
export { ReactComponent as ChevronRightIcon } from "./chevronRight.svg";
export { ReactComponent as CheckBoxIcon } from "./checkbox.svg";
export { ReactComponent as CloseIcon } from "./close.svg";
export { ReactComponent as ScalesIcon } from "./scales.svg";
export { ReactComponent as CloseCircleIcon } from "./closeCircle.svg";
export { ReactComponent as AttentionIcon } from "./attention.svg";
export { ReactComponent as CheckBoxSuccess } from "./checkboxSuccess.svg";
export { ReactComponent as ArrowUpIcon } from "./arrowUp.svg";
export { ReactComponent as ArrowUp2Icon } from "./arrowUp2.svg";
export { ReactComponent as DeleteIcon } from "./delete.svg";
export { ReactComponent as LoadIcon } from "./load.svg";
export { ReactComponent as LoadCardsIcon } from "./loadCards.svg";
export { ReactComponent as BallIcon } from "./ball.svg";
export { ReactComponent as WarningIcon } from "./warning.svg";
export { ReactComponent as Warning2Icon } from "./warning2.svg";
export { ReactComponent as FileIcon } from "./file.svg";
export { ReactComponent as DownloadIcon } from "./download.svg";
export { ReactComponent as PdfIcon } from "./pdf.svg";
export { ReactComponent as DocIcon } from "./doc.svg";
export { ReactComponent as XlsIcon } from "./xls.svg";
export { ReactComponent as Action1Icon } from "./action1.svg";
export { ReactComponent as Action2Icon } from "./action2.svg";
export { ReactComponent as Action3Icon } from "./action3.svg";
export { ReactComponent as Action4Icon } from "./action4.svg";
export { ReactComponent as Action5Icon } from "./action5.svg";
export { ReactComponent as Action6Icon } from "./action6.svg";
export { ReactComponent as LockIcon } from "./lock.svg";
export { ReactComponent as LockSmallIcon } from "./lockSmall.svg";
export { ReactComponent as UnLockIcon } from "./unLock.svg";
export { ReactComponent as Close2Icon } from "./close2.svg";
export { ReactComponent as WhatsuppIcon } from "./whatsapp.svg";
export { ReactComponent as ComplexityIcon } from "./complexity.svg";
export { ReactComponent as ComplexityIcon1 } from "./complexity1.svg";
export { ReactComponent as ComplexityIcon2 } from "./complexity2.svg";
export { ReactComponent as ComplexityIcon3 } from "./complexity3.svg";
export { ReactComponent as ArchivedIcon } from "./archived.svg";
export { ReactComponent as MailToIcon } from "./mailTo.svg";
export { ReactComponent as PostopnedIcon } from "./postponed.svg";
export { ReactComponent as CheckedCalendarIcon } from "./checkedCalendar.svg";
export { ReactComponent as AdminExitIcon } from "./adminExit.svg";
export { ReactComponent as VerticalDotsIcon } from "./verticalDots.svg";
export { ReactComponent as ReloadIcon } from "./reload.svg";
export { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";
export { ReactComponent as UserSquareIcon } from "./userSquare.svg";
export { ReactComponent as CircleCheckedIcon } from "./circleChecked.svg";
export { ReactComponent as GoogleCalendarIcon } from "./googleCalendar.svg";
export { ReactComponent as ChatIcon } from "./chat.svg";
export { ReactComponent as TrialIcon } from "./trial.svg";
export { ReactComponent as CopyIcon } from "./copy.svg";
export { ReactComponent as GroupIcon } from "./group.svg";
export { ReactComponent as CaseIcon } from "./case.svg";
export { ReactComponent as DeadlineIcon } from "./deadline.svg";
