export { setTokenFromStorage, getTokensFromStorage, clearLocalStorage } from "./localStorage";
export { getUserByJwt, getDecodedOfficeId, getDecodedUserRoles } from "./jwtDecode";
export {
  getValueFromArray,
  addedErrorOnField,
  checkForRussianLetters,
  updatedInputsData,
  updatedScheduleData,
  updateAddressInputs,
  clearValues,
  isFormFilled,
} from "./form";
export {
  getInitials,
  hasRole,
  declinationOfNumber,
  declinationNumberMultiple,
  declinationNumberPrefix,
  checkErrorStep,
  formatPhoneNumber,
  isAllDaysNotAvailable,
  getDateString,
  getDefaultStatus,
  formatTime,
  isEmptyObject,
  formatDate,
  calculateAge,
  isPassportExpired,
  formatBytes,
  highlightMatch,
} from "./utils";
export { downloadRequest } from "./downloadRequest";
export { getAppicationStatusNameById } from "./application";
